import React from 'react'
import { TypeMemberStatus } from '@customTypes/member'
import { MemberStatusEnum } from '@enums/member'
import { ReactComponent as ActiveIcon } from '@assets/images/community/member-status-active.svg'
import { ReactComponent as FreeTrialIcon } from '@assets/images/community/member-status-trial.svg'
import { ReactComponent as UnsubscribedIcon } from '@assets/images/community/member-status-unsubscribed.svg'

interface Props {
  status: TypeMemberStatus
}

const MemberStatusIcon: React.FC<Props> = ({ status }) => {
  switch (status) {
    case MemberStatusEnum.Active:
      return <ActiveIcon className='w-4 h-4' />
    case MemberStatusEnum.Trial:
      return <FreeTrialIcon className='w-4 h-4' />
    case MemberStatusEnum.Unsubscribed:
      return <UnsubscribedIcon className='w-4 h-4' />
    default:
      return <ActiveIcon className='w-4 h-4' />
  }
}

export default MemberStatusIcon
