import React, { useCallback, useEffect, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { toast } from 'react-hot-toast'
import {
  getAcceptedFileFormats,
  getFileType,
} from '@services/uploader/uploadUtils'
import { MediaFileTypeEnum } from '@enums/media'
import UploadService from '@services/uploader/uploadService'
import { getMediaUploadError } from '@components/mediaUpload/utils'
import { TypeMediaFileType } from '@customTypes/media'

interface MediaUploadProps {
  isDragActive: boolean
  mediaSrc: string
}

interface Props {
  uploadQueueKey: string
  postParams?: object
  cb?: () => void
  media?: string
  onSetMediaUrl?: (url: string) => void
  onSetMediaFileType?: (fileType: TypeMediaFileType) => void
  children: (props: MediaUploadProps) => React.ReactNode
}

const MediaUploadWrapper: React.FC<Props> = ({
  uploadQueueKey,
  postParams,
  cb,
  media,
  onSetMediaUrl,
  onSetMediaFileType,
  children,
}) => {
  const [mediaSrc, setMediaSrc] = useState<string>(media || '')

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      // handle
      acceptedFiles.forEach(file => {
        const fileUrl = window.URL.createObjectURL(file)
        setMediaSrc(window.URL.createObjectURL(file))
        onSetMediaUrl && onSetMediaUrl(fileUrl)
        onSetMediaFileType && onSetMediaFileType(getFileType(file.type))
        UploadService.uploadQueueItem({
          key: uploadQueueKey,
          cb: cb || null,
          postParams: postParams || {},
          file: file,
        })
      })

      // handle rejected files
      fileRejections.forEach(rejectedFile => {
        console.log('rejected file', rejectedFile)
        toast.error(getMediaUploadError(rejectedFile['errors'][0]), {
          id: rejectedFile.errors[0].code,
        })
      })
    },
    [cb, onSetMediaFileType, onSetMediaUrl, postParams, uploadQueueKey],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDrop,
    maxFiles: 1,
    accept:
      getAcceptedFileFormats(MediaFileTypeEnum.Video) +
      ',' +
      getAcceptedFileFormats(MediaFileTypeEnum.Image),
    maxSize: 500 * 1000 * 1000, // 500 MB
  })

  useEffect(() => {
    if (media) {
      setMediaSrc(media)
    }
  }, [media])

  return (
    <div className='w-full' {...getRootProps()}>
      {children({ isDragActive, mediaSrc })}
      <input {...getInputProps()} />
    </div>
  )
}

export default MediaUploadWrapper
