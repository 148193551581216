import React from 'react'
import { _t } from '@locales/index'
import Modal from '@components/modal/Modal'

interface Props {
  visible: boolean
  onClose: () => void
  onSave: (() => void) | undefined
  className?: string
}

const RevokeCouponPopup: React.FC<Props> = ({ visible, onClose, onSave }) => {
  return (
    <Modal open={visible}>
      <div className={'p-10 w-[360px]'}>
        <p className='text-xl font-bold font-poppins capitalize'>
          {_t('promoCodeRevokePopupTitle')}
        </p>
        <div className='flex justify-center mt-[30px] mr-3'>
          <button
            className='btn-base btn-default-contained shadow-none pl-6 pr-6 pt-2 pb-2 mr-2.5 text-base font-poppins'
            onClick={onClose}
            type={'button'}
          >
            {_t('promoCodeRevokePopupButtonKeep')}
          </button>
          <button
            className='btn-base bg-custom-red-2 btn-inverted-contained rounded pl-6 pr-6 pt-2 pb-2 text-base font-poppins coupon-revoke-btn'
            onClick={onSave}
            type={'button'}
          >
            {_t('promoCodeRevokePopupButtonRevoke')}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default RevokeCouponPopup
