export enum PromoCodeStatusEnum {
  Valid = 'VL',
  Expired = 'EX',
  Revoked = 'RE',
}

export enum PromoCodeDiscountEnum {
  discount25 = '25%',
  discount50 = '50%',
  discount75 = '75%',
  discount100 = '100%',
}
