import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { TypeCoverPhotoFormValue } from '@customTypes/coverPhoto'
import UploadPhotoCoverWizard from '@containers/creator/uploadCoverPhotoWizard/UploadPhotoCoverWizard'
import PhotoCoverWizardScreen from '@containers/creator/uploadCoverPhotoWizard/components/Screen'
import {
  photoCoverDescriptionSchema,
  photoCoverUrlSchema,
} from '@containers/creator/uploadCoverPhoto/utils'
import { FormikHelpers } from 'formik'
import CoverPhotoScreen from '@containers/creator/uploadCoverPhoto/screens/CoverPhoto'
import DescriptionScreen from '@containers/creator/uploadCoverPhoto/screens/Description'
import { useCommunityUpdate, useGetCommunity } from '@api/queries/community'
import uploadService from '@services/uploader/uploadService'
import UploadService from '@services/uploader/uploadService'
import { CommunityMediaTypeEnum, MediaTypeEnum } from '@enums/media'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { toast } from 'react-hot-toast'
import { _t } from '@locales/index'
import { CoverPhotoFormEnum } from '@enums/coverPhoto'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import { useNavigate } from 'react-router-dom'

const UploadCoverPhoto = () => {
  const navigate = useNavigate()
  const communityId = useAppSelector(selectCurrentCommunityId)

  const [uploadQueueKey, setUploadQueueKey] = useState<string>('')
  const { data: community, refetch: refetchCommunity } = useGetCommunity(
    communityId,
    {
      enabled: Boolean(communityId),
    },
  )

  const { mutate: updateCommunity } = useCommunityUpdate({
    onMutate: () => {
      toast.loading(_t('updateCommunityLoading'), {
        id: 'community-update',
      })
    },
    onError: error => {
      toast.error(error.message, { id: 'community-update' })
    },
  })

  const initialValues = useMemo<TypeCoverPhotoFormValue>(() => {
    return {
      [CoverPhotoFormEnum.Photo]: '',
      [CoverPhotoFormEnum.Description]: '',
    }
  }, [])

  const handleUploadMedia = useCallback(() => {
    if (uploadQueueKey) {
      uploadService.runQueue(uploadQueueKey)
    }
  }, [uploadQueueKey])

  const handleSubmit = useCallback(
    (
      formValues: TypeCoverPhotoFormValue,
      _: FormikHelpers<TypeCoverPhotoFormValue>,
    ) => {
      if (formValues[CoverPhotoFormEnum.Photo]) {
        handleUploadMedia()
      }
      if (communityId) {
        updateCommunity(
          {
            id: communityId,
            description: formValues[CoverPhotoFormEnum.Description],
          },
          {
            onSuccess: () => {
              toast.success(_t('updateCommunitySuccess'), {
                id: 'community-update',
              })
              navigate(CREATOR_PATH.communityId.replace(':id', communityId))
            },
          },
        )
      }
    },
    [communityId, handleUploadMedia, navigate, updateCommunity],
  )

  useEffect(() => {
    if (community) {
      const key = `community-welcome-cover-photo-${community.id}`
      UploadService.createQueueItem({
        type: MediaTypeEnum.CommunityMedia,
        postParams: {
          mediaType: MediaTypeEnum.CommunityMedia,
          communityId: community.id,
          subType: CommunityMediaTypeEnum.Cover,
        },
        cb: refetchCommunity,
        key,
        uploadAutomatically: false,
      })
      setUploadQueueKey(key)
    }
  }, [community, refetchCommunity])

  return (
    <Fragment>
      <UploadPhotoCoverWizard
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <PhotoCoverWizardScreen
          label={'url photo'}
          validationSchema={photoCoverUrlSchema}
          disableSubmitOnInvalid
        >
          <CoverPhotoScreen
            title={community?.title}
            uploadKey={uploadQueueKey}
            community={community}
          />
        </PhotoCoverWizardScreen>
        <PhotoCoverWizardScreen
          label={'photo DescriptionScreen'}
          validationSchema={photoCoverDescriptionSchema}
          disableSubmitOnInvalid
        >
          <DescriptionScreen />
        </PhotoCoverWizardScreen>
      </UploadPhotoCoverWizard>
    </Fragment>
  )
}

export default UploadCoverPhoto
