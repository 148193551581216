import React, { useMemo } from 'react'
import { TypeBillingTransaction } from '@customTypes/billing'
import UserInfo from '@containers/creator/communityTransactionsTable/components/UserInfo'
import { _t } from '@locales/index'
import { DEFAULT_CURRENCY_SYMBOL } from '@const/currency'
import classNames from 'classnames'

interface Props {
  transaction: TypeBillingTransaction
  avatarSizeClass?: string
  nameClass?: string
}

const AffiliateFee: React.FC<Props> = ({
  transaction,
  avatarSizeClass,
  nameClass,
}) => {
  const affiliateFee = useMemo<number>(
    () => Math.round((transaction?.affiliateFee || 0) * 100) / 100,
    [transaction?.affiliateFee],
  )

  if (!transaction.affiliateBillingEntity || !transaction.affiliateFee) {
    return null
  }

  return (
    <div className='flex flex-col gap-[9px]'>
      <UserInfo
        entity={transaction.affiliateBillingEntity.entity}
        avatarSizeClass={avatarSizeClass}
        nameClass={classNames('italics', nameClass)}
      />
      <div className='flex items-center gap-20 pl-1'>
        <li className='body2'>{_t('affiliateFeeLabel')}</li>
        <p className='body2'>{`${DEFAULT_CURRENCY_SYMBOL}${affiliateFee}`}</p>
      </div>
    </div>
  )
}

export default AffiliateFee
