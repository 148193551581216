import React, { useEffect, useMemo, useState } from 'react'
import { _t } from '@locales/index'
import InputField from '@components/form/InputField'
import { CommunityFormEnum } from '@enums/community'
import { useFormikContext } from 'formik'
import { TypeCreateCommunityFormValues } from '@customTypes/community'
import { ProfileFormEnum } from '@enums/profile'
import { useGetUserProfile } from '@api/queries/profile'
import { useGetCommunityTypes } from '@api/queries/community'
import TextareaField from '@components/form/TextareaField'

const CommunityName = () => {
  const { data: profile } = useGetUserProfile()
  const { data: communityTypes } = useGetCommunityTypes()

  const [focused, setFocused] = useState<boolean>(false)

  const { values, setFieldValue } =
    useFormikContext<TypeCreateCommunityFormValues>()

  const firstName = useMemo<string>(() => {
    const name = values[ProfileFormEnum.Name] || profile?.name || ''
    return name.split(' ')[0]
  }, [profile?.name, values])

  const communityType = useMemo(() => {
    if (communityTypes && values[CommunityFormEnum.Type]) {
      return communityTypes.find(
        type => type.id === values[CommunityFormEnum.Type],
      )?.name
    }
  }, [communityTypes, values])

  useEffect(() => {
    if (
      !values[CommunityFormEnum.Title] &&
      !focused &&
      firstName &&
      communityType
    ) {
      const defaultName = `${firstName}'s ${communityType} ${_t('community')}`
      setFieldValue(CommunityFormEnum.Title, defaultName)
    }
  }, [communityType, firstName, focused, setFieldValue, values])

  return (
    <div className='community-page'>
      <div className='flex flex-col gap-13'>
        <div className='flex flex-col gap-4'>
          <h4 className='caption sm:h4 text-center sm:text-start capitalize text-custom-pink'>
            {_t('createCommunityNameInputFieldLabel')}
          </h4>
          <div className='sm:hidden w-full'>
            <TextareaField
              name={CommunityFormEnum.Title}
              id={CommunityFormEnum.Title}
              placeholder={_t('createCommunityNameInputFieldPlaceholder')}
              textClass='h3 sm:h2 font-medium capitalize text-center'
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              rows={values[CommunityFormEnum.Title].length > 25 ? 3 : 2}
              tabIndex={-1}
            />
          </div>
          <div className='hidden sm:block min-w-[750px]'>
            <InputField
              name={CommunityFormEnum.Title}
              id={CommunityFormEnum.Title}
              placeholder={_t('createCommunityNameInputFieldPlaceholder')}
              textClass='h3 sm:h2 font-medium capitalize'
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              tabIndex={-1}
            />
          </div>
        </div>
        <p className='body2 text-center sm:text-start text-custom-gray-3'>
          {_t('createCommunityNameDisclaimer')}
        </p>
      </div>
    </div>
  )
}

export default CommunityName
