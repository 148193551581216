export enum LaunchDateStatusEnum {
  Initiated = 'IN',
  Cancelled = 'CA',
  CommunityCreated = 'CC',
  CommunityLaunched = 'CL',
}

export enum ChangeLaunchDateFormEnum {
  option = 'option',
}

export enum ChangeLaunchDateOptionEnum {
  ChangeDate = 'CH',
  PublishNow = 'PU',
  Cancel = 'CA',
}

export enum LaunchDateContactFormEnum {
  subject = 'subject',
  description = 'description',
}
