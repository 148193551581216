import React, { useContext, useState } from 'react'
import { useGetCommunityBillingTransactions } from '@api/queries/billing'
import { CommunityAdminContext } from '@context/community'
import moment from 'moment'
import { TypeBillingTransactionFilters } from '@customTypes/billing'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import ListView from '@containers/creator/communityTransactionsTable/components/ListView'
import TableView from '@containers/creator/communityTransactionsTable/components/TableView'

const CommunityTransactionsTable = () => {
  const isSmallerDevice = useIsSmallerDevice()
  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)

  const [page, setPage] = useState<number>(0)
  const communityAdminContext = useContext(CommunityAdminContext)

  const [filters, setFilters] = useState<TypeBillingTransactionFilters>({
    startDate: moment().startOf('month').unix(),
    endDate: moment().endOf('month').unix(),
  })

  const { data: transactions, isFetching: transactionsLoading } =
    useGetCommunityBillingTransactions(
      {
        communityId: selectedCommunityId || '',
        page: page + 1,
        filters,
        profileId: communityAdminContext?.profileId,
      },
      {
        enabled: Boolean(
          selectedCommunityId && communityAdminContext?.profileId,
        ),
      },
    )

  return isSmallerDevice ? (
    <ListView
      transactions={transactions}
      transactionsLoading={transactionsLoading}
      filters={filters}
      setFilters={setFilters}
      page={page}
      setPage={setPage}
    />
  ) : (
    <TableView
      transactions={transactions}
      transactionsLoading={transactionsLoading}
      filters={filters}
      setFilters={setFilters}
      page={page}
      setPage={setPage}
    />
  )
}

export default CommunityTransactionsTable
