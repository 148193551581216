import React, { useCallback, useState } from 'react'
import Drawer from 'react-modern-drawer'
import { DrawerProps } from '@customTypes/common'
import { ReactComponent as BackArrow } from '@assets/images/common/arrow-2.svg'
import { _t } from '@locales/index'
import { EXPORT_SPREADSHEET_OPTIONS } from '@const/payment'
import classNames from 'classnames'
import { openMobileBottomDrawer } from '@reducers/mobileBottomDrawer'
import { MobileBottomDrawerTypeEnum } from '@enums/common'
import { useAppDispatch, useAppSelector } from '@hooks/store/redux'
import { selectMobileBottomDrawerExportOptionsMeta } from '@selectors/mobileBottomDrawer'
import { isNonZeroFalsy } from '@common/index'
import moment from 'moment'
import useExportTransactionsCSV from '@hooks/payment/useExportTransactions'

const ExportOptionsDrawer: React.FC<DrawerProps> = ({
  open,
  onClose,
  direction,
  ...drawerProps
}) => {
  const dispatch = useAppDispatch()
  const exportOptionsDrawerMeta = useAppSelector(
    selectMobileBottomDrawerExportOptionsMeta,
  )

  const [exportTime, setExportTime] = useState<number>()
  const [exportStartDate, setExportStartDate] = useState<number>()

  useExportTransactionsCSV({
    payload: {
      communityId: exportOptionsDrawerMeta?.communityId,
      profileId: exportOptionsDrawerMeta?.profileId,
      startDate: exportStartDate,
    },
    callback: () => {
      setExportStartDate(undefined)
      onClose && onClose()
    },
    type: exportOptionsDrawerMeta?.type,
  })

  const handleChangeExportTime = useCallback(event => {
    setExportTime(Number(event.target.value))
  }, [])

  const handleClickBack = useCallback(() => {
    dispatch(
      openMobileBottomDrawer({
        type: MobileBottomDrawerTypeEnum.WalletActions,
      }),
    )
  }, [dispatch])

  const handleSubmit = useCallback(() => {
    if (exportTime) {
      setExportStartDate(moment().subtract(exportTime, 'months').unix())
    }
  }, [exportTime])

  return (
    <Drawer
      size={383}
      open={open}
      direction='bottom'
      onClose={onClose}
      className={'overflow-y-auto !w-screen rounded-t-[20px]'}
      {...drawerProps}
    >
      <div className='py-5 flex flex-col gap-5'>
        <div className='flex items-center justify-between py-2.5 px-5'>
          <BackArrow
            className='fill-custom-gray-1 w-5 h-5 rotate-180'
            onClick={handleClickBack}
          />
          <p className='body2'>{_t('exportOptionsDrawerTitle')}</p>
          <div />
        </div>
        <div className='flex flex-col gap-5'>
          {EXPORT_SPREADSHEET_OPTIONS.map((option, i) => (
            <div key={i} className='flex gap-5 justify-center items-center'>
              <div
                className={classNames(
                  'border h-8 w-8 sm:w-6 sm:h-6 rounded-full flex items-center justify-center',
                  exportTime === option.value
                    ? 'border-custom-turquoise border-2'
                    : 'border-px border-custom-gray-3',
                )}
              >
                <input
                  className={'!w-5 !h-5 border-none sm:!w-3.5 sm:!h-3.5'}
                  type='radio'
                  name='exportType'
                  id={option.label}
                  onChange={handleChangeExportTime}
                  value={option.value}
                />
              </div>
              <label htmlFor={option.label} className='body2'>
                {option.label}
              </label>
            </div>
          ))}
        </div>
        <div className='py-2.5 px-5'>
          <button
            className='btn btn-pink w-full h-12'
            onClick={handleSubmit}
            disabled={isNonZeroFalsy(exportTime)}
          >
            {_t('paymentExport')}
          </button>
        </div>
      </div>
    </Drawer>
  )
}

export default ExportOptionsDrawer
