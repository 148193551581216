import React, { Fragment, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@hooks/store/redux'
import { MobileBottomDrawerTypeEnum } from '@enums/common'
import { closeMobileBottomDrawer } from '@reducers/mobileBottomDrawer'
import { selectMobileBottomDrawerType } from '@selectors/mobileBottomDrawer'
import CurrencyPickerDrawer from '@containers/common/mobileBottomDrawer/drawers/CurrencyPickerDrawer'
import CommunitySwitcherMobileDrawer from '@containers/common/mobileBottomDrawer/drawers/CommunitySwitcherDrawer'
import LaunchDateMenuDrawer from '@containers/common/mobileBottomDrawer/drawers/LaunchDateMenuDrawer'
import CreatorNavigationMenuDrawer from '@containers/common/mobileBottomDrawer/drawers/CreatorNavigationMenuDrawer'
import WalletActionsDrawer from '@containers/common/mobileBottomDrawer/drawers/WalletActionsDrawer'
import ExportOptionsDrawer from '@containers/common/mobileBottomDrawer/drawers/ExportOptionsDrawer'
import NavigationMenuDrawer from '@containers/common/mobileBottomDrawer/drawers/NavigationMenuDrawer'

const MobileBottomDrawer = () => {
  const dispatch = useAppDispatch()
  const mobileBottomDrawerType = useSelector(selectMobileBottomDrawerType)

  const handleClose = useCallback(() => {
    dispatch(closeMobileBottomDrawer())
  }, [dispatch])

  return (
    <Fragment>
      {/* ORGANIZATION FLOW DRAWERS */}
      <NavigationMenuDrawer
        open={
          mobileBottomDrawerType === MobileBottomDrawerTypeEnum.NavigationMenu
        }
        direction={'bottom'}
        onClose={handleClose}
      />

      {/* CREATOR FLOW DRAWERS */}
      <CurrencyPickerDrawer
        open={
          mobileBottomDrawerType === MobileBottomDrawerTypeEnum.CurrencyPicker
        }
        direction='bottom'
        onClose={handleClose}
      />
      <CommunitySwitcherMobileDrawer
        open={
          mobileBottomDrawerType ===
          MobileBottomDrawerTypeEnum.CommunitySwitcher
        }
        direction='bottom'
        onClose={handleClose}
      />
      <LaunchDateMenuDrawer
        open={mobileBottomDrawerType === MobileBottomDrawerTypeEnum.LaunchDate}
        direction='bottom'
        onClose={handleClose}
      />
      <CreatorNavigationMenuDrawer
        open={
          mobileBottomDrawerType ===
          MobileBottomDrawerTypeEnum.CreatorNavigationMenu
        }
        direction='bottom'
        onClose={handleClose}
      />
      <WalletActionsDrawer
        open={
          mobileBottomDrawerType === MobileBottomDrawerTypeEnum.WalletActions
        }
        direction='bottom'
        onClose={handleClose}
      />
      <ExportOptionsDrawer
        open={
          mobileBottomDrawerType === MobileBottomDrawerTypeEnum.ExportOptions
        }
        direction='bottom'
        onClose={handleClose}
      />
    </Fragment>
  )
}

export default MobileBottomDrawer
