import React, { useCallback, useEffect, useState } from 'react'
import { _t } from '@locales/index'
import VideoUploadWrapper from '@components/videoUpload/VideoUploadWrapper'
import UploadService from '@services/uploader/uploadService'
import uploadService from '@services/uploader/uploadService'
import { CommunityMediaTypeEnum, MediaTypeEnum } from '@enums/media'
import { useGetCommunity } from '@api/queries/community'
import Loader from '@components/loader/Loader'
import VideoPopup from '@containers/creator/community/components/VideoPopup'
import { EXAMPLE_VIDEO_LINK } from '@const/common'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { useNavigate } from 'react-router-dom'
import Footer from '@components/footer/Footer'
import UploadMediaScreenWrapper from '@containers/creator/uploadCommunityMedia/components/ScreenWrapper'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'
import ExampleVideo from '@containers/creator/uploadWelcomeVideo/components/ExampleVideo'
import classNames from 'classnames'
import PhoneMockup from '@components/phoneMockup/PhoneMockup'
import { CREATOR_PATH } from '@routes/creator/creatorPath'

const UploadWelcomeVideo = () => {
  const navigate = useNavigate()
  const isSmallerDevice = useIsSmallerDevice()
  const id = useAppSelector(selectCurrentCommunityId)

  const { data: community, refetch: refetchCommunity } = useGetCommunity(id, {
    enabled: Boolean(id),
  })

  const [videoUrl, setVideoUrl] = useState<string>('')
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [showExampleVideo, setShowExampleVideo] = useState<boolean>(false)
  const [isMediaUploading, setIsMediaUploading] = useState<boolean>(false)
  const [uploadQueueKey, setUploadQueueKey] = useState<string>('')

  const handleUploadMedia = useCallback(() => {
    if (uploadQueueKey && id) {
      setIsMediaUploading(true)
      uploadService.setQueueItemCallback(uploadQueueKey, async () => {
        await refetchCommunity()
        setIsMediaUploading(false)
        navigate(CREATOR_PATH.communityId.replace(':id', id))
      })
      uploadService.runQueue(uploadQueueKey)
    }
  }, [id, navigate, refetchCommunity, uploadQueueKey])

  const handleBackButton = useCallback(() => {
    if (videoUrl) setVideoUrl('')
    else navigate(-1)
  }, [navigate, videoUrl])

  useEffect(() => {
    if (community) {
      const key = `community-welcome-video-${community.id}`
      UploadService.createQueueItem({
        type: MediaTypeEnum.CommunityMedia,
        postParams: {
          mediaType: MediaTypeEnum.CommunityMedia,
          communityId: community.id,
          subType: CommunityMediaTypeEnum.WelcomeVideo,
        },
        cb: null,
        key,
        uploadAutomatically: false,
      })
      setUploadQueueKey(key)
    }
  }, [community])

  return (
    <div className='flex flex-col flex-1 relative'>
      <UploadMediaScreenWrapper>
        <div className='flex flex-col flex-1 items-center pt-2 sm:pt-26 px-5 pb-5 relative'>
          <div className='flex flex-col sm:flex-row gap-13 sm:gap-26 items-center'>
            <div className='flex flex-col gap-13 max-w-[455px]'>
              <div className={'flex flex-col gap-3 sm:gap-5'}>
                <h1 className='body1 sm:h2 !font-medium capitalize'>
                  {_t('welcomeVideoTitle')}
                </h1>
                <div className='flex flex-col gap-5 sm:gap-3'>
                  <p className='body2 first-letter:uppercase sm:text-custom-black'>
                    {_t('welcomeVideoSubtitle')}
                  </p>
                  <p className='body3 text-custom-gray-3'>
                    {_t('welcomeVideoInstruction3')}
                  </p>
                  <p className='body3 text-custom-gray-3'>
                    {_t('welcomeVideoInstruction2')}
                  </p>
                </div>
              </div>
              <div
                className={classNames(
                  'flex flex-1 items-center w-full',
                  videoUrl && isSmallerDevice && 'hidden',
                )}
              >
                <VideoUploadWrapper
                  uploadQueueKey={uploadQueueKey}
                  onDropFile={setVideoUrl}
                >
                  {() => {
                    return videoUrl ? (
                      <button className='btn btn-outlined w-[165px] border-custom-gray-5'>
                        {_t('welcomeVideoChangeButton')}
                      </button>
                    ) : (
                      <button className='btn btn-pink w-full sm:w-[266px] sm:h-12'>
                        + {_t('welcomeVideoUploadButton')}
                      </button>
                    )
                  }}
                </VideoUploadWrapper>
              </div>
              {!(videoUrl && isSmallerDevice) && (
                <ExampleVideo onSetShowExampleVideo={setShowExampleVideo} />
              )}
            </div>
            {videoUrl && (
              <div className='w-[257px] sm:w-[264px] h-[527px] sm:h-[545px]'>
                <PhoneMockup
                  backgroundMediaUrl={videoUrl}
                  isVideo={true}
                  community={community}
                />
              </div>
            )}
          </div>
        </div>
      </UploadMediaScreenWrapper>
      <Footer
        primaryButton={{
          text: videoUrl ? _t('confirm') : _t('next'),
          onClick: videoUrl ? handleUploadMedia : undefined,
          disabled: !videoUrl,
        }}
        secondaryButton={
          isSmallerDevice
            ? {
                text: _t('back'),
                onClick: handleBackButton,
              }
            : undefined
        }
      />
      <Loader visible={isMediaUploading} fullWidth />
      <VideoPopup
        open={showExampleVideo}
        onClose={() => setShowExampleVideo(false)}
        url={EXAMPLE_VIDEO_LINK}
      />
      <VideoPopup
        open={showPreview}
        onClose={() => setShowPreview(false)}
        url={videoUrl}
      />
    </div>
  )
}

export default UploadWelcomeVideo
