import {
  CreateQueueItemParams,
  TypeQueue,
  TypeQueueItem,
  TypeQueueMedia,
  UploadQueueItemParams,
} from '@customTypes/uploader'
import { QueueItemStatusEnum } from '@enums/uploader'
import { generate } from '@common/objectId'
import {
  createCommunityMedia,
  createFileType,
  createProfileMedia,
  preUploadMedia,
} from '@services/uploader/uploadUtils'
import { MediaTypeEnum } from '@enums/media'

class UploadServiceClass {
  private queue: TypeQueue = {}

  public createQueueItem = ({
    type,
    postParams,
    cb,
    uploadAutomatically = true,
    key = generate(),
  }: CreateQueueItemParams): string => {
    // return the key if queueItem already exists in the queue
    if (this.queue[key]) return key

    // create a new queueItem
    this.queue[key] = {
      q: [],
      callback: cb || null,
      status: QueueItemStatusEnum.Waiting,
      type,
      postParams,
      uploadAutomatically,
    }

    return key
  }

  public getQueueItem = (key: string): TypeQueueItem => {
    return this.queue[key]
  }

  public getAllQueueItems = (): TypeQueue => {
    return this.queue
  }

  public getQueueItemMedia = (
    key: string,
    mediaCode: string,
  ): TypeQueueMedia | undefined => {
    if (this.queue[key]) {
      return this.queue[key].q.find(
        (item: TypeQueueMedia) => item.mediaCode === mediaCode,
      )
    }
  }

  public setQueueItemCallback = (key: string, cb: (r: any) => void): void => {
    const q: TypeQueueItem = this.queue[key]

    if (q) {
      q.callback = cb || null
    }
  }

  public extendQueueItemPostParams = (
    key: string,
    postParams: object,
  ): void => {
    const q: TypeQueueItem = this.queue[key]

    if (q) {
      q['postParams'] = {
        ...q['postParams'],
        ...postParams,
      }
    }
  }

  public deleteQueueItem = (key: string): void => {
    delete this.queue[key]
  }

  // mediaCode - unique id in order to get the upload status
  public uploadQueueItem = ({
    key,
    file,
    cb,
    postParams,
    mediaCode = generate(),
  }: UploadQueueItemParams) => {
    const queueItem = this.queue[key]

    if (!queueItem) {
      console.log('no queue')
      return
    }

    // create queueMedia
    try {
      const queueMedia: TypeQueueMedia = {
        file: createFileType(file),
        mediaCode,
        progress: 0,
        postParams,
        callback: cb,
      }
      queueItem.q.push(queueMedia)
    } catch {
      throw new Error('the file media is not valid')
    }

    if (
      queueItem.status === QueueItemStatusEnum.Waiting &&
      queueItem.uploadAutomatically
    ) {
      this.runQueue(key)
    }
  }

  public runQueue = (key: string) => {
    const queueItem = this.queue[key]

    // if key is not exists
    if (!queueItem) {
      return
    }
    // if the queue is empty change status to waiting else changes to running
    if (queueItem.q.length === 0) {
      queueItem.status = QueueItemStatusEnum.Waiting
      // queueItem.callback && queueItem.callback();
      return
    }
    const queue: TypeQueueItem = queueItem
    queue.status = QueueItemStatusEnum.Running
    const queueMedia: TypeQueueMedia = queue.q[0]

    // uploading the first media in the queue if success delete from queue and rerun the queue
    this.uploadFileNew(queue, queueMedia).then((response: any) => {
      queueMedia.status = response.status
      queueMedia.response = response
      queueMedia.callback && queueMedia.callback(queueMedia)
      queue.callback && queue.callback(queueMedia)
      queue.q.shift()
      this.runQueue(key)
    })
  }

  public uploadFileNew = async (
    queue: TypeQueueItem,
    queueMedia: TypeQueueMedia,
  ) => {
    try {
      const pre = await preUploadMedia(
        queueMedia.file.fileType,
        queueMedia.file.suffix || '',
        queueMedia.file.contentType ||
          queueMedia.file.media.type ||
          'application/octet-stream',
      )

      // set pre
      queueMedia.url = pre.url
      queueMedia.file.key = pre.id
      queueMedia.file.url = pre.url.split('?')[0]

      // upload to s3
      const upload: any = await this.uploadNewMedia(
        queueMedia,
        (p: number) => (queueMedia.progress = p),
      )

      // validation on upload - failed resolve with error
      if (upload.status) {
        let postUpload: any = {}
        const postParams = {
          ...queue.postParams,
          ...queueMedia.postParams,
        }
        switch (queue.type) {
          case MediaTypeEnum.ProfileMedia:
            postUpload = await createProfileMedia(queueMedia, postParams)
            break
          case MediaTypeEnum.CommunityMedia:
            postUpload = await createCommunityMedia(queueMedia, postParams)
            break
          default:
            return { status: false, error: 'wrong queue type' }
        }
        return { status: true, ...postUpload }
      }
    } catch (err) {
      throw err
    }
  }

  public uploadNewMedia = (
    queueMedia: TypeQueueMedia,
    progressCallback?: (p: number) => void,
  ) =>
    new Promise(async resolve => {
      const fileNew: any = {
        uri: queueMedia.file.url,
        type:
          queueMedia.file.contentType ||
          queueMedia.file.media.type ||
          'application/octet-stream',
        name: queueMedia.file.title,
      }

      console.log('fileNew', fileNew)
      const xhr = new XMLHttpRequest()

      xhr.upload.addEventListener(
        'progress',
        (e: any) => {
          progressCallback &&
            progressCallback(Math.ceil((e.loaded / e.total) * 100))
        },
        false,
      )

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          fileNew.status = Boolean(xhr.status === 200)
          if (xhr.status !== 200) {
            console.log('error uploading')
            fileNew.error = 'upload failed'
          }
          resolve(fileNew)
        }
      }
      xhr.open('PUT', queueMedia.url || '')
      // xhr.setRequestHeader('X-Amz-ACL', '-read')
      xhr.setRequestHeader(
        'Content-Type',
        queueMedia.file.contentType ||
          queueMedia.file.media.type ||
          'application/octet-stream',
      )
      xhr.send(queueMedia.file.media)
    })
}

const UploadService = new UploadServiceClass()

export default UploadService
