import React, { useCallback, useMemo, useState } from 'react'

import moment from 'moment'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import { _t } from '@locales/index'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import { useAppSelector } from '@hooks/store/redux'
import { useLaunchDateCreate, useLaunchDateEdit } from '@api/queries/launchDate'
import { selectLaunchDateModalDate } from '@selectors/launchDateModal'

import LunchCommunityWrapper from '@containers/creator/launchCommunity/LaunchCommunityWrapper'
import LaunchScreenFooter from '@containers/creator/launchCommunity/components/LaunchScreenFooter'
import { useGetCommunity } from '@api/queries/community'
import { selectCurrentCommunityId } from '@selectors/community'

const ConfirmLaunchDate = () => {
  const navigate = useNavigate()
  const id = useAppSelector(selectCurrentCommunityId)
  const launchDate = useAppSelector(selectLaunchDateModalDate(id))

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const { data: community } = useGetCommunity(id || '', {
    enabled: Boolean(id),
  })

  const editLaunchDate = useLaunchDateEdit({
    onMutate: () => {
      setIsSubmitting(true)
      toast.loading(_t('editLaunchDateLoading'), {
        id: 'edit-launch-date',
      })
    },
    onSettled: () => {
      setIsSubmitting(false)
    },
  })

  const mutateLaunchDate = useLaunchDateCreate({
    onMutate: () => {
      setIsSubmitting(true)
      toast.loading(_t('setLaunchDateLoading'), {
        id: 'set-launch-date',
      })
    },
    onSettled: () => {
      setIsSubmitting(false)
    },
  })

  const handleClickConfirm = useCallback(() => {
    if (id && launchDate) {
      if (community?.launchDate?.date) {
        editLaunchDate.mutate(
          {
            communityId: id,
            date: moment.utc(launchDate).format(),
          },
          {
            onSuccess: () => {
              toast.success(_t('editLaunchDateSuccess'), {
                id: 'edit-launch-date',
              })
              navigate(CREATOR_PATH.launchDateCreated.replace(':id', id))
            },
            onError: error => {
              toast.error(error.message, {
                id: 'edit-launch-date',
              })
              navigate(CREATOR_PATH.communityId.replace(':id', id))
            },
          },
        )
      } else {
        mutateLaunchDate.mutate(
          {
            communityId: id,
            date: moment.utc(launchDate).format(),
          },
          {
            onSuccess: () => {
              toast.success(_t('setLaunchDateSuccess'), {
                id: 'set-launch-date',
              })
              navigate(CREATOR_PATH.launchDateCreated.replace(':id', id))
            },
            onError: error => {
              toast.error(error.message, {
                id: 'set-launch-date',
              })
              navigate(CREATOR_PATH.communityId.replace(':id', id))
            },
          },
        )
      }
    }
  }, [
    community?.launchDate?.date,
    editLaunchDate,
    id,
    launchDate,
    mutateLaunchDate,
    navigate,
  ])

  const launchTimeRemaining = useMemo<string | undefined>(() => {
    if (launchDate) {
      const momentLaunchDate = moment(launchDate)
      const momentToday = moment()
      const diff = momentLaunchDate.diff(momentToday, 'days')
      if (diff > 24 && diff < 30) {
        return `in ${diff} days`
      }
      return moment.utc(launchDate).local().fromNow()
    }
  }, [launchDate])

  return (
    <LunchCommunityWrapper isSubmitting={isSubmitting}>
      <div className='h-full flex flex-col min-w-[340px]'>
        <div className='flex flex-col items-center p-13 sm:px-26 gap-5'>
          <h4 className='h4 capitalize text-center'>
            {_t('confirmLaunchDateTitle')}
          </h4>
          <div className='flex flex-col items-center gap-1.5'>
            <p className='body2 text-custom-pink text-center'>
              {moment(launchDate).format('dddd - MMM DD, YYYY')}
            </p>
            <p className='body2'>{`(${launchTimeRemaining})`}</p>
          </div>
        </div>
        <LaunchScreenFooter
          nextButtonText={_t('confirmLaunchDateButton')}
          previousButtonText={_t('back')}
          onClickNext={handleClickConfirm}
          onClickPrevious={() => navigate(-1)}
        />
      </div>
    </LunchCommunityWrapper>
  )
}

export default ConfirmLaunchDate
