import { TypeChangeLaunchDateOption } from '@customTypes/launchDate'
import { ChangeLaunchDateOptionEnum } from '@enums/launchDate'
import { _t } from '@locales/index'

export const CHANGE_LAUNCH_DATE_SUBJECT: Record<
  TypeChangeLaunchDateOption,
  string
> = {
  [ChangeLaunchDateOptionEnum.ChangeDate]: _t('changeLaunchDateOption1Subject'),
  [ChangeLaunchDateOptionEnum.PublishNow]: _t('changeLaunchDateOption2Subject'),
  [ChangeLaunchDateOptionEnum.Cancel]: _t('changeLaunchDateOption3Subject'),
}
