import useBreakpoint from '@hooks/responsive/useBreakpoint'
import { useMemo } from 'react'
import { BreakpointsEnum } from '@enums/theme'

const useIsSmallerDevice = (): boolean => {
  const breakpoint = useBreakpoint()

  return useMemo(() => breakpoint === BreakpointsEnum.sm, [breakpoint])
}

export default useIsSmallerDevice
