import React, { FocusEventHandler, useEffect, useMemo, useState } from 'react'
import {
  useGetAllOccupations,
  useGetOccupationsByKey,
} from '@api/queries/occupation'
import classNames from 'classnames'
import { _t, Locale } from '@locales/index'
import { Combobox } from '@headlessui/react'
import { TypeOccupation } from '@customTypes/occupation'
import { ResponseStatusCodeEnum } from '@enums/api'
import { LanguagesEnum } from '@enums/language'

interface Props {
  value: TypeOccupation | null
  setValue: (value: TypeOccupation) => void
  onBlur?: FocusEventHandler | undefined
}

const Occupation: React.FC<Props> = ({ value, setValue, onBlur }) => {
  const [inputText, setInputText] = useState<string>('')
  const [searchFilter, setSearchFilter] = useState<string>('')

  const { data: allOccupations, isLoading: allOccupationsLoading } =
    useGetAllOccupations()

  const {
    data: occupationsByKey,
    isLoading,
    error,
  } = useGetOccupationsByKey(inputText, {
    enabled: Boolean(searchFilter),
  })

  const occupations = useMemo(() => {
    if (Boolean(inputText)) {
      return occupationsByKey
    }
    return allOccupations
  }, [allOccupations, inputText, occupationsByKey])

  const noResults = useMemo(
    () => error?.response?.status === ResponseStatusCodeEnum.ObjectDoesNotExist,
    [error?.response?.status],
  )
  const local_language = Locale.getLanguage()

  const isEnglish = useMemo<boolean>(
    () => local_language === LanguagesEnum.EN,
    [local_language],
  )

  // debounce search filter to avoid making too many api requests
  useEffect(() => {
    const timeout = setTimeout(() => {
      setSearchFilter(inputText)
      if (!isEnglish) {
        setInputText('acting')
        setValue({ name: 'acting', id: '0' })
      }
    }, 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [inputText, isEnglish, setValue])

  return (
    <div className='relative'>
      <Combobox
        value={occupations && !isEnglish ? occupations[0] : value}
        onChange={setValue}
        nullable
      >
        <>
          {isEnglish ? (
            <Combobox.Button as='div'>
              <Combobox.Input
                className={classNames(
                  'w-full px-4 py-2.5 body3 border border-custom-gray-5 placeholder:text-custom-gray-4 placeholder:no-underline focus:ring-0 focus:border-custom-gray-5 outline-none font-light rounded disabled:bg-custom-gray-5 disabled:border-none',
                )}
                onChange={event => setInputText(event.target.value)}
                placeholder={_t('createCommunityProfileOccupationPlaceholder')}
                displayValue={(occupation: TypeOccupation) => occupation?.name}
                autoComplete='off'
                onBlur={onBlur}
                tabIndex={-1}
              />
            </Combobox.Button>
          ) : null}
          <Combobox.Options className='absolute top-full left-0 right-0 border border-custom-gray-5 filter-drop-shadow-2 z-100 bg-white max-h-96 divide-y divide-custom-gray-5 max-h-[300px] overflow-y-auto no-scrollbar'>
            {occupations &&
              occupations.length > 0 &&
              occupations.map(occupation => {
                const isActingOption =
                  occupation.name.toLowerCase() === 'acting'

                return (
                  <Combobox.Option
                    key={occupation.id}
                    value={occupation}
                    className={
                      'px-4 py-[11px] cursor-pointer hover:bg-custom-gray-6 focus:bg-custom-gray-4'
                    }
                    id={isActingOption ? 'acting-occupation' : 'occupation'}
                  >
                    <p className='body3 capitalize'>{occupation.name}</p>
                  </Combobox.Option>
                )
              })}
            {(isLoading || allOccupationsLoading) && (
              <div className='w-full h-[300px] skeleton' />
            )}
            {noResults && (
              <div className='px-4 py-[11px]'>
                <p className='body3 capitalize'>
                  {_t('createCommunityProfileOccupationEmptyState')}
                </p>
              </div>
            )}
          </Combobox.Options>
        </>
      </Combobox>
    </div>
  )
}

export default Occupation
