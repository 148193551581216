import React from 'react'
import CommunityOwnerFlow from '@containers/creator/CommunityOwnerFlow'
import AffiliatorFlow from '@containers/creator/AffiliatorFlow'

interface Props {
  isNewAdminFlow: boolean
}

const CommunityFlow: React.FC<Props> = ({ isNewAdminFlow }) => {
  return (
    <>
      <AffiliatorFlow />
      {!isNewAdminFlow && <CommunityOwnerFlow />}
    </>
  )
}

export default CommunityFlow
