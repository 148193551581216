export enum MemberEngagementEnum {
  High = 'HI',
  Mid = 'MI',
  Low = 'LO',
}

export enum MemberStatusEnum {
  Default = 'DE',
  Active = 'AC',
  Trial = 'TR',
  Unsubscribed = 'IN',
}

export enum MemberJoinTimeEnum {
  OneMonth = 'FM',
  OneToThreeMonths = 'TM',
  ThreeToSixMonths = 'SM',
  SixPlusMonths = 'SP',
}

export enum MemberOrderFilterEnum {
  Alphabetical = 'asc',
  ReverseAlphabetical = 'desc',
}

export enum MemberSortFilterEnum {
  Name = 'name',
  Newest = 'created',
}

export enum MemberLayoutEnum {
  Grid,
  List,
}
