import React, { useMemo } from 'react'
import { ReactComponent as WirelessIcons } from '@assets/images/community/phoneWirelessIcons.svg'
import classNames from 'classnames'
import { PHONE_MOCKUP_URL } from '@const/common'
import { CropperProps } from 'react-easy-crop/Cropper'
import { _t } from '@locales/index'
import Cropper from 'react-easy-crop'
import { TypeCommunity, TypeCommunityAdmin } from '@customTypes/community'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { useGetCommunity } from '@api/queries/community'
import { CommunityAdminRoleEnum } from '@enums/community'

interface Props {
  description?: string
  backgroundMediaUrl?: string
  faded?: boolean
  isVideo?: boolean
  borderRadiusClass?: string
  community?: TypeCommunity
  cropperProps?: CropperProps
}

const PhoneMockup: React.FC<Props> = ({
  description,
  backgroundMediaUrl,
  faded,
  isVideo,
  borderRadiusClass,
  cropperProps,
  community: communityProp,
}) => {
  const currentCommunityId = useAppSelector(selectCurrentCommunityId)

  const { data: currentCommunity } = useGetCommunity(currentCommunityId, {
    enabled: Boolean(currentCommunityId),
  })

  const community = useMemo<TypeCommunity | undefined>(
    () => communityProp || currentCommunity || undefined,
    [communityProp, currentCommunity],
  )

  const isCropperActive = useMemo<boolean>(
    () => Boolean(cropperProps),
    [cropperProps],
  )

  const owner = useMemo<TypeCommunityAdmin | undefined>(() => {
    if (community) {
      return community.admins.find(
        admin => admin.role === CommunityAdminRoleEnum.Owner,
      )
    } else {
      return undefined
    }
  }, [community])

  return (
    <div
      className={classNames(
        `flex flex-col w-full h-full bg-custom-gray-1 border-[6px] sm:border-[9px] border-custom-gray-1 transition-opacity overflow-hidden`,
        faded && 'opacity-30',
        borderRadiusClass || 'rounded-[42px]',
      )}
    >
      <div
        className={
          'w-full h-[36px] sm:h-14 bg-white flex flex-col p-2 sm:gap-[5px] flex items-center justify-center'
        }
      >
        <div
          className={
            'flex text-black text-xs w-full  items-center justify-between px-3'
          }
        >
          <p className='text-[6px] sm:text-[10px] font-normal'>9:41</p>
          <WirelessIcons className={'w-[30px] sm:w-[47px]'} />
        </div>
        <div
          className={
            'bg-custom-gray-8 w-full text-xs flex items-center justify-center rounded-full min-h-[13px] sm:min-h-[22px]'
          }
        >
          <p className='text-[6px] sm:text-[11px] font-normal'>
            {PHONE_MOCKUP_URL}
          </p>
        </div>
      </div>
      <div
        className='bg-center w-full flex-1 relative flex flex-col justify-between relative overflow-hidden'
        style={{
          backgroundImage:
            backgroundMediaUrl && !isVideo
              ? `url(${backgroundMediaUrl})`
              : undefined,
          backgroundSize: '100% 100%',
        }}
      >
        {isCropperActive && cropperProps && (
          <div className='w-full h-full z-10'>
            <Cropper {...cropperProps} />
          </div>
        )}
        {!isCropperActive && (
          <div
            className={classNames(
              'w-full h-full p-2 pb-10 flex flex-col items-center gap-2 justify-end z-10',
              backgroundMediaUrl
                ? 'bg-gradient-to-t from-black'
                : 'bg-custom-gray-2',
            )}
          >
            <div className={'w-full'}>
              {owner?.profile.media[0].url ? (
                <div
                  className={
                    'w-[42px] h-[42px] rounded-full overflow-hidden mb-[7px]'
                  }
                >
                  <img src={owner?.profile.media[0].url} alt={'Profile'} />
                </div>
              ) : null}
              <h1 className='font-bold text-xs text-white line-clamp-2 capitalize drop-shadow-lg shadow-black'>
                {community?.title || _t('communityTitleTitle')}
              </h1>
              <p
                className={
                  'text-xs text-white mb-1 drop-shadow-lg shadow-black'
                }
              >
                {community?.type?.name ||
                  _t('communitySettingsMenuEditCommunityType')}
              </p>
              {isVideo ? null : (
                <p className='text-[8px] text-white first-letter:capitalize line-clamp-4'>
                  {description || _t('welcomePhotoDescExample')}
                </p>
              )}
            </div>
            <div
              className={
                'w-full h-[30px] flex items-center justify-center bg-gradient-to-t from-custom-dark-yellow to-custom-yellow-1 text-[14px] font-medium rounded-full'
              }
            >
              {_t('reserveASpot')}
            </div>
          </div>
        )}
        {isVideo ? (
          <div className={'absolute w-full h-full'}>
            <video
              src={backgroundMediaUrl}
              autoPlay={true}
              muted={true}
              loop={true}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default PhoneMockup
