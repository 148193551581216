import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { _t } from '@locales/index'
import CouponCode from '@containers/creator/coupons/components/CouponCode'
import CouponDiscount from '@containers/creator/coupons/components/Discount'
import CouponDuration from '@containers/creator/coupons/components/Duration'
import { useValidatePromoCode } from '@api/queries/promoCode'
import { TypePromoCodeValues } from '@customTypes/promoCode'
import { useFormikContext } from 'formik'

interface Props {
  onCancelCreateCoupon: Function
  onCreateCoupon: Function
  communityId: string | undefined
}

const CreateCoupon: React.FC<Props> = ({
  onCancelCreateCoupon,
  communityId,
}) => {
  const { values: couponValues, errors } =
    useFormikContext<TypePromoCodeValues>()

  const [couponDiscountError, setCouponDiscountError] = useState<string>('')
  const [couponDurationError, setCouponDurationError] = useState<string>('')
  const [noDataError, setNoDataError] = useState<boolean>(true)
  const [formikValues, setFormikValues] = useState<TypePromoCodeValues>()
  const { data: isCouponValid } = useValidatePromoCode(
    couponValues.couponCode,
    communityId,
    {
      enabled: Boolean(couponValues.couponCode),
      staleTime: 0,
    },
  )

  useEffect(() => {
    if (formikValues !== undefined) {
      if (
        !errors.couponCode &&
        !couponDurationError &&
        !couponDiscountError &&
        formikValues?.couponCode !== '' &&
        formikValues?.couponDiscount !== '' &&
        formikValues?.couponDuration.length > 0
      ) {
        setNoDataError(false)
      }
    }
  }, [
    errors.couponCode,
    couponDurationError,
    couponDiscountError,
    setNoDataError,
    formikValues,
  ])

  const isDisabled = useMemo(() => {
    return !(
      !errors.couponCode &&
      !couponDurationError &&
      !couponDiscountError &&
      !noDataError
    )
  }, [errors.couponCode, couponDurationError, couponDiscountError, noDataError])

  const handleCouponDiscountError = useCallback(
    (error: string, values: any) => {
      setCouponDiscountError(error)
      setFormikValues(values)
    },
    [setCouponDiscountError, setFormikValues],
  )

  const handleCouponDurationError = useCallback(
    (error: string) => {
      setCouponDurationError(error)
    },
    [setCouponDurationError],
  )

  const handleDurationRange = useCallback(
    (range: Date[]) => {
      if (
        range[0].getDate() === range[1].getDate() &&
        range[0].getMonth() === range[1].getMonth() &&
        range[0].getDate() === range[1].getDate()
      ) {
        handleCouponDurationError(_t('promoCodeDurationError2'))
      } else {
        handleCouponDurationError('')
      }
    },
    [handleCouponDurationError],
  )

  return (
    <div className='relative'>
      <div className='border border-custom-gray-5 rounded bg-white p-2.5 w-full xl:w-3/4 flex sm:flex-row flex-col sm:items-center items-start'>
        <CouponCode name={'couponCode'} />
        <div className='min-w-[180px] min-h-[45px] mt-4 sm:mt-0'>
          <CouponDiscount
            onCouponDiscountError={(
              error: string,
              values: TypePromoCodeValues,
            ) => {
              handleCouponDiscountError(error, values)
            }}
          />
        </div>
        <div className='min-w-[160px] min-h-[45px] mt-4 sm:mt-0'>
          <CouponDuration
            onHandleRange={(range: Date[]) => handleDurationRange(range)}
            onCouponDurationError={(error: string) => {
              handleCouponDurationError(error)
            }}
          />
        </div>
      </div>
      <div
        className={
          'ml-8 mt-2.5 text-custom-red-2 text-xs font-poppins font-normal capitalize'
        }
      >
        {isCouponValid === undefined
          ? null
          : !isCouponValid &&
            !errors.couponCode && <p>{_t('promoCodeError')}</p>}
        {errors.couponCode && <p>{errors.couponCode}</p>}
        {couponDiscountError && <p>{couponDiscountError}</p>}
        {couponDurationError && <p>{couponDurationError}</p>}
      </div>
      <div className='flex flex-row mt-5'>
        <button
          type='button'
          className='btn-base btn-default-contained py-2.5 px-6 bg-transparent rounded shadow-none font-bold font-poppins'
          onClick={() => onCancelCreateCoupon()}
        >
          {_t('promoCodeCancelButton')}
        </button>
        <button
          type='submit'
          className='btn-base btn-inverted-contained py-2.5 px-6 rounded shadow-none font-bold font-poppins coupon-create-btn'
          disabled={isDisabled}
        >
          {_t('promoCodeCreateButton')}
        </button>
      </div>
    </div>
  )
}

export default CreateCoupon
