import React, { useMemo } from 'react'
import classNames from 'classnames'
import { Menu } from '@headlessui/react'
import { DropdownItemVariantEnum } from '@enums/theme'
import { DROPDOWN_ITEM_VARIANT_CLASS } from '@const/theme'

interface Props {
  text: string
  onClick: (any: string | undefined) => void
  disabled?: boolean
  variant?: DropdownItemVariantEnum
  customComponent?: React.ReactNode
  customClassName?: string
}

const DropdownMenuItem: React.FC<Props> = ({
  text,
  onClick,
  disabled = false,
  variant = DropdownItemVariantEnum.Default,
  customComponent,
  customClassName,
}) => {
  const classes = useMemo(
    () =>
      classNames(
        `dropdown-item-base`,
        disabled
          ? 'dropdown-item-disabled'
          : DROPDOWN_ITEM_VARIANT_CLASS[variant],
        customClassName,
      ),
    [customClassName, disabled, variant],
  )

  return (
    <Menu.Item>
      <div className={classes} onClick={() => onClick(text)}>
        {customComponent && customComponent}
        <span>{text}</span>
      </div>
    </Menu.Item>
  )
}

export default DropdownMenuItem
