import React, { useCallback, useMemo } from 'react'
import { useGetCommunities } from '@api/queries/community'
import { useAppDispatch, useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { ReactComponent as PointerIcon } from '@assets/images/common/down-pointer.svg'
import { openMobileBottomDrawer } from '@reducers/mobileBottomDrawer'
import { MobileBottomDrawerTypeEnum } from '@enums/common'
import { TypeCommunityBase } from '@customTypes/community'

const CommunitySwitcher = () => {
  const dispatch = useAppDispatch()
  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)

  const { data: communities } = useGetCommunities()

  const community = useMemo<TypeCommunityBase | undefined>(
    () =>
      communities &&
      communities.find(communityObj => communityObj.id === selectedCommunityId),
    [communities, selectedCommunityId],
  )

  const handleOpenCommunitySwitcher = useCallback(() => {
    dispatch(
      openMobileBottomDrawer({
        type: MobileBottomDrawerTypeEnum.CommunitySwitcher,
      }),
    )
  }, [dispatch])

  const title = useMemo<string>(() => {
    if (community?.title) {
      return community.title.length > 23
        ? community.title.slice(0, 20) + '...'
        : community.title
    }
    return ''
  }, [community?.title])

  if (!community) {
    return null
  }

  return (
    <div
      className='flex items-center gap-2'
      onClick={handleOpenCommunitySwitcher}
    >
      <p className='capitalize text-custom-turquoise body2'>{title}</p>
      <PointerIcon className='w-6 h-6 fill-custom-turquoise' />
    </div>
  )
}

export default CommunitySwitcher
