import React from 'react'
import { DEFAULT_CURRENCY_SYMBOL } from '@const/currency'
import { _t } from '@locales/index'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'

interface Props {
  productFee: number
  providerFee: number
  earned: number
}

const FeeDetails: React.FC<Props> = ({ productFee, providerFee, earned }) => {
  const isSmallerDevice = useIsSmallerDevice()

  if (isSmallerDevice) {
    return (
      <div className='flex flex-col'>
        <p className='caption text-custom-gray-3'>{_t('fees')}</p>
        <div className='flex justify-between'>
          <p className='body2'>{_t('platformFee')}</p>
          <p className='body2'>{`${DEFAULT_CURRENCY_SYMBOL}${productFee}`}</p>
        </div>
        <div className='flex justify-between'>
          <p className='body2'>{_t('providerFeeMobile')}</p>
          <p className='body2'>{`${DEFAULT_CURRENCY_SYMBOL}${providerFee}`}</p>
        </div>
        <div className='flex justify-between'>
          <p className='body2 text-custom-turquoise'>{_t('earned')}</p>
          <p className='body2 text-custom-turquoise'>
            {DEFAULT_CURRENCY_SYMBOL + earned}
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className='flex flex-col gap-3'>
      <p className='body2 text-custom-gray-2 italic'>{_t('fees')}</p>
      <div className='flex flex-col gap-2.5'>
        <div className='flex justify-between'>
          <li className='body2'>
            {_t('platformFee') + ' ' + _t('percentFee')}
          </li>
          <p className='body2'>{`${DEFAULT_CURRENCY_SYMBOL}${productFee}`}</p>
        </div>
        <div className='flex justify-between'>
          <li className='body2'>{_t('providerFee')}</li>
          <p className='body2'>{`${DEFAULT_CURRENCY_SYMBOL}${providerFee}`}</p>
        </div>
        <div className='flex justify-between'>
          <p className='body1 font-bold text-custom-turquoise'>
            {_t('earned')}
          </p>
          <p className='body1 font-bold text-custom-turquoise'>
            {DEFAULT_CURRENCY_SYMBOL + earned}
          </p>
        </div>
      </div>
    </div>
  )
}

export default FeeDetails
