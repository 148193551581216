export enum UploadCoverPhotoTypeEnum {
  UploadPhoto,
  EditPhoto,
  ConfirmPhoto,
}

export enum CoverPhotoFormEnum {
  Photo = 'url',
  Description = 'description',
}
