import { useInfiniteQuery, useQuery, UseQueryOptions } from 'react-query'
import { ServerStateKeysEnum } from '@enums/serverState'
import { useProtection } from '@hooks/auth/useProtection'
import {
  TypeMemberCountResponse,
  TypeMemberFilters,
  TypeMemberGrowthDataPayload,
  TypeMemberGrowthDataResponse,
} from '@customTypes/member'
import apiService from '@services/api/apiService'
import { ApiKeyNameEnum } from '@enums/api'
import {
  TypeCommunityMember,
  TypeCommunityMembersResponse,
} from '@customTypes/community'
import queryString from 'query-string'
import { AxiosError } from 'axios'
import { UseInfiniteQueryOptions } from 'react-query/types/react/types'
import snakecaseKeys from 'snakecase-keys'
import { DEFAULT_MEMBER_PAGE_SIZE } from '@const/member'

/**********************************
 GET COMMUNITY MEMBERS
 **********************************/

type TypeGetMembersOptions = UseInfiniteQueryOptions<any, AxiosError, any>

const getCommunityMembers = async (
  communityId: string | undefined,
  page: string,
  filters: TypeMemberFilters | undefined,
): Promise<TypeCommunityMembersResponse> => {
  try {
    const filtersWithPage = {
      ...filters,
      page,
      pageSize: DEFAULT_MEMBER_PAGE_SIZE,
    }
    const queryParams = queryString.stringify(snakecaseKeys(filtersWithPage), {
      arrayFormat: 'comma',
      skipNull: true,
      skipEmptyString: true,
    })
    const qs = communityId + '?' + queryParams
    const response = await apiService.get(ApiKeyNameEnum.community_members, qs)
    return response.data
  } catch (err: any) {
    throw err
  }
}

export const useGetCommunityMembers = (
  id: string | undefined,
  filters?: TypeMemberFilters,
  options?: TypeGetMembersOptions,
) => {
  const queryOptions = useProtection<TypeGetMembersOptions>(options)

  return useInfiniteQuery<
    TypeCommunityMembersResponse,
    AxiosError,
    TypeCommunityMember
  >(
    [ServerStateKeysEnum.CommunityMembers, id, { filters }],
    ({ pageParam }) => getCommunityMembers(id, pageParam, filters),
    {
      select: data => {
        if (!data.pages[0]) {
          return {
            ...data,
            pages: [],
          }
        }
        return {
          ...data,
          pages: data.pages.flatMap(page => page.data),
        }
      },
      getNextPageParam: data => data.nextPage || undefined,
      getPreviousPageParam: data => data.previousPage || undefined,
      ...queryOptions,
    },
  )
}

/**********************************
 GET MEMBERS COUNT
 **********************************/

type TypeGetMembersCount = UseQueryOptions<
  TypeMemberCountResponse,
  AxiosError,
  TypeMemberCountResponse,
  any
>

const getMembersCount = async (
  id: string,
): Promise<TypeMemberCountResponse> => {
  try {
    const response = await apiService.get(ApiKeyNameEnum.members_count, id)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetMembersCount = (
  id: string,
  options?: TypeGetMembersCount,
) => {
  const queryOptions = useProtection<TypeGetMembersCount>(options)

  return useQuery(
    [ServerStateKeysEnum.CommunityMembersCount, id],
    () => getMembersCount(id),
    {
      ...queryOptions,
    },
  )
}

/**********************************
 GET MEMBER GROWTH DATA
 **********************************/

type TypeGetMemberGrowthOptions = UseQueryOptions<
  TypeMemberGrowthDataResponse,
  AxiosError,
  TypeMemberGrowthDataResponse,
  any
>

const getMemberGrowthData = async (
  payload: TypeMemberGrowthDataPayload,
): Promise<TypeMemberGrowthDataResponse> => {
  try {
    const queryParams = {
      year: payload.year,
      month: payload.month,
    }
    const qs = queryString.stringify(snakecaseKeys(queryParams), {
      arrayFormat: 'comma',
      skipNull: true,
      skipEmptyString: true,
    })
    const response = await apiService.get(
      ApiKeyNameEnum.member_growth_data,
      `${payload.communityId}/${payload.profileId}?${qs}`,
    )
    return response.data
  } catch (err: any) {
    throw err
  }
}

export const useGetMemberGrowthData = (
  payload: TypeMemberGrowthDataPayload,
  options?: TypeGetMemberGrowthOptions,
) => {
  const queryOptions = useProtection<TypeGetMemberGrowthOptions>(options)

  return useQuery(
    [ServerStateKeysEnum.CommunityMembers, 'Growth', payload],
    () => getMemberGrowthData(payload),
    {
      ...queryOptions,
    },
  )
}
