import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom'
import { telegramPageUtils } from '@containers/creator/telegramPage/utils'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'

const TelegramPage = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [isTelegramOpen, setIsTelegramOpen] = useState<boolean>(false)
  const isSmallerDevice = useIsSmallerDevice()

  useEffect(() => {
    const domain = searchParams.get('domain')
    const start = searchParams.get('start')
    if (!start || !domain) {
      return navigate(CREATOR_PATH.telegramErrorNoToken)
    }
    // Try open telegram App
    if (isSmallerDevice) {
      window.open(
        telegramPageUtils.generateOpenMemberBotLink(start, domain),
        '_self',
      )
      document.onvisibilitychange = function () {
        if (document.visibilityState === 'hidden') {
          setIsTelegramOpen(true)
        }
      }
      setTimeout(() => {
        if (isSmallerDevice && !isTelegramOpen) {
          navigate(
            CREATOR_PATH.telegramMobileLanding +
              '?' +
              telegramPageUtils.generateLinkQueryParams(start, domain),
          )
        }
      }, 2 * 1000)
    } else {
      navigate(
        CREATOR_PATH.telegramDeskLanding +
          '?' +
          telegramPageUtils.generateLinkQueryParams(start, domain),
      )
    }
  }, [isSmallerDevice, isTelegramOpen, navigate, searchParams])

  return <Outlet />
}

export default TelegramPage
