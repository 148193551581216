import React from 'react'
import classNames from 'classnames'
import { _t } from '@locales/index'
import { TELEGRAM_APP_LINK } from '@const/common'
import { ReactComponent as TelegramIcon } from '@assets/images/common/telegram-icon.svg'
import { ReactComponent as BotIcon } from '@assets/images/common/bot-happy.svg'
import { telegramPageUtils } from '@containers/creator/telegramPage/utils'

interface Props {
  domain: string
  start: string
}

const MobileAfterTg: React.FC<Props> = ({ start, domain }) => {
  return (
    <div
      className={classNames(
        'bg-custom-black text-white flex flex-col items-center justify-center',
      )}
    >
      <div className={classNames('text-white flex flex-col items-center mt-5')}>
        <h1
          className={classNames(
            'text-[32px] text-center font-bold font-extrabold text-transparent  bg-clip-text bg-gradient-to-r from-custom-yellow-1 via-custom-yellow-2 to-custom-yellow-3',
          )}
        >
          {_t('buttonToCommunityTg')}
        </h1>
      </div>
      <div className={'flex flex-col items-center justify-center'}>
        <div className={'flex flex-col items-center justify-between mt-[50px]'}>
          <button
            className={
              'w-[273px] h-[56px] bg-white rounded-full text-custom-black text-[18px] p-2'
            }
          >
            <div className={'flex items-center justify-center relative'}>
              <BotIcon className='w-10 h-10 absolute left-0' />
              <p className={'absolute right-3'}>{_t('openCommunityBot')}</p>
            </div>
          </button>
          <h1 className={classNames('text-[20px] mt-3')}>
            {_t('stillHaveTelegram')}
          </h1>
          <div className={'mt-8'}>
            <a target='_blank' href={TELEGRAM_APP_LINK} rel='noreferrer'>
              <button
                className={
                  'w-[257px] h-[56px] border-2 bg-custom-black rounded-full text-white text-[18px] p-2'
                }
                onClick={() =>
                  window.open(
                    telegramPageUtils.generateOpenMemberBotLink(start, domain),
                    '_self',
                  )
                }
              >
                <div className={'flex items-center justify-center relative'}>
                  <TelegramIcon className='w-10 h-10 absolute left-0' />
                  <p className={'absolute right-3'}>{_t('downloadTgMobile')}</p>
                </div>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileAfterTg
