export const CREATOR_PATH: Record<string, string> = {
  // root
  root: '/',

  setLanguage: '/set-language',
  // user
  user: '/user/',
  profile: '/user/profile',
  account: '/user/account',
  wallet: '/user/wallet',

  // admin
  newAdmin: '/admin/invite-link/',
  affiliator: '/admin/affiliator/',
  affiliatorRedirect: '/admin/affiliator-redirect',
  newAdminRedirect: '/invite-link',

  // Onboarding
  onboarding: '/onboarding',

  // get community
  community: '/community',
  communityId: '/community/:id/',

  // create and edit community
  createCommunity: '/community/create',
  editCommunity: '/community/:id/edit/',

  // community welcome video and photo
  uploadWelcomeVideo: '/community/:id/welcome-video',
  uploadWelcomeCoverPhoto: '/community/:id/cover-photo',

  // community settings
  communitySettings: '/community/:id/settings',
  communitySettingsEditName: '/community/:id/settings/name',
  communitySettingsEditDescription: '/community/:id/settings/description',
  communitySettingsEditSuppliedContent:
    '/community/:id/settings/supplied-content',
  communitySettingsEditWelcomeVideo: '/community/:id/settings/welcome-video',
  communitySettingsEditCoverPhoto: '/community/:id/settings/cover-photo',
  communitySettingsEditCommunityType: '/community/:id/settings/community-type',
  communitySettingsEditSubscriptionFee:
    '/community/:id/settings/subscription-fee',
  communitySettingsEditGuidelines: '/community/:id/settings/guidelines',
  communitySettingsEditLanguage: '/community/:id/settings/Language',
  communitySettingsEditWelcomeMessage:
    '/community/:id/settings/welcome-message',

  // community tabs
  communityHome: '/community/:id/home',
  communityCoupons: '/community/:id/promoCodes',
  communityMembers: '/community/:id/members',
  communityApproved: '/community/:id/approved',
  paymentsAndBilling: '/community/:id/payments-&-billing',
  affiliatePage: '/community/:id/affiliatePage',

  // community publish and launch date
  communityLive: '/community/:id/live',
  publishCommunity: '/community/:id/publish',
  setLaunchDate: '/community/:id/launch/set',
  changeLaunchDate: '/community/:id/launch/edit',
  confirmLaunchDate: '/community/:id/launch/confirm',
  launchDateCreated: '/community/:id/launch/created',
  launchDateContactUs: '/community/:id/launch/contact-us',
  confirmPublishCommunity: '/community/:id/publish/confirm',
  launchDateContactSent: '/community/:id/launch/contact-sent',
  setLaunchDateDisclaimer: '/community/:id/launch/disclaimer',
  changeLaunchDateDisclaimer: '/community/:id/launch/edit-disclaimer',

  // Contact
  contact: '/contact-us',

  // telegram page
  telegramPage: '/telegram',
  telegramLandingPage: '/telegram/community',
  telegramDeskLanding: '/telegram/desktop',
  telegramMobileLanding: '/telegram/mobile',
  telegramErrorNoToken: '/telegram/no-token',

  // No match
  noMatch: '*',
}
