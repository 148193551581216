import { ErrorCode, FileError } from 'react-dropzone'
import { _t } from '@locales/index'

export const getMediaUploadError = (error: FileError): string => {
  switch (error.code) {
    case ErrorCode.FileInvalidType:
      return _t('invalidMediaFileTypeError')
    default:
      return error.message
  }
}
