import { LanguagesEnum } from '@enums/language'
import { TypeLanguageKeys } from '@locales/index'
import * as EN from '@locales/en.json'
import * as HE from '@locales/he.json'
import * as DE from '@locales/de.json'
import * as RU from '@locales/ru.json'
import * as ES from '@locales/es.json'
import * as PT from '@locales/pt.json'
import * as AR from '@locales/ar.json'
import featureTexts from './feature-text.json'

const languages: any = {
  [LanguagesEnum.EN]: EN,
  [LanguagesEnum.HE]: HE,
  [LanguagesEnum.DE]: DE,
  [LanguagesEnum.RU]: RU,
  [LanguagesEnum.ES]: ES,
  [LanguagesEnum.PT]: PT,
  [LanguagesEnum.AR]: AR,
}

const DEFAULT_LANGUAGE = LanguagesEnum.EN

interface FeatureTexts {
  tasks: Record<
    string,
    {
      title: Record<string, string>
      cta: Record<string, string>
      description: Record<string, string>
    }
  >
  communityType: Record<
    string,
    {
      name: Record<string, string>
      description: Record<string, string>
    }
  >
  guideline: Record<
    string,
    {
      title: Record<string, string>
      description: Record<string, string>
    }
  >
  [key: string]: any // Index signature for allowing string indexing
}

class FeatureLocal {
  tasks: FeatureTexts['tasks'] = {}
  communityType: FeatureTexts['communityType'] = {}
  guideline: FeatureTexts['guideline'] = {}
  current: LanguagesEnum = '' as LanguagesEnum
  languageFile: any = null

  constructor() {
    this.setLocal()
  }

  public setLocal = () => {
    this.current = this.getLanguage()
    this.languageFile = languages[this.current] || languages[DEFAULT_LANGUAGE]
  }

  public getText = (key: TypeLanguageKeys) => {
    return this.languageFile[key] || languages[DEFAULT_LANGUAGE][key]
  }

  public getLanguage = (): LanguagesEnum => {
    const language = localStorage.getItem('language') || DEFAULT_LANGUAGE
    return language.slice(0, 2) as LanguagesEnum
  }
}

export const LocaleF = new FeatureLocal()

const _tf = (name: string, key: string) => {
  const languageKey = LocaleF.getLanguage()
  const translations = (featureTexts as FeatureTexts)[name][key]
  try {
    const translatedObject = Object.keys(translations).reduce((acc, curr) => {
      acc[curr] = translations[curr][languageKey] || ''
      return acc
    }, {} as Record<string, string>)

    return translatedObject
  } catch {
    return {}
  }
}

export default _tf
