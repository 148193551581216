import { useGetTransactionsForExport } from '@api/queries/billing'
import {
  TypeBillingTransaction,
  TypeExportTransactionsPayload,
} from '@customTypes/billing'
import { useEffect } from 'react'
import Papa from 'papaparse'
import { TRANSACTION_TYPE_TEXT } from '@const/billing'
import { TypeExportTransactionsType } from '@customTypes/payment'
import { ExportTransactionsTypeEnum } from '@enums/payment'
import moment from 'moment'

const getTransactionsDataForExport = (
  transactions: TypeBillingTransaction[],
): Object[] => {
  return transactions.map(transaction => ({
    Amount: transaction.totalAmount,
    Date: moment.unix(transaction.dueDate).format('DD-MM-YYYY'),
    MemberName:
      transaction.payingBillingEntity?.entity.name ||
      transaction.associatedBillingEntity.entity.name,
    MemberEmail:
      transaction.payingBillingEntity?.entity.email ||
      transaction.associatedBillingEntity.entity.email,
    Status: TRANSACTION_TYPE_TEXT[transaction.type],
    OwnerName: transaction.ownerBillingEntity?.entity.name,
    OwnerEmail: transaction.ownerBillingEntity?.entity.email,
    AffiliateName: transaction.affiliateBillingEntity?.entity.name,
    AffiliateEmail: transaction.affiliateBillingEntity?.entity.email,
    AffiliateFee: transaction.affiliateFee,
    Earned: transaction.amount,
    TransactionFee: transaction.providerFee,
    GrouperFee: transaction.productFee,
  }))
}

interface Props {
  payload: TypeExportTransactionsPayload
  callback: () => void
  type?: TypeExportTransactionsType
}

const useExportTransactionsCSV = ({ payload, callback, type }: Props) => {
  const { data: transactionsForExport } = useGetTransactionsForExport(payload, {
    enabled:
      type === ExportTransactionsTypeEnum.Wallet
        ? Boolean(payload.startDate)
        : Boolean(
            payload.startDate && payload.communityId && payload.profileId,
          ),
  })

  useEffect(() => {
    if (transactionsForExport && transactionsForExport.length > 0) {
      const dataForExport = getTransactionsDataForExport(transactionsForExport)

      const csv = Papa.unparse(dataForExport)

      const blob = new Blob([csv], { type: 'text/csv' })
      const url = URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.href = url
      a.download = 'data.csv'
      a.click()

      URL.revokeObjectURL(url)
      callback()
    }
  }, [callback, transactionsForExport])
}

export default useExportTransactionsCSV
