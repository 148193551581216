import React, { Fragment } from 'react'
import 'react-modern-drawer/dist/index.css'
import { useNavigate } from 'react-router-dom'
import { _t } from '@locales/index'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import DrawerMenuWrapper from '@containers/common/mobileDrawerMenu/components/DrawerMenuWrapper'
import DrawerMenuItem from '@containers/common/mobileDrawerMenu/components/DrawerMenuItem'

interface Props {
  show: boolean
  onClose: () => void
}

const HelpMenu: React.FC<Props> = ({ show, onClose }) => {
  const navigate = useNavigate()

  return (
    <DrawerMenuWrapper
      open={show}
      direction={'right'}
      onClose={onClose}
      size={'100vw'}
      title={_t('help')}
    >
      <Fragment>
        <DrawerMenuItem
          text={_t('contactUs')}
          onClick={() => navigate(CREATOR_PATH.contact)}
          onClose={onClose}
        />
      </Fragment>
    </DrawerMenuWrapper>
  )
}

export default HelpMenu
