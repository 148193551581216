import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { useGetMembersCount } from '@api/queries/member'

export const useGetCommunityMembersCount = (): number => {
  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)
  const { data: membersCountResponse } = useGetMembersCount(
    selectedCommunityId || '',
    {
      enabled: Boolean(selectedCommunityId),
    },
  )

  if (!membersCountResponse) {
    return 0
  }

  return membersCountResponse.count
}
