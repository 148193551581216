import { TypeMobileBottomDrawerType } from '@customTypes/common'
import {
  ExportOptionsDrawerMeta,
  TypeMobileBottomDrawerState,
  TypeSelectCurrencyFunction,
  TypeSelector,
} from '@customTypes/store'
import { createSelector } from '@reduxjs/toolkit'
import { MobileBottomDrawerTypeEnum } from '@enums/common'

const selectBottomDrawerState: TypeSelector<
  TypeMobileBottomDrawerState
> = state => state.mobileBottomDrawer

export const selectMobileBottomDrawerType: TypeSelector<TypeMobileBottomDrawerType | null> =
  createSelector(selectBottomDrawerState, state => state.type)

export const selectMobileBottomDrawerCurrencyFunction: TypeSelector<TypeSelectCurrencyFunction | null> =
  createSelector(selectBottomDrawerState, state => {
    if (
      state.type === MobileBottomDrawerTypeEnum.CurrencyPicker &&
      'onSelectCurrency' in state.meta!
    ) {
      return state.meta!.onSelectCurrency
    } else {
      return null
    }
  })

export const selectMobileBottomDrawerExportOptionsMeta: TypeSelector<ExportOptionsDrawerMeta | null> =
  createSelector(selectBottomDrawerState, state => {
    if (
      state.type === MobileBottomDrawerTypeEnum.ExportOptions &&
      state.meta &&
      'type' in state.meta!
    ) {
      return state.meta
    } else {
      return null
    }
  })
