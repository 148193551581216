import { TypeCoverPhotoModalState } from '@customTypes/store'
import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UploadCoverPhotoTypeEnum } from '@enums/coverPhoto'

const initialState: TypeCoverPhotoModalState = {
  type: UploadCoverPhotoTypeEnum.UploadPhoto,
  title: '',
  uploadKey: '',
  communityTitle: '',
  communityDescription: '',
  communityPhoto: '',
  visible: false,
  onSubmit: () => {},
}

const open: CaseReducer<
  TypeCoverPhotoModalState,
  PayloadAction<TypeCoverPhotoModalState>
> = (state, action) => {
  state.type = action.payload.type
  state.visible = action.payload.visible
  state.onSubmit = action.payload.onSubmit
  state.title = action.payload.title
  state.communityTitle = action.payload.communityTitle
  state.communityPhoto = action.payload.communityPhoto
  state.uploadKey = action.payload.uploadKey
}

const close: CaseReducer<TypeCoverPhotoModalState> = () => {
  return initialState
}

const updatePhoto: CaseReducer<
  TypeCoverPhotoModalState,
  PayloadAction<string>
> = (state, action) => {
  state.communityPhoto = action.payload
}

const uploadCoverPhotoModalSlice = createSlice({
  name: 'UploadCoverPhoto',
  initialState,
  reducers: {
    open,
    close,
    updatePhoto,
  },
})

export const {
  open: openUploadPhotoModal,
  close: closeUploadPhotoModal,
  updatePhoto: updateCoverPhoto,
} = uploadCoverPhotoModalSlice.actions
const uploadCoverPhotoModalReducer = uploadCoverPhotoModalSlice.reducer

export default uploadCoverPhotoModalReducer
