import { Outlet, RouteObject } from 'react-router-dom'

import { CREATOR_PATH } from './creatorPath'
import { ProtectedComponent } from '@components/wrappers/protectedComponent/ProtectedComponent'

import CommunityLayout from '@containers/creator/layouts/CommunityLayout'
import Account from '@containers/creator/account/Account'
import NoMatch from '@containers/common/noMatch/NoMatch'
import Coupons from '@containers/creator/coupons/Coupons'
import Community from '@containers/creator/community/Community'
import ContactUs from '@containers/creator/contactUs/ContactUs'
import OnboardingLayout from '@containers/creator/layouts/OnboardingLayout'
import CommunityMembers from '@containers/creator/members/CommunityMembers'
import CreateCommunity from '@containers/creator/createCommunity/CreateCommunity'
import SetLaunchDate from '@containers/creator/launchCommunity/screens/SetLaunchDate'
import CommunityPayment from '@containers/creator/payments/CommunityPayment'
import ChangeLaunchDate from '@containers/creator/launchCommunity/screens/ChangeLaunchDate'
import CommunityApproved from '@containers/creator/launchCommunity/screens/CommunityApproved'
import ConfirmLaunchDate from '@containers/creator/launchCommunity/screens/ConfirmLaunchDate'
import LaunchDateCreated from '@containers/creator/launchCommunity/screens/LaunchDateCreated'
import CommunityLive from '@containers/creator/launchCommunity/screens/CommunityLive'
import LaunchDateContactUs from '@containers/creator/launchCommunity/screens/LaunchDateContact'
import LaunchDateContactSent from '@containers/creator/launchCommunity/screens/LaunchDateContactSent'
import PublishCommunityConfirm from '@containers/creator/launchCommunity/screens/PublishCommunityConfirm'
import SetLaunchDateDisclaimer from '@containers/creator/launchCommunity/screens/SetLaunchDateDisclaimer'
import ChangeLaunchDateDisclaimer from '@containers/creator/launchCommunity/screens/ChangeLaunchDateDisclaimer'
import Onboarding from '@containers/creator/onboarding/Onboarding'
import UserLayout from '@containers/creator/layouts/UserLayout'
import ErrorPage from '@containers/creator/telegramPage/pages/errorPage'
import TelegramPage from '@containers/creator/telegramPage/TelegramPage'
import MobileLandingPage from '@containers/creator/telegramPage/pages/mobileLanding'
import DeskTelegramLanding from '@containers/creator/telegramPage/pages/DownloadTelegramOptions'
import PublishCommunity from '@containers/creator/launchCommunity/screens/PublishCommunity'
import EditCommunityName from '@containers/creator/editCommunity/signUpPage/EditCommunityName'
import EditWhatsIncluded from '@containers/creator/editCommunity/signUpPage/EditWhatsIncluded'
import EditCommunityType from '@containers/creator/editCommunity/signUpPage/EditCommunityType'
import EditSubscriptionFee from '@containers/creator/editCommunity/signUpPage/EditSubscriptionFee'
import EditCoverPhoto from '@containers/creator/editCommunity/signUpPage/EditCoverPhoto'
import EditCommunityGuidelines from '@containers/creator/editCommunity/EditCommunityGuidelines'
import EditProfileWrapper from '@containers/creator/profile/EditProfileWrapper'
import UploadWelcomeVideo from '@containers/creator/uploadWelcomeVideo/UploadWelcomeVideo'
import UploadCoverPhoto from '@containers/creator/uploadCoverPhoto/UploadCoverPhoto'
import EditWelcomeVideo from '@containers/creator/editCommunity/signUpPage/EditWelcomeVideo'
import EditDescription from '@containers/creator/editCommunity/signUpPage/EditDescription'
import Wallet from '@containers/creator/payments/Wallet'
import PaymentLayout from '@containers/creator/layouts/PaymentLayout'
import AffiliatePage from '@containers/creator/affiliatePage/AffiliatePage'
import EditCommunityLanguage from '@containers/creator/editCommunity/EditCommunityLanguage'

export const COMMUNITY_OWNER_ROUTES: RouteObject[] = [
  // onboarding
  {
    path: CREATOR_PATH.onboarding,
    element: <OnboardingLayout />,
    children: [
      {
        index: true,
        element: <ProtectedComponent component={Onboarding} />,
      },
    ],
  },

  // user
  {
    element: <UserLayout />,
    children: [
      {
        path: CREATOR_PATH.profile,
        element: <ProtectedComponent component={EditProfileWrapper} />,
      },
      {
        path: CREATOR_PATH.account,
        element: <ProtectedComponent component={Account} />,
      },
      {
        path: CREATOR_PATH.contact,
        element: <ProtectedComponent component={ContactUs} />,
      },
    ],
  },

  {
    element: <PaymentLayout />,
    children: [
      {
        path: CREATOR_PATH.wallet,
        element: <ProtectedComponent component={Wallet} />,
      },
    ],
  },

  // create community
  {
    path: CREATOR_PATH.createCommunity,
    element: <ProtectedComponent component={CreateCommunity} />,
  },

  // community
  {
    path: CREATOR_PATH.root,
    element: <CommunityLayout />,
    children: [
      {
        index: true,
        element: <ProtectedComponent component={Community} />,
      },

      {
        path: CREATOR_PATH.communityId,
        element: <ProtectedComponent component={Community} />,
      },

      // community tabs
      {
        path: CREATOR_PATH.communityCoupons,
        element: <ProtectedComponent component={Coupons} />,
      },
      {
        path: CREATOR_PATH.communityMembers,
        element: <ProtectedComponent component={CommunityMembers} />,
      },
      {
        path: CREATOR_PATH.paymentsAndBilling,
        element: <ProtectedComponent component={CommunityPayment} />,
      },
      {
        path: CREATOR_PATH.affiliatePage,
        element: <ProtectedComponent component={AffiliatePage} />,
      },

      // publish and launch community
      {
        path: CREATOR_PATH.communityApproved,
        element: <ProtectedComponent component={CommunityApproved} />,
      },
      {
        path: CREATOR_PATH.publishCommunity,
        element: <ProtectedComponent component={PublishCommunity} />,
      },
      {
        path: CREATOR_PATH.confirmPublishCommunity,
        element: <ProtectedComponent component={PublishCommunityConfirm} />,
      },
      {
        path: CREATOR_PATH.setLaunchDateDisclaimer,
        element: <ProtectedComponent component={SetLaunchDateDisclaimer} />,
      },
      {
        path: CREATOR_PATH.setLaunchDate,
        element: <ProtectedComponent component={SetLaunchDate} />,
      },
      {
        path: CREATOR_PATH.changeLaunchDate,
        element: <ProtectedComponent component={ChangeLaunchDate} />,
      },
      {
        path: CREATOR_PATH.changeLaunchDateDisclaimer,
        element: <ProtectedComponent component={ChangeLaunchDateDisclaimer} />,
      },
      {
        path: CREATOR_PATH.confirmLaunchDate,
        element: <ProtectedComponent component={ConfirmLaunchDate} />,
      },
      {
        path: CREATOR_PATH.launchDateCreated,
        element: <ProtectedComponent component={LaunchDateCreated} />,
      },
      {
        path: CREATOR_PATH.communityLive,
        element: <ProtectedComponent component={CommunityLive} />,
      },
      {
        path: CREATOR_PATH.launchDateContactUs,
        element: <ProtectedComponent component={LaunchDateContactUs} />,
      },
      {
        path: CREATOR_PATH.launchDateContactSent,
        element: <ProtectedComponent component={LaunchDateContactSent} />,
      },
      {
        path: CREATOR_PATH.uploadWelcomeVideo,
        element: <ProtectedComponent component={UploadWelcomeVideo} />,
      },
      {
        path: CREATOR_PATH.uploadWelcomeCoverPhoto,
        element: <ProtectedComponent component={UploadCoverPhoto} />,
      },
    ],
  },

  // community settings
  {
    path: CREATOR_PATH.communitySettings,
    element: <CommunityLayout bgClass='bg-custom-green-gray' />,
    children: [
      {
        path: CREATOR_PATH.communitySettings,
        element: <ProtectedComponent component={EditCommunityName} />,
      },
      {
        path: CREATOR_PATH.communitySettingsEditName,
        element: <ProtectedComponent component={EditCommunityName} />,
      },
      {
        path: CREATOR_PATH.communitySettingsEditDescription,
        element: <ProtectedComponent component={EditDescription} />,
      },
      {
        path: CREATOR_PATH.communitySettingsEditSuppliedContent,
        element: <ProtectedComponent component={EditWhatsIncluded} />,
      },
      {
        path: CREATOR_PATH.communitySettingsEditWelcomeVideo,
        element: <ProtectedComponent component={EditWelcomeVideo} />,
      },
      {
        path: CREATOR_PATH.communitySettingsEditCoverPhoto,
        element: <ProtectedComponent component={EditCoverPhoto} />,
      },
      {
        path: CREATOR_PATH.communitySettingsEditCommunityType,
        element: <ProtectedComponent component={EditCommunityType} />,
      },
      {
        path: CREATOR_PATH.communitySettingsEditSubscriptionFee,
        element: <ProtectedComponent component={EditSubscriptionFee} />,
      },
      {
        path: CREATOR_PATH.communitySettingsEditGuidelines,
        element: <ProtectedComponent component={EditCommunityGuidelines} />,
      },
      {
        path: CREATOR_PATH.communitySettingsEditGuidelines,
        element: <ProtectedComponent component={EditCommunityGuidelines} />,
      },
      {
        path: CREATOR_PATH.communitySettingsEditLanguage,
        element: <ProtectedComponent component={EditCommunityLanguage} />,
      },
      {
        path: CREATOR_PATH.communitySettingsEditWelcomeMessage,
      },
    ],
  },

  // connect to telegram
  {
    path: CREATOR_PATH.telegramPage,
    element: <TelegramPage />,
    children: [
      {
        path: CREATOR_PATH.telegramDeskLanding,
        element: <DeskTelegramLanding />,
      },
      {
        path: CREATOR_PATH.telegramMobileLanding,
        element: <MobileLandingPage />,
      },
      {
        path: CREATOR_PATH.telegramErrorNoToken,
        element: <ErrorPage />,
      },
    ],
  },

  {
    path: CREATOR_PATH.newAdmin,
    element: <Outlet />,
  },
  {
    path: CREATOR_PATH.affiliator,
    element: <Outlet />,
  },
  // No Match
  { path: CREATOR_PATH.noMatch, element: <NoMatch /> },
]
