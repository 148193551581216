import React from 'react'
import { _t } from '@locales/index'

interface Props {
  count: number
  label?: string
}

const MemberCount: React.FC<Props> = ({ count, label }) => {
  return (
    <div className='flex flex-col items-center justify-center gap-2 p-5 bg-white rounded-xl w-full custom-shadow-4'>
      <p className='h1 font-pangram-regular'>{count}</p>
      <p className='body2 text-custom-purple font-pangram-regular'>
        {label || _t('segmentationWidgetAllName')}
      </p>
    </div>
  )
}

export default MemberCount
