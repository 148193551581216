import React, { useMemo } from 'react'
import { ReactComponent as ChatIcon } from '@assets/images/common/chat.svg'
import { _t } from '@locales/index'
import { MEMBER_STATUS_TEXT, MESSAGE_MEMBER_URL } from '@const/member'
import Avatar from '@components/avatar/Avatar'
import moment from 'moment'
import { TypeCommunityMember } from '@customTypes/community'

interface Props {
  member: TypeCommunityMember
}

const MemberRow: React.FC<Props> = ({ member }) => {
  const memberName = useMemo(() => {
    if (member.member.firstName && member.member.lastName) {
      return member.member.firstName + ' ' + member.member.lastName
    } else if (member.member.username) {
      return member.member.username
    }
    return member.member.email
  }, [
    member.member.email,
    member.member.firstName,
    member.member.lastName,
    member.member.username,
  ])

  return (
    <tr className='border-b'>
      <td className='px-2.5 py-3'>
        <div className='flex items-center w-full h-full gap-3'>
          <Avatar name={memberName} widthClass='w-[3.125rem] h-[3.125rem]' />
          <p className='font-poppins text-lg capitalize'>{memberName}</p>
        </div>
      </td>
      <td className='px-2.5 py-3'>
        <div className='flex items-center w-full h-full'>
          <p className='font-poppins capitalize text-lg'>
            {MEMBER_STATUS_TEXT[member.status]}
          </p>
        </div>
      </td>
      {/*<td className='px-2.5 py-3'>*/}
      {/*  <div className='flex items-center w-full h-full'>*/}
      {/*    <p className='font-poppins capitalize text-lg'>*/}
      {/*      {MEMBER_ENGAGEMENT_TEXT[entity.engagement]}*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*</td>*/}
      <td className='px-2.5 py-3'>
        <div className='flex items-center w-full h-full'>
          <p className='font-poppins capitalize text-lg'>
            {moment.unix(member.created).fromNow(true)}
          </p>
        </div>
      </td>
      <td className='px-2.5 py-3'>
        <div className='flex items-center w-full h-full'>
          <a
            href={MESSAGE_MEMBER_URL + member.member.username}
            target='_blank'
            rel='noreferrer'
          >
            <button
              className='btn-base btn-inverted-contained rounded shadow-none w-[120px] text-xs font-poppins'
              type={'button'}
            >
              <ChatIcon fill='white' className='w-4 h-4 mr-[5px]' />
              <span>{_t('message')}</span>
            </button>
          </a>
        </div>
      </td>
    </tr>
  )
}

export default MemberRow
