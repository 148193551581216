import { TypeMemberFilters } from '@customTypes/member'
import { URLSearchParamsInit } from 'react-router-dom'
import queryString from 'query-string'

export const MemberFiltersFormInitialValues: TypeMemberFilters = {
  search: '',
  // engagement: [],
  time: [],
  status: [],
}

export const getInitialFormValues = (
  searchParams: URLSearchParams,
): TypeMemberFilters => {
  if (searchParams) {
    const params = queryString.parse(searchParams.toString(), {
      arrayFormat: 'separator',
    })

    const values: Record<string, any> = {}

    // converting params from url to form values
    for (const key in params) {
      const value = params[key]
      if (Array.isArray(params[key])) {
        values[key] = value
      } else if (typeof value === 'string') {
        if (['orderBy', 'sortBy'].includes(key)) {
          values[key] = value
        } else {
          values[key] = [value]
        }
      }
    }

    return {
      ...MemberFiltersFormInitialValues,
      ...(values as TypeMemberFilters),
    }
  }
  return MemberFiltersFormInitialValues
}

export const getMemberFilterSearchParams = (
  values: TypeMemberFilters,
): URLSearchParamsInit => {
  return queryString.stringify(values, {
    arrayFormat: 'separator',
    skipNull: true,
    skipEmptyString: true,
  })
}
