import React, { useCallback } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { _t } from '@locales/index'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import ScissorsImage from '@assets/images/common/scissors.png'

import LunchCommunityWrapper from '@containers/creator/launchCommunity/LaunchCommunityWrapper'

const CommunityLive = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const handleConfirmClick = useCallback(() => {
    if (id) {
      navigate(CREATOR_PATH.communityId.replace(':id', id))
    } else {
      navigate('/')
    }
  }, [id, navigate])

  return (
    <LunchCommunityWrapper>
      <div className='h-full p-5 sm:p-13 flex flex-col items-center gap-13'>
        <div>
          <h3 className='h3 capitalize text-center'>
            {_t('communityLaunchedTitle')}
          </h3>
          <p className='body1 capitalize text-center text-custom-pink'>
            {_t('communityLaunchedSubtitle')}
          </p>
        </div>
        <img src={ScissorsImage} alt='scissors' className='w-40 h-40' />
        <button className='btn btn-pink' onClick={handleConfirmClick}>
          {_t('communityLaunchedButton')}
        </button>
      </div>
    </LunchCommunityWrapper>
  )
}

export default CommunityLive
