import React, { useCallback } from 'react'
import 'react-modern-drawer/dist/index.css'

interface Props {
  text: string
  onClick?: () => void
  closeOnClick?: boolean
  onClose?: () => void
}

const DrawerMenuItem: React.FC<Props> = ({ text, onClick, onClose }) => {
  const handleClick = useCallback(() => {
    onClose && onClose()
    onClick && onClick()
  }, [onClick, onClose])

  return (
    <div
      className='w-full h-[70px] px-5 flex items-center border-b-2 border-custom-gray-5 cursor-pointer bg-white'
      onClick={handleClick}
    >
      <div className='w-4' />
      <p className='text-lg  font-poppins capitalize'>{text}</p>
    </div>
  )
}

export default DrawerMenuItem
