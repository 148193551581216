import { array, object, string } from 'yup'
import { _t } from '@locales/index'
import { MINIMUM_PROMO_CODE_LENGTH } from '@const/promoCode'
import { TypePromoCode } from '@customTypes/promoCode'
import { PromoCodeStatusEnum } from '@enums/promoCode'
import moment from 'moment'

const couponsValidationSchema = object().shape({
  couponCode: string().min(MINIMUM_PROMO_CODE_LENGTH, _t('promoCodeErrorMin')),
  couponDuration: array().min(2, _t('promoCodeDurationError')),
  couponDiscount: string().required(_t('promoCodeDiscountError')),
})

const handleDaysLeft = (coupon: TypePromoCode) => {
  const validFrom = moment(coupon.validFromDate).format('MMM D YYYY') //new Date(coupon.validFromDate)
  const validUntil = moment(coupon.validUntilDate).format('MMM D YYYY')
  const today = moment().format('MMM D YYYY')
  let daysLeft = 0

  if (
    coupon.status === PromoCodeStatusEnum.Revoked ||
    coupon.status === PromoCodeStatusEnum.Expired
  ) {
    return daysLeft
  }
  // done for start in today's month
  if (Number(moment(validFrom).format('M')) === Number(moment().format('M'))) {
    // // if start date day === to end date day
    if (
      Number(moment(validFrom).format('D')) ===
      Number(moment(validUntil).format('D'))
    ) {
      daysLeft =
        moment(coupon.validUntilDate).diff(
          moment(coupon.validFromDate),
          'days',
        ) + 1
    } else {
      const startDayDate =
        moment(validFrom).format('D') > moment().format('D') ? validFrom : today
      const diff = moment(coupon.validUntilDate).diff(moment(startDayDate))
      daysLeft =
        diff > 0
          ? Number(
              moment(
                moment(coupon.validUntilDate).diff(moment(startDayDate)),
              ).format('D'),
            )
          : 0
    }
  }
  // end if for start in today's month
  else if (
    Number(moment(validFrom).format('M')) > Number(moment().format('M'))
  ) {
    daysLeft = Number(
      moment(
        moment(coupon.validUntilDate).diff(moment(coupon.validFromDate)),
      ).format('D'),
    )
  }
  //validFromDate month smaller than today month
  // validUntilDate month for sure == to today month, and day bigger than today, else revoked.
  else {
    daysLeft = Number(
      moment(moment(coupon.validUntilDate).diff(moment(today))).format('D'),
    )
  }
  return daysLeft < 0 ? 0 : daysLeft
}

const formatDateString = (date: Date) => {
  const day =
    date.getDate().toString().length === 1
      ? '0' + date.getDate()
      : date.getDate()
  const month =
    (date.getMonth() + 1).toString().length === 1
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1
  return date.getFullYear() + '-' + month + '-' + day
}

export const CouponsUtils: Record<string, any> = {
  handleDaysLeft,
  formatDateString,
  couponsValidationSchema,
}
