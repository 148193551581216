import React, { Fragment } from 'react'
import LogoWithText from '@assets/images/common/logo-with-text-black.svg'
import WhiteLogoWithText from '@assets/images/common/logo-with-text-white.svg'

interface Props {
  onClick?: () => void
  logoColor?: string
}

const Logo: React.FC<Props> = ({ onClick, logoColor }) => {
  return (
    <Fragment>
      <img
        src={logoColor === 'white' ? WhiteLogoWithText : LogoWithText}
        className='cursor-pointer h-[28px] sm:h-8'
        alt='logo'
        onClick={onClick}
      />
    </Fragment>
  )
}

export default Logo
