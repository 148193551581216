import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TypeModalState, TypeModalType } from '@customTypes/store'

const initialState: TypeModalState = {
  type: null,
}

/* CASE REDUCERS */
const setType: CaseReducer<
  TypeModalState,
  PayloadAction<TypeModalType | null>
> = (state, action) => {
  state.type = action.payload
}

const close: CaseReducer<TypeModalState> = state => {
  state.type = null
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setType,
    close,
  },
})

export const { setType: setModalType, close: closeModal } = modalSlice.actions
const modalReducer = modalSlice.reducer

export default modalReducer
