import { propEq } from 'ramda'

import {
  TypeAffiliateLink,
  TypeAffiliateLinkType,
} from '@customTypes/affiliate'
import { _t } from '@locales/index'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import { AffiliateLinkTypeEnum } from '@enums/affiliate'
import {
  TypeCommunity,
  TypeCommunityAdminRole,
  TypeCommunityMenu,
} from '@customTypes/community'
import { TypeCommunityMedia, TypeCommunityMediaType } from '@customTypes/media'

import { ReactComponent as VoucherIcon } from '@assets/images/sideMenu/voucher.svg'
import { ReactComponent as SettingsIcon } from '@assets/images/sideMenu/settings.svg'
import { ReactComponent as MembersIcon } from '@assets/images/sideMenu/members.svg'
import { ReactComponent as ShareIcon } from '@assets/images/sideMenu/share-icon.svg'
import { ReactComponent as HomeIcon } from '@assets/images/sideMenu/home.svg'
import { ReactComponent as PaymentIcon } from '@assets/images/sideMenu/payment.svg'

import { CommunityMediaTypeEnum } from '@enums/media'
import { TELEGRAM_COMMUNITY_LINK } from '@const/community'
import { TypeMenuItem, TypeSubmenuItem } from '@customTypes/sidebar'
import { CommunityAdminRoleEnum } from '@enums/community'
import { OPEN_TELEGRAM_BROWSER_LINK_BASE } from '@const/common'

export const getCommunityMenu = (
  community?: TypeCommunity,
  communityAdminRole?: TypeCommunityAdminRole,
): TypeCommunityMenu | null => {
  if (!community) {
    return null
  }

  const { id: communityId, title } = community

  const inviteUrl = getAffiliateUrlByType(
    AffiliateLinkTypeEnum.CommunityProfile,
    community.affiliateLinks,
  )
  const communityUrl = TELEGRAM_COMMUNITY_LINK + community.providerId
  let isCoverImage = false
  if ('media' in community && community.media !== null) {
    isCoverImage = community?.media[0].subType === CommunityMediaTypeEnum.Cover
  }

  let menuItems = [
    {
      text: _t('payment'),
      icon: PaymentIcon,
      path: CREATOR_PATH.paymentsAndBilling.replace(':id', communityId),
    },
    {
      text: _t('members'),
      icon: MembersIcon,
      path: CREATOR_PATH.communityMembers.replace(':id', communityId),
    },
    {
      text: _t('affiliatePage'),
      icon: ShareIcon,
      path: CREATOR_PATH.affiliatePage.replace(':id', communityId),
    },
  ]

  let showFullMenu = communityAdminRole === CommunityAdminRoleEnum.Owner

  let showAffiliateMenu =
    communityAdminRole === CommunityAdminRoleEnum.Affiliate

  if (showAffiliateMenu) {
    menuItems = [
      {
        text: _t('members'),
        icon: MembersIcon,
        path: CREATOR_PATH.communityMembers.replace(':id', communityId),
      },
      {
        text: _t('payment'),
        icon: PaymentIcon,
        path: CREATOR_PATH.paymentsAndBilling.replace(':id', communityId),
      },
    ]
  }
  if (showFullMenu) {
    menuItems = [
      {
        text: _t('home'),
        icon: HomeIcon,
        path: CREATOR_PATH.communityId.replace(':id', communityId),
      },
      {
        text: _t('promoCodes'),
        icon: VoucherIcon,
        path: CREATOR_PATH.communityCoupons.replace(':id', communityId),
      },
      {
        text: _t('payment'),
        icon: PaymentIcon,
        path: CREATOR_PATH.paymentsAndBilling.replace(':id', communityId),
      },
      {
        text: _t('members'),
        icon: MembersIcon,
        path: CREATOR_PATH.communityMembers.replace(':id', communityId),
      },
    ]
  }

  const menu: TypeCommunityMenu = {
    id: communityId,
    title: title,
    communityStatus: community.communityStatus,
    inviteUrl,
    communityUrl,
    menuItems: menuItems,
  }

  const settingsMenu: TypeMenuItem = {
    text: _t('settings'),
    icon: SettingsIcon,
    path: CREATOR_PATH.communitySettings.replace(':id', communityId),
    expandedMenu: [
      {
        text: _t('communitySettingsMenuEditSignUpPage'),
        subMenu: [
          {
            text: _t('communitySettingsMenuEditName'),
            path: CREATOR_PATH.communitySettingsEditName.replace(
              ':id',
              communityId,
            ),
          },
          {
            text: _t('communitySettingsMenuEditSuppliedContent'),
            path: CREATOR_PATH.communitySettingsEditSuppliedContent.replace(
              ':id',
              communityId,
            ),
          },
          {
            text: isCoverImage
              ? _t('communitySettingsMenuEditCoverPhoto')
              : _t('communitySettingsMenuEditWelcomeVideo'),
            path: isCoverImage
              ? CREATOR_PATH.communitySettingsEditCoverPhoto.replace(
                  ':id',
                  communityId,
                )
              : CREATOR_PATH.communitySettingsEditWelcomeVideo.replace(
                  ':id',
                  communityId,
                ),
          },
          {
            text: _t('communitySettingsMenuEditCommunityType'),
            path: CREATOR_PATH.communitySettingsEditCommunityType.replace(
              ':id',
              communityId,
            ),
          },
          {
            text: _t('communitySettingsMenuEditSubscriptionFee'),
            path: CREATOR_PATH.communitySettingsEditSubscriptionFee.replace(
              ':id',
              communityId,
            ),
          },
        ],
      },
      {
        text: _t('communitySettingsMenuEditGuidelines'),
        path: CREATOR_PATH.communitySettingsEditGuidelines.replace(
          ':id',
          communityId,
        ),
      },
      {
        text: _t('communitySettingsMenuEditLanguage'),
        path: CREATOR_PATH.communitySettingsEditLanguage.replace(
          ':id',
          communityId,
        ),
      },
    ],
  }
  const descriptionMenuItem: TypeSubmenuItem = {
    text: _t('description'),
    path: CREATOR_PATH.communitySettingsEditDescription.replace(
      ':id',
      communityId,
    ),
  }
  const affiliateMenuItem = {
    text: _t('affiliatePage'),
    icon: ShareIcon,
    path: CREATOR_PATH.affiliatePage.replace(':id', communityId),
  }
  if (inviteUrl && menuItems) {
    menuItems.splice(3, 0, affiliateMenuItem)
  }

  if (isCoverImage && settingsMenu.expandedMenu) {
    settingsMenu?.expandedMenu[0]?.subMenu?.splice(2, 0, descriptionMenuItem)
  }
  if (showFullMenu) {
    menu.menuItems.push(settingsMenu)
  }
  return menu
}

export const getCommunityMediaByType = (
  mediaType: TypeCommunityMediaType,
  media: TypeCommunityMedia[],
): TypeCommunityMedia[] | undefined =>
  media ? media.filter(propEq('subType', mediaType)) : undefined

export const getAffiliateUrlByType = (
  type: TypeAffiliateLinkType,
  links: TypeAffiliateLink[],
): string | undefined =>
  links ? links.find(propEq('linkType', type))?.shortenUrl : undefined

export const getAffiliateTelegramUrlByType = (
  type: TypeAffiliateLinkType,
  profileId: string,
  links: TypeAffiliateLink[],
  customBotId: string,
): string | undefined => {
  const matchingLink = links.find(
    af => af.profileId === profileId && af.linkType === type,
  )

  if (matchingLink && matchingLink.token) {
    return `${OPEN_TELEGRAM_BROWSER_LINK_BASE}${customBotId}?start=${matchingLink.token}`
  }

  return undefined
}

export const getAffiliateUrlByTypeAndProfile = (
  type: TypeAffiliateLinkType,
  profileId: string,
  links: TypeAffiliateLink[],
): string | undefined =>
  links
    ? links
        .filter(af => af.profileId === profileId)
        .find(af => af.linkType === type)?.shortenUrl
    : undefined

export const getLocalStorageCommunityAdminKey = (
  communityId: string,
  profileId: string,
): string => {
  return `${profileId} + ${communityId} + role`
}
