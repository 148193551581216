import React, { useMemo } from 'react'
import { selectCurrentCommunityId } from '@selectors/community'
import { useAppSelector } from '@hooks/store/redux'
import classNames from 'classnames'
import { SIDEBAR_WIDTH } from '@const/layout'
import { ORGANIZATION_PATH } from '@routes/organization/organizationPath'
import { ReactComponent as MembersIcon } from '@assets/images/sideMenu/members.svg'
import { _t } from '@locales/index'
import { useGetAudiences } from '@api/queries/segmentation'
import { useGetCommunity } from '@api/queries/community'
import Skeleton from '@components/skeleton/Skeleton'
import { TypeSidebarMenuItems } from '@customTypes/sidebar'
import OrganizationSidebarMenuItem from '@containers/organization/sidebar/components/OrganizationSidebarMenuItem'
import ContactButton from '@root/src/containers/organization/sidebar/components/ContactButton'

const OrganizationSidebar = () => {
  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)

  const { isLoading: isCommunityLoading } = useGetCommunity(
    selectedCommunityId,
    {
      enabled: Boolean(selectedCommunityId),
    },
  )
  const { data: communityAudiences, isLoading: isCommunityAudiencesLoading } =
    useGetAudiences(selectedCommunityId, {
      enabled: Boolean(selectedCommunityId),
    })

  const organizationSidebarMenuItems =
    useMemo<TypeSidebarMenuItems | null>(() => {
      if (communityAudiences && communityAudiences.length > 0) {
        return communityAudiences.map(audience => {
          return {
            label: audience.title,
            path: ORGANIZATION_PATH.audience
              .replace(':id', selectedCommunityId || '')
              .replace(':audienceId', audience.id),
            description: audience.description,
          }
        })
      }
      return null
    }, [communityAudiences, selectedCommunityId])

  return (
    <div className='hidden sm:flex'>
      <div
        className={classNames(
          'bg-white max-h-screen h-full border-r border-custom-gray-5 relative transition-all ease-linear z-10 flex flex-col',
        )}
        style={{
          width: SIDEBAR_WIDTH,
        }}
      >
        {isCommunityLoading || isCommunityAudiencesLoading ? (
          <Skeleton />
        ) : (
          <>
            <div className='flex flex-col h-full no-scrollbar overflow-y-scroll no-scrollbar'>
              <OrganizationSidebarMenuItem
                label={_t('organizationAllMembersMenuItem')}
                path={ORGANIZATION_PATH.organizationId.replace(
                  ':id',
                  selectedCommunityId || '',
                )}
                Icon={MembersIcon}
              />

              {organizationSidebarMenuItems?.map((menuItem, i) =>
                menuItem.label ? (
                  <OrganizationSidebarMenuItem key={i} {...menuItem} />
                ) : null,
              )}
            </div>
            <ContactButton />
          </>
        )}
      </div>
    </div>
  )
}

export default OrganizationSidebar
