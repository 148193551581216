import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TypeBannerState, TypeBannerType } from '@customTypes/store'

const initialState: TypeBannerState = {
  type: null,
}

/* CASE REDUCERS */
const setType: CaseReducer<
  TypeBannerState,
  PayloadAction<TypeBannerType | null>
> = (state, action) => {
  state.type = action.payload
}

const bannerSlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    setType,
  },
})

export const { setType: setBannerType } = bannerSlice.actions
const bannerReducer = bannerSlice.reducer

export default bannerReducer
