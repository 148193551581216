import { useMemo } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { useGetCommunities } from '@api/queries/community'
import { CommunityApprovalStatusEnum } from '@enums/community'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'

const useShowCommunityMenu = (): boolean => {
  const { isAuthenticated } = useAuth0()
  const isSmallerDevice = useIsSmallerDevice()
  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)

  const { data: communities } = useGetCommunities()

  const selectedCommunity = useMemo(
    () =>
      communities &&
      communities.find(community => community.id === selectedCommunityId),
    [communities, selectedCommunityId],
  )

  const isSelectedCommunityApproved = useMemo<Boolean>(
    () =>
      selectedCommunity?.communityStatus ===
      CommunityApprovalStatusEnum.Approved,
    [selectedCommunity?.communityStatus],
  )

  return useMemo(
    () =>
      Boolean(
        isAuthenticated &&
          isSmallerDevice &&
          selectedCommunity &&
          isSelectedCommunityApproved,
      ),
    [
      isAuthenticated,
      isSelectedCommunityApproved,
      isSmallerDevice,
      selectedCommunity,
    ],
  )
}

export default useShowCommunityMenu
