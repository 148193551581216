import React from 'react'
import { _t } from '@locales/index'
import {
  TypeBillingTransactionFilters,
  TypeBillingTransactionResponse,
} from '@customTypes/billing'
import MonthNavigation from './MonthNavigation'
import Pagination from './Pagination'
import TransactionItem from '@containers/creator/communityTransactionsTable/components/TransactionItem'
import Skeleton from '@components/skeleton/Skeleton'

interface Props {
  transactions: TypeBillingTransactionResponse | undefined
  transactionsLoading: boolean
  filters: TypeBillingTransactionFilters
  setFilters: React.Dispatch<
    React.SetStateAction<TypeBillingTransactionFilters>
  >
  page: number
  setPage: (page: number) => void
}

const ListView: React.FC<Props> = ({
  transactions,
  transactionsLoading,
  filters,
  setFilters,
  page,
  setPage,
}) => (
  <div className='flex flex-col gap-4 py-5 rounded-xl bg-white'>
    <div className='flex justify-between px-3'>
      <h3 className='body1'>{_t('membersTransactionsHistory')}</h3>
      <MonthNavigation date={filters.startDate} setFilters={setFilters} />
    </div>
    {transactions && transactions.numPages > 1 && (
      <div className='flex justify-end'>
        <Pagination
          pageCount={transactions.numPages}
          currentPage={page}
          onPageChange={setPage}
        />
      </div>
    )}
    <div className='flex flex-col'>
      {!transactionsLoading &&
        transactions &&
        transactions.data.map(transaction => (
          <TransactionItem key={transaction.id} transaction={transaction} />
        ))}
      {transactionsLoading && <Skeleton className='w-full h-12' />}
    </div>
  </div>
)

export default ListView
