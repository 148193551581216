import React, { useCallback } from 'react'
import { _t } from '@locales/index'
import { ReactComponent as BigMicrophoneIcon } from '@assets/images/common/big-mic.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import LunchCommunityWrapper from '@containers/creator/launchCommunity/LaunchCommunityWrapper'
import LaunchScreenFooter from '@containers/creator/launchCommunity/components/LaunchScreenFooter'

const SetLaunchDateDisclaimer = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const handleClickContinue = useCallback(() => {
    if (id) {
      navigate(CREATOR_PATH.setLaunchDate.replace(':id', id))
    }
  }, [id, navigate])

  return (
    <LunchCommunityWrapper>
      <div className='max-w-[468px]'>
        <div className='p-5 sm:p-13 py-13 flex flex-col gap-7.5'>
          <div className='flex flex-col gap-3'>
            <div className='flex gap-4 items-center'>
              <BigMicrophoneIcon fill={'#D02E8F'} className='w-12 h-12' />
              <p className='body2 capitalize text-custom-pink'>
                {_t('launchDateDisclaimerTitle')}
              </p>
            </div>
            <h4 className='h4'>{_t('launchDateDisclaimerSubtitle')}</h4>
          </div>
          <div className='flex flex-col gap-[2px]'>
            <p className='body2 text-custom-gray-3'>
              {_t('launchDateDisclaimerBody1')}
            </p>
            <p className='body2'>{_t('launchDateDisclaimerBody2')}</p>
          </div>
        </div>
        <LaunchScreenFooter
          nextButtonText={_t('gotIt')}
          previousButtonText={_t('cancel')}
          onClickNext={handleClickContinue}
          onClickPrevious={() => navigate(-1)}
        />
      </div>
    </LunchCommunityWrapper>
  )
}

export default SetLaunchDateDisclaimer
