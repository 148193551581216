import React, { useCallback, useMemo } from 'react'
import { _t } from '@locales/index'
import { CommunityFormEnum } from '@enums/community'
import { Form, Formik } from 'formik'
import {
  useCommunityUpdateSuppliedContent,
  useGetCommunity,
} from '@api/queries/community'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { TypeCommunitySuppliedContentFormValue } from '@customTypes/community'
import { communitySuppliedContentSchema } from '@containers/creator/createCommunity/utils'
import { toast } from 'react-hot-toast'
import SuppliedContent from '@containers/creator/createCommunity/components/SuppliedContent'
import EditSignUpPageWrapper from '@containers/creator/editCommunity/components/EditSignUpPageWrapper'

const EditWhatsIncluded = () => {
  const communityId = useAppSelector(selectCurrentCommunityId)

  const { data: community, refetch } = useGetCommunity(communityId, {
    enabled: Boolean(communityId),
  })

  const { mutate: updateSuppliedContent, isLoading } =
    useCommunityUpdateSuppliedContent({
      onMutate: () => {
        toast.loading(_t('updateCommunityLoading'), {
          id: 'community-update',
        })
      },
      onError: error => {
        toast.error(error.message, { id: 'community-update' })
      },
    })

  const initialValues = useMemo<TypeCommunitySuppliedContentFormValue>(() => {
    return {
      [CommunityFormEnum.SuppliedContent]:
        community?.suppliedContent?.map(
          suppliedContent => suppliedContent.id,
        ) || [],
    }
  }, [community?.suppliedContent])

  const handleSubmit = useCallback(
    (formValues: TypeCommunitySuppliedContentFormValue) => {
      if (community) {
        const values = {
          communityId: community.id,
          suppliedContentIds: formValues[CommunityFormEnum.SuppliedContent],
        }
        updateSuppliedContent(values, {
          onSuccess: async () => {
            toast.success(_t('updateCommunitySuccess'), {
              id: 'community-update',
            })
            await refetch()
          },
        })
      }
    },
    [community, refetch, updateSuppliedContent],
  )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={communitySuppliedContentSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isValid, isSubmitting, dirty }) => (
        <Form className='w-full'>
          <EditSignUpPageWrapper
            loading={isLoading}
            submitDisabled={!isValid || !dirty || isSubmitting}
            title={_t('editSignUpPageWhatsIncluded')}
            mobileTitle={_t('editSignUpPageWhatsIncludedMobileTitle')}
          >
            <div className='max-w-[550px]'>
              <SuppliedContent
                name={CommunityFormEnum.SuppliedContent}
                justifyClass='justify-center sm:justify-start'
              />
            </div>
          </EditSignUpPageWrapper>
        </Form>
      )}
    </Formik>
  )
}

export default EditWhatsIncluded
