import React, { useCallback, useMemo, useRef } from 'react'
import { _t } from '@locales/index'
import { CommunityMediaTypeEnum } from '@enums/media'
import CommunityMediaOption from '@containers/creator/uploadCommunityMedia/components/CommunityMediaOption'
import { TypeCommunityMediaType } from '@customTypes/media'
import classNames from 'classnames'
import Slider, { Settings as SliderSettings } from 'react-slick'
import '../style.css'
import { EXAMPLE_VIDEO_LINK } from '@const/common'
import exampleCoverPhoto from '@assets/images/community/example-cover-photo.png'

interface Props {
  mediaType: TypeCommunityMediaType | undefined
  onSetMediaType: (mediaType: TypeCommunityMediaType) => void
}

const CommunityMediaOptions: React.FC<Props> = ({
  mediaType,
  onSetMediaType,
}) => {
  const sliderRef = useRef<Slider>(null)

  const sliderSettings: SliderSettings = useMemo(() => {
    return {
      infinite: false,
      arrows: false,
      dots: false,
      draggable: false,
      touchMove: false,
    }
  }, [])

  const handleChange = useCallback(
    (type: TypeCommunityMediaType) => {
      onSetMediaType(type)
      if (sliderRef.current) {
        sliderRef.current.slickGoTo(
          type === CommunityMediaTypeEnum.Cover ? 1 : 0,
        )
      }
    },
    [onSetMediaType],
  )

  const coverPhotoDesc = useMemo<string>(
    () =>
      _t('welcomeSelectMediaPhotoDesc1') +
      _t('welcomeSelectMediaPhotoDesc2') +
      _t('welcomeSelectMediaPhotoDesc3'),
    [],
  )

  return (
    <div className='flex flex-col gap-8'>
      <div
        className={classNames(
          'overflow-hidden transition-all ease-in duration-300 sm:hidden welcome-media-description',
          mediaType ? 'max-h-[300px]' : 'max-h-0',
        )}
      >
        <Slider ref={sliderRef} {...sliderSettings}>
          <p className={'body2'}>
            <span className='text-custom-pink text-bold font-bold'>
              {_t('welcomeSelectMediaVideoDesc1')}
            </span>{' '}
            {_t('welcomeSelectMediaVideoDesc2')}
          </p>
          <p className={'body2'}>
            {_t('welcomeSelectMediaPhotoDesc1')}{' '}
            <span className='text-custom-pink text-bold font-bold'>
              {_t('welcomeSelectMediaPhotoDesc2')}
            </span>{' '}
            {_t('welcomeSelectMediaPhotoDesc3')}
          </p>
        </Slider>
      </div>
      <div className='flex w-full items-center justify-center gap-5 sm:gap-7.5'>
        <CommunityMediaOption
          label={_t('communityEditWelcomeVideo')}
          description={_t('welcomeSelectMediaVideoDesc')}
          value={CommunityMediaTypeEnum.WelcomeVideo}
          isSelected={mediaType === CommunityMediaTypeEnum.WelcomeVideo}
          video={true}
          phonePreviewImage={EXAMPLE_VIDEO_LINK}
          phonePreviewTitle={_t('createCommunityNameScreenLabel')}
          onSelect={handleChange}
        />
        <CommunityMediaOption
          label={_t('communityWelcomeCoverPhotoTitle')}
          description={coverPhotoDesc}
          value={CommunityMediaTypeEnum.Cover}
          isSelected={mediaType === CommunityMediaTypeEnum.Cover}
          phonePreviewImage={exampleCoverPhoto}
          phonePreviewTitle={_t('createCommunityNameScreenLabel')}
          phonePreviewDescription={_t('welcomePhotoDescExample')}
          onSelect={handleChange}
        />
      </div>
    </div>
  )
}

export default CommunityMediaOptions
