import Loader from '@components/loader/Loader'
import useAffiliatorRedirect from '@hooks/auth/useAffiliatorRedirect'
const AffiliatorRedirect = () => {
  const { isProfileBeingCreated, isProfileBeingAttached } =
    useAffiliatorRedirect()

  return (
    <div className='w-screen h-screen'>
      <Loader
        visible={isProfileBeingCreated || isProfileBeingAttached}
        fullWidth
      />
    </div>
  )
}

export default AffiliatorRedirect
