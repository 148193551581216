import { TypeLanguageChoice } from '@customTypes/language'
import { supportedLanguages } from '@locales/index'
const useSupportedLanguages = () => {
  const languageObjects: TypeLanguageChoice[] = []

  supportedLanguages.forEach(language => {
    const languageObject: TypeLanguageChoice = {
      language: language.language,
      name: language.name,
      languageIcon: language.icon,
    }

    languageObjects.push(languageObject)
  })

  return languageObjects
}

export default useSupportedLanguages
