import React, { useCallback } from 'react'
import Drawer from 'react-modern-drawer'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { useGetCommunities } from '@api/queries/community'
import { DrawerProps } from '@customTypes/common'
import { _t } from '@locales/index'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import { useNavigate } from 'react-router-dom'
import { MOBILE_BOTTOM_DRAWER_ITEM_HEIGHT } from '@const/layout'

const LaunchDateMenuDrawer: React.FC<DrawerProps> = ({
  open,
  onClose,
  direction,
  ...drawerProps
}) => {
  const navigate = useNavigate()
  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)

  const { data: communities } = useGetCommunities()

  const handleClickChangeLaunchDate = useCallback(() => {
    if (selectedCommunityId) {
      navigate(
        CREATOR_PATH.changeLaunchDate.replace(':id', selectedCommunityId),
      )
      onClose && onClose()
    }
  }, [selectedCommunityId, navigate, onClose])

  if (!communities) {
    return null
  }

  return (
    <Drawer
      size={66}
      open={open}
      direction='bottom'
      onClose={onClose}
      className={'overflow-y-auto !w-screen rounded-t-[20px]'}
      {...drawerProps}
    >
      <div className='flex flex-col divide-y px-3'>
        <div
          className='flex items-center justify-center gap-3'
          style={{ height: MOBILE_BOTTOM_DRAWER_ITEM_HEIGHT }}
          onClick={handleClickChangeLaunchDate}
        >
          <p className='body2 capitalize'>{_t('changeLaunchDate')}</p>
        </div>
      </div>
    </Drawer>
  )
}

export default LaunchDateMenuDrawer
