import { useGetUserProfile } from '@api/queries/profile'
import { useEffect, useState } from 'react'
import { ProfileStatusEnum } from '@enums/profile'
import { toast } from 'react-hot-toast'

interface TypeUseIsProfileApprovedOptions {
  // enabled: boolean
  keepCheckingForApproval?: boolean
}

const useIsProfileApproved = (
  options?: TypeUseIsProfileApprovedOptions,
): boolean => {
  const [approvalStatus, setApprovalStatus] = useState<ProfileStatusEnum>()
  const [isApproved, setIsApproved] = useState<boolean>(false)

  const { data: profile } = useGetUserProfile({
    // enabled: options?.enabled,
    refetchInterval: () => {
      // don't run the interval is keepCheckingForApproval is false
      if (!options?.keepCheckingForApproval) {
        return false
      }

      // stop the interval once approved
      if (isApproved) {
        return false
      }
      return 10 * 1000 // 10 seconds
    },
    refetchOnWindowFocus: () => {
      if (!options?.keepCheckingForApproval) {
        return false
      }
      if (!isApproved) {
        toast.loading('Rechecking telegram connection...', {
          id: 'telegram-connection',
          duration: 2000,
        })
      }
      return isApproved ? false : 'always'
    },
    onSuccess: data => {
      if (
        approvalStatus === ProfileStatusEnum.Processing &&
        data &&
        data.status === ProfileStatusEnum.Approved
      ) {
        toast.success('Connected to telegram!', { id: 'telegram-connection' })
      }
    },
  })

  useEffect(() => {
    setApprovalStatus(profile && profile.status)
    setIsApproved(!!(profile && profile.status === ProfileStatusEnum.Approved))
  }, [profile])

  return isApproved
}

export default useIsProfileApproved
