import React, { useCallback, useMemo, useState } from 'react'
import { _t } from '@locales/index'
import telegramLogoAndText from '@assets/images/socials/telegramLogoAndText.svg'
import { DOWNLOAD_TELEGRAM_DESKTOP_LINK, SUPPORT_EMAIL } from '@const/common'
import Logo from '@containers/creator/navbar/components/Logo'
import CommunityPageDesk from '@containers/creator/telegramPage/components/desktop/communityPageDesk'
import { telegramPageUtils } from '@containers/creator/telegramPage/utils'
import FirstPageDesk from '@containers/creator/telegramPage/components/desktop/InitialDesktopViewDescription'
import DownloadTgPage from '@containers/creator/telegramPage/components/desktop/downloadTgAppDesk'
import { useSearchParams } from 'react-router-dom'
import { useGetProfileLink } from '@api/queries/profile'
import { getProfileMediaByType } from '@containers/creator/profile/utils'
import { ProfileMediaTypeEnum } from '@enums/media'
import Avatar from '@components/avatar/Avatar'

interface Props {}

const DownloadTelegramOptions: React.FC<Props> = () => {
  const [searchParams] = useSearchParams()

  const domain = useMemo(() => searchParams.get('domain'), [searchParams])
  const start = useMemo(() => searchParams.get('start'), [searchParams])

  const { data: profile } = useGetProfileLink(start || '', {
    enabled: Boolean(start),
  })

  const ownerProfileImageUrl = useMemo<string | undefined>(() => {
    const profileMedia = getProfileMediaByType(
      ProfileMediaTypeEnum.Profile,
      profile?.media || [],
    )
    if (profileMedia && profileMedia.length > 0) {
      return profileMedia[0].url
    }
    return ''
  }, [profile])

  const [doesNotHaveTelegram, setDoesNotHaveTelegram] = useState(false)
  const [downloadAppButtonClicked, setDownloadAppButtonClicked] =
    useState(false)

  const noHaveTG = useCallback(() => {
    setDoesNotHaveTelegram(true)
  }, [])

  const downloadTelegram = useCallback(() => {
    telegramPageUtils.openInNewTab(DOWNLOAD_TELEGRAM_DESKTOP_LINK)
    setTimeout(() => setDownloadAppButtonClicked(true), 2000)
  }, [])

  const renderOptions = useMemo(() => {
    // if user does not have telegram
    if (!start || !domain) {
      return
    }

    if (doesNotHaveTelegram && !downloadAppButtonClicked) {
      return (
        <DownloadTgPage
          onClickDownloadTelegram={downloadTelegram}
          domain={domain}
          start={start}
        />
      )
    }

    if (downloadAppButtonClicked)
      return <CommunityPageDesk domain={domain} start={start} />

    return <FirstPageDesk clickTG={noHaveTG} domain={domain} start={start} />
  }, [
    start,
    domain,
    doesNotHaveTelegram,
    downloadAppButtonClicked,
    downloadTelegram,
    noHaveTG,
  ])

  return (
    <div
      className={
        'bg-custom-black text-white w-[100vw] h-[100vh] flex flex-col items-center justify-center relative'
      }
    >
      <div className={'absolute top-10 left-10'}>
        <Logo logoColor={'white'} />
      </div>

      <div
        className={'w-[150px] h-[150px] border-2 rounded-full overflow-auto'}
      >
        <Avatar
          imageUrl={ownerProfileImageUrl}
          name={profile?.name}
          widthClass='w-full h-full'
        />
      </div>
      <div className={'text-white flex flex-col items-center mt-5'}>
        <h1 className={'text-base'}>{_t('tgMyCommunityIsOnTG')}</h1>
        <img
          src={telegramLogoAndText}
          width={'280px'}
          height={'67px'}
          alt={'telegram'}
        />
      </div>

      {renderOptions}
      <a
        className={'mt-[174px]'}
        href={`mailto:${SUPPORT_EMAIL}?subject=I have issue connect my telegram account`}
      >
        <h1>{_t('connectSupport')}</h1>
      </a>
    </div>
  )
}

export default DownloadTelegramOptions
