import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import { TypeExpandedMenuItem, TypeMenuItem } from '@customTypes/sidebar'
// import { useAppSelector } from '@hooks/store/redux'
// import { selectCommunityMenuItem } from '@selectors/community'
import { ReactComponent as ArrowIcon } from '@assets/images/common/arrow.svg'
import { Disclosure, Transition } from '@headlessui/react'
import CommunityExpandedMenuMenuItemMobile from '@containers/creator/sidebar/components/CommunityExpandedMenuItemMobile'

type Props = {
  menuItem: TypeMenuItem
  onClick: (menuItem: TypeMenuItem) => void
  onExpandedMenuItemClick?: (menuItem: TypeExpandedMenuItem) => void
  triggerOnMount?: boolean
}

const CommunityMenuItem: React.FC<Props> = ({
  menuItem,
  onClick,
  onExpandedMenuItemClick,
  triggerOnMount = true,
}) => {
  // const selectedCommunityMenuItem = useAppSelector(selectCommunityMenuItem)
  //
  // const pathMatch = useMatch({
  //   path: menuItem.path,
  //   end: false,
  // })

  // useEffect(() => {
  //   if (
  //     pathMatch &&
  //     selectedCommunityMenuItem?.path !== menuItem.path &&
  //     triggerOnMount
  //   ) {
  //     onClick(menuItem)
  //   }
  // }, [
  //   menuItem,
  //   onClick,
  //   pathMatch,
  //   selectedCommunityMenuItem?.path,
  //   triggerOnMount,
  // ])

  return (
    <Disclosure as={'div'}>
      <Disclosure.Button as={Fragment}>
        {({ open }) => (
          <NavLink
            to={menuItem.path}
            className={({ isActive }) =>
              classNames(
                'px-5 sm:px-3 h-16 flex items-center cursor-pointer gap-2.5 border-r border-custom-gray-5',
                isActive
                  ? 'bg-custom-green-gray text-custom-turquoise fill-custom-turquoise'
                  : 'bg-white text-custom-gray-1 fill-custom-gray-1',
              )
            }
            onClick={() => onClick(menuItem)}
          >
            {menuItem.icon && (
              <menuItem.icon className={classNames('w-6 h-6 fill-inherit')} />
            )}
            <p className='body3 sm:body2 capitalize text-inherit'>
              {menuItem.text}
            </p>
            {menuItem?.expandedMenu && (
              <ArrowIcon
                className={classNames(
                  'ml-auto sm:hidden transition-transform',
                  open
                    ? 'rotate-90 fill-custom-turquoise'
                    : '-rotate-90 fill-custom-black',
                )}
              />
            )}
          </NavLink>
        )}
      </Disclosure.Button>
      {menuItem?.expandedMenu && (
        <Transition
          enter='transition duration-100 ease-out'
          enterFrom='transform scale-95 opacity-0'
          enterTo='transform scale-100 opacity-100'
          leave='transition duration-75 ease-out'
          leaveFrom='transform scale-100 opacity-100'
          leaveTo='transform scale-95 opacity-0'
        >
          <Disclosure.Panel as='div' className='sm:hidden'>
            {menuItem?.expandedMenu.map((expandedMenuItem, i) => (
              <CommunityExpandedMenuMenuItemMobile
                key={i}
                expandedMenuItem={expandedMenuItem}
                onClick={onExpandedMenuItemClick}
              />
            ))}
          </Disclosure.Panel>
        </Transition>
      )}
    </Disclosure>
  )
}

export default CommunityMenuItem
