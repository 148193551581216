import React, { useCallback, useMemo, useRef, useState } from 'react'
// library css
import './styles.css'
import 'slick-carousel/slick/slick.css'
import { TypeCoverPhotoFormValue } from '@customTypes/coverPhoto'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import Slider, { Settings as SliderSettings } from 'react-slick'
import usePrevious from '@hooks/common/usePrevious'
import PhotoCoverWizardFooter from '@containers/creator/uploadCoverPhotoWizard/components/Footer'
import { PhotoWizardScreenProps } from '@containers/creator/uploadCoverPhotoWizard/components/Screen'
import { useGetCommunity } from '@api/queries/community'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { useNavigate } from 'react-router-dom'

interface Props {
  initialValues: TypeCoverPhotoFormValue
  onSubmit: (
    values: TypeCoverPhotoFormValue,
    formikHelpers: FormikHelpers<TypeCoverPhotoFormValue>,
  ) => void
  children: React.ReactNode
}

const UploadPhotoCoverWizard: React.FC<Props> = ({
  children,
  initialValues,
  onSubmit,
}) => {
  const navigate = useNavigate()
  const sliderRef = useRef<Slider>(null)
  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [currentScreenIndex, setCurrentScreenIndex] = useState<number>(0)
  const prevCurrentScreenIndex = usePrevious<number>(currentScreenIndex)

  const [snapshot, setSnapshot] =
    useState<TypeCoverPhotoFormValue>(initialValues)

  const { data: community } = useGetCommunity(selectedCommunityId, {
    enabled: Boolean(selectedCommunityId),
  })
  const screens = useMemo(
    () => React.Children.toArray(children) as React.ReactElement[],
    [children],
  )
  const currentScreen: React.ReactElement<PhotoWizardScreenProps> = useMemo(
    () => screens[currentScreenIndex],
    [currentScreenIndex, screens],
  )
  const totalScreens = useMemo<number>(() => screens.length, [screens])
  const isLastScreen = useMemo(
    () => currentScreenIndex === totalScreens - 1,
    [currentScreenIndex, totalScreens],
  )

  const handleNext = useCallback((values: TypeCoverPhotoFormValue) => {
    if (sliderRef && sliderRef.current) {
      setSnapshot(values)
      sliderRef.current.slickNext()
    }
  }, [])

  const handleBack = useCallback(
    (values: TypeCoverPhotoFormValue) => {
      if (sliderRef && sliderRef.current) {
        setSnapshot(values)
        sliderRef.current.slickPrev()
      }
      if (currentScreenIndex === 0) {
        navigate(-1)
      }
    },
    [currentScreenIndex, navigate],
  )

  const handleSubmit = useCallback(
    async (
      values: TypeCoverPhotoFormValue,
      formikHelpers: FormikHelpers<TypeCoverPhotoFormValue>,
    ) => {
      setIsSubmitting(true)
      if (community) {
        if (currentScreen?.props?.onSubmit) {
          await currentScreen.props.onSubmit(values, formikHelpers)
        }

        if (isLastScreen) {
          await onSubmit(values, formikHelpers)
        } else {
          formikHelpers.setTouched({})
          await handleNext(values)
          setIsSubmitting(false)
        }
      }
    },
    [community, currentScreen.props, isLastScreen, onSubmit, handleNext],
  )
  const sliderSettings: SliderSettings = useMemo(() => {
    return {
      infinite: false,
      arrows: false,
      dots: false,
      afterChange: setCurrentScreenIndex,
      draggable: false,
      touchMove: false,
    }
  }, [])

  return (
    <Formik
      initialValues={snapshot}
      validationSchema={currentScreen?.props?.validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, isValid }: FormikProps<TypeCoverPhotoFormValue>) => (
        <Form className='flex flex-col flex-1'>
          <div className={'cover-photo-wizard flex flex-col justify-between'}>
            <Slider ref={sliderRef} {...sliderSettings}>
              {children}
            </Slider>

            {!currentScreen?.props?.hideFooter && (
              <div className={'sticky bottom-0 bg-white'}>
                <PhotoCoverWizardFooter
                  community={community}
                  initialValues={values}
                  totalScreens={totalScreens}
                  currentScreen={currentScreenIndex}
                  prevCurrentScreen={prevCurrentScreenIndex}
                  onClickPreviousScreen={() => handleBack(values)}
                  disableNext={
                    currentScreen?.props?.disableSubmitOnInvalid
                      ? !isValid
                      : isSubmitting
                  }
                  isLastStep={isLastScreen}
                  primaryButtonLabel={currentScreen?.props?.primaryButtonLabel}
                />
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default UploadPhotoCoverWizard
