import React, { useMemo } from 'react'
import { _t } from '@locales/index'
import { useField } from 'formik'
import CurrencyPicker from '@containers/creator/createCommunity/components/CurrencyPicker'
import { useGetAllCurrencies } from '@api/queries/currency'
import PriceField from '@containers/creator/createCommunity/components/PriceField'
import { TypeCurrency } from '@customTypes/currency'
import { CommunityFormEnum } from '@enums/community'
import { ProfileOnboardingStatusEnum } from '@enums/profile'
import { useGetUserProfile } from '@api/queries/profile'

const SubscriptionFee = () => {
  const { data: profile } = useGetUserProfile()
  const { data: currencyData } = useGetAllCurrencies()

  const isFirstCommunity = useMemo<Boolean>(
    () =>
      Boolean(
        profile?.onboardingStatus === ProfileOnboardingStatusEnum.Initiated,
      ),
    [profile?.onboardingStatus],
  )

  const [, priceMeta, priceHelpers] = useField<number>(CommunityFormEnum.Price)
  const [, currencyMeta, currencyHelpers] = useField<string>(
    CommunityFormEnum.Currency,
  )

  const selectedCurrency = useMemo<TypeCurrency | undefined>(() => {
    return currencyData?.currencies.find(
      currency =>
        currency.id ===
        (isFirstCommunity ? currencyMeta.value : profile?.currency?.id),
    )
  }, [
    currencyData?.currencies,
    currencyMeta.value,
    isFirstCommunity,
    profile?.currency?.id,
  ])

  return (
    <div className='community-page'>
      <div className='flex flex-col gap-13'>
        <h2 className='h4 sm:h2 capitalize text-center first-letter:uppercase'>
          {_t('createCommunitySubscriptionFeeHeading')}
        </h2>
        <div className='flex flex-col items-center sm:items-start gap-8 max-w-[452px]'>
          {isFirstCommunity && (
            <div className='flex flex-col items-center sm:items-start gap-5 sm:gap-3'>
              <p className='body2 sm:body1 text-custom-gray-3 first-letter:uppercase sm:capitalize'>
                {_t('createCommunitySubscriptionFeeCurrencyTitle')}
              </p>
              {currencyData && (
                <div className='self-center sm:self-start'>
                  <CurrencyPicker
                    currencyOptions={currencyData.currencies}
                    value={currencyMeta.value}
                    setValue={currencyHelpers.setValue}
                  />
                </div>
              )}
            </div>
          )}
          <div className='flex flex-col items-center sm:items-start gap-5 sm:gap-3'>
            <p className='body2 sm:body1 text-custom-gray-3 first-letter:uppercase sm:capitalize'>
              {isFirstCommunity
                ? _t('createCommunitySubscriptionFeePriceTitle')
                : _t('createCommunitySubscriptionFeePriceTitle2')}
            </p>
            {selectedCurrency && (
              <PriceField
                currencySymbol={selectedCurrency.symbol}
                value={priceMeta.value}
                setValue={priceHelpers.setValue}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionFee
