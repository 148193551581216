import { combineReducers } from '@reduxjs/toolkit'
import communityReducer from '@store/reducers/community'
import launchDateReducer from '@reducers/launchDateModal'
import drawerMenuReducer from '@reducers/drawerMenu'
import guidelinesModalReducer from '@reducers/guidelinesModal'
import deleteGuidelinesModalReducer from '@reducers/deleteGuidelinesModal'
import changeLaunchDateReducer from '@reducers/changeLaunchDateModal'
import bannerReducer from '@reducers/banner'
import mobileBottomDrawerReducer from '@reducers/mobileBottomDrawer'
import uploadCoverPhotoModalReducer from '@reducers/coverPhotoModal'
import newAdminReducer from '@reducers/newAdmin'
import userSegmentationFiltersReducer from '@reducers/userSegmentationFilters'
import footerReducer from '@reducers/footer'
import modalReducer from '@reducers/modal'

const AppReducers = combineReducers({
  banner: bannerReducer,
  community: communityReducer,
  drawerMenu: drawerMenuReducer,
  launchDateModal: launchDateReducer,
  guidelinesModal: guidelinesModalReducer,
  mobileBottomDrawer: mobileBottomDrawerReducer,
  changeLaunchDateModal: changeLaunchDateReducer,
  deleteGuidelinesModal: deleteGuidelinesModalReducer,
  uploadCoverPhoto: uploadCoverPhotoModalReducer,
  newAdmin: newAdminReducer,
  userSegmentationFilters: userSegmentationFiltersReducer,
  footer: footerReducer,
  modal: modalReducer,
})

export const rootReducer = (state: any, action: any) => {
  return AppReducers(state, action)
}
