import { TypeSegmentationValue } from '@customTypes/segmentation'
import React, { useCallback, useState } from 'react'
import classNames from 'classnames'
import { ReactComponent as ChipIcon } from '@assets/images/common/select-chip-icon.svg'

interface Props {
  segmentationValue: TypeSegmentationValue
  handleSegmentationValueClick: (value: TypeSegmentationValue) => void
}

const SegmentationBadge: React.FC<Props> = ({
  segmentationValue,
  handleSegmentationValueClick,
}) => {
  const [isClick, setIsClick] = useState(false)
  const handleClick = useCallback(
    (clickedSegmentationValue: TypeSegmentationValue) => {
      handleSegmentationValueClick(clickedSegmentationValue)
      setIsClick(!isClick)
    },
    [handleSegmentationValueClick, isClick],
  )
  return (
    <div
      onClick={() => handleClick(segmentationValue)}
      className={classNames(
        'flex items-center border border-[#E0E0E0] rounded-[6.25rem] mx-1 px-5 cursor-pointer ease-in duration-200',
        isClick ? 'bg-custom-purple text-white' : 'bg-white',
      )}
    >
      <p className='py-1.5 m-0 whitespace-nowrap text-center body2'>
        {segmentationValue.value}
      </p>
      {isClick ? <ChipIcon fill={'white'} /> : null}
    </div>
  )
}

export default SegmentationBadge
