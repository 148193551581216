import { useMutation, UseMutationOptions, useQueryClient } from 'react-query'
import { ServerStateKeysEnum } from '@enums/serverState'
import { useProtection } from '@hooks/auth/useProtection'
import apiService from '@services/api/apiService'
import { ApiKeyNameEnum } from '@enums/api'
import { AxiosError } from 'axios'
import {
  TypeLaunchDate,
  TypeLaunchDateCreate,
  TypeLaunchDateEdit,
} from '@customTypes/launchDate'

/**********************************
 CREATE LAUNCH DATE
 **********************************/

type TypeLaunchDateCreateOptions = UseMutationOptions<
  TypeLaunchDate,
  AxiosError,
  TypeLaunchDateCreate
>

const createLaunchDate = async (
  values: TypeLaunchDateCreate,
): Promise<TypeLaunchDate> => {
  try {
    const response = await apiService.post(ApiKeyNameEnum.launch_date, values)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useLaunchDateCreate = (options?: TypeLaunchDateCreateOptions) => {
  const queryClient = useQueryClient()

  const queryOptions = useProtection<TypeLaunchDateCreateOptions>(options)

  return useMutation(createLaunchDate, {
    onSuccess: () => {
      return queryClient.invalidateQueries(ServerStateKeysEnum.Community)
    },
    ...queryOptions,
  })
}

/**********************************
 EDIT LAUNCH DATE
 **********************************/

type TypeLaunchDateEditOptions = UseMutationOptions<
  TypeLaunchDate,
  AxiosError,
  TypeLaunchDateEdit
>

const editLaunchDate = async (
  values: TypeLaunchDateEdit,
): Promise<TypeLaunchDate> => {
  try {
    const response = await apiService.put(ApiKeyNameEnum.launch_date, values)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useLaunchDateEdit = (options?: TypeLaunchDateEditOptions) => {
  const queryClient = useQueryClient()

  const queryOptions = useProtection<TypeLaunchDateEditOptions>(options)

  return useMutation(editLaunchDate, {
    onSuccess: () => {
      return queryClient.invalidateQueries(ServerStateKeysEnum.Community)
    },
    ...queryOptions,
  })
}

/**********************************
 DELETE LAUNCH DATE
 **********************************/

type TypeDeleteLaunchDateOptions = UseMutationOptions<
  TypeLaunchDate,
  AxiosError,
  string
>

const deleteLaunchDate = async (
  communityId: string,
): Promise<TypeLaunchDate> => {
  try {
    const qs = '?community_id=' + communityId
    const response = await apiService.deleteUrl(ApiKeyNameEnum.launch_date, qs)
    return response?.data
  } catch (err) {
    throw err
  }
}

export const useDeleteLaunchDate = (options?: TypeDeleteLaunchDateOptions) => {
  const queryClient = useQueryClient()
  const queryOptions = useProtection<TypeDeleteLaunchDateOptions>(options)

  return useMutation(deleteLaunchDate, {
    onSuccess: () => {
      return queryClient.invalidateQueries(ServerStateKeysEnum.Community)
    },
    ...queryOptions,
  })
}
