import { Form, Formik } from 'formik'
import React, { useMemo } from 'react'
import Loader from '@components/loader/Loader'
import Modal from '@components/modal/Modal'
import {
  useGetCommunityUserSegmentation,
  useGetUserSegmentationCount,
} from '@api/queries/userSegmentation'
import { BreakpointsEnum } from '@enums/theme'
import useBreakpoint from '@hooks/responsive/useBreakpoint'
import { useAppDispatch, useAppSelector } from '@hooks/store/redux'
import { setUserSegmentationFilters } from '@reducers/userSegmentationFilters'
import { selectCurrentCommunityId } from '@selectors/community'
import {
  TypeSegmentationValueFilters,
  TypeSegmentationValueFiltersPayload,
} from '@customTypes/segmentation'
import classNames from 'classnames'
import FiltersFormHeader from './components/FilterFormHeader'
import SegmentationLabelWrapper from './components/SegmentationLabelWrapper'
import { getInitialValues } from './utils'
import { ReactComponent as ArrowIcon } from '@assets/images/common/arrow.svg'
import { SegmentationFormEnum } from '@enums/segmentation'

interface Props {
  isShowFiltersForm: boolean
  onClose: () => void
}

const FiltersForm: React.FC<Props> = ({ isShowFiltersForm, onClose }) => {
  const breakpoint = useBreakpoint()
  const isMobile: boolean = useMemo(
    () => breakpoint < BreakpointsEnum.lg,
    [breakpoint],
  )
  const dispatch = useAppDispatch()
  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)

  const { data: userSegmentation } = useGetCommunityUserSegmentation(
    selectedCommunityId,
    {
      enabled: Boolean(selectedCommunityId),
    },
  )

  const {
    mutate: getUserSegmentationCount,
    isLoading: isGettingUserSegmentationCount,
  } = useGetUserSegmentationCount()

  const createFilters = async (values: TypeSegmentationValueFilters) => {
    const newValues = Object.fromEntries(
      Object.entries(values).filter(
        ([_, v]) => v != null && v[SegmentationFormEnum.value].length > 0,
      ),
    )
    const valuesCount = Object.values(newValues).flat().length
    const requestValues: TypeSegmentationValueFiltersPayload = {
      segmentationsValues: newValues,
      communityId: selectedCommunityId,
    }
    getUserSegmentationCount(requestValues, {
      onSuccess: data => {
        dispatch(
          setUserSegmentationFilters({
            communityId: selectedCommunityId,
            segmentationsValues: newValues,
            valuesCount: valuesCount,
            filteredMemberCount: data.communityMembersCount,
          }),
        )
        onClose()
      },
    })
  }

  const initialValues = useMemo<TypeSegmentationValueFilters | {}>(
    () => getInitialValues(userSegmentation?.segmentations),
    [userSegmentation?.segmentations],
  )

  return (
    <div>
      <Modal
        open={isShowFiltersForm}
        showClose={!isMobile}
        onClose={onClose}
        isMobileVersion={isMobile}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting }) => {
            await createFilters(values)
            setSubmitting(false)
          }}
        >
          {({ handleSubmit, dirty, isSubmitting }) => (
            <>
              <Loader visible={isGettingUserSegmentationCount} />
              <Form
                className={classNames(
                  'flex flex-col overflow-hidden rounded-xl',
                  isMobile ? '' : 'min-w-[70dvh]',
                )}
              >
                <FiltersFormHeader
                  onSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  dirty={dirty}
                />
                <SegmentationLabelWrapper />
                {/*<section className='flex px-3 py-4 items-center justify-center gap-3 bg-custom-gray-5'>*/}
                {/*  <h1 className='body1 text-custom-gray-3'>*/}
                {/*    {_t('filtersContactUs')}*/}
                {/*  </h1>*/}
                {/*  <ContactButton customClassName={'rounded-full'} />*/}
                {/*</section>*/}
                {isMobile ? (
                  <section className='flex gap-3 px-5 py-4'>
                    <ArrowIcon
                      onClick={() => onClose()}
                      fill={'white'}
                      width={48}
                      height={48}
                      className='pr-1  bg-custom-purple  rounded-full'
                    />
                  </section>
                ) : null}
              </Form>
            </>
          )}
        </Formik>
      </Modal>
    </div>
  )
}

export default FiltersForm
