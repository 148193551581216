import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TypeDeleteGuidelinesModalState } from '@customTypes/store'

const initialState: TypeDeleteGuidelinesModalState = {
  visible: false,
  onConfirm: () => {},
  guidelineId: undefined,
}

/* CASE REDUCERS */
const open: CaseReducer<
  TypeDeleteGuidelinesModalState,
  PayloadAction<TypeDeleteGuidelinesModalState>
> = (state, action) => {
  state.visible = action.payload.visible
  state.onClose = action.payload.onClose
  state.onConfirm = action.payload.onConfirm
  state.guidelineId = action.payload.guidelineId
}

const close: CaseReducer<TypeDeleteGuidelinesModalState> = state => {
  state.visible = false
  state.onConfirm = () => {}
  state.guidelineId = undefined
}

/* SLICE */
const deleteGuidelinesModalSlice = createSlice({
  name: 'deleteGuidelinesModal',
  initialState,
  reducers: {
    open,
    close,
  },
})

export const {
  open: openDeleteGuidelinesModal,
  close: closeDeleteGuidelinesModal,
} = deleteGuidelinesModalSlice.actions
const deleteGuidelinesModalReducer = deleteGuidelinesModalSlice.reducer

export default deleteGuidelinesModalReducer
