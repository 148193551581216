import React, { useMemo } from 'react'
import { Form, Formik } from 'formik'
import { TypeMemberFilters } from '@customTypes/member'
import { getInitialFormValues } from '@containers/creator/members/utils'
import CommunityMembersInner from '@containers/creator/members/CommunityMembersInner'
import { useSearchParams } from 'react-router-dom'
import CommunityPageWrapper from '@containers/creator/community/components/CommunityPageWrapper'

const CommunityMembers = () => {
  const [searchParams] = useSearchParams()
  const initialValues = useMemo<TypeMemberFilters>(
    () => getInitialFormValues(searchParams),
    [searchParams],
  )

  return (
    <CommunityPageWrapper bgClass={'bg-custom-green-gray'}>
      <div className='bg-custom-green-gray p-5 pt-2 sm:p-13 flex flex-1 flex-col'>
        <Formik initialValues={initialValues} onSubmit={() => undefined}>
          <Form className='flex flex-col flex-1'>
            <CommunityMembersInner />
          </Form>
        </Formik>
      </div>
    </CommunityPageWrapper>
  )
}

export default CommunityMembers
