import React from 'react'
import { _t } from '@locales/index'
import InputField from '@components/form/InputField'
import { ProfileFormEnum } from '@enums/profile'
import ProfileImage from '@components/profileImage/ProfileImage'
import { useField } from 'formik'
import Occupation from '@containers/creator/profile/components/Occupation'
import { TypeOccupation } from '@customTypes/occupation'

interface Props {
  uploadKey?: string
}

const PersonalDetails: React.FC<Props> = ({ uploadKey }) => {
  const [, profileMeta, profileImageHelpers] = useField<string>(
    ProfileFormEnum.ProfileImage,
  )
  const [, occupationMeta, occupationHelpers] = useField<TypeOccupation | null>(
    ProfileFormEnum.Occupation,
  )

  return (
    <div className='community-page'>
      <div className='flex flex-col items-center gap-13'>
        <p className='h4 sm:h2 text-center capitalize'>
          {_t('createCommunityProfileScreenTitle')}
        </p>
        <div className='flex flex-col gap-8'>
          <div className='flex flex-col gap-2 w-64'>
            <InputField
              name={ProfileFormEnum.Name}
              id={ProfileFormEnum.Name}
              placeholder={_t('createCommunityProfileNamePlaceholder')}
              autoComplete='off'
              hideErrorMessage
              textClass='body3'
              tabIndex={-1}
            />
            <Occupation
              value={occupationMeta.value}
              setValue={occupationHelpers.setValue}
            />
          </div>
          {uploadKey && (
            <ProfileImage
              uploadKey={uploadKey}
              onSetImageUrl={profileImageHelpers.setValue}
              image={profileMeta.value}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default PersonalDetails
