import { RouteObject } from 'react-router-dom'

import NoMatch from '@containers/common/noMatch/NoMatch'
import OrganizationHome from '@containers/organization/organization/OrganizationHome'
import OrganizationLayout from '@containers/organization/layouts/OrganizationLayout'

import { ORGANIZATION_PATH } from '@routes/organization/organizationPath'
import { ProtectedComponent } from '@components/wrappers/protectedComponent/ProtectedComponent'
import CreateOrganization from '@root/src/containers/organization/onboarding/CreateOrganization'
import ContactUs from '@containers/creator/contactUs/ContactUs'
import OrganizationAudience from '@containers/organization/organization/OrganizationAudience'

export const ORGANIZATION_ROUTES: RouteObject[] = [
  {
    path: ORGANIZATION_PATH.root,
    element: <OrganizationLayout />,
    children: [
      {
        index: true,
        element: <ProtectedComponent component={OrganizationHome} />,
      },
      {
        path: ORGANIZATION_PATH.organizationId,
        element: <ProtectedComponent component={OrganizationHome} />,
      },
      {
        path: ORGANIZATION_PATH.audience,
        element: <ProtectedComponent component={OrganizationAudience} />,
      },
      {
        path: ORGANIZATION_PATH.createOrganization,
        element: <ProtectedComponent component={CreateOrganization} />,
      },
    ],
  },

  {
    path: ORGANIZATION_PATH.contact,
    element: <ProtectedComponent component={ContactUs} />,
  },

  // No Match
  { path: ORGANIZATION_PATH.noMatch, element: <NoMatch /> },
]
