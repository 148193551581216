import React, { Fragment, useCallback, useMemo } from 'react'
import { TypeCurrency } from '@customTypes/currency'
import { Listbox, Transition } from '@headlessui/react'
import { ReactComponent as PointerIcon } from '@assets/images/common/down-pointer.svg'
import { useAppDispatch } from '@hooks/store/redux'
import { openMobileBottomDrawer } from '@reducers/mobileBottomDrawer'
import { MobileBottomDrawerTypeEnum } from '@enums/common'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'
import classNames from 'classnames'
import { LanguagesSettings } from '@const/languages'
import { LanguagesDirection } from '@enums/language'
import { Locale } from '@locales/index'

interface Props {
  currencyOptions: TypeCurrency[]
  value: string
  setValue: (value: string) => void
}

const CurrencyPicker: React.FC<Props> = ({
  currencyOptions,
  value,
  setValue,
}) => {
  const dispatch = useAppDispatch()
  const isSmallerDevice = useIsSmallerDevice()

  const selectedCurrency = useMemo<TypeCurrency | undefined>(
    () => currencyOptions.find(option => option.id === value),
    [currencyOptions, value],
  )

  const handleOpenCurrency = useCallback(() => {
    if (isSmallerDevice) {
      dispatch(
        openMobileBottomDrawer({
          type: MobileBottomDrawerTypeEnum.CurrencyPicker,
          meta: {
            onSelectCurrency: (currencyISO: string) => {
              setValue(currencyISO)
            },
          },
        }),
      )
    }
  }, [dispatch, isSmallerDevice, setValue])

  const local_language = Locale.getLanguage()

  const isLtlLanguage: boolean = useMemo(
    () =>
      LanguagesSettings[local_language].direction === LanguagesDirection.RTL,
    [local_language],
  )
  return (
    <div className='w-full h-full'>
      <Listbox value={value} onChange={setValue}>
        {({ open }) => (
          <div className='relative mt-1 z-[9] inline'>
            <div onClick={handleOpenCurrency}>
              <Listbox.Button
                className='border border-custom-gray-5 py-2 px-5 flex items-center gap-1.5 rounded'
                tabIndex={-1}
              >
                <span className='body2 text-custom-turquoise-dark'>
                  {selectedCurrency?.symbol + ' '} {selectedCurrency?.iso || ''}
                </span>
                <PointerIcon
                  className={'w-6 h-6 fill-custom-gray-1 hidden sm:block'}
                />
              </Listbox.Button>
            </div>
            <div className='hidden sm:block'>
              <Transition
                show={open}
                as={Fragment}
                enter='transition ease-out duration-200'
                enterFrom='opacity-0 translate-y-1'
                enterTo='opacity-100 translate-y-0'
                leave='transition ease-in duration-150'
                leaveFrom='opacity-100 translate-y-0'
                leaveTo='opacity-0 translate-y-1'
              >
                <Listbox.Options
                  static
                  className={classNames(
                    'absolute w-auto right-0 bg-white border border-custom-gray-5 filter-drop-shadow-2 divide-y divide-custom-gray-5 max-h-[300px] overflow-y-auto no-scrollbar',
                    isLtlLanguage ?? 'left-0',
                  )}
                >
                  {currencyOptions.map(option => (
                    <Listbox.Option
                      value={option.iso}
                      key={option.id}
                      className='flex items-center justify-center cursor-pointer py-1.5 gap-2 hover:bg-custom-gray-6 focus:bg-custom-gray-4'
                    >
                      <span className='body3 font-semibold text-right'>
                        {option.symbol}
                      </span>
                      <span className='body2 uppercase text-left'>
                        {option.iso}
                      </span>
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </div>
        )}
      </Listbox>
    </div>
  )
}

export default CurrencyPicker
