import React, { useCallback, useMemo, useRef, useState } from 'react'

import Slider, { Settings as SliderSettings } from 'react-slick'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'

// library css
import './styles.css'
import 'slick-carousel/slick/slick.css'

import { TypeCreateCommunityFormValues } from '@customTypes/community'
import { CommunityWizardScreenProps } from '@containers/creator/communityWizard/components/Screen'
import Footer from '@containers/creator/communityWizard/components/Footer'
import Sidebar from '@containers/creator/communityWizard/components/Sidebar'
import Header from '@containers/creator/communityWizard/components/Header'
import usePrevious from '@hooks/common/usePrevious'

interface Props {
  initialValues: TypeCreateCommunityFormValues
  onSubmit: (
    values: TypeCreateCommunityFormValues,
    formikHelpers: FormikHelpers<TypeCreateCommunityFormValues>,
  ) => void
  children: React.ReactNode
}

const CommunityWizard: React.FC<Props> = ({
  children,
  initialValues,
  onSubmit,
}) => {
  const sliderRef = useRef<Slider>(null)
  const [currentScreenIndex, setCurrentScreenIndex] = useState<number>(0)
  const prevCurrentScreenIndex = usePrevious<number>(currentScreenIndex)
  const [snapshot, setSnapshot] =
    useState<TypeCreateCommunityFormValues>(initialValues)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const screens = useMemo(
    () => React.Children.toArray(children) as React.ReactElement[],
    [children],
  )
  const currentScreen: React.ReactElement<CommunityWizardScreenProps> = useMemo(
    () => screens[currentScreenIndex],
    [currentScreenIndex, screens],
  )
  const totalScreens = useMemo<number>(() => screens.length, [screens])
  const isLastScreen = useMemo(
    () => currentScreenIndex === totalScreens - 1,
    [currentScreenIndex, totalScreens],
  )

  const screenLabels = useMemo(
    () => screens.map(screen => screen.props.label),
    [screens],
  )

  const sliderSettings: SliderSettings = useMemo(() => {
    return {
      infinite: false,
      arrows: false,
      dots: false,
      afterChange: setCurrentScreenIndex,
      draggable: false,
      touchMove: false,
    }
  }, [])

  const handleNext = useCallback((values: TypeCreateCommunityFormValues) => {
    if (sliderRef && sliderRef.current) {
      setSnapshot(values)
      sliderRef.current.slickNext()
    }
  }, [])

  const handleBack = useCallback((values: TypeCreateCommunityFormValues) => {
    if (sliderRef && sliderRef.current) {
      setSnapshot(values)
      sliderRef.current.slickPrev()
    }
  }, [])

  const handleSubmit = useCallback(
    async (
      values: TypeCreateCommunityFormValues,
      formikHelpers: FormikHelpers<TypeCreateCommunityFormValues>,
    ) => {
      setIsSubmitting(true)
      if (currentScreen?.props?.onSubmit) {
        await currentScreen.props.onSubmit(values, formikHelpers)
      }
      if (isLastScreen) {
        await onSubmit(values, formikHelpers)
      } else {
        formikHelpers.setTouched({})
        await handleNext(values)
        setIsSubmitting(false)
      }
    },
    [currentScreen?.props, isLastScreen, onSubmit, handleNext],
  )

  return (
    <Formik
      initialValues={snapshot}
      validationSchema={currentScreen?.props?.validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, isValid }: FormikProps<TypeCreateCommunityFormValues>) => (
        <Form className='flex flex-col overflow-hidden w-full h-full-screen'>
          <Header />
          <div className='flex flex-1 community-wizard'>
            <Sidebar
              screenLabels={screenLabels}
              currentScreenIndex={currentScreenIndex}
            />
            <div className='flex flex-col'>
              <Slider ref={sliderRef} {...sliderSettings}>
                {children}
              </Slider>
              {!currentScreen?.props?.hideFooter && (
                <Footer
                  totalScreens={totalScreens}
                  currentScreen={currentScreenIndex}
                  prevCurrentScreen={prevCurrentScreenIndex}
                  onClickPreviousScreen={() => handleBack(values)}
                  disableNext={
                    currentScreen?.props?.disableSubmitOnInvalid
                      ? !isValid
                      : isSubmitting
                  }
                  isLastStep={isLastScreen}
                />
              )}
            </div>
            {isSubmitting && (
              <div className='absolute inset-0 rounded-lg bg-custom-gray-6/90 opacity-50 blur-md' />
            )}
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default CommunityWizard
