import React, { useCallback, useMemo, useState } from 'react'
import Modal from '@components/modal/Modal'
import { _t } from '@locales/index'
import { ReactComponent as Check } from '@assets/images/common/check-icon.svg'
import classNames from 'classnames'
import useSupportedLanguages from '@hooks/language/useLanguage'
import { profileLanguageSchema } from '@containers/creator/createCommunity/utils'
import { Form, Formik, FormikProps } from 'formik'
import { TypeEditLanguageFormValue } from '@customTypes/language'
import { ProfileLanguageFormEnum } from '@enums/profile'
import { LocalStorageTypeEnum } from '@enums/localStorage'
import LogoWithText from '@assets/images/common/logo-with-text-black.svg'
import { useAuth0 } from '@auth0/auth0-react'
import EditLanguagePopup from '@containers/creator/editCommunity/components/EditLanguagePopup'

const SetLanguagePopup = () => {
  const [showEditLanguagePopup, setShowEditLanguagePopup] =
    useState<boolean>(false)
  const { loginWithRedirect } = useAuth0()
  const initialValues = useMemo<TypeEditLanguageFormValue>(() => {
    return { [ProfileLanguageFormEnum.Language]: '' }
  }, [])

  const languageList = useSupportedLanguages()

  const handleSubmit = useCallback(
    async (values: TypeEditLanguageFormValue) => {
      localStorage.setItem(
        LocalStorageTypeEnum.FirstLanguage,
        values[ProfileLanguageFormEnum.Language],
      )
      localStorage.setItem(LocalStorageTypeEnum.IsSelectedLanguage, 'true')
      await loginWithRedirect({
        redirectUri: window.location.origin,
        entity: 'profile',
        language: values[ProfileLanguageFormEnum.Language],
      })
    },
    [loginWithRedirect],
  )
  return (
    <Modal open={true}>
      <div className={''}>
        <Formik
          initialValues={initialValues}
          validationSchema={profileLanguageSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            submitForm,
            setFieldValue,
          }: FormikProps<TypeEditLanguageFormValue>) => (
            <Form className='flex flex-col flex-1 items-center justify-center'>
              <div className='p-2 h-full w-full sm:h-[628px] sm:w-[628px] bg-white sm:p-13 flex sm:flex-1 sm:flex-col items-center justify-center sm:border rounded-xl'>
                <div className='flex h-full w-full flex-col justify-between sm:justify-center items-center gap-5'>
                  <p className={'h-4 text-[24px] sm:hidden mb-5'}>
                    {_t('setLanguageMobileTitle')}
                  </p>
                  <img
                    src={LogoWithText}
                    className='cursor-pointer hidden sm:block h-8'
                    alt='logo'
                  />
                  <div className='flex flex-col gap-3'>
                    <p className='h4 text-[24px] capitalize font-light hidden sm:block'>
                      {_t('setLanguageDescription')}
                    </p>
                    <p className={'body-2 text-custom-gray-3 sm:hidden block'}>
                      {_t('setLanguageMobileSubTitle')}
                    </p>
                  </div>
                  <div
                    className={
                      'flex flex-col items-center justify-center gap-2  sm:w-[350px] h-[260px] sm:h-[260px] bg-white rounded-xl'
                    }
                  >
                    {languageList.map((val, index) => (
                      <button
                        onClick={() => {
                          setFieldValue(
                            ProfileLanguageFormEnum.Language,
                            val.language,
                          )
                        }}
                        type={'button'}
                        className={classNames(
                          'flex items-center justify-center m-0 w-[326px] sm:w-[326px] h-[50px] gap-1.5 bg-white rounded-md border',
                          values[ProfileLanguageFormEnum.Language] ===
                            val.language
                            ? 'border-custom-turquoise'
                            : '',
                        )}
                        key={index}
                      >
                        <img src={val.languageIcon} alt={val.name} /> {val.name}
                        {values[ProfileLanguageFormEnum.Language] ===
                        val.language ? (
                          <Check fill={'#3F9AA1'} width={10} height={8} />
                        ) : null}
                      </button>
                    ))}
                  </div>
                  <button
                    type={'button'}
                    disabled={!values[ProfileLanguageFormEnum.Language]}
                    className={classNames(
                      'h-12 sm:w-full w-full cursor-pointer rounded',
                      !values[ProfileLanguageFormEnum.Language]
                        ? 'bg-gray-100'
                        : 'btn-pink',
                    )}
                    onClick={() => {
                      setShowEditLanguagePopup(true)
                    }}
                  >
                    {_t('setLanguageSubmitTitle')}
                  </button>
                </div>
              </div>
              <EditLanguagePopup
                onSubmit={submitForm}
                visible={showEditLanguagePopup}
                onClose={() => setShowEditLanguagePopup(false)}
              />
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}

export default SetLanguagePopup
