import React, { useCallback, useMemo } from 'react'
import {
  selectCommunityMenuItem,
  selectCurrentCommunityId,
} from '@selectors/community'
import { useAppDispatch, useAppSelector } from '@hooks/store/redux'
import { selectCommunity, setCommunityMenuItem } from '@reducers/community'
import { useGetCommunities, useGetCommunity } from '@api/queries/community'
import Skeleton from '@components/skeleton/Skeleton'
import CommunityMenu from '@containers/creator/sidebar/components/CommunityMenu'
import { propEq, reject } from 'ramda'
import { useNavigate } from 'react-router-dom'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import classNames from 'classnames'
import { EXPANDED_SIDEBAR_WIDTH, SIDEBAR_WIDTH } from '@const/layout'
import { TypeMenuItem } from '@customTypes/sidebar'
import CommunityExpandedMenu from '@containers/creator/sidebar/components/CommunityExpandedMenu'
import { getCommunityMenu } from '@containers/creator/community/utils'
import { TypeCommunityMenu } from '@customTypes/community'
import useGetCommunityAdminRole from '@hooks/common/useGetCommunityAdminRole'

const CommunitySidebar = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { communityAdminRole } = useGetCommunityAdminRole()
  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)
  const selectedCommunityMenuItem = useAppSelector(selectCommunityMenuItem)

  const { data: communities, isLoading: communitiesLoading } =
    useGetCommunities()
  const { data: community, isLoading: selectedCommunityLoading } =
    useGetCommunity(selectedCommunityId, {
      enabled: Boolean(selectedCommunityId),
    })

  const otherCommunities = useMemo(() => {
    if (communities && selectedCommunityId) {
      return reject(propEq('id', selectedCommunityId), communities)
    }
    return undefined
  }, [communities, selectedCommunityId])

  const showExpandedMenu = useMemo(
    () =>
      Boolean(
        selectedCommunityMenuItem?.expandedMenu &&
          selectedCommunityMenuItem.expandedMenu.length > 0,
      ),
    [selectedCommunityMenuItem?.expandedMenu],
  )

  const selectedCommunityMenu = useMemo<TypeCommunityMenu | null>(
    () => getCommunityMenu(community, communityAdminRole),
    [community, communityAdminRole],
  )

  const handleClickChangeCommunity = useCallback(
    (communityId: string) => {
      dispatch(selectCommunity(communityId))
      navigate(CREATOR_PATH.communityId.replace(':id', communityId))
    },
    [dispatch, navigate],
  )

  const handleClickMenuItem = useCallback(
    (menuItem: TypeMenuItem) => {
      dispatch(setCommunityMenuItem(menuItem))
    },
    [dispatch],
  )

  if (!communitiesLoading && !communities?.length) {
    return null
  }

  return (
    <div className='hidden sm:flex'>
      <div
        className={classNames(
          'bg-white max-h-screen h-full border-r border-custom-gray-5 relative transition-all ease-linear z-10 ',
        )}
        style={{
          width: SIDEBAR_WIDTH,
        }}
      >
        {(communitiesLoading || selectedCommunityLoading) && <Skeleton />}
        <div className='flex flex-col h-full no-scrollbar w-[inherit]'>
          {selectedCommunityMenu && (
            <CommunityMenu
              menu={selectedCommunityMenu}
              otherCommunities={otherCommunities}
              onClickOtherCommunity={handleClickChangeCommunity}
              onClickMenuItem={handleClickMenuItem}
            />
          )}
        </div>
      </div>
      {selectedCommunityMenuItem && (
        <div
          className={classNames(
            'bg-white max-h-screen h-full border-r border-custom-gray-5 relative transition-all ease-linear z-[9]',
          )}
          style={{
            width: showExpandedMenu ? EXPANDED_SIDEBAR_WIDTH : 0,
          }}
        >
          <div
            className={classNames(
              'transition-all ease-linear delay-150',
              showExpandedMenu ? 'opacity-1' : 'opacity-0',
            )}
          >
            {selectedCommunityMenuItem?.expandedMenu && (
              <CommunityExpandedMenu
                menuItems={selectedCommunityMenuItem.expandedMenu}
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default CommunitySidebar
