import React, { Fragment, useCallback } from 'react'
import { useSelector } from 'react-redux'
import CommunityMenu from '@containers/common/mobileDrawerMenu/menus/Community'
import { useAppDispatch } from '@hooks/store/redux'
import { closeDrawerMenu } from '@reducers/drawerMenu'
import { DrawerMenuTypeEnum } from '@enums/common'
import UserMenu from '@containers/common/mobileDrawerMenu/menus/User'
import HelpMenu from '@containers/common/mobileDrawerMenu/menus/Help'
import NotificationsMenu from '@containers/common/mobileDrawerMenu/menus/Notifications'
import { selectDrawerMenuType } from '@selectors/drawerMenu'
import OrganizationMenu from '@containers/common/mobileDrawerMenu/menus/Organization'

const MobileDrawerMenu = () => {
  const dispatch = useAppDispatch()
  const menuType = useSelector(selectDrawerMenuType)

  const handleClose = useCallback(() => {
    dispatch(closeDrawerMenu())
  }, [dispatch])

  return (
    <Fragment>
      <OrganizationMenu
        show={menuType === DrawerMenuTypeEnum.Organization}
        onClose={handleClose}
      />
      <CommunityMenu
        show={menuType === DrawerMenuTypeEnum.Community}
        onClose={handleClose}
      />
      <UserMenu
        show={menuType === DrawerMenuTypeEnum.User}
        onClose={handleClose}
      />
      <HelpMenu
        show={menuType === DrawerMenuTypeEnum.Help}
        onClose={handleClose}
      />
      <NotificationsMenu
        show={menuType === DrawerMenuTypeEnum.Notifications}
        onClose={handleClose}
      />
    </Fragment>
  )
}

export default MobileDrawerMenu
