import React, { useCallback } from 'react'
import { _t } from '@locales/index'
import { useNavigate } from 'react-router-dom'
import { ORGANIZATION_PATH } from '@routes/organization/organizationPath'
import classNames from 'classnames'

interface Props {
  customClassName?: string
  onClick?: () => void
}

const ContactButton: React.FC<Props> = ({
  customClassName,
  onClick = () => {},
}) => {
  const navigate = useNavigate()

  const handleClick = useCallback(() => {
    navigate(ORGANIZATION_PATH.contact)
    onClick()
  }, [navigate, onClick])

  return (
    <button
      className={classNames(
        'mt-auto btn btn-turquoise py-4 sm:py-3',
        customClassName,
      )}
      onClick={handleClick}
    >
      {_t('contactUs')}
    </button>
  )
}

export default ContactButton
