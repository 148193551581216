import React, { useMemo } from 'react'
import Avatar, { AvatarProps } from '@components/avatar/Avatar'
import { TypeProfile } from '@customTypes/profile'
import { getProfileMediaByType } from '@containers/creator/profile/utils'
import { ProfileMediaTypeEnum } from '@enums/media'

interface Props extends AvatarProps {
  profile: TypeProfile
  imageUrl?: string
}

const ProfileAvatar: React.FC<Props> = ({ profile, ...avatarProps }) => {
  const profileImageUrl = useMemo<string | undefined>(() => {
    if (profile) {
      const media = getProfileMediaByType(
        ProfileMediaTypeEnum.Profile,
        profile.media,
      )
      if (media) {
        return media?.[0]?.url || ''
      }
    }
  }, [profile])

  return (
    <Avatar {...avatarProps} imageUrl={profileImageUrl} name={profile.name} />
  )
}

export default ProfileAvatar
