import React, { useMemo } from 'react'
import moment from 'moment'
import { ReactComponent as Arrow } from '@assets/images/common/arrow-thin.svg'

interface Props {
  date: number
  onClickNext: () => void
  onClickPrevious: () => void
  showYear?: boolean
}

const MonthPicker: React.FC<Props> = ({
  date,
  onClickNext,
  onClickPrevious,
  showYear = true,
}) => {
  const isCurrentMonth = useMemo(
    () => date === moment().startOf('month').unix(),
    [date],
  )

  return (
    <div className='flex items-center gap-2.5'>
      <Arrow className='cursor-pointer' onClick={onClickPrevious} />
      <p className='body3'>
        {moment.unix(date).format(showYear ? 'MMM YYYY' : 'MMM')}
      </p>
      {!isCurrentMonth ? (
        <Arrow className='rotate-180 cursor-pointer' onClick={onClickNext} />
      ) : (
        <div className='w-6' />
      )}
    </div>
  )
}

export default MonthPicker
