import { MediaFileTypeEnum } from '@enums/media'
import { TypeMediaFileType } from '@customTypes/media'
import apiService from '@services/api/apiService'
import { ApiKeyNameEnum } from '@enums/api'
import {
  TypeCreateCommunityMediaRequest,
  TypeCreateProfileMediaRequest,
  TypeMediaPreUploadRequest,
  TypeMediaPreUploadResponse,
  TypeQueueFile,
  TypeQueueMedia,
} from '@customTypes/uploader'
import { includes } from 'ramda'

const VIDEO_SUFFIX_TYPES = ['mov', 'mp4', 'm4a', 'm4v', 'webm']
const IMAGE_SUFFIX_TYPES = ['jpeg', 'png', 'jpg']
const AUDIO_SUFFIX_TYPES = ['aac', 'mp3', 'm4a', 'wav']
const DOCUMENT_SUFFIX_TYPES = ['pdf']
const ALL_SUFFIX_TYPES = [
  ...VIDEO_SUFFIX_TYPES,
  ...IMAGE_SUFFIX_TYPES,
  ...AUDIO_SUFFIX_TYPES,
  ...DOCUMENT_SUFFIX_TYPES,
]

export const createFileType = (file: File): TypeQueueFile => {
  const newFile: TypeQueueFile = {} as TypeQueueFile
  newFile.title = removeFileExtension(file.name)
  newFile.fileType = getFileTypeFromMIME(file.type.split('/')[0])
  newFile.media = file
  newFile.contentType = newFile.media.type
  newFile.suffix = '.' + getFileSuffix(newFile.title, newFile.fileType)

  if (newFile.fileType !== MediaFileTypeEnum.Document) {
    newFile.contentType = undefined
  }
  return newFile
}

export const removeFileExtension = (filename: any): string => {
  return filename.replace(/\.[^/.]+$/, '')
}

export const getFileTypeFromMIME = (mimeType: string): TypeMediaFileType => {
  const format = mimeType.split('/')[0]

  switch (format) {
    case 'image':
      return MediaFileTypeEnum.Image
    case 'video':
      return MediaFileTypeEnum.Video
    case 'audio':
      return MediaFileTypeEnum.Audio
    case 'text':
    case 'application':
      return MediaFileTypeEnum.Document
    default:
      return MediaFileTypeEnum.Image
  }
}

export const getFileSuffix = (
  filename: string,
  mediaFileType: TypeMediaFileType,
): string => {
  if (!filename) {
    return getSuffixByMime(mediaFileType)
  }

  const ext: string = filename.substring(
    filename.lastIndexOf('.') + 1,
    filename.length,
  )
  if (ALL_SUFFIX_TYPES.indexOf(ext.toLowerCase()) > -1) {
    return ext
  } else {
    return getSuffixByMime(mediaFileType)
  }
}

export const getSuffixByMime = (mimeType: TypeMediaFileType) => {
  switch (mimeType) {
    case MediaFileTypeEnum.Image:
      return 'jpg'
    case MediaFileTypeEnum.Video:
      return 'mp4'
    case MediaFileTypeEnum.Audio:
      return 'aac'
    case MediaFileTypeEnum.Document:
      return 'pdf'
    default:
      return ''
  }
}

export const preUploadMedia = async (
  mediaFileType: TypeMediaFileType,
  mediaSuffix: string,
  contentType: string,
): Promise<TypeMediaPreUploadResponse> => {
  const mediaPreUploadData: TypeMediaPreUploadRequest = {
    suffix: mediaSuffix,
    fileType: mediaFileType,
    contentType: contentType,
  }

  try {
    const response = await apiService.post(ApiKeyNameEnum.media_pre_upload, {
      ...mediaPreUploadData,
    })
    return response.data
  } catch (err) {
    throw err
  }
}

const postUpload = async (mediaData: any) => {
  try {
    const response = await apiService.post(ApiKeyNameEnum.media_create, {
      ...mediaData,
    })
    return response.data
  } catch (err) {
    throw err
  }
}

export const getCreateMediaBaseData = (queueMedia: TypeQueueMedia) => {
  return {
    fileType: queueMedia.file.fileType,
    url: queueMedia.file.url,
    key: queueMedia.file.key,
    name: queueMedia.file.title,
  }
}

export const createProfileMedia = (file: TypeQueueMedia, postParams: any) => {
  const mediaData: TypeCreateProfileMediaRequest = {
    ...getCreateMediaBaseData(file),
    profileId: postParams.profileId,
    mediaType: postParams.mediaType,
    subType: postParams.subType,
  }
  return postUpload(mediaData)
}

export const createCommunityMedia = (file: TypeQueueMedia, postParams: any) => {
  const mediaData: TypeCreateCommunityMediaRequest = {
    ...getCreateMediaBaseData(file),
    communityId: postParams.communityId,
    mediaType: postParams.mediaType,
    subType: postParams.subType,
  }
  return postUpload(mediaData)
}

const ACCEPTED_IMAGE_FILE_FORMATS = ['image/jpeg', 'image/png', 'image/jpg']

const ACCEPTED_AUDIO_FILE_FORMATS = [
  'audio/x-m4a',
  'audio/mp3',
  'audio/mpeg',
  'audio/x-wav',
  'audio/wav',
  'audio/aac',
  'audio/m4a',
  'audio/aac',
]

const ACCEPTED_VIDEO_FILE_FORMATS = [
  'video/mp4',
  'video/x-flv',
  'video/MP2T',
  'video/x-ms-wmv',
  'video/quicktime',
  'video/3gpp',
  'video/mpeg',
]

const ACCEPTED_DOCUMENT_FILE_FORMATS = ['application/pdf']

export const ACCEPTED_FILE_FORMATS = [
  ...ACCEPTED_IMAGE_FILE_FORMATS,
  ...ACCEPTED_AUDIO_FILE_FORMATS,
  ...ACCEPTED_VIDEO_FILE_FORMATS,
  ...ACCEPTED_DOCUMENT_FILE_FORMATS,
]

export const getAcceptedFileFormats = (
  fileType?: TypeMediaFileType,
): string => {
  let accepted_file_formats: string[]
  switch (fileType) {
    case MediaFileTypeEnum.Image:
      accepted_file_formats = ACCEPTED_IMAGE_FILE_FORMATS
      break
    case MediaFileTypeEnum.Video:
      accepted_file_formats = ACCEPTED_VIDEO_FILE_FORMATS
      break
    case MediaFileTypeEnum.Audio:
      accepted_file_formats = ACCEPTED_AUDIO_FILE_FORMATS
      break
    case MediaFileTypeEnum.Document:
      accepted_file_formats = ACCEPTED_DOCUMENT_FILE_FORMATS
      break
    default:
      accepted_file_formats = ACCEPTED_FILE_FORMATS
  }
  return accepted_file_formats.toString()
}

export const getFileType = (type: string): TypeMediaFileType => {
  if (includes(type, ACCEPTED_IMAGE_FILE_FORMATS)) {
    return MediaFileTypeEnum.Image
  } else if (includes(type, ACCEPTED_AUDIO_FILE_FORMATS)) {
    return MediaFileTypeEnum.Audio
  } else if (includes(type, ACCEPTED_VIDEO_FILE_FORMATS)) {
    return MediaFileTypeEnum.Video
  } else if (includes(type, ACCEPTED_DOCUMENT_FILE_FORMATS)) {
    return MediaFileTypeEnum.Document
  }
  return MediaFileTypeEnum.Image
}
