import React from 'react'
import { TypeOptions } from '@customTypes/common'
import classNames from 'classnames'

interface Props<T> {
  options: TypeOptions<T>
  value: T
  onChange: (value: T) => void
  showBorder?: boolean
}

const ToggleButtons = <T,>({
  options,
  value,
  onChange,
  showBorder = false,
}: Props<T>) => {
  return (
    <div
      className={classNames(
        'flex items-center',
        showBorder && 'border border-custom-gray-5 rounded-[5px]',
      )}
    >
      {options.map((option, i) => {
        const isSelected = value === option.value
        return (
          <p
            key={i}
            className={classNames(
              'body3 transition cursor-pointer px-1.5 py-2 last:pr-5 first:pl-5',
              isSelected
                ? 'text-custom-turquoise font-bold'
                : 'text-custom-gray-4',
            )}
            onClick={() => onChange(option.value)}
          >
            {option.label}
          </p>
        )
      })}
    </div>
  )
}

export default ToggleButtons
