import { TypeLaunchDateModalState, TypeSelector } from '@customTypes/store'
import { createSelector } from '@reduxjs/toolkit'

const selectLaunchDateModalState: TypeSelector<
  TypeLaunchDateModalState
> = state => state.launchDateModal

export const selectLaunchDateModalDate = (
  communityId: string | undefined,
): TypeSelector<Date | undefined> =>
  createSelector(selectLaunchDateModalState, state => {
    if (!communityId) {
      return undefined
    }
    return state[communityId]?.date || undefined
  })
