import React, { useMemo } from 'react'
import { TypeCommunityTask } from '@customTypes/community'
import { TaskStateEnum } from '@enums/common'
import { ReactComponent as CheckedIcon } from '@assets/images/common/checked-short.svg'
import classNames from 'classnames'
import _tf from '@locales/featureLocal'

interface Props {
  communityTask: TypeCommunityTask
  onClick: (taskId: string) => void
}

const Task: React.FC<Props> = ({ communityTask, onClick }) => {
  const isTaskStateDone = useMemo<boolean>(
    () => communityTask?.state === TaskStateEnum.Done,
    [communityTask?.state],
  )

  return (
    <div className='w-[21.875rem] flex flex-col px-5 gap-8 items-start rounded-xl bg-white'>
      <div className='flex flex-col items-start gap-5'>
        <div
          className={classNames(
            'mt-[1.461rem]',
            isTaskStateDone ? 'opacity-30' : null,
          )}
        >
          <img src={communityTask.taskTemplate.iconUrl} alt={'task icon'} />
        </div>
        <div className='flex flex-col items-start gap-1'>
          <p
            className={classNames(
              'body2 font-bold leading-[1.625rem]',
              isTaskStateDone ? 'opacity-30' : null,
            )}
          >
            {_tf('tasks', communityTask.taskId).title ||
              communityTask.taskTemplate.title}
          </p>
          <p
            className={classNames(
              'body2 leading-[1.625rem]',
              isTaskStateDone ? 'opacity-30' : null,
            )}
          >
            {_tf('tasks', communityTask.taskId).description ||
              communityTask.taskTemplate.description}
          </p>
        </div>
      </div>
      <button
        disabled={isTaskStateDone}
        className={classNames(
          `body3 capitalize flex justify-center items-center px-3 w-[19.375rem] h-10 rounded mb-5 mt-auto`,
          isTaskStateDone
            ? 'bg-custom-turquoise text-white'
            : 'bg-custom-yellow-1',
        )}
        onClick={() => onClick(communityTask.taskId)}
      >
        <div className='flex flex-row gap-1.5 h-[1.313rem]'>
          {isTaskStateDone ? (
            <span>
              <CheckedIcon className='h-full fill-white' />
            </span>
          ) : null}
          <span className='h-[1.313rem]'>
            {_tf('tasks', communityTask.taskId).cta ||
              communityTask.taskTemplate.ctaTitle}
          </span>
        </div>
      </button>
    </div>
  )
}

export default Task
