import React, { useCallback } from 'react'
import Drawer from 'react-modern-drawer'
import { DrawerProps } from '@customTypes/common'
import ExportSpreadsheet from '@containers/creator/payments/components/ExportSpreadsheet'
import PaypalAccount from '@containers/creator/payments/components/PaypalAccount'
import { openMobileBottomDrawer } from '@reducers/mobileBottomDrawer'
import { useAppDispatch } from '@hooks/store/redux'
import { MobileBottomDrawerTypeEnum } from '@enums/common'
import { ExportTransactionsTypeEnum } from '@enums/payment'

const WalletActionsDrawer: React.FC<DrawerProps> = ({
  open,
  onClose,
  direction,
  ...drawerProps
}) => {
  const dispatch = useAppDispatch()

  const handleClickExport = useCallback(() => {
    dispatch(
      openMobileBottomDrawer({
        type: MobileBottomDrawerTypeEnum.ExportOptions,
        meta: {
          communityId: undefined,
          profileId: undefined,
          type: ExportTransactionsTypeEnum.Wallet,
        },
      }),
    )
  }, [dispatch])

  return (
    <Drawer
      size={180}
      open={open}
      direction='bottom'
      onClose={onClose}
      className={'overflow-y-auto !w-screen rounded-t-[20px]'}
      {...drawerProps}
    >
      <div className='flex flex-col items-center gap-3 py-5'>
        <div className='p-4' onClick={handleClickExport}>
          <ExportSpreadsheet />
        </div>
        <div className='p-4'>{open && <PaypalAccount />}</div>
      </div>
    </Drawer>
  )
}

export default WalletActionsDrawer
