import React, { useCallback, useRef } from 'react'
import { TypeGuideline } from '@customTypes/guidelines'
import { ReactComponent as DragIcon } from '@assets/images/common/drag.svg'
import { ReactComponent as DotsOption } from '@assets/images/common/dots-2.svg'
import classNames from 'classnames'
import { useDrag, useDrop, XYCoord } from 'react-dnd'
import { Identifier } from 'typescript'
import { DragItemTypeEnum } from '@enums/dragItem'
import { _t } from '@locales/index'
import DropdownMenuItem from '@components/dropdownMenu/DropdownMenuItem'
import DropdownMenu from '@components/dropdownMenu/DropdownMenu'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'
import _tf from '@locales/featureLocal'

interface Props {
  guideline: TypeGuideline
  onRemove: (id: string) => void
  onEdit: (id: string) => void
  showRemove?: boolean
  onMove: (dragIndex: number, hoverIndex: number) => void
  index: number
  onDrop: () => void
}

interface DragItem {
  index: number
  id: string
  type: string
}

const Guideline: React.FC<Props> = ({
  guideline,
  onRemove,
  onEdit,
  showRemove = false,
  onMove,
  index,
  onDrop,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const isSmallerDevice = useIsSmallerDevice()

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: DragItemTypeEnum.Guideline,
    collect: (monitor: any) => {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover: (item: DragItem, monitor) => {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      onMove(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
    drop: onDrop,
  })

  const [{ isDragging }, drag] = useDrag({
    type: DragItemTypeEnum.Guideline,
    item: () => {
      return { id: guideline.id, index: index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  const handleRemove = useCallback(() => {
    if (!showRemove) {
      return
    }
    onRemove(guideline.id)
  }, [guideline.id, onRemove, showRemove])

  return (
    <div
      ref={isSmallerDevice ? null : ref}
      data-handler-id={handlerId}
      className='border border-custom-gray-5 bg-white max-w-[448px] py-3 px-5 flex flex-col  items-start gap-2 rounded-md cursor-pointer transition-all hover:bg-opacity-70'
      style={{ opacity }}
    >
      <div className='flex w-full items-center justify-between'>
        <div className='flex items-center gap-3'>
          <DragIcon
            className={classNames(
              'hidden sm:block cursor-pointer w-6 h-6 sm:w-8 sm:h-8',
              !showRemove && 'invisible',
            )}
          />
          <p className='body2 sm:body1 capitalize'>
            {_tf('guideline', guideline.id).title ?? guideline.title}
          </p>
        </div>
        <DropdownMenu
          triggerComponent={
            <DotsOption className='fill-black w-6 h-6 fill-custom-gray-3' />
          }
          positionClass='origin-top-right right-0 sm:right-auto sm:origin-top-left sm:left-0'
        >
          <DropdownMenuItem
            text={_t('edit')}
            onClick={() => onEdit(guideline.id)}
          />
          <DropdownMenuItem text={_t('delete')} onClick={handleRemove} />
        </DropdownMenu>
      </div>
      <p className='body3 sm:body2 text-custom-gray-2'>
        {_tf('guideline', guideline.id).description ?? guideline.description}
      </p>
    </div>
  )
}

export default Guideline
