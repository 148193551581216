import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  useGetUserProfile,
  useUpdateProfile,
  useUpdateProfileOccupation,
} from '@api/queries/profile'
import { Form, Formik } from 'formik'
import {
  convertProfileDataToFormValues,
  editProfileFormSchema,
} from '@containers/creator/profile/utils'
import { TypeEditProfileFormValues } from '@customTypes/profile'
import EditProfile from '@containers/creator/profile/EditProfile'
import { _t } from '@locales/index'
import { ReactComponent as BackIcon } from '@assets/images/common/arrow.svg'
import { useNavigate } from 'react-router-dom'
import UploadService from '@services/uploader/uploadService'
import { MediaTypeEnum } from '@enums/media'
import { ProfileFormEnum } from '@enums/profile'

const EditProfileWrapper = () => {
  const navigate = useNavigate()
  const { data: profile, refetch: refetchProfile } = useGetUserProfile()

  const [queueKey, setQueueKey] = useState<string>('')
  const [isMediaUploading, setIsMediaUploading] = useState<boolean>(false)

  const {
    mutate: updateProfileOccupation,
    isLoading: isOccupationBeingUpdated,
  } = useUpdateProfileOccupation({
    onSuccess: async () => {
      await refetchProfile()
    },
  })

  const { mutate: updateProfile, isLoading: isProfileBeingUpdated } =
    useUpdateProfile({
      onSuccess: async () => {
        await refetchProfile()
      },
    })

  const initialValues = useMemo<TypeEditProfileFormValues>(
    () => convertProfileDataToFormValues(profile),
    [profile],
  )

  const uploadMedia = useCallback(() => {
    if (queueKey) {
      setIsMediaUploading(true)
      UploadService.setQueueItemCallback(queueKey, async () => {
        await refetchProfile()
        setIsMediaUploading(false)
      })
      UploadService.runQueue(queueKey)
    }
  }, [queueKey, refetchProfile])

  const handleSubmit = useCallback(
    (values: TypeEditProfileFormValues) => {
      if (profile) {
        if (
          values[ProfileFormEnum.Occupation]?.id !==
          initialValues[ProfileFormEnum.Occupation]?.id
        ) {
          updateProfileOccupation({
            occupationId: values[ProfileFormEnum.Occupation]?.id || '',
            profileId: profile.id,
          })
        }

        if (
          values[ProfileFormEnum.Name] !==
            initialValues[ProfileFormEnum.Name] ||
          values[ProfileFormEnum.Bio] !== initialValues[ProfileFormEnum.Bio]
        ) {
          updateProfile({
            name: values[ProfileFormEnum.Name],
            bio: values[ProfileFormEnum.Bio],
            profileId: profile.id,
          })
        }

        if (
          values[ProfileFormEnum.ProfileImage] !==
          initialValues[ProfileFormEnum.ProfileImage]
        ) {
          uploadMedia()
        }
      }
    },
    [
      initialValues,
      profile,
      updateProfile,
      updateProfileOccupation,
      uploadMedia,
    ],
  )

  const handleClickBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  useEffect(() => {
    if (profile) {
      const key = `profile-queue-${profile.id}`
      UploadService.createQueueItem({
        type: MediaTypeEnum.ProfileMedia,
        postParams: {
          mediaType: MediaTypeEnum.ProfileMedia,
          profileId: profile.id,
        },
        cb: null,
        key,
        uploadAutomatically: false,
      })
      setQueueKey(key)
    }
  }, [profile])

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={editProfileFormSchema}
      enableReinitialize
    >
      {({ isValid, dirty, isSubmitting }) => (
        <Form className='bg-custom-green-gray flex flex-1 flex-col w-full h-full items-center relative'>
          <div className='sm:hidden pt-2.5 px-2.5 w-full'>
            <BackIcon
              className='fill-custom-black h-8 w-8'
              onClick={handleClickBack}
            />
          </div>
          <div className='w-full sm:w-auto flex flex-1 flex-col p-5 sm:p-13 overflow-y-auto'>
            <EditProfile
              isUpdating={
                isOccupationBeingUpdated ||
                isProfileBeingUpdated ||
                isMediaUploading
              }
              isMediaUploading={isMediaUploading}
              uploadMedia={uploadMedia}
              queueKey={queueKey}
            />
          </div>
          <div className='w-full h-20 bg-white px-5 flex items-center justify-between mt-auto sm:hidden'>
            <button
              className='btn btn-text h-12'
              type={'button'}
              onClick={handleClickBack}
            >
              {_t('editProfileCancelButton')}
            </button>
            <button
              className='btn btn-pink h-12'
              type={'submit'}
              disabled={!isValid || !dirty || isSubmitting}
            >
              {_t('editProfileUpdateButton')}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default EditProfileWrapper
