import React from 'react'
import { _t } from '@locales/index'
import { CommunityFormEnum } from '@enums/community'
import SelectCommunityType from '@containers/creator/createCommunity/components/SelectCommunityType'

const CommunityType = () => {
  return (
    <div className='community-page'>
      <div className='flex flex-col gap-13'>
        <h2 className='h4 sm:h2 capitalize text-center'>
          {_t('createCommunitySelectTypeHeading')}
        </h2>
        <div className='pb-2'>
          <SelectCommunityType
            name={CommunityFormEnum.Type}
            justifyClass='justify-center'
          />
        </div>
      </div>
    </div>
  )
}

export default CommunityType
