import React from 'react'
import Modal from '@components/modal/Modal'

interface Props {
  open: boolean
  onClose: () => void
  url: string
}

const VideoPopup: React.FC<Props> = ({ open, url, onClose }) => {
  return (
    <Modal
      open={open}
      showClose
      onClose={onClose}
      closeIconPositionClassName={'right-0 top-0 sm:top-0 sm:-right-12'}
    >
      <div className={'py-8 px-6'}>
        <video src={url} width={400} height={650} controls />
      </div>
    </Modal>
  )
}

export default VideoPopup
