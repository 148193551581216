import React, { useEffect, useState } from 'react'
import SelectBox from '@components/form/SelectBox'
import { useFormikContext } from 'formik'
import { TypePromoCodeStatusValue } from '@customTypes/promoCode'
import { PROMO_CODE_STATUS } from '@const/promoCode'

interface Props {
  onChangeStatus: (any: string) => void
}
const SelectStatusBox: React.FC<Props> = ({ onChangeStatus }) => {
  const couponsStatus = PROMO_CODE_STATUS()
  const [statusLabel, setStatusLabel] = useState<string>(
    couponsStatus.options[0].text,
  )
  const { values: status } = useFormikContext<TypePromoCodeStatusValue>()

  useEffect(() => {
    if (status.couponStatus !== null) {
      onChangeStatus(status.couponStatus)
      status.couponStatus === couponsStatus.options[0].value
        ? setStatusLabel(couponsStatus.options[0].text)
        : status.couponStatus === couponsStatus.options[1].value
        ? setStatusLabel(couponsStatus.options[1].text)
        : setStatusLabel(couponsStatus.options[2].text)
    }
  }, [status, onChangeStatus, couponsStatus.options])

  return (
    <SelectBox
      name={'couponStatus'}
      label={statusLabel}
      options={couponsStatus.options}
      buttonClassName='border border-custom-gray-5 rounded sm:ml-8 px-3 py-1 min-w-[150px] flex flex-row items-center justify-between'
      fontClassName='font-normal'
      listOptionsClassName='absolute mt-1 w-auto min-w-full overflow-auto rounded bg-white shadow-lg focus:outline-none border border-custom-gray-5 divide-y divide-custom-gray-5 z-10'
    />
  )
}

export default SelectStatusBox
