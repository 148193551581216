import React, { useCallback } from 'react'
import Modal from '@components/modal/Modal'
import { _t } from '@locales/index'
import LogoWithText from '@assets/images/common/logo-with-text-black.svg'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'
interface Props {
  onSubmit: () => void
  visible: boolean
  onClose: () => void
}

const EditLanguagePopup: React.FC<Props> = ({ onSubmit, visible, onClose }) => {
  const handleSubmitClick = useCallback(async () => {
    await onSubmit()
    onClose()
  }, [onClose, onSubmit])

  const isSmallerDevice = useIsSmallerDevice()

  return (
    <Modal
      open={visible}
      backgroundClassName='!bg-black/35 blur-xl'
      mainContainerClassName='px-5'
    >
      <div className={'flex flex-col rounded-xl overflow-y-hidden p-6'}>
        <div
          className={
            'flex flex-col items-center justify-center sm:gap-6 gap-14 mb-6'
          }
        >
          <img
            src={LogoWithText}
            className='w-[7.178rem] h-8'
            alt='logo-with-text'
          />
          <div
            className={
              'bg-white sm:w-[513px] h-[60px] flex flex-col items-center justify-center'
            }
          >
            <p className={'sm:body1 text-[16px] font-light'}>
              {_t('editSignUpPageCommunityLanguagePopupTitle')}
            </p>
            <p className={'sm:body1 text-[16px] font-light text-center'}>
              {_t('editSignUpPageCommunityLanguagePopupDescription')}
            </p>
          </div>
        </div>
        <div
          className={
            'flex items-center justify-between w-full h-20 border-t mt-6'
          }
        >
          <button
            className={'h-12 sm:w-[193px] w-[111px] btn cursor-pointer'}
            onClick={onClose}
          >
            {_t('editSignUpPageCommunityLanguagePopupCancelButton')}
          </button>
          <button
            type={'submit'}
            className={
              ' h-12 sm:w-[193px] w-[111px] btn-pink cursor-pointer rounded'
            }
            onClick={handleSubmitClick}
          >
            {_t(
              isSmallerDevice
                ? 'editSignUpPageCommunityLanguagePopupMobileSubmitButton'
                : 'editSignUpPageCommunityLanguagePopupSubmitButton',
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default EditLanguagePopup
