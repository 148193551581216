import React from 'react'
import classNames from 'classnames'
import { Disclosure } from '@headlessui/react'
import { ReactComponent as PointerIcon } from '@assets/images/common/pointer.svg'

interface Props {
  hidden?: boolean
  open: boolean
}

const ExpandContractButton: React.FC<Props> = ({ hidden = false, open }) => {
  if (hidden) {
    return null
  }

  return (
    <Disclosure.Button
      as='div'
      className='flex h-8 w-8 justify-center items-center cursor-pointer'
      hidden={hidden}
    >
      <PointerIcon
        fill={'#202020'}
        className={classNames(
          'w-4 transition-transform',
          open ? 'rotate-90' : '-rotate-90',
        )}
      />
    </Disclosure.Button>
  )
}

export default ExpandContractButton
