import React, { useCallback, useMemo } from 'react'
import { _t } from '@locales/index'
import { TypeCommunity } from '@customTypes/community'
import moment from 'moment'
import { ReactComponent as SettingsIcon } from '@assets/images/common/settings.svg'
import DropdownMenu from '@components/dropdownMenu/DropdownMenu'
import DropdownMenuItem from '@components/dropdownMenu/DropdownMenuItem'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as PartyIcon } from '@assets/images/common/partyPopper.svg'
import { BANNER_HEIGHT } from '@const/layout'
import useBreakpoint from '@hooks/responsive/useBreakpoint'
import { openMobileBottomDrawer } from '@reducers/mobileBottomDrawer'
import { MobileBottomDrawerTypeEnum } from '@enums/common'
import { useAppDispatch } from '@hooks/store/redux'

interface Props {
  community: TypeCommunity | undefined
}

const LaunchDateBanner: React.FC<Props> = ({ community }) => {
  const navigate = useNavigate()
  const breakpoint = useBreakpoint()
  const dispatch = useAppDispatch()

  const launchTimeRemaining = useMemo<string | undefined>(() => {
    if (community && community.launchDate) {
      const launchDate = community.launchDate.date
      const momentLaunchDate = moment(launchDate)
      const momentToday = moment()
      const diff = momentLaunchDate.diff(momentToday, 'days')
      if (diff > 24 && diff < 30) {
        return `in ${diff} days`
      }
      return moment.utc(launchDate).local().fromNow()
    }
  }, [community])

  const handleClickChangeLaunchDate = useCallback(() => {
    if (community) {
      navigate(CREATOR_PATH.changeLaunchDate.replace(':id', community.id))
    }
  }, [community, navigate])

  const handleClickLaunchDateSettings = useCallback(() => {
    if (community) {
      dispatch(
        openMobileBottomDrawer({
          type: MobileBottomDrawerTypeEnum.LaunchDate,
        }),
      )
    }
  }, [community, dispatch])

  if (!launchTimeRemaining) {
    return null
  }

  return (
    <div
      className='px-5 sm:px-13 flex items-center justify-between sm:justify-start bg-custom-yellow-1 z-1'
      style={{
        height: BANNER_HEIGHT[breakpoint],
      }}
    >
      <div className='flex items-center sm:mx-auto gap-4'>
        <PartyIcon className={'w-9 h-9'} />
        <p className='body2 sm:body1'>
          {`${_t('launchCommunityBannerTitle')} ${launchTimeRemaining}!`}
        </p>
      </div>
      <SettingsIcon
        fill={'#333333'}
        className={'w-6 h-6 ml-auto sm:hidden'}
        onClick={handleClickLaunchDateSettings}
      />
      <DropdownMenu
        triggerComponent={
          <SettingsIcon
            fill={'#333333'}
            className={'w-6 h-6 ml-auto hidden sm:block'}
          />
        }
      >
        <DropdownMenuItem
          text={_t('changeLaunchDate')}
          onClick={handleClickChangeLaunchDate}
        />
      </DropdownMenu>
    </div>
  )
}

export default LaunchDateBanner
