import React from 'react'
import { _t } from '@locales/index'
import { telegramPageUtils } from '@containers/creator/telegramPage/utils'
import { OPEN_TELEGRAM_BROWSER_LINK_BASE } from '@const/common'

interface Props {
  onClickDownloadTelegram?: () => void
  domain: string
  start: string
}

const DownloadTgPage: React.FC<Props> = ({
  onClickDownloadTelegram,
  domain,
  start,
}) => {
  return (
    <div className={'flex flex-col items-center justify-center'}>
      <div className={'flex  justify-between mt-[50px] mb-10'}>
        <button
          onClick={onClickDownloadTelegram}
          className={
            'w-[325px] h-[56px] bg-white rounded-full text-custom-black'
          }
        >
          {_t('downloadTgDeskApp')}
        </button>
        <button
          className={
            'w-[187px] h-[56px] bg-custom-black ml-4 border-2 text-white rounded-full'
          }
          onClick={() =>
            telegramPageUtils.openBotInBrowser(
              OPEN_TELEGRAM_BROWSER_LINK_BASE,
              domain,
              start,
            )
          }
        >
          {_t('openTgInBrowser')}
        </button>
      </div>
      <h1>( {_t('recommendMobileApp')} )</h1>
    </div>
  )
}

export default DownloadTgPage
