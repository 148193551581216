import { useNavigate } from 'react-router-dom'
import { useEffect, useMemo } from 'react'
import {
  selectNewAdminCommunityRole,
  selectNewAdminIsAffiliatorLoggedIn,
} from '@selectors/newAdmin'
import { useAppDispatch, useAppSelector } from '@hooks/store/redux'
import { useCreateProfile, useGetUserProfile } from '@api/queries/profile'
import { useAttachProfileToCommunity } from '@api/queries/community'
import { clearNewAdmin } from '@reducers/newAdmin'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import { useGetAccessToken } from '@api/queries/auth'
import { getLocalStorageCommunityAdminKey } from '@containers/creator/community/utils'
import { LocalStorageTypeEnum } from '@enums/localStorage'
import { selectCurrentCommunityId } from '@selectors/community'
import { CommunityGenreEnum } from '@enums/community'
import { useQueryClient } from 'react-query'
import { ServerStateKeysEnum } from '@enums/serverState'
import useIsAffiliateProfile from '@hooks/common/useIsAffiliateProfile'

interface ReturnProps {
  isProfileBeingCreated: boolean
  isProfileBeingAttached: boolean
}

const useAffiliatorRedirect = (): ReturnProps => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const isAffiliatorLoggedIn = useAppSelector(
    selectNewAdminIsAffiliatorLoggedIn,
  )
  const isAffiliateProfile = useIsAffiliateProfile()

  const selectedCommunityRole = useAppSelector(selectNewAdminCommunityRole)
  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)

  const { data: accessToken } = useGetAccessToken()
  const {
    data: profile,
    isFetching: isProfileBeingFetched,
    isLoading: isProfileLoading,
    isFetchedAfterMount: isProfileFetchedAfterMount,
  } = useGetUserProfile()

  const { mutate: createProfile, isLoading: isProfileBeingCreated } =
    useCreateProfile({
      onSuccess: createdProfile => {
        queryClient.invalidateQueries(ServerStateKeysEnum.Profile).then()
      },
    })
  const profileLoadingOrBeingCreated = useMemo(
    () =>
      Boolean(
        isProfileBeingFetched || isProfileLoading || isProfileBeingCreated,
      ),
    [isProfileBeingCreated, isProfileBeingFetched, isProfileLoading],
  )

  const {
    mutate: attachProfileToCommunity,
    isLoading: isProfileBeingAttached,
  } = useAttachProfileToCommunity()

  useEffect(() => {
    if (profile && selectedCommunityId && !isAffiliateProfile) {
      if (selectedCommunityRole) {
        const values = {
          profileId: profile.id,
          communityId: selectedCommunityId,
          communityRole: selectedCommunityRole,
        }
        attachProfileToCommunity(values, {
          onSuccess: communityAdmin => {
            queryClient
              .invalidateQueries([ServerStateKeysEnum.Community, 'all'])
              .then()
            dispatch(clearNewAdmin())
            localStorage.setItem(
              LocalStorageTypeEnum.CurrentCommunity,
              selectedCommunityId,
            )
            const communityAdminKey = getLocalStorageCommunityAdminKey(
              selectedCommunityId,
              profile.id,
            )
            localStorage.setItem(communityAdminKey, communityAdmin.role)
            navigate(
              CREATOR_PATH.affiliatePage.replace(':id', selectedCommunityId),
            )
          },
        })
      }
    } else if (isAffiliateProfile && selectedCommunityId) {
      navigate(CREATOR_PATH.affiliatePage.replace(':id', selectedCommunityId))
    }
  }, [
    attachProfileToCommunity,
    dispatch,
    isAffiliateProfile,
    navigate,
    profile,
    queryClient,
    selectedCommunityId,
    selectedCommunityRole,
  ])

  useEffect(() => {
    if (
      !profile &&
      !profileLoadingOrBeingCreated &&
      isProfileFetchedAfterMount &&
      isAffiliatorLoggedIn &&
      accessToken &&
      !isAffiliateProfile
    ) {
      createProfile({ genere: CommunityGenreEnum.Community })
    }
  }, [
    isAffiliatorLoggedIn,
    createProfile,
    accessToken,
    selectedCommunityId,
    selectedCommunityRole,
    attachProfileToCommunity,
    dispatch,
    navigate,
    profile,
    isProfileBeingCreated,
    profileLoadingOrBeingCreated,
    isProfileFetchedAfterMount,
    isAffiliateProfile,
  ])

  return { isProfileBeingCreated, isProfileBeingAttached }
}

export default useAffiliatorRedirect
