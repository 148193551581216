import React, { useCallback, useState } from 'react'
import { ReactComponent as SearchIcon } from '@assets/images/common/search.svg'
import classNames from 'classnames'
import { TypeLocation } from '@customTypes/location'
import { AxiosError } from 'axios'

interface Props {
  id?: string
  value: string
  onChange: (text: string) => void
  onSelect: (option: TypeLocation) => void
  className?: string
  wrapperClassName?: string
  data?: TypeLocation[]
  dataLoading: boolean
  dataError: AxiosError | null
}

const LocationsSearchBar: React.FC<Props> = ({
  id = 'locations-search-bar',
  value,
  onChange,
  onSelect,
  wrapperClassName,
  className,
  data,
}) => {
  const [showData, setShowData] = useState<boolean>(false)

  const handleOptionClick = useCallback(
    (option: TypeLocation) => {
      onSelect(option)
      setShowData(false)
    },
    [onSelect],
  )

  return (
    <div className={`w-full relative ${wrapperClassName}`}>
      <SearchIcon className={'absolute w-6 h-6 bottom-1.5'} fill='#333333' />
      <input
        type={'text'}
        id={id}
        value={value}
        onFocus={() => setShowData(true)}
        onBlur={() => setShowData(false)}
        onChange={e => onChange(e.target.value)}
        className={classNames(
          `text-black text-lg font-normal font-source-sans w-full mt-0 pr-0.5 pl-8 pt-0 pb-1 border-0 border-b border-custom-gray-1 focus:ring-0 focus:border-black`,
          [className && className],
        )}
        autoComplete={'off'}
      />
      {showData && data && (
        <div
          className='absolute top-full left-0 right-0 w-full z-10 bg-white max-h-52 overflow-y-scroll no-scrollbar shadow-xl'
          onMouseDown={e => e.preventDefault()}
        >
          {data.map(location => (
            <div
              className='px-8 py-3 border-b border-custom-gray-5 cursor-pointer hover:bg-custom-gray-5'
              key={location.id}
              onClick={() => handleOptionClick(location)}
            >
              <p className='text-lg font-source-sans'>{location.name}</p>
            </div>
          ))}
          {data.length === 0 && (
            <div className='px-8 py-3 border-b border-custom-gray-5 cursor-pointer hover:bg-custom-gray-5'>
              <p className='text-lg text-center font-source-sans'>No results</p>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default LocationsSearchBar
