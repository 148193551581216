import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { _t } from '@locales/index'
import CouponArchive from '@containers/creator/coupons/components/CouponArchive'
import CreateCoupon from '@containers/creator/coupons/components/CreateCoupon'
import { TypePromoCode } from '@customTypes/promoCode'
import RevokeCouponPopup from '@containers/creator/coupons/components/RevokeCouponPopup'
import CouponArchiveMobile from '@containers/creator/coupons/components/CouponArchiveMobile'
import useBreakpoint from '@hooks/responsive/useBreakpoint'
import { BreakpointsEnum } from '@enums/theme'
import {
  useCreatePromoCode,
  useGetPromoCodes,
  useRevokePromoCode,
} from '@api/queries/promoCode'
import { useParams } from 'react-router-dom'
import './CouponCalendar.css'
import { CouponsUtils } from '@containers/creator/coupons/utils'
import { Form, Formik } from 'formik'
import { useGetUserProfile } from '@api/queries/profile'

const Coupons = () => {
  const { id } = useParams()
  const breakpoint = useBreakpoint()
  const [coupons, setCoupons] = useState<TypePromoCode[]>([])
  const [isCreateCouponOpen, setIsCreateCouponOpen] = useState<boolean>(false)
  const [isRevokePopupVisible, setIsRevokePopupVisible] =
    useState<boolean>(false)
  const [clickedCoupon, setClickedCoupon] = useState<TypePromoCode>()
  const { data: couponsData, refetch: refetchGetCoupons } = useGetPromoCodes(id)
  const revokeCouponById = useRevokePromoCode()
  const { data: profile } = useGetUserProfile()
  const createCoupon = useCreatePromoCode()

  const isSmallerDevice: boolean = useMemo(
    () => breakpoint < BreakpointsEnum.xl,
    [breakpoint],
  )

  useEffect(() => {
    couponsData && setCoupons(couponsData.coupons)
  }, [couponsData])

  const handleCancelCreateCoupon = useCallback(() => {
    setIsCreateCouponOpen(false)
  }, [])

  const handleRevokeClick = useCallback(
    coupon => {
      setClickedCoupon(coupon)
      setIsRevokePopupVisible(true)
    },
    [setClickedCoupon, setIsRevokePopupVisible],
  )

  const revokeCoupon = useCallback(() => {
    // change clickedCoupon status to revoked
    clickedCoupon &&
      revokeCouponById.mutate(clickedCoupon.id, {
        onSuccess: () => {
          setIsRevokePopupVisible(false)
          refetchGetCoupons().then(r => {
            r.data?.coupons && setCoupons(r.data?.coupons)
          })
        },
      })
  }, [clickedCoupon, revokeCouponById, refetchGetCoupons])

  const handleCouponCreated = useCallback(() => {
    setIsCreateCouponOpen(false)
    refetchGetCoupons().then(r => {
      r.data?.coupons && setCoupons(r.data?.coupons)
    })
  }, [setIsCreateCouponOpen, refetchGetCoupons])

  const handleSendCouponInfo = useCallback(
    values => {
      if (profile) {
        const fromDate = CouponsUtils.formatDateString(values.couponDuration[0])
        const untilDate = CouponsUtils.formatDateString(
          values.couponDuration[1],
        )
        const discount = values.couponDiscount.substring(
          0,
          values.couponDiscount.indexOf('%'),
        )

        const couponObj = {
          communityId: id ? id : '',
          code: values.couponCode,
          discount: discount,
          validFromDate: fromDate,
          validUntilDate: untilDate,
          creatorId: profile?.id,
        }
        createCoupon.mutate(couponObj, {
          onSuccess: () => {
            handleCouponCreated()
          },
        })
      }
    },
    [profile, id, createCoupon, handleCouponCreated],
  )

  return (
    <div className='pt-12 sm:px-12 pb-24 page bg-custom-green-gray coupon-calendar'>
      <div className='flex flex-col mb-[50px]'>
        <div className='mb-5'>
          <h1 className='font-poppins text-2xl text-custom-black'>
            {_t('promoCodesTitle')}
          </h1>
        </div>
        <div className='mb-12 w-full lg:w-2/3 xl:w-1/2'>
          <h1 className='font-poppins font-normal text-lg text-custom-black'>
            {_t('promoCodesDescription')}
          </h1>
        </div>
        {!isCreateCouponOpen && (
          <button
            type='button'
            className='btn-base btn-inverted-contained py-2.5 w-[186px] rounded shadow-none font-bold'
            onClick={() => setIsCreateCouponOpen(true)}
          >
            {_t('createPromoCode')}
          </button>
        )}
        {isCreateCouponOpen && (
          <Formik
            initialValues={{
              couponCode: '',
              couponDiscount: '',
              couponDuration: [],
            }}
            validationSchema={CouponsUtils.couponsValidationSchema}
            onSubmit={handleSendCouponInfo}
          >
            <Form className='h-full'>
              <CreateCoupon
                communityId={id}
                onCancelCreateCoupon={handleCancelCreateCoupon}
                onCreateCoupon={handleCouponCreated}
              />
            </Form>
          </Formik>
        )}
      </div>
      <div>
        {isSmallerDevice && coupons ? (
          <CouponArchiveMobile
            coupons={coupons}
            onRevokeClick={(coupon: any) => handleRevokeClick(coupon)}
          />
        ) : coupons ? (
          <CouponArchive
            coupons={coupons}
            onRevokeClick={(coupon: any) => handleRevokeClick(coupon)}
          />
        ) : null}
      </div>
      <div>
        <RevokeCouponPopup
          visible={isRevokePopupVisible}
          onClose={() => setIsRevokePopupVisible(false)}
          onSave={revokeCoupon}
        />
      </div>
    </div>
  )
}

export default Coupons
