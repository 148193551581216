export enum ServerStateKeysEnum {
  Profile = 'profile',
  Communities = 'communities',
  Community = 'community',
  CommunityAudiences = 'communityAudiences',
  CommunityUserSegmentation = 'communityUserSegmentation',
  CommunityTypes = 'communityTypes',
  CommunityTasks = 'CommunityTasks',
  CommunityAdmin = 'CommunityAdmin',
  SuppliedContent = 'suppliedContent',
  AccessToken = 'accessToken',
  Media = 'media',
  CommunityMembers = 'communityMembers',
  CommunityMembersCount = 'communityMembersCount',
  Interests = 'interests',
  Locations = 'cities',
  SubInterests = 'subInterests',
  TemplateGuidelines = 'templateGuidelines',
  Payment = 'payment',
  CommunityBillingDetails = 'communityBillingDetails',
  ProfileBillingDetails = 'profileBillingDetails',
  AvailableFunds = 'availableFunds',
  PayoutSettings = 'payoutSettings',
  BillingTransactions = 'billingTransactions',
  Coupons = 'coupons',
  UserAgreement = 'userAgreement',
  Currencies = 'currencies',
  Occupations = 'occupations',
  RevenueSummary = 'revenueSummary',
  ExportBillingTransactions = 'exportBillingTransactions',
  Segmentation = 'Segmentation',
  AudienceSegmentation = 'audienceSegmentation',
}
