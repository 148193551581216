import React from 'react'
import { _t } from '@locales/index'
import { telegramPageUtils } from '@containers/creator/telegramPage/utils'
import { OPEN_TELEGRAM_BROWSER_LINK_BASE } from '@const/common'

interface Props {
  clickTG?: () => void
  domain: string
  start: string
}

const InitialDesktopViewDescription: React.FC<Props> = ({
  clickTG,
  start,
  domain,
}) => {
  return (
    <div className={'flex flex-col h-[130px] justify-between mt-[50px]'}>
      <button
        className={
          'w-[325px] h-[56px] bg-custom-black border-2 text-white rounded-full'
        }
        //bot link
        onClick={() =>
          telegramPageUtils.openBotInBrowser(
            OPEN_TELEGRAM_BROWSER_LINK_BASE,
            domain,
            start,
          )
        }
      >
        {_t('tgIUseTelegramInThisPc')}
      </button>
      <button
        className={'w-[325px] h-[56px] bg-white rounded-full text-custom-black'}
        onClick={clickTG}
      >
        {_t('iDontHaveTelegram')}
      </button>
    </div>
  )
}

export default InitialDesktopViewDescription
