import React from 'react'
import { TypeLocation } from '@customTypes/location'
import LocationPill from '@containers/creator/locations/components/LocationPill'

interface Props {
  clickedLocationId: string
  locations: TypeLocation[]
  selectedLocationIds: string[]
  onSelect: (locationId: string) => void
  onUnselect: (locationId: string) => void
}

const InterestList: React.FC<Props> = ({
  locations,
  selectedLocationIds,
  onSelect,
  onUnselect,
}) => {
  return (
    <div className='flex flex-col items-start space-y-5'>
      <div className='flex flex-wrap gap-4'>
        {locations &&
          locations.map(location => (
            <LocationPill
              location={location}
              isSelected={selectedLocationIds.some(
                sLocation => sLocation === location.id,
              )}
              key={location.id}
              onSelect={onSelect}
              onUnselect={onUnselect}
            />
          ))}
      </div>
    </div>
  )
}

export default InterestList
