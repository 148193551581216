import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GuidelinesModalModeEnum } from '@enums/guidelines'
import { TypeGuidelinesModalState } from '@customTypes/store'

const initialState: TypeGuidelinesModalState = {
  mode: GuidelinesModalModeEnum.Create,
  visible: false,
  onSubmit: () => {},
  guideline: undefined,
}

/* CASE REDUCERS */
const open: CaseReducer<
  TypeGuidelinesModalState,
  PayloadAction<TypeGuidelinesModalState>
> = (state, action) => {
  state.mode = action.payload.mode
  state.visible = action.payload.visible
  state.onSubmit = action.payload.onSubmit
  state.guideline = action.payload.guideline
}

const close: CaseReducer<TypeGuidelinesModalState> = state => {
  state.visible = false
  state.onSubmit = () => {}
  state.guideline = undefined
}

/* SLICE */
const guidelinesModalSlice = createSlice({
  name: 'guidelinesModal',
  initialState,
  reducers: {
    open,
    close,
  },
})

export const { open: openGuidelinesModal, close: closeGuidelinesModal } =
  guidelinesModalSlice.actions
const guidelinesModalReducer = guidelinesModalSlice.reducer

export default guidelinesModalReducer
