import React from 'react'
import { ReactComponent as GroupAimIcon } from '@assets/images/socials/group-aim.svg'

import { _t } from '@locales/index'
import classNames from 'classnames'
interface Props {
  onSubmit: () => void
  isSubmitting: boolean
  dirty: boolean
}
const FiltersFormHeader: React.FC<Props> = ({
  onSubmit,
  isSubmitting,
  dirty,
}) => {
  return (
    <div className='flex w-full px-3 py-5 items-center bg-custom-purple'>
      <div className='flex flex-1 items-center gap-3'>
        <GroupAimIcon />
        <h1 className='h3 text-white'>{_t('filter')}</h1>
      </div>

      <button
        type={'submit'}
        onClick={onSubmit}
        disabled={isSubmitting || !dirty}
        className={classNames(
          ' py-2 px-6 rounded-[55px] text-lg font-pangram-regular capitalize',
          isSubmitting
            ? 'bg-custom-gray-4  text-white'
            : 'bg-white  text-custom-purple',
        )}
      >
        {_t('applyFilters')}
      </button>
    </div>
  )
}

export default FiltersFormHeader
