import React, { useCallback, useEffect, useMemo, useState } from 'react'
import InputField from '@root/src/components/form/InputField'
import { _t } from '@root/src/core/locales'
import { Form, Formik, FormikProps } from 'formik'
import {
  useGetUserProfile,
  useUpdateProfile,
} from '@root/src/core/api/queries/profile'
import { MediaTypeEnum } from '@root/src/core/enums/media'
import UploadService from '@root/src/core/services/uploader/uploadService'
import uploadService from '@root/src/core/services/uploader/uploadService'
import MobilePhoneInput from '@root/src/components/form/MobilePhoneInput'
import ProfileImage from '@root/src/components/profileImage/ProfileImage'
import {
  useCommunityCreate,
  useGetCommunities,
} from '@root/src/core/api/queries/community'
import { TypeCommunityCreate } from '@root/src/core/types/community'
import { CommunityGenreEnum } from '@root/src/core/enums/community'
import { useNavigate } from 'react-router-dom'
import { ServerStateKeysEnum } from '@root/src/core/enums/serverState'
import { useQueryClient } from 'react-query'
import { ORGANIZATION_PATH } from '@root/src/core/routes/organization/organizationPath'
import Loader from '@components/loader/Loader'
import { TypeCreateOrganizationFormValues } from '@customTypes/organization'
import { OrganizationFormEnum } from '@enums/organization'
import { createOrganizationSchema } from '@containers/organization/onboarding/utils'
import { ProfileOnboardingStatusEnum } from '@enums/profile'

const CreateOrganization: React.FC = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [queueKey, setQueueKey] = useState<string>()

  const { data: communities, isLoading: isCommunitiesLoading } =
    useGetCommunities()
  const { data: profile, isLoading: isProfileLoading } = useGetUserProfile()

  const { mutate: createCommunity, isLoading: isCommunityBeingCreated } =
    useCommunityCreate({
      onSuccess: async () => {
        await queryClient.invalidateQueries([ServerStateKeysEnum.Communities])
      },
    })

  const { mutate: createInitialProfile, isLoading: isProfileBeingCreated } =
    useUpdateProfile()

  useEffect(() => {
    if (profile) {
      const key = `profile-queue-${profile.id}`
      UploadService.createQueueItem({
        type: MediaTypeEnum.ProfileMedia,
        postParams: {
          mediaType: MediaTypeEnum.ProfileMedia,
          profileId: profile.id,
        },
        cb: null,
        key,
        uploadAutomatically: false,
      })
      setQueueKey(key)
    }
  }, [profile])

  useEffect(() => {
    if (
      profile &&
      profile.onboardingStatus !== ProfileOnboardingStatusEnum.Initiated
    ) {
      if (communities && communities.length > 0) {
        navigate(
          ORGANIZATION_PATH.organizationId.replace(':id', communities[0].id),
        )
      }
    }
  }, [communities, navigate, profile])

  const uploadMedia = useCallback(() => {
    if (queueKey) {
      uploadService.setQueueItemCallback(queueKey, async () => {
        await queryClient.invalidateQueries([ServerStateKeysEnum.Profile])
      })
      uploadService.runQueue(queueKey)
    }
  }, [queueKey, queryClient])

  const handleSubmit = useCallback(
    (formValues, formikHelpers) => {
      if (!profile) return

      const values: TypeCommunityCreate = {
        genere: CommunityGenreEnum.Organization,
        title: `${formValues[OrganizationFormEnum.Name]} Org`,
        profileId: profile.id,
        price: 0,
      }

      createInitialProfile({
        profileId: profile.id,
        name: formValues[OrganizationFormEnum.Name],
        phone: formValues[OrganizationFormEnum.Phone],
      })

      createCommunity(values, {
        onSuccess: () => {
          formikHelpers.resetForm()
        },
      })
      uploadMedia()
    },
    [createCommunity, createInitialProfile, profile, uploadMedia],
  )

  const initialValues = useMemo<TypeCreateOrganizationFormValues>(() => {
    return {
      [OrganizationFormEnum.Name]: '',
      [OrganizationFormEnum.Phone]: '',
      [OrganizationFormEnum.ProfileImage]: '',
    }
  }, [])

  return (
    <main className='flex flex-col mt-24 items-center gap-14'>
      <h1 className='text-6xl text-center'>
        {_t('createOrganizationProfileTitle')}
      </h1>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={createOrganizationSchema}
        enableReinitialize
      >
        {({
          values,
          setFieldValue,
          isValid,
        }: FormikProps<TypeCreateOrganizationFormValues>) => (
          <Form className='flex flex-col w-4/5 gap-4'>
            <InputField
              name={OrganizationFormEnum.Name}
              id={OrganizationFormEnum.Name}
              placeholder={_t('createOrganizationProfileNamePlaceholder')}
            />

            <MobilePhoneInput
              id={OrganizationFormEnum.Phone}
              name={OrganizationFormEnum.Phone}
              placeholder={_t('createOrganizationProfilePhonePlaceholder')}
            />

            <ProfileImage
              isRectangleVersion={true}
              uploadKey={queueKey || ''}
              onSetImageUrl={src =>
                setFieldValue(OrganizationFormEnum.ProfileImage, src)
              }
              image={values[OrganizationFormEnum.ProfileImage]}
            />

            <button
              type={'submit'}
              disabled={!isValid}
              className='mt-20 p-4 bg-custom-purple rounded-[55px] text-white'
            >
              {_t('createOrganizationProfileSubmitButton')}
            </button>
          </Form>
        )}
      </Formik>
      <Loader
        visible={
          isCommunityBeingCreated ||
          isProfileBeingCreated ||
          isProfileLoading ||
          isCommunitiesLoading
        }
        fullWidth
      />
    </main>
  )
}

export default CreateOrganization
