import { useQuery, UseQueryOptions } from 'react-query'
import { ServerStateKeysEnum } from '@enums/serverState'
import { ApiKeyNameEnum } from '@enums/api'
import apiService from '@services/api/apiService'
import { TypeGetLocationsResponse, TypeLocation } from '@customTypes/location'
import { useProtection } from '@hooks/auth/useProtection'
import { AxiosError } from 'axios'

type TypeGetLocationsOptions = UseQueryOptions<
  TypeGetLocationsResponse,
  AxiosError,
  TypeLocation[],
  any
>

const getLocationsByKey = async (
  key?: string,
): Promise<TypeGetLocationsResponse> => {
  try {
    const response = await apiService.get(ApiKeyNameEnum.search_locations, key)
    return response?.data
  } catch (err) {
    throw err
  }
}

export const useGetLocationsByKey = (
  searchKey: string,
  options?: TypeGetLocationsOptions,
) => {
  const queryOptions = useProtection<TypeGetLocationsOptions>(options)

  return useQuery<TypeGetLocationsResponse, AxiosError, TypeLocation[]>(
    [ServerStateKeysEnum.Locations, 'search', searchKey],
    () => getLocationsByKey(searchKey),
    {
      select: data => {
        return data.cities
      },
      ...queryOptions,
    },
  )
}
