import { useGetUserProfile } from '@api/queries/profile'

interface UseCallQueryAfterProfileFn {
  <Type>(arg: any): Type
}

export const useCallQueryAfterProfile: UseCallQueryAfterProfileFn = options => {
  const { data: profile } = useGetUserProfile()

  return {
    ...options,
    enabled:
      options && 'enabled' in options
        ? Boolean(profile && options.enabled)
        : Boolean(profile),
  }
}
