import { BroadcastMessageFormEnum } from '@enums/broadcastMessage'
import { object, string } from 'yup'

export const broadcastMessageSchema = object().shape({
  [BroadcastMessageFormEnum.Message]: string().required(),
  [BroadcastMessageFormEnum.MediaUrl]: string(),
  [BroadcastMessageFormEnum.MediaFileType]: string(),
  [BroadcastMessageFormEnum.Link]: string(),
  [BroadcastMessageFormEnum.LinkLabel]: string(),
})
