import React, { useCallback, useRef } from 'react'
import Modal from '@components/modal/Modal'
import Webcam from 'react-webcam'
import { getCapturedImageFile } from '@components/cameraPopup/utils'
import { TypeCapturedImage } from '@customTypes/uploader'

interface Props {
  onSubmit: (capturedImage: TypeCapturedImage) => void
  visible: boolean
  onClose: () => void
}

const CameraPopup: React.FC<Props> = ({ onSubmit, visible, onClose }) => {
  const webcamRef = useRef<Webcam>() as any

  const handleCaptureClick = useCallback(async () => {
    if (webcamRef?.current) {
      const canvas = webcamRef?.current?.getCanvas()
      const imageUrl = webcamRef?.current?.getScreenshot()
      const imageFile = await getCapturedImageFile(canvas)
      const capturedImage: TypeCapturedImage = {
        imageUrl,
        imageFile,
      }
      onSubmit(capturedImage)
      onClose()
    }
  }, [onClose, onSubmit])

  return (
    <Modal
      open={visible}
      backgroundClassName='!bg-white/95 blur-none'
      mainContainerClassName='px-5'
    >
      <div className={'flex flex-col rounded-xl overflow-y-hidden'}>
        <div className={'bg-gray-600 relative'}>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat='image/jpeg'
            className={'w-full h-full'}
          />
        </div>
        <div className={'flex items-center justify-between w-full h-13'}>
          <button
            className={'h-full w-full btn-outlined cursor-pointer'}
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className={' h-full w-full btn-pink cursor-pointer'}
            onClick={handleCaptureClick}
          >
            Take a picture
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default CameraPopup
