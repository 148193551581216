import React, { useCallback, useEffect, useState } from 'react'

import Locations from '@containers/creator/locations/Locations'

import {
  useConnectPayPalPayment,
  useDeletePayPalPayment,
} from '@api/queries/payment'
import { _t } from '@locales/index'
import { PaymentProviderTypeEnum } from '@enums/payment'
import { PAYPAL_URL } from '@services/payout/payoutConfig'
import { useSearchParams } from 'react-router-dom'
import { useGetAccessToken } from '@api/queries/auth'
import { useGetPayoutSettings } from '@api/queries/payout'
import { toast } from 'react-hot-toast'
import { TypeChangePasswordRequest } from '@customTypes/user'
import { useChangePassword } from '@api/queries/user'

const Account = () => {
  const { data: accessToken } = useGetAccessToken()
  const [searchParams, setSearchParams] = useSearchParams()
  const [isPaypalConnected, setIsPaypalConnected] = useState<boolean>(false)

  const deletePaypal = useDeletePayPalPayment()
  const { mutate: connectToPaypalMutate } = useConnectPayPalPayment({
    onSuccess: async () => {
      setSearchParams('')
      await refetchPayment()
    },
  })

  const changePassword = useChangePassword({
    onError: error => {
      toast.error(error.message, { id: 'change-password' })
    },
    onSuccess: data => {
      window.location.href = data.url
    },
  })

  const { data: payment, refetch: refetchPayment } = useGetPayoutSettings()

  useEffect(() => {
    setIsPaypalConnected(payment?.providerId === PaymentProviderTypeEnum.PayPal)
  }, [payment])

  useEffect(() => {
    const code = searchParams.get('code')
    if (code && accessToken) {
      connectToPaypalMutate({ code })
    }
  }, [accessToken, connectToPaypalMutate, searchParams])

  const handleDisconnectClick = useCallback(
    (id: string | undefined) => {
      if (id) {
        deletePaypal.mutate(id, {
          onSuccess: () => {
            setIsPaypalConnected(false)
          },
        })
      }
    },
    [deletePaypal],
  )

  const handleChangePassword = useCallback(() => {
    const values: TypeChangePasswordRequest = {}
    changePassword.mutate(values)
  }, [changePassword])

  return (
    <div className='p-5 sm:p-12 sm:pb-24 page'>
      <div className='mb-10'>
        <h1 className='font-poppins font-bold text-4xl text-custom-black'>
          {_t('account')}
        </h1>
      </div>
      <div className='mb-[37px]'>
        <h1 className='font-roboto font-medium text-2xl text-custom-black'>
          {_t('accountEditPassword')}
        </h1>
      </div>
      <div className='mb-24'>
        <button
          type='button'
          className='btn-base btn-inverted-contained h-12 w-40 rounded custom-shadow-1'
          onClick={handleChangePassword}
        >
          {_t('accountRedirectToAuth0')}
        </button>
      </div>
      <div className='mb-24'>
        <h1 className='font-roboto font-medium text-2xl text-custom-black'>
          {_t('accountLocation')}
        </h1>
        <Locations
          wrapperClassName='!gap-8'
          searchWrapperClassName='!flex-auto'
          locationListWrapperClassName='!flex-auto'
        />
      </div>
      {isPaypalConnected ? (
        <div>
          <div className='mb-9'>
            <h1 className='font-roboto font-medium text-2xl text-custom-gray-3'>
              {_t('accountPayPalConnected')}
            </h1>
            <h1 className='font-roboto font-normal text-lg text-custom-blue-1'>
              {payment?.email}
            </h1>
          </div>
          <div>
            <button
              type='button'
              className='btn-base btn-default-contained h-12 w-40 rounded custom-shadow-1 border border-black'
              onClick={() => payment && handleDisconnectClick(payment.id)}
            >
              {_t('accountDisconnectPayPalBtn')}
            </button>
          </div>
        </div>
      ) : (
        <div>
          <div className='mb-9'>
            <h1 className='font-roboto font-medium text-2xl text-custom-gray-3'>
              {_t('accountConnectPayPal')}
            </h1>
            <h1 className='font-roboto font-normal text-lg text-custom-black'>
              {_t('accountGetPaid')}
            </h1>
          </div>
          <div>
            <a href={PAYPAL_URL}>
              <button
                type='button'
                className='btn-base btn-inverted-contained h-12 !w-40 rounded custom-shadow-1'
              >
                {_t('accountConnectPayPalBtn')}
              </button>
            </a>
          </div>
        </div>
      )}
    </div>
  )
}

export default Account
