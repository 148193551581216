import React from 'react'
import { _t } from '@locales/index'
import { ReactComponent as WebsiteIcon } from '@assets/images/common/website-icon.svg'
import { ReactComponent as TelegramIcon } from '@assets/images/common/telegram-icon.svg'
import classNames from 'classnames'

interface Props {
  link: string
  telegramFlow: boolean
  handleClick: (link: string) => void
}

const AffiliateLinkBox: React.FC<Props> = ({
  telegramFlow,
  link,
  handleClick,
}) => {
  return (
    <div className='flex shadow-xl flex-col sm:w-[350px] sm:h-[298px] bg-white px-5 py-[21px] rounded-xl '>
      <div
        className={classNames(
          'flex items-center gap-2 mb-6',
          telegramFlow ? 'text-[#34B0DF]' : 'text-custom-turquoise-dark',
        )}
      >
        {telegramFlow ? (
          <TelegramIcon className={'w-9 h-6 w-5 sh-12'} />
        ) : (
          <WebsiteIcon className={'w-9 h-6 w-5 sh-12'} />
        )}
        <p className='body2 font-bold capitalize'>
          {telegramFlow
            ? _t('affiliateGuidelineTelegramTitle')
            : _t('affiliateGuidelineLandingPageTitle')}
        </p>
      </div>
      <div className={'mb-8 sm:min-h-[130px]'}>
        <p className={'body2 font-normal'}>
          {telegramFlow
            ? _t('affiliateTelegramFlowDescription')
            : _t('affiliateLandingPageDescription')}
        </p>
      </div>
      <button
        onClick={() => handleClick(link)}
        className={classNames(
          'w-[310px] h-[46px] rounded-md',
          telegramFlow
            ? 'bg-gradient-to-t from-[#1E88D3] to-[#34B0DF] text-white'
            : 'bg-custom-yellow',
        )}
      >
        {telegramFlow
          ? _t('affiliateTelegramFlowButton')
          : _t('affiliateLandingPageButton')}
      </button>
    </div>
  )
}

export default AffiliateLinkBox
