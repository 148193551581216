import { array, number, object, string } from 'yup'
import { TypeCreateCommunityFormValues } from '@customTypes/community'
import { DEFAULT_MEMBERSHIP_PRICE } from '@const/community'
import { DEFAULT_CURRENCY_ID } from '@const/currency'
import { _t } from '@locales/index'
import { CommunityFormEnum } from '@enums/community'
import { ProfileFormEnum, ProfileLanguageFormEnum } from '@enums/profile'
import { TypeProfile } from '@customTypes/profile'
import { ProfileMediaTypeEnum } from '@enums/media'
import { getProfileMediaByType } from '@containers/creator/profile/utils'
import { CoverPhotoFormEnum } from '@enums/coverPhoto'

export const communityInitialValues: TypeCreateCommunityFormValues = {
  [CommunityFormEnum.Type]: '',
  [CommunityFormEnum.SuppliedContent]: [],
  [CommunityFormEnum.Currency]: DEFAULT_CURRENCY_ID,
  [CommunityFormEnum.Price]: DEFAULT_MEMBERSHIP_PRICE,
  [CommunityFormEnum.Title]: '',
  [ProfileFormEnum.Name]: '',
  [ProfileFormEnum.Occupation]: null,
  [ProfileFormEnum.ProfileImage]: '',
}

export const convertProfileDataToCommunityValues = (
  profile?: TypeProfile,
): TypeCreateCommunityFormValues => {
  let profileImageUrl = ''
  if (profile) {
    const profileMedia = getProfileMediaByType(
      ProfileMediaTypeEnum.Profile,
      profile?.media,
    )
    profileImageUrl =
      profileMedia && profileMedia.length > 0 ? profileMedia[0].url : ''
  }

  return {
    ...communityInitialValues,
    [ProfileFormEnum.ProfileImage]: profileImageUrl,
  }
}

export const communityTypeSchema = object().shape({
  [CommunityFormEnum.Type]: string().required(),
})

export const communitySuppliedContentSchema = object().shape({
  [CommunityFormEnum.SuppliedContent]: array().of(string()).required().min(1),
})

export const communityWelcomeVideoSchema = object().shape({
  [CommunityFormEnum.WelcomeVideo]: string().required(),
})

export const photoCoverUrlSchema = object().shape({
  [CoverPhotoFormEnum.Photo]: string().required(),
})

const COMMUNITY_NAME_MIN_LENGTH = 2
const COMMUNITY_NAME_MAX_LENGTH = 64

const COMMUNITY_DESCRIPTION_MIN_LENGTH = 3
const COMMUNITY_DESCRIPTION_MAX_LENGTH = 155

export const communityNameSchema = object().shape({
  [CommunityFormEnum.Title]: string()
    .min(
      COMMUNITY_NAME_MIN_LENGTH,
      _t('communityNameMinLengthError').replace(
        '{X}',
        COMMUNITY_NAME_MIN_LENGTH,
      ),
    )
    .max(
      COMMUNITY_NAME_MAX_LENGTH,
      _t('communityNameMaxLengthError').replace(
        '{X}',
        COMMUNITY_NAME_MAX_LENGTH,
      ),
    )
    .required(_t('communityNameRequiredError')),
})

export const communityDescriptionSchema = object().shape({
  [CoverPhotoFormEnum.Description]: string()
    .min(
      COMMUNITY_DESCRIPTION_MIN_LENGTH,
      _t('communityNameMinLengthError').replace(
        '{X}',
        COMMUNITY_DESCRIPTION_MIN_LENGTH,
      ),
    )
    .max(
      COMMUNITY_DESCRIPTION_MAX_LENGTH,
      _t('communityNameMaxLengthError').replace(
        '{X}',
        COMMUNITY_DESCRIPTION_MAX_LENGTH,
      ),
    )
    .required(_t('communityNameRequiredError')),
})

export const communityPriceSchema = object().shape({
  [CommunityFormEnum.Price]: number().required().min(1),
})

export const profileLanguageSchema = object().shape({
  [ProfileLanguageFormEnum.Language]: string().required(),
})

export const communitySubscriptionFeeSchema = object().shape({
  [CommunityFormEnum.Currency]: string().required(),
  [CommunityFormEnum.Price]: number().required().min(1),
})

export const createInitialProfileSchema = object().shape({
  [ProfileFormEnum.Name]: string().required(),
  [ProfileFormEnum.Occupation]: object()
    .shape({
      id: string().required(),
      name: string().required(),
    })
    .required(),
  [ProfileFormEnum.ProfileImage]: string().required(),
})

export const guidelineSchema = object().shape({
  title: string().required('Guideline must have a title'),
  description: string().required('Guideline must have a description'),
  order: number().required(),
})

export const communityGuidelinesSchema = object().shape({
  [CommunityFormEnum.Guidelines]: array().of(guidelineSchema).min(1).required(),
})
