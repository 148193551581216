import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const useBlockClickBack = () => {
  const navigate = useNavigate()
  useEffect(() => {
    const handleBackButton = (event: PopStateEvent) => {
      event.preventDefault()
      if (window.location.pathname === '/') {
        navigate('/')
      } else {
        window.history.back()
      }
    }

    window.addEventListener('popstate', handleBackButton)
    return () => {
      window.removeEventListener('popstate', handleBackButton)
    }
  }, [navigate])
}

export default useBlockClickBack
