import React, { useCallback } from 'react'
import { ReactComponent as SortIcon } from '@assets/images/common/sort.svg'
import DropdownMenu from '@components/dropdownMenu/DropdownMenu'
import { MEMBER_SORT_FILTER } from '@const/member'
import { useFormikContext } from 'formik'
import { TypeMemberFilters } from '@customTypes/member'
import SortOrderOption from '@containers/creator/members/components/SortOrderOption'

const SortOptions = () => {
  const { setFieldValue } = useFormikContext<TypeMemberFilters>()

  const handleSelectOption = useCallback(
    (value: string, orderValue: string) => {
      setFieldValue('sortBy', value)
      setFieldValue('orderBy', orderValue)
    },
    [setFieldValue],
  )

  return (
    <DropdownMenu triggerComponent={<SortIcon fill={'#202020'} />}>
      {MEMBER_SORT_FILTER.options.map((option, idx) => (
        <div
          className='flex w-full justify-center items-center p-3.5 gap-2'
          key={idx}
        >
          <SortOrderOption
            option={option.order[0]}
            onSelect={handleSelectOption}
            sortValue={option.value}
          />
          <span>/</span>
          <SortOrderOption
            option={option.order[1]}
            onSelect={handleSelectOption}
            sortValue={option.value}
          />
        </div>
      ))}
    </DropdownMenu>
  )
}

export default SortOptions
