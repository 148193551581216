import { BreakpointsEnum, DropdownItemVariantEnum } from '@enums/theme'

export const BREAKPOINTS: Record<BreakpointsEnum, number> = {
  [BreakpointsEnum.sm]: 640,
  [BreakpointsEnum.md]: 768,
  [BreakpointsEnum.lg]: 1024,
  [BreakpointsEnum.xl]: 1280,
  [BreakpointsEnum.dxl]: 1536,
}

export const DROPDOWN_ITEM_VARIANT_CLASS: Record<
  DropdownItemVariantEnum,
  string
> = {
  [DropdownItemVariantEnum.Default]: 'dropdown-item-default',
  [DropdownItemVariantEnum.Inverted]: 'dropdown-item-inverted',
  [DropdownItemVariantEnum.Yellow]: 'dropdown-item-yellow',
}
