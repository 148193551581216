import React, { useMemo } from 'react'
import { TypeGuideline } from '@customTypes/guidelines'
import Guideline from '@containers/creator/guidelines/components/Guideline'
import { _t } from '@locales/index'
import { ReactComponent as PlusIcon } from '@assets/images/common/plus-1.svg'

interface Props {
  guidelines: TypeGuideline[]
  onRemove: (id: string) => void
  onClickEdit: (id: string) => void
  onClickCreate: () => void
  onMove: (dragIndex: number, hoverIndex: number) => void
  onDrop: () => void
}

const Guidelines: React.FC<Props> = ({
  guidelines,
  onRemove,
  onClickEdit,
  onClickCreate,
  onMove,
  onDrop,
}) => {
  const showRemove = useMemo(() => guidelines?.length > 1, [guidelines?.length])

  return (
    <div className='flex flex-col gap-5'>
      <button
        type='button'
        className='btn btn-yellow w-[190px]'
        onClick={onClickCreate}
      >
        <PlusIcon className='w-6 h-6 fill-custom-gray-1' />
        {_t('guidelinesCreateNewButton')}
      </button>
      <div className='flex flex-col gap-3'>
        {guidelines &&
          guidelines.length > 0 &&
          guidelines.map((guideline, index) => {
            return (
              <Guideline
                guideline={guideline}
                key={guideline.id}
                onRemove={onRemove}
                showRemove={showRemove}
                onEdit={onClickEdit}
                onMove={onMove}
                onDrop={onDrop}
                index={index}
              />
            )
          })}
      </div>
    </div>
  )
}

export default Guidelines
