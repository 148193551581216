import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query'
import apiService from '@services/api/apiService'
import { ApiKeyNameEnum } from '@enums/api'
import {
  TypeConnectPayPalPayment,
  TypeGetWalletDataPayload,
  TypeSuccessPaypalConnectResponse,
  TypeSuccessPaypalDeleteResponse,
  TypeWalletDataResponse,
} from '@customTypes/payment'
import { AxiosError } from 'axios'
import { useProtection } from '@hooks/auth/useProtection'
import { getApiQueryString } from '@common/index'
import { ServerStateKeysEnum } from '@enums/serverState'

/**********************************
 CONNECT PAYPAL PAYMENT
 **********************************/

type TypeConnectPayPalPaymentOptions = UseMutationOptions<
  TypeSuccessPaypalConnectResponse,
  AxiosError,
  TypeConnectPayPalPayment
>

const connectPayPalPayment = async (
  values: TypeConnectPayPalPayment,
): Promise<TypeSuccessPaypalConnectResponse> => {
  try {
    const response = await apiService.post(
      ApiKeyNameEnum.connect_paypal,
      values,
    )
    return response?.data
  } catch (err) {
    console.log('in identity err', err)
    throw err
  }
}

export const useConnectPayPalPayment = (
  options?: TypeConnectPayPalPaymentOptions,
) => {
  return useMutation(connectPayPalPayment, {
    ...options,
  })
}

/**********************************
 DELETE PAYPAL PAYMENT
 **********************************/

type TypeDeletePaymentOptions = UseMutationOptions<
  TypeSuccessPaypalDeleteResponse,
  AxiosError,
  string
>

export const deletePayPalPayment = async (
  id: string,
): Promise<TypeSuccessPaypalDeleteResponse> => {
  try {
    const response = await apiService.deleteUrl(
      ApiKeyNameEnum.delete_paypal,
      id,
    )
    return response?.data
  } catch (err) {
    throw err
  }
}

export const useDeletePayPalPayment = (options?: TypeDeletePaymentOptions) => {
  const queryOptions = useProtection<TypeDeletePaymentOptions>(options)

  return useMutation(deletePayPalPayment, {
    ...queryOptions,
  })
}

/**********************************
 Wallet
 **********************************/

type TypeGetWalletDataOptions = UseQueryOptions<
  TypeWalletDataResponse,
  AxiosError,
  TypeWalletDataResponse,
  any
>

const getWalletData = async (
  payload?: TypeGetWalletDataPayload,
): Promise<TypeWalletDataResponse> => {
  try {
    const queryParams = {
      ...payload,
    }
    const qs = getApiQueryString(queryParams)
    const response = await apiService.get(ApiKeyNameEnum.wallet, `?${qs}`)
    return response.data
  } catch (err: any) {
    throw err
  }
}

export const useGetWalletData = (
  payload?: TypeGetWalletDataPayload,
  options?: TypeGetWalletDataOptions,
) => {
  const queryOptions = useProtection<TypeGetWalletDataOptions>(options)

  return useQuery(
    [ServerStateKeysEnum.RevenueSummary, payload],
    () => getWalletData(payload),
    {
      ...queryOptions,
    },
  )
}
