import React, { useMemo, useState } from 'react'
import { _t } from '@locales/index'
import { ReactComponent as FilterIcon } from '@assets/images/common/filter.svg'
import { useGetCommunityMembersCount } from '@hooks/common/useGetCommunityMembersCount'
import FiltersForm from '@containers/organization/filtersForm/FiltersForm'
import { useAppSelector } from '@hooks/store/redux'
import { selectUserSegmentationFiltersState } from '@selectors/userSegmentationFilters'

const FiltersButton = () => {
  const membersCount = useGetCommunityMembersCount()
  const userSegmentationsFilters = useAppSelector(
    selectUserSegmentationFiltersState,
  )

  const [showFiltersForm, setShowFiltersForm] = useState<boolean>(false)

  const badge = useMemo(() => {
    if (userSegmentationsFilters.valuesCount === undefined) {
      return null
    }

    return (
      <div className='w-4 h-4 rounded-full bg-custom-mustard absolute top-0 left-0 sm:-top-1 sm:-left-1 flex items-center justify-center'>
        <p className='text-[10px] font-pangram-regular text-white'>
          {userSegmentationsFilters.valuesCount}
        </p>
      </div>
    )
  }, [userSegmentationsFilters.valuesCount])

  if (!membersCount) {
    return null
  }

  return (
    <>
      <div className='relative'>
        <button
          className='btn btn-purple rounded-full h-[30px] hidden sm:flex'
          onClick={() => setShowFiltersForm(true)}
        >
          {_t('filtersButton')}
        </button>
        <button
          className='btn btn-purple rounded-full h-12 w-12 p-0 sm:hidden'
          onClick={() => setShowFiltersForm(true)}
        >
          <FilterIcon />
        </button>
        {badge}
      </div>
      <FiltersForm
        isShowFiltersForm={showFiltersForm}
        onClose={() => setShowFiltersForm(false)}
      />
    </>
  )
}

export default FiltersButton
