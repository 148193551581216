import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query'
import { ServerStateKeysEnum } from '@enums/serverState'
import { useProtection } from '@hooks/auth/useProtection'
import apiService from '@services/api/apiService'
import { ApiKeyNameEnum } from '@enums/api'
import { AxiosError } from 'axios'
import { TypeUserSegmentationAudience } from '@customTypes/userSegmentation'
import { TypeSegmentationValueFiltersPayload } from '@customTypes/segmentation'

/**********************************
 GET USER SEGMENTATION BY COMMUNITY
 **********************************/
type TypeGetCommunityUserSegmentationOptions = UseQueryOptions<
  TypeUserSegmentationAudience,
  AxiosError,
  TypeUserSegmentationAudience,
  any
>

const getCommunityUserSegmentation = async (
  id: string | undefined,
): Promise<TypeUserSegmentationAudience> => {
  try {
    const response = await apiService.get(
      ApiKeyNameEnum.community_user_segmentation,
      id,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetCommunityUserSegmentation = (
  id: string | undefined,
  options?: TypeGetCommunityUserSegmentationOptions,
) => {
  const queryOptions =
    useProtection<TypeGetCommunityUserSegmentationOptions>(options)

  return useQuery(
    [ServerStateKeysEnum.CommunityUserSegmentation, id],
    () => getCommunityUserSegmentation(id),
    {
      ...queryOptions,
    },
  )
}
/**********************************
 GET USER SEGMENTATION BY AUDIENCE
 **********************************/

type TypeGetAudienceUserSegmentationOptions = UseQueryOptions<
  TypeUserSegmentationAudience,
  AxiosError,
  TypeUserSegmentationAudience,
  any
>

const getAudienceUserSegmentation = async (
  id: string | undefined,
): Promise<TypeUserSegmentationAudience> => {
  try {
    const response = await apiService.get(
      ApiKeyNameEnum.audience_user_segmentation,
      id,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetAudienceUserSegmentation = (
  id: string | undefined,
  options?: TypeGetAudienceUserSegmentationOptions,
) => {
  const queryOptions =
    useProtection<TypeGetAudienceUserSegmentationOptions>(options)

  return useQuery(
    [ServerStateKeysEnum.AudienceSegmentation, id],
    () => getAudienceUserSegmentation(id),
    {
      ...queryOptions,
    },
  )
}

/**********************************
 GET SEGMENTATION COUNT BY FILTERS
 **********************************/
type TypeGetUserSegmentationCountOptions = UseMutationOptions<
  TypeUserSegmentationAudience,
  AxiosError,
  TypeSegmentationValueFiltersPayload
>

const getUserSegmentationCount = async (
  values: TypeSegmentationValueFiltersPayload,
): Promise<TypeUserSegmentationAudience> => {
  try {
    const response = await apiService.post(
      ApiKeyNameEnum.user_segmentation_filters,
      values,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetUserSegmentationCount = (
  options?: TypeGetUserSegmentationCountOptions,
) => {
  const queryClient = useQueryClient()

  const queryOptions =
    useProtection<TypeGetUserSegmentationCountOptions>(options)

  return useMutation(getUserSegmentationCount, {
    onSuccess: () => {
      return queryClient.invalidateQueries(
        ServerStateKeysEnum.CommunityUserSegmentation,
      )
    },
    ...queryOptions,
  })
}
