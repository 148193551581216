import React from 'react'

import { useNavigate } from 'react-router-dom'

import useBreakpoint from '@hooks/responsive/useBreakpoint'
import { COMMUNITY_WIZARD_HEADER_HEIGHT } from '@const/layout'

import { ReactComponent as Arrow } from '@assets/images/common/arrow-2.svg'
import { ReactComponent as Logo } from '@assets/images/common/logo-black.svg'

const CommunityWizardHeader = () => {
  const navigate = useNavigate()
  const breakpoint = useBreakpoint()

  return (
    <div
      className='h-12 flex items-center justify-between px-5 sm:hidden'
      style={{
        height: COMMUNITY_WIZARD_HEADER_HEIGHT[breakpoint],
      }}
    >
      <Arrow
        className='fill-custom-black rotate-180 w-6 h-6'
        onClick={() => navigate(-1)}
      />
      <Logo className='h-6 w-[25.76px]' />
    </div>
  )
}

export default CommunityWizardHeader
