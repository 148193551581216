import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query'
import { ServerStateKeysEnum } from '@enums/serverState'
import { useProtection } from '@hooks/auth/useProtection'
import apiService from '@services/api/apiService'
import { ApiKeyNameEnum } from '@enums/api'
import { AxiosError } from 'axios'
import {
  TypeAudience,
  TypeCreateAudience,
  TypeSegmentation,
} from '@customTypes/segmentation'
import { TypeCommunity } from '../../types/community'

/**********************************
 GET SEGMENTATION BY ID
 **********************************/

type TypeGetSegmentationOptions = UseQueryOptions<
  TypeSegmentation,
  AxiosError,
  TypeSegmentation,
  any
>

const getSegmentation = async (
  id: string | undefined,
): Promise<TypeSegmentation> => {
  try {
    const response = await apiService.get(ApiKeyNameEnum.segmentation, id)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetSegmentation = (
  id: string | undefined,
  options?: TypeGetSegmentationOptions,
) => {
  const queryOptions = useProtection<TypeGetSegmentationOptions>(options)

  return useQuery(
    [ServerStateKeysEnum.Segmentation, id],
    () => getSegmentation(id),
    {
      ...queryOptions,
    },
  )
}

/**********************************
 GET AUDIENCES BY COMMUNITY
 **********************************/
type TypeGetAudienceOptions = UseQueryOptions<
  TypeAudience[],
  AxiosError,
  TypeAudience[],
  any
>

const getAudiences = async (
  communityId: string | undefined,
): Promise<TypeAudience[]> => {
  try {
    const response = await apiService.get(
      ApiKeyNameEnum.audience_community,
      communityId,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetAudiences = (
  communityId: string | undefined,
  options?: TypeGetAudienceOptions,
) => {
  const queryOptions = useProtection<TypeGetAudienceOptions>(options)

  return useQuery(
    [ServerStateKeysEnum.CommunityAudiences, communityId],
    () => getAudiences(communityId),
    {
      ...queryOptions,
    },
  )
}

/******************************************
 CREATE GROUP
 ******************************************/

type TypeCreateAudienceOptions = UseMutationOptions<
  TypeCommunity,
  AxiosError,
  TypeCreateAudience
>

const createAudience = async (
  values: TypeCreateAudience,
): Promise<TypeCommunity> => {
  try {
    const response = await apiService.post(
      ApiKeyNameEnum.create_audience,
      values,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useCreateAudience = (options?: TypeCreateAudienceOptions) => {
  const queryOptions = useProtection<TypeCreateAudienceOptions>(options)

  return useMutation(createAudience, {
    ...queryOptions,
  })
}
