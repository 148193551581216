import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TypeUserSegmentationFiltersState } from '@customTypes/store'

const initialState: TypeUserSegmentationFiltersState = {
  segmentationsValues: {},
  communityId: undefined,
  valuesCount: undefined,
  filteredMemberCount: undefined,
}

/* CASE REDUCERS */
const set: CaseReducer<
  TypeUserSegmentationFiltersState,
  PayloadAction<TypeUserSegmentationFiltersState>
> = (state, action) => {
  state.segmentationsValues = action.payload.segmentationsValues
  state.communityId = action.payload.communityId
  state.valuesCount = action.payload.valuesCount
  state.filteredMemberCount = action.payload.filteredMemberCount
}

const clear: CaseReducer<TypeUserSegmentationFiltersState> = () => {
  return initialState
}

const userSegmentationFiltersSlice = createSlice({
  name: 'userSegmentationFilters',
  initialState,
  reducers: {
    set,
    clear,
  },
})

export const {
  set: setUserSegmentationFilters,
  clear: clearUserSegmentationFilters,
} = userSegmentationFiltersSlice.actions
const userSegmentationFiltersReducer = userSegmentationFiltersSlice.reducer

export default userSegmentationFiltersReducer
