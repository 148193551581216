import React, { useCallback, useMemo, useState } from 'react'
import { _t } from '@locales/index'
import UploadCoverPhotoPopup from '@containers/creator/uploadCoverPhoto/components/UploadCoverPhotoPopup'
import { ReactComponent as PlusIcon } from '@assets/images/common/plus-1.svg'
import { CoverPhotoFormEnum } from '@enums/coverPhoto'
import { useField } from 'formik'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'
import PhoneMockup from '@components/phoneMockup/PhoneMockup'
import exampleCoverPhoto from '@assets/images/community/example-cover-photo.png'
import ImageUploadWrapper from '@components/imageUpload/ImageUploadWrapper'
import { ImageEditorTypeEnum } from '@enums/common'
import { TypeCommunity } from '@customTypes/community'
import classNames from 'classnames'

interface Props {
  uploadKey: string
  title?: string
  community?: TypeCommunity
}

const CoverPhotoScreen: React.FC<Props> = ({ uploadKey, title, community }) => {
  const isSmallerDevice = useIsSmallerDevice()

  const [, meta, helpers] = useField<string>(CoverPhotoFormEnum.Photo)

  const [showUploadPopup, setShowUploadPopup] = useState<boolean>(false)

  const handleCloseUploadPopup = useCallback(() => {
    setShowUploadPopup(false)
  }, [])

  const handleClickCancelPopup = useCallback(() => {
    setShowUploadPopup(false)
  }, [])

  const uploadButton = useMemo(() => {
    if (meta.value) {
      return (
        <button
          type='button'
          className='btn btn-outlined w-full sm:w-[215px] px-2'
        >
          {_t('communityCoverChangePhoto')}
        </button>
      )
    } else {
      return (
        <button className='btn btn-pink w-full sm:w-[244px]'>
          <PlusIcon className='fill-white w-[18px]' />{' '}
          {_t('communityUploadCoverPhotoButton')}
        </button>
      )
    }
  }, [meta.value])

  return (
    <div className='flex items-center justify-center p-5 sm:p-26'>
      <div className='flex flex-col sm:flex-row gap-13 sm:gap-26 justify-center items-center sm:w-full h-full'>
        <div className='flex flex-col items-center sm:items-start gap-13'>
          <div className='flex mt-6 sm:mt-0 flex-col gap-3 sm:gap-5'>
            <p className='capitalize body1 sm:h2'>
              {isSmallerDevice
                ? _t('communityUploadCoverPhotoTitleMobile')
                : _t('communityUploadCoverPhotoTitle')}
            </p>
            <div className='sm:w-[455px] flex flex-col gap-5 sm:gap-3'>
              <p className='body2'>
                {_t('communityUploadCoverPhotoDescription')}
              </p>
              <p className='body3 font-bold sm:font-normal text-custom-gray-3'>
                {_t('communityUploadCoverPhotoSize')}
              </p>
            </div>
          </div>
          <div
            className={classNames(
              'w-full',
              meta.value ? 'hidden sm:block' : 'block',
            )}
          >
            {isSmallerDevice ? (
              <ImageUploadWrapper
                uploadQueueKey={uploadKey}
                onSetImageUrl={helpers.setValue}
                editorType={ImageEditorTypeEnum.PhoneMockup}
              >
                {({ open }) => <div onClick={open}>{uploadButton}</div>}
              </ImageUploadWrapper>
            ) : (
              // open popup in desktop for user to choose to upload from computer or from camera
              <div onClick={() => setShowUploadPopup(true)}>{uploadButton}</div>
            )}
          </div>
        </div>
        <div className={'flex flex-col gap-5'}>
          {(meta.value || isSmallerDevice) && (
            <div className='h-[529px] w-[257px]'>
              <PhoneMockup
                community={community}
                description={_t('communityCoverDescriptionExample')}
                backgroundMediaUrl={meta.value || exampleCoverPhoto}
              />
            </div>
          )}
          {isSmallerDevice && meta.value ? (
            <ImageUploadWrapper
              uploadQueueKey={uploadKey}
              onSetImageUrl={helpers.setValue}
              editorType={ImageEditorTypeEnum.PhoneMockup}
            >
              {({ open }) => <div onClick={open}>{uploadButton}</div>}
            </ImageUploadWrapper>
          ) : null}
        </div>
      </div>
      <UploadCoverPhotoPopup
        uploadKey={uploadKey}
        visible={showUploadPopup}
        coverPhotoUrl={meta.value}
        onSubmit={helpers.setValue}
        onClose={handleCloseUploadPopup}
        onClickCancel={handleClickCancelPopup}
        community={community}
      />
    </div>
  )
}

export default CoverPhotoScreen
