import React, { Fragment } from 'react'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import DrawerMenuTitle from '@containers/common/mobileDrawerMenu/components/DrawerMenuTitle'
import { DRAWER_MENU_TITLE_HEIGHT } from '@const/layout'
import { DrawerProps } from '@customTypes/common'

interface Props extends DrawerProps {
  title: string
  hideTitle?: boolean
}

const DrawerMenuWrapper: React.FC<Props> = ({
  title,
  children,
  onClose,
  hideTitle,
  ...drawerProps
}) => {
  return (
    <Drawer onClose={onClose} {...drawerProps}>
      <Fragment>
        {!hideTitle && <DrawerMenuTitle text={title} onClick={onClose} />}
        <div
          className='overflow-y-scroll no-scrollbar bg-custom-gray-5'
          style={{
            height: `calc(var(--vh, 1vh) * 100 - ${DRAWER_MENU_TITLE_HEIGHT}px)`,
          }}
        >
          {children}
        </div>
      </Fragment>
    </Drawer>
  )
}

export default DrawerMenuWrapper
