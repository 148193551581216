import { useAppDispatch, useAppSelector } from '@hooks/store/redux'
import { selectNewAdminProfileId } from '@selectors/newAdmin'
import { useAttachNewAuthUser } from '@api/queries/profile'
import { useEffect } from 'react'
import { useGetAccessToken } from '@api/queries/auth'
import { clearNewAdmin } from '@reducers/newAdmin'

interface ReturnProps {
  isLoading: boolean
  isSuccess: boolean
}

const useNewAdminRedirect = (): ReturnProps => {
  const dispatch = useAppDispatch()
  const profileId = useAppSelector(selectNewAdminProfileId)

  const { data: accessToken } = useGetAccessToken()

  const {
    mutate: attachNewAuthUser,
    isLoading,
    isSuccess,
  } = useAttachNewAuthUser()

  useEffect(() => {
    if (profileId && accessToken) {
      const values = {
        profileId: profileId,
      }

      attachNewAuthUser(values, {
        onSuccess: () => {
          dispatch(clearNewAdmin())
        },
      })
    }
  }, [profileId, attachNewAuthUser, accessToken, dispatch])

  return { isLoading, isSuccess }
}

export default useNewAdminRedirect
