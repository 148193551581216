import { TypeNewAdminState, TypeSelector } from '@customTypes/store'
import { createSelector } from '@reduxjs/toolkit'

export const selectNewAdminState: TypeSelector<TypeNewAdminState> = state =>
  state.newAdmin

export const selectNewAdminProfileId: TypeSelector<string | undefined> =
  createSelector(selectNewAdminState, state => state.profileId)

export const selectNewAdminCommunityRole: TypeSelector<string | undefined> =
  createSelector(selectNewAdminState, state => state.communityRole)

export const selectNewAdminIsAffiliatorLoggedIn: TypeSelector<boolean> =
  createSelector(selectNewAdminState, state => state.isAffiliatorLoggedIn)
