import React from 'react'
import { _t } from '@locales/index'
import ExportSpreadsheet from '@containers/creator/payments/components/ExportSpreadsheet'
import DropdownMenu from '@components/dropdownMenu/DropdownMenu'
import DropdownMenuItem from '@components/dropdownMenu/DropdownMenuItem'
import { EXPORT_SPREADSHEET_OPTIONS } from '@const/payment'

interface Props {
  onClickExport: (value?: number) => void
}

const CommunityPaymentHeader: React.FC<Props> = ({ onClickExport }: Props) => {
  return (
    <div className='flex items-center justify-center sm:justify-between'>
      <p className='h5 font-bold sm:font-normal'>
        {_t('communityPaymentTitle')}
      </p>
      <div className='hidden sm:flex items-center gap-8'>
        <DropdownMenu
          triggerComponent={<ExportSpreadsheet />}
          showDividers={false}
        >
          {EXPORT_SPREADSHEET_OPTIONS.map((option, i) => (
            <DropdownMenuItem
              key={i}
              text={option.label}
              onClick={() => onClickExport(option.value)}
            />
          ))}
        </DropdownMenu>
      </div>
    </div>
  )
}

export default CommunityPaymentHeader
