import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { _t } from '@locales/index'
import Search from '@containers/creator/members/components/Search'
import classNames from 'classnames'
import MemberFilters from '@containers/creator/members/components/Filters'
import { TypeMemberFilters, TypeMemberLayout } from '@customTypes/member'
import { getMemberFilterSearchParams } from '@containers/creator/members/utils'
import { useParams, useSearchParams } from 'react-router-dom'
import { MemberLayoutEnum } from '@enums/member'
import MemberLayoutOptions from '@containers/creator/members/components/LayoutOptions'
import NoResults from '@containers/creator/members/components/NoResults'
import { useGetCommunityMembers } from '@api/queries/member'
import MembersGrid from '@containers/creator/members/components/MemberGrid'
import MembersList from '@containers/creator/members/components/MemberTable'
import { useInView } from 'react-intersection-observer'
import SortOptions from '@containers/creator/members/components/Sort'
import { isEmpty } from 'ramda'
import { useFormikContext } from 'formik'

const CommunityMembersInner = () => {
  const { id } = useParams()

  const { values: filters, setFieldValue: setFilterValue } =
    useFormikContext<TypeMemberFilters>()

  const { ref: scrollMoreRef, inView } = useInView()
  const [, setSearchParams] = useSearchParams()
  const [layout, setLayout] = useState<TypeMemberLayout>(MemberLayoutEnum.Grid)
  const [searchFocus, setSearchFocus] = useState<boolean>(false)

  const {
    data: members,
    isLoading,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetCommunityMembers(id, filters, {
    enabled: Boolean(id),
  })

  const handleClearFilters = useCallback(() => {
    setFilterValue('search', '')
    setFilterValue('status', [])
    setFilterValue('engagement', [])
    setFilterValue('time', [])
  }, [setFilterValue])

  useEffect(() => {
    setSearchParams(getMemberFilterSearchParams(filters))
  }, [setSearchParams, filters])

  useEffect(() => {
    if (inView) {
      fetchNextPage().then()
    }
  }, [fetchNextPage, hasNextPage, inView])

  const emptyState = useMemo<boolean>(
    () => error?.response?.status === 204 || members?.pages.length === 0,
    [error?.response?.status, members?.pages.length],
  )

  const filtersApplied = useMemo<boolean>(
    () =>
      Boolean(
        !isEmpty(filters.search) ||
          !isEmpty(filters.status) ||
          // !isEmpty(filters.engagement) ||
          !isEmpty(filters.time),
      ),
    [filters],
  )

  return (
    <div className='container flex flex-col flex-1'>
      <div className='w-full flex flex-wrap justify-between gap-4 items-center'>
        <h1 className='capitalize font-poppins text-2xl font-bold'>
          {_t('communityMembersTitle')}
        </h1>
        <div
          className={classNames(
            'h-11 transition-[width]',
            searchFocus ? 'w-full sm:w-48' : 'w-full sm:w-32',
          )}
        >
          <Search
            name='search'
            id='search'
            onFocus={() => setSearchFocus(true)}
            onBlur={() => setSearchFocus(false)}
          />
        </div>
      </div>
      <div className='h-5 sm:h-10' />
      <div className='justify-between items-center hidden sm:flex'>
        <div className='flex items-center gap-[54px]'>
          <MemberFilters />
          {filtersApplied && (
            <button
              onClick={handleClearFilters}
              className='btn-base btn-inverted-contained shadow-none py-1.5 px-4 font-bold font-poppins text-sm leading-[21px]'
            >
              {_t('clearAllFilters')}
            </button>
          )}
        </div>
        <div className='flex items-center gap-14 z-0'>
          <SortOptions />
          <MemberLayoutOptions layout={layout} setLayout={setLayout} />
        </div>
      </div>
      <div className={emptyState ? 'h-0' : 'h-8'} />
      {emptyState ? (
        <NoResults filtersApplied={members?.pages.length === 0} />
      ) : layout === MemberLayoutEnum.Grid ? (
        <MembersGrid
          isLoading={isLoading || isFetchingNextPage}
          members={members?.pages}
        />
      ) : (
        <MembersList
          isLoading={isLoading || isFetchingNextPage}
          members={members?.pages}
        />
      )}
      {hasNextPage && <div ref={scrollMoreRef} />}
    </div>
  )
}

export default CommunityMembersInner
