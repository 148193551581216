import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import Modal from '@components/modal/Modal'
import { BreakpointsEnum } from '@enums/theme'
import useBreakpoint from '@hooks/responsive/useBreakpoint'
import classNames from 'classnames'
import { useUploadMembersCSV } from '@root/src/core/api/queries/community'
import { useGetUserProfile } from '@root/src/core/api/queries/profile'
import { useAppSelector } from '@root/src/core/hooks/store/redux'
import { selectCurrentCommunityId } from '@root/src/core/store/selectors/community'
import Loader from '@root/src/components/loader/Loader'
import { ServerStateKeysEnum } from '@enums/serverState'
import { useQueryClient } from 'react-query'

interface Props {
  show: boolean
  onClose: () => void
}

const CSVUploadMembersForm: React.FC<Props> = ({ show, onClose }) => {
  const queryClient = useQueryClient()
  const breakpoint = useBreakpoint()
  const isMobile: boolean = useMemo(
    () => breakpoint < BreakpointsEnum.md,
    [breakpoint],
  )
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const { data: profile } = useGetUserProfile()
  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)

  const { mutate: uploadMembersCSV } = useUploadMembersCSV()

  useEffect(() => {
    if (show && fileInputRef.current) {
      fileInputRef.current.click()
    }
  }, [show])

  const handleFileInputChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault()

      if (!(selectedCommunityId && profile?.id)) {
        return
      }

      const selectedFile = event.target.files?.[0]

      if (!selectedFile) return

      try {
        const file = new FormData()
        file.append('file', selectedFile)

        if (!file) return

        uploadMembersCSV(
          {
            communityId: selectedCommunityId,
            profileId: profile.id,
            csv_file: file,
          },
          {
            onSuccess: () => {
              queryClient
                .invalidateQueries(ServerStateKeysEnum.AudienceSegmentation)
                .then()
              queryClient
                .invalidateQueries(ServerStateKeysEnum.CommunityMembersCount)
                .then()
            },
          },
        )
        onClose()
      } catch (error) {
        console.error('Error uploading file:', error)
        onClose()
      }
    },
    [onClose, profile?.id, queryClient, selectedCommunityId, uploadMembersCSV],
  )

  return (
    <>
      <Modal showClose={true} open={show} onClose={onClose}>
        <div
          className={classNames(
            'flex flex-col items-center p-5 gap-9 h-[25dvh]',
            isMobile ? 'w-[90dvw]' : 'w-[30dvw]',
          )}
        >
          <Loader visible={show} />
        </div>
      </Modal>
      <input
        type='file'
        accept='.csv'
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileInputChange}
      />
    </>
  )
}

export default CSVUploadMembersForm
