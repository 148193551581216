import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Line } from 'react-chartjs-2'
import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import { MONTH_NAMES } from '@const/common'
import { _t } from '@locales/index'
import ToggleButtons from '@components/toggleButtons/ToggleButtons'
import { GRAPH_PERIOD_OPTIONS } from '@const/payment'
import { TypeGraphPeriodOptions } from '@customTypes/payment'
import { GraphPeriodOptionsEnum } from '@enums/payment'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { useGetMemberGrowthData } from '@api/queries/member'
import moment from 'moment'
import MonthPicker from '@containers/creator/payments/components/MonthPicker'
import { CommunityAdminContext } from '@context/community'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
)

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
      labels: {
        font: {
          size: 12,
          family: 'Poppins',
          weight: 'bold',
        },
      },
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 1,
        precision: 0,
      },
    },
  },
}

interface Props {}

const MemberGrowth: React.FC<Props> = () => {
  const [chartData, setChartData] =
    useState<ChartData<'line', number[], string>>()
  const [period, setPeriod] = useState<TypeGraphPeriodOptions>(
    GraphPeriodOptionsEnum.Year,
  )
  const communityAdminContext = useContext(CommunityAdminContext)

  const [date, setDate] = useState<number>(moment().unix())

  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)

  const { data: memberGrowthData } = useGetMemberGrowthData(
    {
      communityId: selectedCommunityId,
      profileId: communityAdminContext?.profileId,
      year: moment().year(),
      month:
        period === GraphPeriodOptionsEnum.Month
          ? moment.unix(date).month() + 1
          : undefined,
    },
    {
      enabled: Boolean(
        selectedCommunityId && date && communityAdminContext?.profileId,
      ),
    },
  )

  useEffect(() => {
    const data = memberGrowthData?.memberGrowthData
    let labels: string[] = MONTH_NAMES
    let dataset: number[] = Array(12).fill(0)

    // Check if data contains day information
    if (data && data.length > 0) {
      if (data[0].day) {
        // If data contains day information, labels are numbers from 1 to the number of days in the month
        const daysInMonth = new Date(data[0].year, data[0].month, 0).getDate()
        labels = Array.from({ length: daysInMonth }, (_, i) =>
          (i + 1).toString(),
        )
        dataset = Array(daysInMonth).fill(0)

        data.forEach(item => {
          if (item.day) {
            dataset[item.day - 1] = item.count
          }
        })
      } else {
        data.forEach(item => {
          dataset[item.month - 1] = item.count
        })
      }
    }

    setChartData({
      labels: labels,
      datasets: [
        {
          label: `Members`,
          data: dataset,
          fill: true,
          backgroundColor: 'rgba(208, 46, 143, 0.3)',
          borderColor: 'rgba(208, 46, 143, 1)', // pink
        },
      ],
    })
  }, [memberGrowthData])

  const handleClickNextMonth = useCallback(() => {
    setDate(prevDate => moment.unix(prevDate).add(1, 'M').unix())
  }, [])

  const handleClickPreviousMonth = useCallback(() => {
    setDate(prevDate => moment.unix(prevDate).subtract(1, 'M').unix())
  }, [])

  const monthPicker = useMemo(() => {
    if (period === GraphPeriodOptionsEnum.Year) {
      return null
    }
    return (
      <MonthPicker
        date={date}
        onClickNext={handleClickNextMonth}
        onClickPrevious={handleClickPreviousMonth}
        showYear={false}
      />
    )
  }, [date, handleClickNextMonth, handleClickPreviousMonth, period])

  return (
    <div className='w-full h-full bg-white p-3 sm:p-5 rounded-xl'>
      <div className='w-full h-full flex flex-col gap-[34px] sm:gap-5'>
        <div className='flex flex-col gap-2.5'>
          <div className='flex flex-1 items-center justify-between'>
            <p className='body1 capitalize'>{_t('memberGrowthTitle')}</p>
            <div className='hidden sm:block'>{monthPicker}</div>
            <ToggleButtons<TypeGraphPeriodOptions>
              options={GRAPH_PERIOD_OPTIONS}
              value={period}
              onChange={setPeriod}
              showBorder
            />
          </div>
          <div className='flex items-center justify-end sm:hidden'>
            {monthPicker}
          </div>
        </div>
        <div className='w-full'>
          {chartData && <Line options={options} data={chartData} />}
        </div>
      </div>
    </div>
  )
}

export default MemberGrowth
