import React, { useCallback, useState } from 'react'
import { _t } from '@locales/index'
import { CommunityMediaTypeEnum } from '@enums/media'
import { useNavigate } from 'react-router-dom'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import { TypeCommunityMediaType } from '@customTypes/media'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import classNames from 'classnames'
import Footer from '@components/footer/Footer'
import CommunityMediaOptions from '@containers/creator/uploadCommunityMedia/components/CommunityMediaOptions'
import UploadMediaScreenWrapper from '@containers/creator/uploadCommunityMedia/components/ScreenWrapper'

const UploadCommunityMedia = () => {
  const navigate = useNavigate()
  const isSmallerDevice = useIsSmallerDevice()
  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)

  const [mediaType, setMediaType] = useState<TypeCommunityMediaType>()

  const handleChoice = useCallback(() => {
    if (selectedCommunityId) {
      console.log(selectedCommunityId)
      if (mediaType === CommunityMediaTypeEnum.WelcomeVideo) {
        navigate(
          CREATOR_PATH.uploadWelcomeVideo.replace(':id', selectedCommunityId),
        )
      }
      if (mediaType === CommunityMediaTypeEnum.Cover) {
        navigate(
          CREATOR_PATH.uploadWelcomeCoverPhoto.replace(
            ':id',
            selectedCommunityId,
          ),
        )
      }
    }
  }, [selectedCommunityId, navigate, mediaType])

  return (
    <div className='flex flex-col w-full flex-1 '>
      <UploadMediaScreenWrapper>
        <div className='flex flex-col items-center justify-center py-5 px-3 sm:p-5 pt-8 sm:pt-26 gap-5 sm:gap-13'>
          <div
            className={classNames('flex flex-col px-2', mediaType && 'gap-5')}
          >
            <div className='flex flex-col items-center gap-3'>
              <h1 className={'body1 sm:h1 capitalize sm:text-center'}>
                {isSmallerDevice
                  ? _t('welcomeSelectMediaMobileTitle')
                  : _t('welcomeSelectMediaTitle')}
              </h1>
              <p className={'h5 text-custom-gray-3 hidden sm:block capitalize'}>
                {_t('welcomeSelectMediaDeskSubtitle')}
              </p>
            </div>
          </div>
          <CommunityMediaOptions
            mediaType={mediaType}
            onSetMediaType={setMediaType}
          />
        </div>
      </UploadMediaScreenWrapper>
      <Footer
        primaryButton={{
          text: _t('next'),
          onClick: handleChoice,
        }}
      />
    </div>
  )
}

export default UploadCommunityMedia
