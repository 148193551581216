import React, { useCallback } from 'react'
import { _t } from '@locales/index'
import Modal from '@components/modal/Modal'
import { useDispatch } from 'react-redux'
import { TypeDeleteGuidelinesModalState } from '@customTypes/store'
import { closeDeleteGuidelinesModal } from '@reducers/deleteGuidelinesModal'

type Props = TypeDeleteGuidelinesModalState

const ConfirmDeleteGuidelineModal: React.FC<Props> = ({
  onClose,
  visible,
  onConfirm,
  guidelineId,
}) => {
  const dispatch = useDispatch()

  const handleSubmit = useCallback(() => {
    if (guidelineId) {
      onConfirm(guidelineId)
      dispatch(closeDeleteGuidelinesModal())
    }
  }, [dispatch, guidelineId, onConfirm])

  const handleClose = useCallback(() => {
    onClose && onClose()
    dispatch(closeDeleteGuidelinesModal())
  }, [dispatch, onClose])

  if (!guidelineId) {
    return null
  }

  return (
    <Modal
      open={visible}
      backgroundClassName='!z-[60]'
      mainContainerClassName='!z-[70]'
      onClose={onClose}
      containerClassName='!w-11/12 sm:!w-[359px]'
    >
      <div className='flex flex-col gap-2.5 p-5 bg-white w-full rounded-xl'>
        <p className='body1 font-medium text-center'>
          {_t('guidelineDeletePopupTitle')}
        </p>
        <div className='flex justify-between items-center pt-[18px]'>
          <button
            className='btn btn-outlined'
            type='button'
            onClick={handleClose}
          >
            {_t('cancel')}
          </button>
          <button
            className='btn text-custom-error-red'
            type='submit'
            onClick={handleSubmit}
          >
            {_t('delete')}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmDeleteGuidelineModal
