import { useSearchParams } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { selectNewAdminIsAffiliatorLoggedIn } from '@selectors/newAdmin'
import { useAppDispatch, useAppSelector } from '@hooks/store/redux'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import { Locale } from '@locales/index'

const useAffiliatorLogIn = () => {
  const [searchParams] = useSearchParams()
  const communityId = searchParams.get('communityId')
  const communityRole = searchParams.get('communityRole')
  const communityLanguage = searchParams.get('communityLanguage')
  const { loginWithRedirect } = useAuth0()
  const dispatch = useAppDispatch()

  const isAffiliatorLoggedIn = useAppSelector(
    selectNewAdminIsAffiliatorLoggedIn,
  )

  useEffect(() => {
    ;(async () => {
      if (communityId && communityRole && !isAffiliatorLoggedIn) {
        await loginWithRedirect({
          redirectUri: window.location.origin + CREATOR_PATH.affiliatorRedirect,
          entity: 'profile',
          appState: {
            newAdmin: {
              isAffiliatorLoggedIn: true,
              communityId,
              communityRole,
            },
          },
          language: communityLanguage || Locale.getLanguage(),
        })
      }
    })()
  }, [
    loginWithRedirect,
    communityId,
    communityRole,
    isAffiliatorLoggedIn,
    dispatch,
    communityLanguage,
  ])
}

export default useAffiliatorLogIn
