import React from 'react'
import { ErrorMessage, useField } from 'formik'
import classNames from 'classnames'
import { _t } from '@locales/index'

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string
  id: string
  showCharacterLimit?: boolean
  textClass?: string
  disabled?: boolean
  label?: string
  hideErrorMessage?: boolean
  fontWeightClass?: string
}

const TextareaField: React.FC<Props> = ({
  className,
  textClass,
  hideErrorMessage,
  fontWeightClass,
  label,
  maxLength,
  showCharacterLimit,
  ...props
}) => {
  const [field, meta] = useField(props)
  const error = meta.touched && meta.error

  return (
    <div className='w-full flex flex-col gap-2'>
      {label && (
        <label
          htmlFor={props.id}
          className='body2 text-custom-gray-3 capitalize'
        >
          {label}
        </label>
      )}
      <textarea
        autoComplete='off'
        className={classNames(
          'flex-1 w-full resize-none px-4 py-2.5 border placeholder:text-custom-gray-4 placeholder:no-underline focus:ring-0 outline-none rounded disabled:bg-custom-gray-5 disabled:border-none',
          error
            ? 'border-custom-error-red bg-custom-red bg-opacity-10 focus:border-custom-error-red'
            : 'border-custom-gray-5 focus:ring-custom-gray-5 focus:border-custom-gray-5',
          className,
          textClass || 'body2',
          fontWeightClass || 'font-light',
        )}
        rows={4}
        maxLength={maxLength}
        {...field}
        {...props}
      />
      <div className='flex flex-row justify-between items-center mt-2'>
        {!hideErrorMessage && (
          <ErrorMessage
            name={props.name}
            component={'p'}
            className='text-custom-error-red body-3 capitalize mr-auto'
          />
        )}
        {showCharacterLimit && maxLength && (
          <p className={'body-3 text-custom-gray-3 ml-auto dynamic-align'}>{`(${
            maxLength - meta.value.length
          } ${_t('charactersLeft')})`}</p>
        )}
      </div>
    </div>
  )
}

export default TextareaField
