import React, { useCallback } from 'react'
import ReactPaginate from 'react-paginate'
import { ReactComponent as Arrow } from '@assets/images/common/arrow-thin.svg'

interface Props {
  pageCount: number
  currentPage: number
  onPageChange: (page: number) => void
}

const Pagination: React.FC<Props> = ({
  pageCount,
  currentPage,
  onPageChange,
}) => {
  const handlePageChange = useCallback(
    selectedItem => {
      onPageChange(selectedItem.selected)
    },
    [onPageChange],
  )

  return (
    <ReactPaginate
      pageCount={pageCount}
      previousLabel={<Arrow className='cursor-pointer' />}
      nextLabel={<Arrow className='cursor-pointer rotate-180' />}
      className='flex gap-2.5 font-source-sans text-custom-gray-3'
      activeClassName='text-custom-black font-bold'
      onPageChange={handlePageChange}
      forcePage={currentPage}
    />
  )
}

export default Pagination
