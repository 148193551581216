import React from 'react'
import SelectBox from '@components/form/SelectBox'
import {
  // MEMBER_ENGAGEMENT_FILTER,
  MEMBER_STATUS_FILTER,
  MEMBER_TIME_FILTER,
} from '@const/member'

const MemberFilters = () => {
  return (
    <div className='flex items-center gap-8 flex-wrap'>
      {/*<div>*/}
      {/*  <SelectBox*/}
      {/*    multi*/}
      {/*    name={'engagement'}*/}
      {/*    label={MEMBER_ENGAGEMENT_FILTER.label}*/}
      {/*    options={MEMBER_ENGAGEMENT_FILTER.options}*/}
      {/*  />*/}
      {/*</div>*/}
      <div>
        <SelectBox
          multi
          name={'status'}
          label={MEMBER_STATUS_FILTER.label}
          options={MEMBER_STATUS_FILTER.options}
        />
      </div>
      <div>
        <SelectBox
          multi
          name={'time'}
          label={MEMBER_TIME_FILTER.label}
          options={MEMBER_TIME_FILTER.options}
        />
      </div>
    </div>
  )
}

export default MemberFilters
