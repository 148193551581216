import React, { useCallback } from 'react'
import moment from 'moment'
import MonthPicker from '@containers/creator/payments/components/MonthPicker'
import { TypeBillingTransactionFilters } from '@customTypes/billing'

interface MonthNavigationProps {
  date: number
  setFilters: React.Dispatch<
    React.SetStateAction<TypeBillingTransactionFilters>
  >
}

const MonthNavigation: React.FC<MonthNavigationProps> = ({
  date,
  setFilters,
}) => {
  const handleClickNextMonth = useCallback(() => {
    setFilters(prevState => {
      return {
        startDate: moment.unix(prevState.startDate).add(1, 'M').unix(),
        endDate: moment.unix(prevState.endDate).add(1, 'M').unix(),
      }
    })
  }, [setFilters])

  const handleClickPreviousMonth = useCallback(() => {
    setFilters(prevState => {
      return {
        startDate: moment.unix(prevState.startDate).subtract(1, 'M').unix(),
        endDate: moment.unix(prevState.endDate).subtract(1, 'M').unix(),
      }
    })
  }, [setFilters])

  return (
    <MonthPicker
      date={date}
      onClickNext={handleClickNextMonth}
      onClickPrevious={handleClickPreviousMonth}
    />
  )
}

export default MonthNavigation
