import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TypeMobileBottomDrawerType } from '@customTypes/common'
import { OpenPayload, TypeMobileBottomDrawerState } from '@customTypes/store'

const initialState: TypeMobileBottomDrawerState = {
  type: null,
}

/* CASE REDUCERS */
const open: CaseReducer<
  TypeMobileBottomDrawerState,
  PayloadAction<OpenPayload<TypeMobileBottomDrawerType>>
> = (state, action) => {
  state.type = action.payload.type
  state.meta = action.payload.meta
}

const close: CaseReducer<TypeMobileBottomDrawerState> = state => {
  state.type = null
  state.meta = undefined
}

/* SLICE */
const mobileBottomDrawer = createSlice({
  name: 'mobileBottomDrawer',
  initialState,
  reducers: {
    open,
    close,
  },
})

export const { open: openMobileBottomDrawer, close: closeMobileBottomDrawer } =
  mobileBottomDrawer.actions
const mobileBottomDrawerReducer = mobileBottomDrawer.reducer

export default mobileBottomDrawerReducer
