import React, { useCallback } from 'react'

import { ReactComponent as BackIcon } from '@assets/images/common/arrow.svg'
import { useNavigate } from 'react-router-dom'

interface Props {
  onClickBack?: () => void
}

const LaunchScreenMobileHeader: React.FC<Props> = ({ onClickBack }) => {
  const navigate = useNavigate()

  const handleClickBack = useCallback(() => {
    if (onClickBack) {
      onClickBack()
      return
    }
    navigate(-1)
  }, [navigate, onClickBack])

  return (
    <div className='sm:hidden pt-2.5 px-2.5'>
      <BackIcon
        className='fill-custom-black h-8 w-8'
        onClick={handleClickBack}
      />
    </div>
  )
}

export default LaunchScreenMobileHeader
