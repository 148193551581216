import { useRoutes } from 'react-router-dom'
import { AFFILIATOR_ROUTES } from '@routes/creator/affiliatorRoutes'

const AffiliatorFlow = () => {
  const newAdminRoutes = useRoutes(AFFILIATOR_ROUTES)

  return <>{newAdminRoutes}</>
}

export default AffiliatorFlow
