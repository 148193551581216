import { LanguagesDirection, LanguagesEnum } from '@enums/language'
import { LanguagesSettings } from '@const/languages'
import { Locale } from '@locales/index'
import { useGetUserProfile, useUpdateProfile } from '@api/queries/profile'
import { LocalStorageTypeEnum } from '@enums/localStorage'
import { useEffect } from 'react'

const useSetLanguage = (language?: LanguagesEnum) => {
  const htmlElement = document.documentElement
  const { data: profile, refetch: refetchProfile } = useGetUserProfile()
  const local_language = Locale.getLanguage()
  const { mutate: updateProfile } = useUpdateProfile({
    onSuccess: async () => {
      await refetchProfile()
      localStorage.removeItem(LocalStorageTypeEnum.FirstLanguage)
    },
    onError: error => {
      console.log(error)
    },
  })

  useEffect(() => {
    const userSelectLanguage = localStorage.getItem(
      LocalStorageTypeEnum.FirstLanguage,
    )
    if (profile && userSelectLanguage) {
      updateProfile({
        language: userSelectLanguage,
        profileId: profile.id,
      })
    }
  }, [profile, updateProfile])

  const profileLanguage: LanguagesEnum =
    profile?.language || local_language || language || LanguagesEnum.EN
  if (profile) {
    Locale.setLanguage(profileLanguage)
    if (
      LanguagesSettings[profileLanguage].direction === LanguagesDirection.RTL
    ) {
      if (htmlElement) {
        htmlElement.dir = 'rtl'
        htmlElement.style.textAlign = 'right'
        document.documentElement.style.setProperty('--text-item', 'right')
        document.documentElement.style.setProperty('--direction-page', 'rtl')
        document.documentElement.style.setProperty('--flex-item', 'row-reverse')
      }
    }
  }
  return null
}

export default useSetLanguage
