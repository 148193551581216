import React, { useMemo } from 'react'
import { ReactComponent as CalendarIcon } from '@assets/images/sideMenu/calendar.svg'
import { ReactComponent as ChatIcon } from '@assets/images/common/chat.svg'
import { _t, Locale } from '@locales/index'
import { MEMBER_STATUS_TEXT, MESSAGE_MEMBER_URL } from '@const/member'
import Avatar from '@components/avatar/Avatar'
import moment from 'moment'
import MemberStatusIcon from '@containers/creator/members/components/EngagementIcon'
import { TypeCommunityMember } from '@customTypes/community'
import { LanguagesEnum } from '@enums/language'

interface Props {
  member: TypeCommunityMember
}

const MemberCard: React.FC<Props> = ({ member }) => {
  const memberName = useMemo(() => {
    if (member.member.firstName && member.member.lastName) {
      return member.member.firstName + ' ' + member.member.lastName
    } else if (member.member.username) {
      return member.member.username
    }
    return member.member.email
  }, [
    member.member.email,
    member.member.firstName,
    member.member.lastName,
    member.member.username,
  ])
  const local_language = Locale.getLanguage()
  const isHebrew = useMemo<boolean>(
    () => local_language === LanguagesEnum.HE,
    [local_language],
  )
  return (
    <div className='rounded bg-white shadow-lg p-3 pr-6'>
      <Avatar name={memberName} widthClass='w-[3.125rem] h-[3.125rem]' />
      <div className='h-1' />
      <p className='font-poppins font-bold text-sm capitalize'>{memberName}</p>
      <div className='h-3' />
      <div className='flex flex-col gap-2'>
        <div className='flex items-center gap-[5px]'>
          <CalendarIcon fill='#202020' className='w-4 h-4' />
          <p className='font-poppins capitalize text-sm text-custom-gray-2'>
            {_t('joined') +
              ' ' +
              moment
                .unix(member.created)
                .format(isHebrew ? 'DD-MM-YYYY' : 'MM-DD-YYYY')}
          </p>
        </div>
        {/*<div className='flex items-center gap-[5px]'>*/}
        {/*  <div*/}
        {/*    className='w-4 h-4 rounded-full'*/}
        {/*    style={{*/}
        {/*      backgroundColor: MEMBER_ENGAGEMENT_COLOR[entity.engagement],*/}
        {/*    }}*/}
        {/*  />*/}
        {/*  <p className='font-poppins capitalize text-sm text-custom-gray-2'>*/}
        {/*    {_t('xEngagement').replace(*/}
        {/*      '{X}',*/}
        {/*      MEMBER_ENGAGEMENT_TEXT[entity.engagement],*/}
        {/*    )}*/}
        {/*  </p>*/}
        {/*</div>*/}
        <div className='flex items-center gap-[5px]'>
          <MemberStatusIcon status={member.status} />
          <p className='font-poppins capitalize text-sm text-custom-gray-2'>
            {MEMBER_STATUS_TEXT[member.status]}
          </p>
        </div>
        <div className='h-5' />
        <a
          href={MESSAGE_MEMBER_URL + member.member.username}
          target='_blank'
          rel='noreferrer'
        >
          <button
            className='btn-base btn-inverted-contained rounded shadow-none w-[120px] text-xs font-poppins'
            type={'button'}
          >
            <ChatIcon fill='white' className='w-4 h-4 mr-[5px]' />
            <span>{_t('message')}</span>
          </button>
        </a>
      </div>
    </div>
  )
}

export default MemberCard
