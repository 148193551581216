export enum DrawerMenuTypeEnum {
  Community = 'community',
  Organization = 'organization',
  Notifications = 'notifications',
  Help = 'help',
  User = 'details',
}

export enum MobileBottomDrawerTypeEnum {
  CurrencyPicker = 'currencyPicker',
  CommunitySwitcher = 'communitySwitcher',
  LaunchDate = 'launchDate',
  CreatorNavigationMenu = 'creatorNavigationMenu',
  WalletActions = 'walletActions',
  ExportOptions = 'exportOptions',
  NavigationMenu = 'navigationMenu',
}

export enum SortOrderEnum {
  Ascending = 'asc',
  Descending = 'desc',
}

export enum TaskStateEnum {
  Default = 'DF',
  Incomplete = 'IC',
  Done = 'DO',
}

export enum TaskCtaTargetEnum {
  Telegram = 'TG',
  GrouperBlog = 'GB',
  CreatorPlatform = 'CP',
  LandingPage = 'LP',
  Empty = 'EM',
}

export enum TaskActionTypeEnum {
  Redirect = 'RD',
  CopyToClipboard = 'CTC',
}

export enum ButtonTypeEnum {
  Submit = 'submit',
  Button = 'button',
}

export enum ImageEditorTypeEnum {
  Default = 'default',
  PhoneMockup = 'phoneMockup',
}

export enum DeviationEnum {
  Up,
  Down,
}
