import { useProtection } from '@hooks/auth/useProtection'
import { useMutation, UseMutationOptions } from 'react-query'
import apiService from '@services/api/apiService'
import { ApiKeyNameEnum } from '@enums/api'
import { TypeBroadcastMessagePayload } from '@customTypes/broadcastMessage'
import { AxiosError } from 'axios'

type TypeBroadcastMessageOptions = UseMutationOptions<
  TypeBroadcastMessagePayload,
  AxiosError,
  TypeBroadcastMessagePayload,
  any
>

const sendBroadcastMessage = async (
  payload: TypeBroadcastMessagePayload,
): Promise<any> => {
  try {
    const response = await apiService.post(
      ApiKeyNameEnum.broadcast_message,
      payload,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useSendBroadcastMessage = (
  options?: TypeBroadcastMessageOptions,
) => {
  const mutationOptions = useProtection<TypeBroadcastMessageOptions>(options)

  return useMutation(sendBroadcastMessage, mutationOptions)
}
