import React, { useEffect, useState } from 'react'
import { _t } from '@locales/index'
import PhoneMockup from '@components/phoneMockup/PhoneMockup'
import { useField } from 'formik'
import TextareaField from '@components/form/TextareaField'
import { CoverPhotoFormEnum } from '@enums/coverPhoto'
import classNames from 'classnames'
import { useGetCommunity } from '@api/queries/community'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { useInView } from 'react-intersection-observer'
import { COMMUNITY_DESCRIPTION_LIMIT } from '@const/common'

interface Props {
  title?: string
  communityDescription?: string
  coverPhoto?: string
}

const DescriptionScreen: React.FC<Props> = () => {
  const { ref: descBoxRef, inView } = useInView()
  const communityId = useAppSelector(selectCurrentCommunityId)

  const [, metaPhoto] = useField<string>(CoverPhotoFormEnum.Photo)
  const [, metaDescription] = useField<string>(CoverPhotoFormEnum.Description)
  const [descBoxVisible, setDescBoxVisible] = useState<boolean>(false)

  const { data: community } = useGetCommunity(communityId, {
    enabled: Boolean(communityId),
  })

  useEffect(() => {
    if (inView) {
      setDescBoxVisible(true)
    }
  }, [inView])

  return (
    <div
      className={
        'flex flex-col sm:flex-row items-center justify-center gap-[100px] sm:mt-[100px] p-5 relative'
      }
    >
      <div ref={descBoxRef} />
      <div
        className={classNames(
          'absolute z-100 sm:static ease-in duration-500 border-b border-custom-gray-5 shadow-2xl sm:shadow-none sm:border-0',
          descBoxVisible ? 'top-0' : 'top-[-250px]',
        )}
      >
        <div
          className={classNames(
            'bg-white flex flex-col sm:items-start items-center justify-center sm:w-[500px] p-5',
          )}
        >
          <p className={'capitalize body1 sm:h2 mb-5'}>
            {_t('communityCoverDescriptionGuidelines')}
          </p>
          <div className={'flex flex-col items-end gap-5 w-full'}>
            <TextareaField
              name={CoverPhotoFormEnum.Description}
              id={CoverPhotoFormEnum.Description}
              placeholder={_t('communityCoverDescriptionExample')}
              showCharacterLimit
              hideErrorMessage
              maxLength={COMMUNITY_DESCRIPTION_LIMIT}
              rows={5}
              className={
                'w-full sm:w-[455px] border-custom-gray-5 rounded resize-none body3'
              }
              textClass='body3 placeholder:italic'
            />
          </div>
        </div>
      </div>
      <div className={'w-[242px] h-[500px]'}>
        <PhoneMockup
          community={community}
          description={metaDescription.value}
          backgroundMediaUrl={metaPhoto.value}
        />
      </div>
    </div>
  )
}

export default DescriptionScreen
