import { TypeCustomRangeInputValues } from '@customTypes/form'

export const COMMUNITY_MINIMUM_PRICE = 10
export const COMMUNITY_MAXIMUM_PRICE = 100
export const COMMUNITY_DEFAULT_PRICE = 25
export const TELEGRAM_CONNECTION_ERROR_TIMEOUT = 5 * 60 * 1000 // 5 minutes

export const COMMUNITY_CAPACITY_OPTIONS: TypeCustomRangeInputValues[] = [
  {
    inputValue: 0,
    value: 10,
    displayValue: '10',
  },
  {
    inputValue: 50,
    value: 50,
    displayValue: '50',
  },
  {
    inputValue: 100,
    value: 100,
    displayValue: '100',
  },
  {
    inputValue: 150,
    value: 250,
    displayValue: '250',
  },
  {
    inputValue: 200,
    value: 500,
    displayValue: '500',
  },
  {
    inputValue: 250,
    value: 200000,
    displayValue: 'Unlimited',
  },
]

export const COMMUNITY_MINIMUM_CAPACITY = COMMUNITY_CAPACITY_OPTIONS[0].value
export const COMMUNITY_MAXIMUM_CAPACITY =
  COMMUNITY_CAPACITY_OPTIONS[COMMUNITY_CAPACITY_OPTIONS.length - 1].value

export const DEFAULT_MEMBERSHIP_PRICE = 10

export const TELEGRAM_COMMUNITY_LINK = 'https://web.telegram.org/z/#-'
