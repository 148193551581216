import { TypeSegmentationValueFilters } from '@customTypes/segmentation'
import { TypeUserSegmentationCount } from '@customTypes/userSegmentation'
import { SegmentationFormEnum } from '@enums/segmentation'
export const segValueFiltersObj: TypeSegmentationValueFilters = {}

export const getInitialValues = (
  // segmentationIds: string[],
  segmentations: TypeUserSegmentationCount[] | null | undefined,
): TypeSegmentationValueFilters => {
  if (segmentations) {
    segmentations.forEach(segmentation => {
      segValueFiltersObj[segmentation.segmentationId] = {
        [SegmentationFormEnum.value]: [],
        [SegmentationFormEnum.operator]: '',
      }
    })
    return segValueFiltersObj
  }
  return {}
}
