import React, { useContext, useMemo } from 'react'
import { _t } from '@locales/index'
import { DEFAULT_CURRENCY_SYMBOL } from '@const/currency'
import { useGetBillingDetails } from '@api/queries/billing'
import { useParams } from 'react-router-dom'
import { CommunityAdminContext } from '@context/community'

interface Props {}

const MembershipFee: React.FC<Props> = () => {
  const { id: communityId } = useParams()

  const communityAdminContext = useContext(CommunityAdminContext)

  const { data: paymentDetails, isLoading } = useGetBillingDetails(
    { communityId, profileId: communityAdminContext?.profileId },
    {
      enabled: Boolean(communityId && communityAdminContext?.profileId),
    },
  )

  const membershipFee = useMemo<number>(
    () => Math.round((paymentDetails?.membershipFee || 0) * 100) / 100,
    [paymentDetails?.membershipFee],
  )

  return (
    <div className='w-full bg-white p-3 sm:p-5 sm:pr-8 rounded-xl'>
      <div className='w-full h-full flex justify-between items-start'>
        <div className='h-full flex flex-col sm:flex-col-reverse gap-6 justify-between'>
          {isLoading ? (
            <div className='skeleton' />
          ) : (
            <p className='h4 sm:h2 leading-normal sm:leading-[3.5rem]'>{`${DEFAULT_CURRENCY_SYMBOL}${membershipFee}`}</p>
          )}
          <div className='flex justify-between items-center'>
            <p className='caption text-custom-gray-4 capitalize sm:body2 sm:text-custom-black sm:!leading-[2.25rem]'>
              {_t('membershipFee')}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MembershipFee
