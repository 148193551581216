import React from 'react'

import { NAVBAR_HEIGHT, LAYOUT_FOOTER } from '@const/layout'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'
import useBreakpoint from '@hooks/responsive/useBreakpoint'

interface Props {
  children: React.ReactNode
}

const UploadMediaScreenWrapper: React.FC<Props> = ({ children }) => {
  const breakpoint = useBreakpoint()
  const isSmallerDevice = useIsSmallerDevice()

  return (
    <div
      className={
        'w-full flex flex-col justify-between relative overflow-y-auto'
      }
      style={{
        height: `calc((${
          isSmallerDevice ? 'var(--vh, 1vh) * 100' : '100vh'
        } - ${LAYOUT_FOOTER[breakpoint]}px) - ${NAVBAR_HEIGHT[breakpoint]}px`,
      }}
    >
      {children}
    </div>
  )
}

export default UploadMediaScreenWrapper
