import { TypeApiKeys } from '@customTypes/api'
import { ApiKeyNameEnum } from '@enums/api'

export const apiKeys: TypeApiKeys = {
  // profile
  [ApiKeyNameEnum.profile]: '/profile/',
  [ApiKeyNameEnum.profile_link]: '/profile/profile_link/',
  [ApiKeyNameEnum.my_profile]: '/profile/',
  [ApiKeyNameEnum.occupation]: '/common/occupation/',
  [ApiKeyNameEnum.profile_currency]: '/profile/contract/currency/',
  [ApiKeyNameEnum.profile_occupation]: '/profile/occupation/',
  [ApiKeyNameEnum.attach_auth_user]: '/profile/attach_auth_user/',

  // community
  [ApiKeyNameEnum.community]: '/community/',
  [ApiKeyNameEnum.communities]: '/community/all',
  [ApiKeyNameEnum.publish_community]: '/community/publish/',
  [ApiKeyNameEnum.community_types]: '/common/community_type/',
  [ApiKeyNameEnum.community_supplied_content]: '/community/supplied_content/',
  [ApiKeyNameEnum.community_task]: '/community/community_task/',
  [ApiKeyNameEnum.attach_profile_to_community]: '/community/attach_profile/',
  [ApiKeyNameEnum.upload_members_manually]:
    '/community/members/import/manually/',
  [ApiKeyNameEnum.upload_members_csv]: '/community/members/import/csv/',
  // currency
  [ApiKeyNameEnum.currency]: '/common/currency/',

  // supplied content
  [ApiKeyNameEnum.supplied_content]: '/common/supplied_content/',

  // members
  [ApiKeyNameEnum.member]: '/entity/',
  [ApiKeyNameEnum.community_members]: '/community/members/',
  [ApiKeyNameEnum.members_count]: '/community/members_count/',
  [ApiKeyNameEnum.member_subscribed_email]: '/entity/subscribed_email',
  [ApiKeyNameEnum.member_growth_data]: '/community/member_growth/',

  //launch_date
  [ApiKeyNameEnum.launch_date]: '/community/launch_date/',

  // media
  [ApiKeyNameEnum.media]: '/media/',
  [ApiKeyNameEnum.user_media]: '/media/user_media/',
  [ApiKeyNameEnum.media_create]: '/media/create/',
  [ApiKeyNameEnum.media_pre_upload]: '/media/pre_upload/',

  // interests
  [ApiKeyNameEnum.main_interests]: '/common/interests/main',
  [ApiKeyNameEnum.related_interests]: '/common/interests/related/',
  [ApiKeyNameEnum.parent_interests]: '/common/interests/parents/',
  [ApiKeyNameEnum.search_interests]: '/common/interests/search/',
  [ApiKeyNameEnum.community_interests]: '/community/interests/',
  [ApiKeyNameEnum.profile_interests]: '/profile/interests/',

  //locations
  [ApiKeyNameEnum.search_locations]: '/common/location/',

  // guidelines
  [ApiKeyNameEnum.community_guidelines]: '/community/guidelines/',
  [ApiKeyNameEnum.template_guidelines]: '/common/guideline_templates/',

  //paypal payment
  [ApiKeyNameEnum.create_paypal_payment]: '/payment/paypal/payout/',
  [ApiKeyNameEnum.delete_paypal]: '/payment/payout/',
  [ApiKeyNameEnum.connect_paypal]: '/payment/paypal/identity/',

  // payment
  [ApiKeyNameEnum.stripe_setup_intent]: '/payment/stripe/setup_intent/',
  [ApiKeyNameEnum.community_unsubscribe_request]:
    '/payment/community/unsubscribe/',
  [ApiKeyNameEnum.change_payment_method]: '/payment/change_payment_method/',

  // entity
  [ApiKeyNameEnum.member_request]: '/entity/request/',

  // billing
  [ApiKeyNameEnum.billing_details]: '/payment/billing_details/',

  [ApiKeyNameEnum.profile_billing_details]: '/payment/billing_details/profile/',

  [ApiKeyNameEnum.available_funds]: '/payment/available_funds/',

  [ApiKeyNameEnum.billing_transactions]: '/payment/billing_transaction/',
  [ApiKeyNameEnum.export_billing_transactions]:
    '/payment/billing_transaction/export/',

  [ApiKeyNameEnum.revenue]: '/payment/revenue/community/',

  // payout
  [ApiKeyNameEnum.payout_settings]: '/payment/payout/',

  // wallet
  [ApiKeyNameEnum.wallet]: '/payment/wallet/',

  //contact_us
  [ApiKeyNameEnum.contact_us]: '/common/contact_us/',

  //promoCodes
  [ApiKeyNameEnum.get_coupon_list]: '/payment/coupon/',
  [ApiKeyNameEnum.create_coupon]: '/payment/coupon/',
  [ApiKeyNameEnum.delete_coupon]: '/payment/coupon/',
  [ApiKeyNameEnum.validate_coupon_code]: '/payment/coupon/validate_code/',

  //contact_us
  [ApiKeyNameEnum.change_password]: '/user/change_password/',

  //product_request
  [ApiKeyNameEnum.community_payment_request]: '/product_request/community/pay/',
  [ApiKeyNameEnum.community_join_free_request]:
    '/product_request/community/join_free/',
  [ApiKeyNameEnum.community_coupon_code_check]:
    '/payment/coupon/validate_coupon/',

  //user agreement
  [ApiKeyNameEnum.user_agreement]: '/agreement/user_agreement/',
  [ApiKeyNameEnum.user_agreement_for_member]:
    '/agreement/user_agreement/entity',

  //Segmentation
  [ApiKeyNameEnum.segmentation]: '/segmentation/',
  [ApiKeyNameEnum.audience_community]: '/segmentation/audience/community/',
  [ApiKeyNameEnum.audience_user_segmentation]:
    '/segmentation/user_segmentation/audience/',
  [ApiKeyNameEnum.community_user_segmentation]:
    '/segmentation/user_segmentation/community/',
  [ApiKeyNameEnum.create_audience]: '/segmentation/audience/',
  [ApiKeyNameEnum.user_segmentation_filters]:
    '/segmentation/user_segmentation/filters/',

  //broadcast_message
  [ApiKeyNameEnum.broadcast_message]: '/segmentation/broadcast_message/',
}
