import { useEffect, useState } from 'react'
import { useGetCommunities } from '@api/queries/community'
import { CommunityApprovalStatusEnum } from '@enums/community'
import { TypeCommunityBase } from '@customTypes/community'

const useCheckCommunityApproval = () => {
  const [pendingCommunity, setPendingCommunity] = useState<TypeCommunityBase>()
  const { data: communities } = useGetCommunities({
    refetchInterval: () => {
      // stop the interval once approved
      if (!pendingCommunity) {
        return false
      }
      return 10 * 60 * 1000 // 10 minutes
    },
  })

  useEffect(() => {
    if (communities) {
      let firstPendingCommunity = undefined
      communities.forEach(community => {
        if (
          community.communityStatus ===
          CommunityApprovalStatusEnum.AwaitingReview
        ) {
          firstPendingCommunity = community
          return
        }
      })
      setPendingCommunity(firstPendingCommunity)
    }
  }, [communities])
}

export default useCheckCommunityApproval
