import React, { useCallback, useMemo } from 'react'
import 'react-modern-drawer/dist/index.css'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import CommunityMenuItem from '@containers/creator/sidebar/components/CommunityMenuItem'
import { TypeMenuItem } from '@customTypes/sidebar'
import { useGetCommunity } from '@api/queries/community'
import Drawer from 'react-modern-drawer'
import { MOBILE_COMMUNITY_SIDEBAR_FOOTER_HEIGHT } from '@const/layout'
import { getCommunityMenu } from '@containers/creator/community/utils'
import { TypeCommunityMenu } from '@customTypes/community'
import useGetCommunityAdminRole from '@hooks/common/useGetCommunityAdminRole'

interface Props {
  show: boolean
  onClose: () => void
}

const CommunityMenu: React.FC<Props> = ({ show, onClose }) => {
  const { communityAdminRole } = useGetCommunityAdminRole()
  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)

  const { data: community } = useGetCommunity(selectedCommunityId, {
    enabled: Boolean(selectedCommunityId),
  })

  const menu = useMemo<TypeCommunityMenu | null>(
    () => getCommunityMenu(community, communityAdminRole),
    [community, communityAdminRole],
  )

  const handleMenuItemClick = useCallback(
    (menuItem: TypeMenuItem) => {
      if (menuItem.expandedMenu && menuItem.expandedMenu.length > 0) {
        return
      } else {
        onClose()
      }
    },
    [onClose],
  )

  return (
    <Drawer
      open={show}
      direction={'left'}
      onClose={onClose}
      size={'75vw'}
      className='rounded-r-2xl overflow-hidden !h-[calc(var(--vh,_1vh)_*_100)]'
      enableOverlay={true}
    >
      <div className='h-full'>
        {menu && (
          <div className='flex flex-col h-full bg-white'>
            <div
              className='overflow-y-auto no-scrollbar'
              style={{
                height: `calc(100% - ${MOBILE_COMMUNITY_SIDEBAR_FOOTER_HEIGHT}px)`,
              }}
            >
              {menu.menuItems.map((menuItem, index) => (
                <CommunityMenuItem
                  menuItem={menuItem}
                  onClick={handleMenuItemClick}
                  onExpandedMenuItemClick={onClose}
                  triggerOnMount={false}
                  key={index}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </Drawer>
  )
}

export default CommunityMenu
