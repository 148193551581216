import React, { useMemo } from 'react'

import Lottie from 'lottie-react'

import { _t } from '@locales/index'

import Modal from '@components/modal/Modal'
import CornerConfetti from '@assets/lottie-animations/corner-confetti.json'
import { ReactComponent as CornerBanner } from '@assets/images/common/corner-banner.svg'
import useBreakpoint from '@hooks/responsive/useBreakpoint'
import { BreakpointsEnum } from '@enums/theme'

interface Props {
  open: boolean
  onClose: () => void
}

const CommunityCreatedPopup: React.FC<Props> = ({ open, onClose }) => {
  const breakpoint = useBreakpoint()

  const isSmallerDevice: boolean = useMemo(
    () => breakpoint === BreakpointsEnum.sm,
    [breakpoint],
  )

  return (
    <Modal
      open={open}
      onClose={onClose}
      showClose={!isSmallerDevice}
      mainContainerClassName={'p-5'}
      containerClassName={'popup-shadow'}
      closeIconPositionClassName={'right-0 -top-10 sm:top-0 sm:-right-12'}
    >
      <div className='flex flex-col gap-13 sm:gap-0 max-w-[500px] bg-white pt-26 pb-16 p-6 sm:p-13 rounded-xl relative'>
        <div className='flex flex-col gap-5 sm:gap-0'>
          <h2 className='h3 sm:h2 text-custom-turquoise-dark'>
            {_t('communityCreatedPopupTitle')}
          </h2>
          <div className='flex flex-col gap-3'>
            <p className='body3 sm:body2'>
              {_t('communityCreatedPopupBody1Line1')}
              <br />
              <span className='font-bold'>
                {_t('communityCreatedPopupBody1Line2')}
              </span>
            </p>
            <p className='body3 sm:body2'>{_t('communityCreatedPopupBody2')}</p>
          </div>
        </div>
        <div className='sm:hidden flex justify-center'>
          <button className='btn btn-pink w-[165px] z-100' onClick={onClose}>
            {_t('next')}
          </button>
        </div>
        <CornerBanner
          className={'absolute w-[200px] h-[114px] top-[-5px] right-0'}
        />
        <Lottie
          animationData={CornerConfetti}
          className='absolute inset-0'
          loop={2}
        />
      </div>
    </Modal>
  )
}

export default CommunityCreatedPopup
