import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TypeCommunityState } from '@customTypes/store'
import { TypeMenuItem } from '@customTypes/sidebar'

const initialState: TypeCommunityState = {
  selectedMenuItem: undefined,
  selectedCommunityId: undefined,
}

/* CASE REDUCERS */
const select: CaseReducer<TypeCommunityState, PayloadAction<string>> = (
  state,
  action,
) => {
  state.selectedCommunityId = action.payload
}

const setSelectedMenuItem: CaseReducer<
  TypeCommunityState,
  PayloadAction<TypeMenuItem | undefined>
> = (state, action) => {
  if (!action.payload) {
    state.selectedMenuItem = undefined
    return
  }
  state.selectedMenuItem = {
    ...action.payload,
  }
}

const communitySlice = createSlice({
  name: 'community',
  initialState,
  reducers: {
    select,
    setSelectedMenuItem,
  },
})

export const {
  select: selectCommunity,
  setSelectedMenuItem: setCommunityMenuItem,
} = communitySlice.actions
const communityReducer = communitySlice.reducer

export default communityReducer
