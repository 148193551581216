import { LanguagesDirection, LanguagesEnum } from '@enums/language'
import { TypeLanguageSettings } from '@customTypes/language'

export const LanguagesSettings: Record<LanguagesEnum, TypeLanguageSettings> = {
  [LanguagesEnum.EN]: {
    name: 'English',
    direction: LanguagesDirection.LTR,
  },
  [LanguagesEnum.DE]: {
    name: 'DE',
    direction: LanguagesDirection.LTR,
  },
  [LanguagesEnum.PT]: {
    name: 'PT',
    direction: LanguagesDirection.LTR,
  },
  [LanguagesEnum.RU]: {
    name: 'RU',
    direction: LanguagesDirection.LTR,
  },
  [LanguagesEnum.HE]: {
    name: 'עברית',
    direction: LanguagesDirection.RTL,
  },
  [LanguagesEnum.ES]: {
    name: 'español',
    direction: LanguagesDirection.LTR,
  },
  [LanguagesEnum.AR]: {
    name: 'عربيه',
    direction: LanguagesDirection.RTL,
  },
}
