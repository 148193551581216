import React, { useCallback, useState } from 'react'
import { ReactComponent as PointerIcon } from '@assets/images/common/down-pointer.svg'
import { useGetSegmentation } from '@api/queries/segmentation'
import { LanguagesEnum } from '@enums/language'
import classNames from 'classnames'
import SegmentationBadge from '@containers/organization/filtersForm/components/SegmentationBadge'
import {
  TypeSegmentationValue,
  TypeSegmentationValueFilters,
} from '@customTypes/segmentation'
import { useFormikContext } from 'formik'
import { Operators, SegmentationFormEnum } from '@enums/segmentation'
import * as R from 'ramda'

interface Props {
  segmentationId: string
}

const FilterSegmentationLabelDD: React.FC<Props> = ({ segmentationId }) => {
  const { values, setFieldValue } =
    useFormikContext<TypeSegmentationValueFilters>()
  const [isValuesShowing, setIsValuesShowing] = useState(false)

  const handlePointerClick = useCallback(() => {
    setIsValuesShowing(!isValuesShowing)
  }, [isValuesShowing])

  const { data: segmentation } = useGetSegmentation(segmentationId, {
    enabled: Boolean(segmentationId),
  })

  const handleSegmentationValueClick = useCallback(
    (clickedSegmentationValue: TypeSegmentationValue) => {
      let segValuesObj: Record<string, any> = {}
      let valuesArray: string[] = []
      segValuesObj[SegmentationFormEnum.operator] = Operators.In
      if (
        !R.isEmpty(values) &&
        values[segmentationId] &&
        values[segmentationId][SegmentationFormEnum.value].length > 0
      ) {
        segValuesObj[SegmentationFormEnum.value] =
          values[segmentationId][SegmentationFormEnum.value]
        valuesArray = segValuesObj[SegmentationFormEnum.value]
      }
      if (valuesArray.some(value => value === clickedSegmentationValue.value)) {
        valuesArray = valuesArray.filter(
          value => value !== clickedSegmentationValue.value,
        )
        segValuesObj[SegmentationFormEnum.value] = valuesArray
        setFieldValue(segmentationId, segValuesObj)
      } else {
        valuesArray.push(clickedSegmentationValue.value)
        segValuesObj[SegmentationFormEnum.value] = valuesArray
        setFieldValue(segmentationId, segValuesObj)
      }
    },
    [segmentationId, setFieldValue, values],
  )

  return (
    <div
      className={classNames(
        'min-h-12 font-pangram-regular text-lg ',
        isValuesShowing ? 'bg-[#F6F2FD] border-t-2 border-t-white' : 'bg-white',
      )}
    >
      <div
        className='flex items-center  cursor-pointer px-3 py-2'
        onClick={handlePointerClick}
      >
        <section className={'flex flex-1 gap-2 items-center'}>
          <p>
            {
              segmentation?.labelsLanguages.find(language => {
                return language.languageId === LanguagesEnum.EN
              })?.text
            }
          </p>
          <p className={'text-custom-gray-4 text-sm'}>
            {values &&
            !R.isEmpty(values[segmentationId]) &&
            values[segmentationId][SegmentationFormEnum.value].length
              ? '+' + values[segmentationId][SegmentationFormEnum.value].length
              : null}
          </p>
          {/*<SegmentationIndicationBadges values={values} segmentationId={segmentationId}/>*/}
        </section>
        <PointerIcon
          className={classNames(
            ' fill-black',
            isValuesShowing ? 'rotate-180' : '',
          )}
        />
      </div>
      <div className='flex flex-wrap w-full'>
        <div
          className={
            'w-full flex flex-wrap bg-[#F6F2FD] justify-center gap-1 px-4 mb-4 overflow-x-auto'
          }
        >
          {isValuesShowing
            ? segmentation?.values.map((segmentationValue, index) => {
                return (
                  <SegmentationBadge
                    segmentationValue={segmentationValue}
                    key={index}
                    handleSegmentationValueClick={handleSegmentationValueClick}
                  />
                )
              })
            : null}
        </div>
      </div>
    </div>
  )
}

export default FilterSegmentationLabelDD
