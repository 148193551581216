import classNames from 'classnames'
import { ErrorMessage, useField } from 'formik'
import { startsWith } from 'ramda'
import { useMemo } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  wrapperClassName?: string // tailwind class
  fontClass?: string // tailwind class
  color?: string // tailwind class
  borderClass?: string // tailwind class
  inputStyle?: Object
  placeholder?: string
  hideErrorMessage?: boolean
}

const MobilePhoneInput: React.FC<Props> = ({
  id,
  className,
  wrapperClassName,
  fontClass,
  color,
  borderClass,
  inputStyle,
  placeholder,
  hideErrorMessage,
  ...props
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(props.name || '')

  const error = useMemo(
    () => meta.touched && meta.error,
    [meta.error, meta.touched],
  )

  return (
    <div
      className={classNames(
        'w-full ltr',
        className,
        error
          ? 'border-custom-error-red bg-opacity-10'
          : 'border-custom-gray-5',
      )}
    >
      <PhoneInput
        {...props}
        {...field}
        value={field.value}
        disableDropdown={true}
        country={'us'}
        placeholder={placeholder}
        onChange={value => {
          helpers.setValue(value)
        }}
        inputProps={{
          id: id,
        }}
        isValid={(inputNumber, c, countries) => {
          return countries.some((country: any) => {
            return (
              startsWith(inputNumber, country.dialCode) ||
              startsWith(country.dialCode, inputNumber)
            )
          })
        }}
        containerStyle={{
          direction: 'ltr',
        }}
        buttonStyle={{
          direction: 'ltr',
          backgroundColor: 'transparent',
          borderColor: error ? 'red' : 'transparent',
        }}
        inputStyle={{
          direction: 'ltr',
          width: '100%',
          margin: 0,
          paddingBottom: 24,
          paddingTop: 24,
          borderColor: error ? 'red' : '#E0E0E0',
          backgroundColor: error ? '#F2E5E3' : '',
          ...inputStyle,
        }}
      />
      {!hideErrorMessage ? (
        <ErrorMessage
          name={props.name || ''}
          component={'p'}
          className='text-red-500 mt-2'
        />
      ) : null}
    </div>
  )
}

export default MobilePhoneInput
