export enum LanguagesEnum {
  EN = 'en',
  DE = 'de',
  PT = 'pt',
  RU = 'ru',
  ES = 'es',
  HE = 'he',
  AR = 'ar',
}

export enum LanguagesDirection {
  RTL = 'rtl',
  LTR = 'ltr',
}
