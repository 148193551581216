import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query'
import { AxiosError } from 'axios'
import apiService from '@services/api/apiService'
import { ApiKeyNameEnum } from '@enums/api'
import { useProtection } from '@hooks/auth/useProtection'
import { ServerStateKeysEnum } from '@enums/serverState'
import {
  TypePayoutRequest,
  TypePayoutResponse,
  TypePayoutSettings,
} from '@customTypes/payout'

/**********************************
 GET PAYOUT
 **********************************/

type TypeGetPayoutSettingsOptions = UseQueryOptions<
  TypePayoutSettings,
  AxiosError,
  TypePayoutSettings,
  any
>

const getPayoutSettings = async (): Promise<TypePayoutSettings> => {
  try {
    const response = await apiService.get(ApiKeyNameEnum.payout_settings)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetPayoutSettings = (
  options?: TypeGetPayoutSettingsOptions,
) => {
  const queryOptions = useProtection<TypeGetPayoutSettingsOptions>(options)

  return useQuery([ServerStateKeysEnum.PayoutSettings], getPayoutSettings, {
    ...queryOptions,
  })
}

/**********************************
 CREATE PAYOUT
 **********************************/

type TypePayoutOptions = UseMutationOptions<
  TypePayoutResponse,
  AxiosError,
  TypePayoutRequest
>

const createPayoutSettings = async (
  values: TypePayoutRequest,
): Promise<TypePayoutResponse> => {
  try {
    const response = await apiService.post(
      ApiKeyNameEnum.create_paypal_payment,
      values,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const usePayout = (options?: TypePayoutOptions) => {
  const queryOptions = useProtection<TypePayoutOptions>(options)

  return useMutation(createPayoutSettings, {
    ...queryOptions,
  })
}
