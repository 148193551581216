import React, { useCallback, useEffect } from 'react'

import moment from 'moment'
import { useNavigate } from 'react-router-dom'

import Calendar, {
  FormatterCallback,
  OnChangeDateCallback,
} from 'react-calendar'

import { _t } from '@locales/index'

import { CREATOR_PATH } from '@routes/creator/creatorPath'
import { useAppDispatch, useAppSelector } from '@hooks/store/redux'
import LunchCommunityWrapper from '@containers/creator/launchCommunity/LaunchCommunityWrapper'
import { selectLaunchDateModalDate } from '@selectors/launchDateModal'
import { setLaunchDate } from '@reducers/launchDateModal'
import LaunchScreenFooter from '@containers/creator/launchCommunity/components/LaunchScreenFooter'
import { useGetCommunity } from '@api/queries/community'
import { selectCurrentCommunityId } from '@selectors/community'
import LaunchScreenMobileHeader from '@containers/creator/launchCommunity/components/LaunchScreenMobileHeader'
import { ReactComponent as ArrowIcon } from '@assets/images/common/arrow-2.svg'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'

const SetLaunchDate = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isSmallerDevice = useIsSmallerDevice()
  const id = useAppSelector(selectCurrentCommunityId)
  const launchDate = useAppSelector(selectLaunchDateModalDate(id))

  const { data: community } = useGetCommunity(id, {
    enabled: Boolean(id),
  })

  useEffect(() => {
    if (community) {
      if (community.launchDate?.date) {
        const date = new Date(community.launchDate.date)
        dispatch(
          setLaunchDate({
            communityId: community.id,
            date,
          }),
        )
      }
    }
  }, [community, dispatch])

  const formatShortWeekday: FormatterCallback = useCallback((locale, date) => {
    return moment(date).format('dd')
  }, [])

  const handleSelectDate: OnChangeDateCallback = useCallback(
    value => {
      if (id) {
        const momentDate = moment(value)
        momentDate.hour(12)
        momentDate.minutes(0)
        momentDate.seconds(0)
        dispatch(
          setLaunchDate({
            communityId: id,
            date: momentDate.toDate(),
          }),
        )
      }
    },
    [dispatch, id],
  )

  const handleClickNext = useCallback(() => {
    if (id) {
      navigate(CREATOR_PATH.confirmLaunchDate.replace(':id', id))
    }
  }, [id, navigate])

  return (
    <LunchCommunityWrapper isFullScreenOnMobile>
      <div className='h-full flex flex-col'>
        <LaunchScreenMobileHeader />
        <div className='p-5 sm:px-26 sm:py-13'>
          <div className='flex flex-col gap-3'>
            <h3 className='h5 sm:h3 capitalize'>
              {_t('communitySetLaunchDateTitle')}
            </h3>
            <p className='body2 text-custom-gray-3'>
              {_t('communitySetLaunchDateSubtitle')}
            </p>
          </div>
          <div className='h-13' />
          <p className='hidden sm:block caption text-custom-turquoise'>
            {moment(launchDate).format('dddd - MMM DD, YYYY')}
          </p>
          <div className='h-3' />
          <div className='border border-custom-gray-5 rounded-xl p-5'>
            <Calendar
              onChange={handleSelectDate}
              value={launchDate}
              showDoubleView={!isSmallerDevice}
              formatShortWeekday={formatShortWeekday}
              showFixedNumberOfWeeks={false}
              showNeighboringMonth={false}
              minDate={moment().add(1, 'd').toDate()}
              maxDate={moment().add(1, 'd').add(1, 'month').toDate()}
              className={'launch-calendar'}
              defaultView={'month'}
              nextLabel={
                <ArrowIcon className='fill-custom-gray-3 w-3 h-3 sm:hidden' />
              }
              prevLabel={
                <ArrowIcon className='fill-custom-gray-3 w-3 h-3 sm:hidden rotate-180' />
              }
            />
          </div>
        </div>
        <LaunchScreenFooter
          nextButtonText={_t('next')}
          previousButtonText={_t('back')}
          onClickNext={handleClickNext}
          onClickPrevious={() => navigate(-1)}
          nextButtonDisabled={!Boolean(launchDate)}
        />
      </div>
    </LunchCommunityWrapper>
  )
}

export default SetLaunchDate
