import React from 'react'
import { _t } from '@locales/index'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'
import { setModalType } from '@reducers/modal'
import { ModalTypeEnum } from '@enums/modal'
import { useAppDispatch } from '@hooks/store/redux'

const ImportMembersFooter: React.FC = () => {
  const dispatch = useAppDispatch()
  const isSmallerDevice = useIsSmallerDevice()

  if (!isSmallerDevice) {
    return null
  }

  return (
    <div className='flex flex-col items-center gap-6 px-8 py-6 bg-custom-purple/20 z-1'>
      <p className='body1 flex-1 whitespace-nowrap text-custom-purple'>
        {_t('importMembersBarTitle')}
      </p>
      <div className='flex flex-col sm:flex-row gap-1.5'>
        <button
          className='py-4 px-8 btn bg-white text-custom-purple rounded-full'
          onClick={() =>
            dispatch(setModalType(ModalTypeEnum.ImportMembersManually))
          }
        >
          {_t('importMembersUploadManually')}
        </button>
        <button
          className='py-4 px-8 btn btn-purple rounded-full'
          onClick={() => dispatch(setModalType(ModalTypeEnum.ImportMembersCSV))}
        >
          {_t('importMembersUploadCsvButton')}
        </button>
      </div>
    </div>
  )
}

export default ImportMembersFooter
