export enum DatePeriodEnum {
  LastThreeMonths = 'LT',
  Custom = 'CU',
}

export enum Operators {
  Equals = 'EQ',
  lessThan = 'LT',
  greaterThan = 'GT',
  startsWith = 'SW',
  endsWith = 'EW',
  In = 'IN',
}

export enum SegmentationFormEnum {
  segmentationId = 'segmentationId',
  value = 'value',
  operator = 'operator',
}

export enum AudienceTypeEnum {
  All = 'AL',
  Filtered = 'FI',
}

export enum FilterModeEnum {
  Default,
  Create,
  Edit,
}
