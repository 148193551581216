import { FieldArray, FieldArrayRenderProps } from 'formik'
import { ReactComponent as AddButton } from '@assets/images/common/plus-icon.svg'
import { ReactComponent as TrashButton } from '@assets/images/common/trash-icon.svg'
import InputField from '@components/form/InputField'
import MobilePhoneInput from '@root/src/components/form/MobilePhoneInput'

interface Props {
  fieldName: string
  onValuesChange: (isAdding: boolean) => void
}

const ImportMembersField: React.FC<Props> = ({ fieldName, onValuesChange }) => {
  return (
    <FieldArray name={fieldName}>
      {(fieldArrayProps: FieldArrayRenderProps) => {
        const { push, remove, form } = fieldArrayProps

        return (
          <div className='flex flex-col gap-2 items-start '>
            <section className='max-h-96 overflow-y-scroll no-scrollbar'>
              {form.values[fieldName].map((item: any, index: number) => {
                return (
                  <div key={index} className='flex gap-4 items-center my-2'>
                    <TrashButton
                      width={40}
                      height={32}
                      onClick={() => {
                        remove(index)
                        onValuesChange(false)
                      }}
                    />

                    <div key={index} className='flex gap-1 items-center'>
                      <InputField
                        placeholder={'Name'}
                        name={`${fieldName}.${index}.name`}
                        id={`${fieldName}.${index}.name`}
                        hideErrorMessage={true}
                      />
                      <MobilePhoneInput
                        className=''
                        id={`${fieldName}.${index}.phone`}
                        name={`${fieldName}.${index}.phone`}
                        hideErrorMessage={true}
                      />
                    </div>
                  </div>
                )
              })}
            </section>

            <AddButton
              onClick={() => {
                push({})
                onValuesChange(true)
              }}
            />
          </div>
        )
      }}
    </FieldArray>
  )
}

export default ImportMembersField
