import React, { useCallback, useEffect, useState } from 'react'

import Calendar from 'react-calendar'
import classNames from 'classnames'

import { WEEKDAY } from '@const/calendar'
import { ReactComponent as LeftArrow } from '@assets/images/common/left-arrow.svg'
import { ReactComponent as RightArrow } from '@assets/images/common/right-arrow.svg'
import { Popover } from '@headlessui/react'
import { _t } from '@locales/index'

interface Props {
  onSaveDurationDates: (any: Date[]) => void
  onGetClickedDay: (any: Date) => void
  title1?: string
  title2?: string
  showDoubleView: boolean
  maxDate?: Date | undefined
  showFooter?: boolean
}

const CalendarWrapper: React.FC<Props> = ({
  onSaveDurationDates,
  title1,
  title2,
  showDoubleView,
  maxDate,
  onGetClickedDay,
  showFooter,
}) => {
  const [value, setValue] = useState<Date[]>([
    new Date(),
    new Date(Date.now() + 3600 * 1000 * 24),
  ])

  useEffect(() => {
    onSaveDurationDates(value)
  }, [onSaveDurationDates, value])

  const formatDate = useCallback((date: any) => {
    return WEEKDAY[date.getDay()]
  }, [])

  return (
    <div
      className={classNames(
        'absolute rounded-xl custom-shadow-1 min-w-[310px] max-w-[658px] z-[100] sm:rtl:right-[-950px]',
        showDoubleView ? 'w-[658px]' : 'w-[310px]',
      )}
    >
      <div className='bg-white flex flex-col rounded-xl'>
        <div className='flex flex-row justify-between px-5 pt-5 pb-2.5'>
          <h1 className='capitalize text-xs font-poppins text-custom-black font-bold'>
            {title1 || null}
          </h1>
          <h1 className='capitalize text-xs font-poppins text-custom-blue-1 font-normal'>
            {title2 || null}
          </h1>
        </div>
        <hr className='border border-t border-custom-gray-5 mx-5' />
        <div className='flex flex-row w-full justify-center'>
          <Calendar
            className='flex flex-col w-full p-5
                      react-calendar react-calendar__viewContainer
                      react-calendar__tile.react-calendar__month-view__days__day
                      react-calendar__month-view__days react-calendar__navigation__label__divider
                      react-calendar__navigation react-calendar__navigation__arrow.react-calendar__navigation__prev2-button
                      react-calendar__navigation__arrow.react-calendar__navigation__next2-button
                      react-calendar__navigation__label react-calendar__month-view__weekdays
                      react-calendar__month-view__days__day--neighboringMonth react-calendar__month-view__weekdays__weekday.abbr
                      react-calendar__tile.react-calendar__month-view__days__day react-calendar__month-view
                      react-calendar__year-view__months'
            returnValue={'range'}
            onChange={setValue}
            value={[value[0], value[1]]}
            selectRange={true}
            maxDate={maxDate}
            minDate={new Date()}
            showFixedNumberOfWeeks={true}
            showDoubleView={showDoubleView}
            formatShortWeekday={(locale, date) => formatDate(date)}
            nextLabel={<RightArrow fill={'#828282'} width={12} height={12} />}
            prevLabel={<LeftArrow fill={'#828282'} width={12} height={12} />}
            onClickDay={day => onGetClickedDay(day)}
          />
        </div>
        {showFooter && (
          <>
            <hr className='border border-t border-custom-gray-5 mx-5' />
            <div className='px-5 py-2 w-full flex justify-between items-center'>
              <Popover.Button className='btn btn-text' type='button'>
                {_t('cancel')}
              </Popover.Button>
              <Popover.Button type='submit' className='btn btn-pink'>
                {_t('next')}
              </Popover.Button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default CalendarWrapper
