import React, { useMemo } from 'react'
import 'react-modern-drawer/dist/index.css'
import Drawer from 'react-modern-drawer'
import OrganizationSidebarMenuItem from '@containers/organization/sidebar/components/OrganizationSidebarMenuItem'
import { _t } from '@locales/index'
import { ORGANIZATION_PATH } from '@routes/organization/organizationPath'
import ContactButton from '@root/src/containers/organization/sidebar/components/ContactButton'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { useGetAudiences } from '@api/queries/segmentation'
import { TypeSidebarMenuItems } from '@customTypes/sidebar'
import { ReactComponent as MembersIcon } from '@assets/images/sideMenu/members.svg'

interface Props {
  show: boolean
  onClose: () => void
}

const OrganizationMenu: React.FC<Props> = ({ show, onClose }) => {
  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)

  const { data: communityAudiences } = useGetAudiences(selectedCommunityId, {
    enabled: Boolean(selectedCommunityId),
  })

  const organizationSidebarMenuItems =
    useMemo<TypeSidebarMenuItems | null>(() => {
      if (communityAudiences && communityAudiences.length > 0) {
        return communityAudiences.map(audience => {
          return {
            label: audience.title,
            path: ORGANIZATION_PATH.audience
              .replace(':id', selectedCommunityId || '')
              .replace(':audienceId', audience.id),
            description: audience.description,
          }
        })
      }
      return null
    }, [communityAudiences, selectedCommunityId])

  return (
    <Drawer
      open={show}
      direction={'left'}
      onClose={onClose}
      size={'75vw'}
      className='rounded-tr-2xl overflow-hidden !h-[calc(var(--vh,_1vh)_*_100)]'
      enableOverlay={true}
    >
      <div className='flex flex-col h-full bg-white'>
        <div className='overflow-y-auto no-scrollbar'>
          <OrganizationSidebarMenuItem
            label={_t('organizationAllMembersMenuItem')}
            path={ORGANIZATION_PATH.organizationId.replace(
              ':id',
              selectedCommunityId || '',
            )}
            Icon={MembersIcon}
            onClick={onClose}
          />
          {organizationSidebarMenuItems?.map((menuItem, i) => (
            <OrganizationSidebarMenuItem
              key={i}
              {...menuItem}
              onClick={onClose}
            />
          ))}
        </div>
        <ContactButton onClick={onClose} />
      </div>
    </Drawer>
  )
}

export default OrganizationMenu
