import React from 'react'
import { TypeSelectBoxOption } from '@customTypes/common'
import { Listbox } from '@headlessui/react'
import { ReactComponent as CheckedIcon } from '@assets/images/common/checked-circle.svg'
import { ReactComponent as UncheckedIcon } from '@assets/images/common/unchecked-circle.svg'

interface Props {
  option: TypeSelectBoxOption
  checkbox?: string
}

const SelectBoxOption: React.FC<Props> = ({ option, checkbox }) => {
  return (
    <Listbox.Option
      className={({ selected }) =>
        `relative cursor-pointer select-none py-3 px-2.5 pr-12 text-sm font-poppins capitalize hover:bg-gray-100 active:bg-gray-100 flex items-center gap-2.5 ${
          selected ? '!bg-custom-gray-5' : 'bg-white'
        }`
      }
      value={option.value}
    >
      {({ selected }) => (
        <>
          {selected ? (
            checkbox === 'solid' ? (
              <div className='w-4 h-4 rounded-full bg-custom-black'></div>
            ) : (
              <CheckedIcon fill='#202020' />
            )
          ) : checkbox === 'solid' ? (
            <div className='w-4 h-4 rounded-full border border-custom-black'></div>
          ) : (
            <UncheckedIcon fill='#202020' />
          )}
          <span
            className={`block truncate ${
              selected ? 'font-medium' : 'font-normal'
            }`}
          >
            {option.text}
          </span>
        </>
      )}
    </Listbox.Option>
  )
}

export default SelectBoxOption
