import React from 'react'
import { _t } from '@locales/index'
import { TypeCommunity } from '@customTypes/community'
import { ReactComponent as WarningIcon } from '@assets/images/common/waiting_approval.svg'
import useBreakpoint from '@hooks/responsive/useBreakpoint'
import { BANNER_HEIGHT } from '@const/layout'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'

interface Props {
  community: TypeCommunity | undefined
}

const UnpublishedCommunityBanner: React.FC<Props> = () => {
  const breakpoint = useBreakpoint()
  const isSmallerDevice = useIsSmallerDevice()

  return (
    <div
      className='px-4 sm:px-5 flex justify-center items-center gap-5 sm:gap-26 flex-wrap bg-custom-gray-5 z-1'
      style={{
        height: BANNER_HEIGHT[breakpoint],
      }}
    >
      <div className='flex items-center gap-2 sm:gap-3'>
        <WarningIcon className='w-9 h-9 sm:w-12 sm:h-12 fill-custom-yellow' />
        <div className='flex flex-col gap-1.5'>
          <p className='body3 sm:body1 font-bold sm:font-normal text-custom-turquoise-dark'>
            {_t('unapprovedCommunityBannerTitle')}
          </p>
          <p className='caption sm:body2 font-normal capitalize text-custom-turquoise-dark'>
            {_t('unapprovedCommunityBannerSubtitle')}
          </p>
        </div>
      </div>
      {isSmallerDevice ? null : (
        <button className='btn btn-pink body3' disabled>
          {_t('unapprovedCommunityBannerButton')}
        </button>
      )}
    </div>
  )
}

export default UnpublishedCommunityBanner
