import React, { useCallback } from 'react'
import Drawer from 'react-modern-drawer'
import { useGetAllCurrencies } from '@api/queries/currency'
import { useAppSelector } from '@hooks/store/redux'
import { selectMobileBottomDrawerCurrencyFunction } from '@selectors/mobileBottomDrawer'
import { DrawerProps } from '@customTypes/common'

const CurrencyOptionsMobileDrawer: React.FC<DrawerProps> = ({
  open,
  onClose,
  direction,
  ...drawerProps
}) => {
  const selectCurrencyFunction = useAppSelector(
    selectMobileBottomDrawerCurrencyFunction,
  )
  const { data: currencyData } = useGetAllCurrencies()

  const handleSelectCurrency = useCallback(
    (currencyIso: string) => {
      if (selectCurrencyFunction) {
        selectCurrencyFunction(currencyIso)
        onClose && onClose()
      }
    },
    [onClose, selectCurrencyFunction],
  )

  if (!currencyData) {
    return null
  }

  return (
    <Drawer
      size={500}
      open={open}
      direction={'bottom'}
      onClose={onClose}
      className={'overflow-y-auto !w-screen rounded-t-[20px]'}
      {...drawerProps}
    >
      <div className='flex flex-col divide-y px-3'>
        {currencyData.currencies.map(option => (
          <div
            key={option.id}
            className='font-source-sans flex items-center justify-center cursor-pointer py-3 gap-2 hover:bg-custom-gray-6 focus:bg-custom-gray-4'
            onClick={() => handleSelectCurrency(option.iso)}
          >
            <span className='body1 text-right'>{option.symbol}</span>
            <span className='body1 uppercase text-left'>{option.iso}</span>
          </div>
        ))}
      </div>
    </Drawer>
  )
}

export default CurrencyOptionsMobileDrawer
