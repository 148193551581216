import React, { useCallback, useMemo } from 'react'
import { _t } from '@locales/index'
import { useNavigate } from 'react-router-dom'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import { TypeCommunity } from '@customTypes/community'
import { ReactComponent as PartyIcon } from '@assets/images/common/partyPopper.svg'
import useBreakpoint from '@hooks/responsive/useBreakpoint'
import { BreakpointsEnum } from '@enums/theme'
import { BANNER_HEIGHT } from '@const/layout'

interface Props {
  community: TypeCommunity | undefined
}

const UnpublishedCommunityBanner: React.FC<Props> = ({ community }) => {
  const navigate = useNavigate()
  const breakpoint = useBreakpoint()

  const handlePublishClick = useCallback(() => {
    if (community) {
      navigate(CREATOR_PATH.publishCommunity.replace(':id', community.id))
    }
  }, [community, navigate])

  const isSmallerDevice: boolean = useMemo(
    () => breakpoint === BreakpointsEnum.sm,
    [breakpoint],
  )

  return (
    <div
      className='flex flex-row px-5 justify-between sm:justify-around items-center bg-white border-b border-custom-gray-5 z-1'
      style={{
        height: BANNER_HEIGHT[breakpoint],
      }}
    >
      <div className='flex gap-3 sm:gap-5 items-center'>
        <PartyIcon className={'w-9 h-9 sm:w-12 sm:h-12'} />
        <div className='flex flex-col gap-1.5'>
          <p className='body3 sm:body1 !font-bold capitalize'>
            {_t('unpublishedCommunityBannerTitle')}
          </p>
          <p className='caption capitalize font-normal sm:body2'>
            {_t('unpublishedCommunityBannerSubtitle')}
          </p>
        </div>
      </div>
      <button
        className='btn btn-pink w-26 sm:w-[250px] body3'
        onClick={handlePublishClick}
      >
        {isSmallerDevice
          ? _t('publish')
          : _t('unpublishedCommunityBannerButton')}
      </button>
    </div>
  )
}

export default UnpublishedCommunityBanner
