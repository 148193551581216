import { ErrorCode, FileError } from 'react-dropzone'
import { _t } from '@locales/index'

export const getVideoUploadError = (error: FileError): string => {
  switch (error.code) {
    case ErrorCode.FileInvalidType:
      return _t('invalidVideoFileTypeError')
    default:
      return error.message
  }
}
