import React, { useCallback } from 'react'

import { CommunityApprovalStatusEnum } from '@enums/community'
import { ReactComponent as PointerIcon } from '@assets/images/common/down-pointer.svg'
import { ReactComponent as PlusIcon } from '@assets/images/common/plus.svg'
import { _t } from '@locales/index'
import DropdownMenu from '@components/dropdownMenu/DropdownMenu'
import { TypeCommunityBase } from '@customTypes/community'
import { Menu } from '@headlessui/react'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import { useNavigate } from 'react-router-dom'

interface Props {
  text: string
  status: CommunityApprovalStatusEnum
  onClick?: () => void
  otherCommunities: TypeCommunityBase[] | undefined
  onClickOtherCommunity: (communityId: string) => void
}

const CommunityMenuTitle: React.FC<Props> = ({
  text,
  onClick,
  otherCommunities,
  onClickOtherCommunity,
}) => {
  const navigate = useNavigate()

  const handleClickCreateNewCommunity = useCallback(() => {
    navigate(CREATOR_PATH.createCommunity)
  }, [navigate])

  return (
    <div
      className='w-full text-white h-12 flex items-center gap-[7px] px-2.5 capitalize border-b border-custom-turquoise-dark'
      onClick={onClick}
    >
      <p className='body2 overflow-hidden overflow-ellipsis text-custom-turquoise-dark whitespace-nowrap max-w-[160px]'>
        {text}
      </p>
      <div className='ml-auto'>
        <DropdownMenu
          triggerComponent={
            <PointerIcon className='w-6 h-6 transition-transform cursor-pointer fill-custom-turquoise-dark' />
          }
          customClass={'w-[300px] left-0 filter-drop-shadow-1'}
        >
          <div className='bg-custom-green-gray w-full overflow-y-auto max-h-[500px] relative'>
            {otherCommunities &&
              otherCommunities.map(community => (
                <Menu.Item key={community.id}>
                  <div
                    className={'menu-item-base'}
                    onClick={() => onClickOtherCommunity(community.id)}
                  >
                    {community.title}
                  </div>
                </Menu.Item>
              ))}
            {otherCommunities && otherCommunities.length > 0 && (
              <div className={'h-2.5'} />
            )}
            <Menu.Item>
              <div
                className='menu-item-base body3 sticky bottom-0 left-0 right-0'
                onClick={handleClickCreateNewCommunity}
              >
                <PlusIcon className='w-6 h-6 fill-custom-turquoise' />
                <span>{_t('createANewCommunity')}</span>
              </div>
            </Menu.Item>
          </div>
        </DropdownMenu>
      </div>
    </div>
  )
}

export default CommunityMenuTitle
