import { _t } from '@locales/index'
import { BillingTransactionTypeEnum } from '@enums/billing'
import { TypeBillingTransactionType } from '@customTypes/billing'

export const MINIMUM_WITHDRAWAL_AMOUNT = 50

export const DEFAULT_TRANSACTIONS_PAGE_SIZE = 10

export const TRANSACTION_TYPE_TEXT: Record<TypeBillingTransactionType, string> =
  {
    [BillingTransactionTypeEnum.Revenue]: _t('transactionStatusRevenue'),
    [BillingTransactionTypeEnum.Expenses]: _t('transactionStatusExpense'),
    [BillingTransactionTypeEnum.ManualRefund]: _t(
      'transactionStatusManualRefund',
    ),
  }
