import React from 'react'
import MonthNavigation from './MonthNavigation'
import Pagination from './Pagination'
import { _t } from '@locales/index'
import {
  TypeBillingTransactionFilters,
  TypeBillingTransactionResponse,
} from '@customTypes/billing'
import TransactionItem from '@containers/creator/communityTransactionsTable/components/TransactionItem'
import Skeleton from '@components/skeleton/Skeleton'

interface Props {
  transactions: TypeBillingTransactionResponse | undefined
  transactionsLoading: boolean
  filters: TypeBillingTransactionFilters
  setFilters: React.Dispatch<
    React.SetStateAction<TypeBillingTransactionFilters>
  >
  page: number
  setPage: (page: number) => void
}

const DesktopView: React.FC<Props> = ({
  transactions,
  transactionsLoading,
  filters,
  setFilters,
  page,
  setPage,
}) => (
  <div className='min-w-max flex flex-col px-5 py-6 rounded-xl bg-white'>
    <div className='flex justify-between pb-6'>
      <h3 className='body1'>{_t('membersTransactionsHistory')}</h3>
      <MonthNavigation date={filters.startDate} setFilters={setFilters} />
    </div>
    {transactions && transactions.numPages > 1 && (
      <div className='flex justify-end'>
        <Pagination
          pageCount={transactions.numPages}
          currentPage={page}
          onPageChange={setPage}
        />
      </div>
    )}
    <div className='grid grid-cols-10'>
      <div className='col-span-10 grid grid-cols-10 border-[1px] rounded p-3 mx-1 mb-9'>
        <div className='col-span-2 body3 font-bold'>
          {_t('transactionTableHead1')}
        </div>
        <div className='col-span-2 body3 font-bold'>
          {_t('transactionTableHead2')}
        </div>
        <div className='col-span-4 body3 font-bold'>
          {_t('transactionTableHead3')}
        </div>
        <div className='col-span-2 body3 font-bold'>
          {_t('transactionTableHead4')}
        </div>
      </div>
      <div className='col-span-10 grid grid-cols-10 '>
        {!transactionsLoading &&
          transactions &&
          transactions.data.map(transaction => (
            <TransactionItem key={transaction.id} transaction={transaction} />
          ))}
        {transactionsLoading && <Skeleton className='w-full h-12' />}
      </div>
    </div>
  </div>
)

export default DesktopView
