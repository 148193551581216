import React, { useMemo } from 'react'

interface Props {
  label: string
  value: number
  inputProps: React.InputHTMLAttributes<HTMLInputElement>
}

const ImageEditorRangeSlider: React.FC<Props> = ({
  label,
  value,
  inputProps,
}) => {
  const displayValue = useMemo(() => value - 100, [value])

  return (
    <div className='flex flex-col gap-1.5 w-full'>
      <label className='caption sm:body2 text-custom-gray-3 sm:text-custom-gray-2'>
        {label}
      </label>
      <div className='flex gap-[13px] sm:gap-1.5 items-center'>
        <p className='caption font-bold sm:font-normal text-custom-gray-3 sm:text-custom-gray-2'>
          {displayValue < 0 ? displayValue : 0}
        </p>
        <input
          className='w-full sm:w-[155px] '
          type={'range'}
          value={value}
          {...inputProps}
        />
        <p className='caption font-bold sm:font-normal text-custom-gray-3 sm:text-custom-gray-2'>
          {displayValue > 0 ? displayValue : 0}
        </p>
      </div>
    </div>
  )
}

export default ImageEditorRangeSlider
