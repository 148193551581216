import React, { useMemo, useState } from 'react'
import { _t } from '@locales/index'
import { DEFAULT_CURRENCY_SYMBOL } from '@const/currency'
import ToggleButtons from '@components/toggleButtons/ToggleButtons'
import { TOTAL_INCOME_OPTIONS } from '@const/payment'
import { RevenueChangeEnum, TotalIncomeOptionsEnum } from '@enums/payment'
import { TypeTotalIncomeOptions } from '@customTypes/payment'
import { useGetUserProfile } from '@api/queries/profile'
import { useGetCommunityRevenue } from '@api/queries/billing'
import DeviationArrow from '@components/deviationArrow/DeviationArrow'
import { TypeDeviation } from '@customTypes/common'
import { DeviationEnum } from '@enums/common'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { useGetWalletData } from '@api/queries/payment'

interface Props {
  isCommunity?: boolean
}

const TotalIncome: React.FC<Props> = ({ isCommunity = false }) => {
  const isSmallerDevice = useIsSmallerDevice()
  const [period, setPeriod] = useState<TypeTotalIncomeOptions>(
    isSmallerDevice
      ? TotalIncomeOptionsEnum.Total
      : TotalIncomeOptionsEnum.LastMonth,
  )

  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)

  const { data: profile } = useGetUserProfile()
  const { data: communityRevenueData, isLoading: communityRevenueLoading } =
    useGetCommunityRevenue(
      {
        profileId: profile?.id,
        communityId: selectedCommunityId,
      },
      {
        enabled: Boolean(isCommunity && profile?.id && selectedCommunityId),
      },
    )

  const { data: profileRevenueData, isLoading: profileRevenueLoading } =
    useGetWalletData(
      {},
      {
        enabled: Boolean(!isCommunity),
      },
    )

  const revenueData = useMemo(
    () =>
      profileRevenueData
        ? profileRevenueData.revenueSummary
        : communityRevenueData,
    [communityRevenueData, profileRevenueData],
  )

  const isLoading = useMemo(
    () => profileRevenueLoading || communityRevenueLoading,
    [communityRevenueLoading, profileRevenueLoading],
  )

  const deviation = useMemo<TypeDeviation>(() => {
    if (!revenueData || !revenueData.revenueChange) {
      return null
    }
    if (revenueData.revenueChange === RevenueChangeEnum.Increase) {
      return DeviationEnum.Up
    } else {
      return DeviationEnum.Down
    }
  }, [revenueData])

  const revenue = useMemo<number>(() => {
    let value =
      period === TotalIncomeOptionsEnum.Total
        ? revenueData?.totalRevenue || 0
        : revenueData?.lastMonthRevenue || 0
    return Math.round(value * 100) / 100
  }, [period, revenueData?.lastMonthRevenue, revenueData?.totalRevenue])

  return (
    <div className='w-full bg-white p-3 sm:p-5 sm:pr-8 rounded-xl'>
      <div className='w-full h-full flex justify-between items-start'>
        <div className='h-full flex flex-col sm:flex-col-reverse gap-6 justify-between'>
          {isLoading ? (
            <div className='skeleton' />
          ) : (
            <div className='flex items-center gap-3'>
              <p className='h4 sm:h2 leading-normal sm:leading-[3.5rem]'>{`${DEFAULT_CURRENCY_SYMBOL}${revenue}`}</p>
              {period === TotalIncomeOptionsEnum.LastMonth && revenueData && (
                <DeviationArrow deviation={deviation} />
              )}
            </div>
          )}
          <div className='flex justify-between items-center'>
            <p className='caption text-custom-gray-4 capitalize sm:body2 sm:text-custom-black sm:!leading-[2.25rem]'>
              {_t('totalIncome')}
            </p>
          </div>
        </div>
        <div className='hidden sm:block'>
          <ToggleButtons<TypeTotalIncomeOptions>
            options={TOTAL_INCOME_OPTIONS}
            value={period}
            onChange={setPeriod}
          />
        </div>
      </div>
    </div>
  )
}

export default TotalIncome
