export enum PaymentProviderTypeEnum {
  PayPal = 'PayPal',
  Stripe = 'Stripe',
}

export enum PaymentProcessEnum {
  Auto = 'AU',
  Manual = 'MA',
  Admin = 'AD',
}

export enum PaymentCouponStatusEnum {
  Default = 'DE',
  Invalid = 'IN',
  Approve = 'AP',
}

export enum PaymentCouponTypeEnum {
  Percentage = 'PE',
  FixedPrice = 'FP',
}

export enum PaymentDoneFormEnum {
  MemberEmail = 'MemberEmail',
}

export enum PaymentTncFormEnum {
  Date = 'Date',
  AcceptTerms = 'AcceptTerms',
}

export enum TotalIncomeOptionsEnum {
  LastMonth,
  Total,
}

export enum GraphPeriodOptionsEnum {
  Year,
  Month,
}

export enum RevenueChangeEnum {
  Increase = 'increase',
  Decrease = 'decrease',
}

export enum ExportTransactionsTypeEnum {
  CommunityPayment,
  Wallet,
}
