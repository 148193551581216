import React, { useCallback, useMemo } from 'react'
import { _t } from '@locales/index'
import { CommunityFormEnum } from '@enums/community'
import { Form, Formik } from 'formik'
import { useCommunityUpdate, useGetCommunity } from '@api/queries/community'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { TypeCommunityPriceFormValue } from '@customTypes/community'
import { communityPriceSchema } from '@containers/creator/createCommunity/utils'
import { toast } from 'react-hot-toast'
import PriceField from '@containers/creator/createCommunity/components/PriceField'
import { useGetAllCurrencies } from '@api/queries/currency'
import { TypeCurrency } from '@customTypes/currency'
import EditSignUpPageWrapper from '@containers/creator/editCommunity/components/EditSignUpPageWrapper'

const EditSubscriptionFee = () => {
  const communityId = useAppSelector(selectCurrentCommunityId)

  const { data: community } = useGetCommunity(communityId, {
    enabled: Boolean(communityId),
  })

  const { data: currencyData } = useGetAllCurrencies()

  const selectedCurrency = useMemo<TypeCurrency | undefined>(() => {
    return currencyData?.currencies.find(
      currency => currency.id === community?.product.prices[0].currencyId,
    )
  }, [currencyData?.currencies, community])
  const { mutate: updateCommunity, isLoading } = useCommunityUpdate({
    onMutate: () => {
      toast.loading(_t('updateCommunityLoading'), {
        id: 'community-update',
      })
    },
    onError: error => {
      toast.error(error.message, { id: 'community-update' })
    },
  })

  const initialValues = useMemo<TypeCommunityPriceFormValue>(() => {
    return {
      [CommunityFormEnum.Price]: community?.product.prices[0].price || 10,
    }
  }, [community?.product.prices])

  const handleSubmit = useCallback(
    function (formValues: TypeCommunityPriceFormValue) {
      if (community) {
        const values = {
          id: community.id,
          price: formValues[CommunityFormEnum.Price],
        }
        updateCommunity(values, {
          onSuccess: () => {
            toast.success(_t('updateCommunitySuccess'), {
              id: 'community-update',
            })
          },
        })
      }
    },
    [community, updateCommunity],
  )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={communityPriceSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isValid, isSubmitting, values, setValues, dirty }) => (
        <Form className='w-full'>
          <EditSignUpPageWrapper
            loading={isLoading}
            submitDisabled={!isValid || !dirty || isSubmitting}
            title={_t('editSignUpPageSubscriptionFee')}
            mobileTitle={_t('editSignUpPageSubscriptionFeeMobileTitle')}
          >
            <div className='bg-white w-full sm:w-[260px] flex justify-center sm:justify-start p-5 rounded-xl'>
              <PriceField
                currencySymbol={selectedCurrency?.symbol || '$'}
                value={values[CommunityFormEnum.Price]}
                setValue={value =>
                  setValues({ [CommunityFormEnum.Price]: value })
                }
              />
            </div>
          </EditSignUpPageWrapper>
        </Form>
      )}
    </Formik>
  )
}

export default EditSubscriptionFee
