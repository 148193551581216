import {
  TypeUserAgreement,
  TypeUserAgreementForMemberRequest,
  TypeUserAgreementRequest,
  TypeUserAgreementResponse,
} from '@customTypes/userAgreement'
import apiService from '@services/api/apiService'
import { ApiKeyNameEnum } from '@enums/api'
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query'
import { ServerStateKeysEnum } from '@enums/serverState'
import { AxiosError } from 'axios'
import { useProtection } from '@hooks/auth/useProtection'
import { useCallQueryAfterProfile } from '@hooks/auth/useCallQueryAfterProfile'

type TypeGetUserAgreementOptions = UseQueryOptions<any, AxiosError, any, any>

const getUserAgreement = async (
  id: string | undefined,
): Promise<TypeUserAgreement> => {
  try {
    const response = await apiService.get(ApiKeyNameEnum.user_agreement, id)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetUserAgreement = (
  id: string | undefined,
  options?: TypeGetUserAgreementOptions,
) => {
  let queryOptions = useProtection<TypeGetUserAgreementOptions>(options)

  queryOptions =
    useCallQueryAfterProfile<TypeGetUserAgreementOptions>(queryOptions)

  return useQuery(
    [ServerStateKeysEnum.UserAgreement, id],
    () => getUserAgreement(id),
    {
      ...queryOptions,
    },
  )
}

type TypeUpdateAgreementInfoOptions = UseMutationOptions<
  TypeUserAgreementResponse,
  AxiosError,
  TypeUserAgreementRequest
>

const updateAgreementInfo = async (
  values: TypeUserAgreementRequest,
): Promise<TypeUserAgreementResponse> => {
  try {
    const response = await apiService.post(
      ApiKeyNameEnum.user_agreement,
      values,
    )
    return response?.data
  } catch (err) {
    throw err
  }
}

export const useUpdateAgreementInfo = (
  options?: TypeUpdateAgreementInfoOptions,
) => {
  return useMutation(updateAgreementInfo, {
    ...options,
  })
}

type TypePostMemberAgreementInfoOptions = UseMutationOptions<
  TypeUserAgreementResponse,
  AxiosError,
  TypeUserAgreementForMemberRequest
>

const postMemberAgreementInfo = async (
  values: TypeUserAgreementForMemberRequest,
): Promise<TypeUserAgreementResponse> => {
  try {
    const response = await apiService.post(
      ApiKeyNameEnum.user_agreement_for_member,
      values,
    )
    return response?.data
  } catch (err) {
    console.log('in postMemberAgreementInfo err', err)
    throw err
  }
}

export const usePostMemberAgreementInfo = (
  options?: TypePostMemberAgreementInfoOptions,
) => {
  return useMutation(postMemberAgreementInfo, {
    ...options,
  })
}
