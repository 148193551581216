import { useMutation, UseMutationOptions } from 'react-query'
import { AxiosError } from 'axios'
import {
  TypeSendContactUsRequest,
  TypeSendContactUsResponse,
} from '@customTypes/contactUs'
import apiService from '@services/api/apiService'
import { ApiKeyNameEnum } from '@enums/api'

type TypeSendContactUsOptions = UseMutationOptions<
  TypeSendContactUsResponse,
  AxiosError,
  TypeSendContactUsRequest
>

const sendContactUsInfo = async (
  values: TypeSendContactUsRequest,
): Promise<TypeSendContactUsResponse> => {
  try {
    const response = await apiService.post(ApiKeyNameEnum.contact_us, values)
    return response?.data
  } catch (err) {
    console.log('in identity err', err)
    throw err
  }
}

export const useSendContactUsInfo = (options?: TypeSendContactUsOptions) => {
  return useMutation(sendContactUsInfo, {
    ...options,
  })
}
