export enum ProfileStatusEnum {
  Approved = 'AP',
  Processing = 'PR',
}

export enum ProfileOnboardingStatusEnum {
  Initiated = 'IN',
  ProfileCreated = 'PC',
  TelegramConnected = 'TC',
  CommunityCreated = 'CC',
}

export enum ProfileFormEnum {
  Name = 'name',
  Occupation = 'occupation',
  ProfileImage = 'profileImage',
  Bio = 'bio',
}

export enum ProfileLanguageFormEnum {
  Language = 'language',
}
