import React, { Fragment, useMemo } from 'react'
import CommunityMenuTitle from '@containers/creator/sidebar/components/CommunityMenuTitle'
import CommunityMenuItem from '@containers/creator/sidebar/components/CommunityMenuItem'
import classNames from 'classnames'
import { TypeCommunityBase, TypeCommunityMenu } from '@customTypes/community'
import { CommunityApprovalStatusEnum } from '@enums/community'
import { useGetCommunity } from '@api/queries/community'
import { TypeMenuItem } from '@customTypes/sidebar'

interface Props {
  menu: TypeCommunityMenu
  otherCommunities: TypeCommunityBase[] | undefined
  onClickOtherCommunity: (communityId: string) => void
  onClickMenuItem: (menuItem: TypeMenuItem) => void
}

const CommunityMenu: React.FC<Props> = ({
  menu,
  otherCommunities,
  onClickOtherCommunity,
  onClickMenuItem,
}) => {
  const { data: community } = useGetCommunity(menu.id)

  const isApproved = useMemo<Boolean>(
    () => community?.communityStatus === CommunityApprovalStatusEnum.Approved,
    [community?.communityStatus],
  )

  return (
    <Fragment>
      <CommunityMenuTitle
        text={menu.title}
        status={menu.communityStatus}
        otherCommunities={otherCommunities}
        onClickOtherCommunity={onClickOtherCommunity}
      />
      <div
        className={classNames(
          'flex flex-col flex-1 overflow-hidden transition-all duration-300',
        )}
      >
        {isApproved &&
          menu.menuItems.map((menuItem, index) => {
            return (
              <CommunityMenuItem
                key={index}
                menuItem={menuItem}
                onClick={onClickMenuItem}
              />
            )
          })}
      </div>
    </Fragment>
  )
}

export default CommunityMenu
