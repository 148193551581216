import React from 'react'
import { TypeMemberLayout } from '@customTypes/member'
import { ReactComponent as ListIcon } from '@assets/images/common/list.svg'
import { ReactComponent as GridIcon } from '@assets/images/common/grid.svg'
import { MemberLayoutEnum } from '@enums/member'

interface Props {
  layout: TypeMemberLayout
  setLayout: (layout: TypeMemberLayout) => void
}

const MemberLayoutOptions: React.FC<Props> = ({ layout, setLayout }) => {
  return (
    <div className='flex items-center gap-5'>
      <GridIcon
        fill={layout === MemberLayoutEnum.Grid ? '#202020' : '#828282'}
        className='w-6 h-6 cursor-pointer'
        onClick={() => setLayout(MemberLayoutEnum.Grid)}
      />
      <ListIcon
        fill={layout === MemberLayoutEnum.List ? '#202020' : '#828282'}
        className='w-6 h-6 cursor-pointer'
        onClick={() => setLayout(MemberLayoutEnum.List)}
      />
    </div>
  )
}

export default MemberLayoutOptions
