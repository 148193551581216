import {
  TypeBannerState,
  TypeBannerType,
  TypeSelector,
} from '@customTypes/store'
import { createSelector } from '@reduxjs/toolkit'

const selectBannerState: TypeSelector<TypeBannerState> = state => state.banner

export const selectBannerType: TypeSelector<TypeBannerType | null> =
  createSelector(selectBannerState, state => state.type)
