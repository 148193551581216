import React, { useCallback, useMemo, useState } from 'react'
import { _t } from '@locales/index'
import { ReactComponent as MenuArrow } from '@assets/images/common/menu-arrow.svg'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { useGetCommunity } from '@api/queries/community'
import DropdownMenu from '@components/dropdownMenu/DropdownMenu'
import DropdownMenuItem from '@components/dropdownMenu/DropdownMenuItem'
import { CommunityAdminRoleEnum } from '@enums/community'
import { TypeCommunityAdmin } from '@customTypes/community'
import ProfileAvatar from '@components/avatar/ProfileAvatar'

interface Props {
  onSelect: (affiliate?: TypeCommunityAdmin) => void
}

const AffiliatePicker: React.FC<Props> = ({ onSelect }) => {
  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)
  const [selectedAffiliateId, setSelectedAffiliateId] = useState<string>()

  const { data: community } = useGetCommunity(selectedCommunityId || '', {
    enabled: Boolean(selectedCommunityId),
  })

  const handleClickAffiliate = useCallback(
    (affiliate?: TypeCommunityAdmin) => {
      setSelectedAffiliateId(affiliate?.id)
      onSelect(affiliate)
    },
    [onSelect],
  )

  const communityAffiliates = useMemo(() => {
    if (community) {
      return community.admins.filter(
        admin => admin.role === CommunityAdminRoleEnum.Affiliate,
      )
    }
    return null
  }, [community])

  const dropdownTrigger = useMemo(() => {
    const selectedAffiliate = communityAffiliates?.find(
      affiliate => affiliate.id === selectedAffiliateId,
    )
    return (
      <div className='flex items-center justify-between gap-3 py-1.5 px-3 bg-white border border-custom-gray-5 rounded-md'>
        <div className='flex items-center gap-3'>
          {selectedAffiliate && (
            <ProfileAvatar profile={selectedAffiliate.profile} />
          )}
          <p className='body3 sm:body2 capitalize'>
            {selectedAffiliate
              ? selectedAffiliate.profile.name
              : _t('affiliatePickerTitle')}
          </p>
        </div>
        <MenuArrow className='fill-custom-gray-1' />
      </div>
    )
  }, [communityAffiliates, selectedAffiliateId])

  if (
    !community ||
    !communityAffiliates ||
    (communityAffiliates && communityAffiliates.length === 0)
  ) {
    return null
  }

  return (
    <DropdownMenu
      triggerComponent={dropdownTrigger}
      zIndex={9}
      customClass='w-full'
    >
      <DropdownMenuItem
        text={_t('affiliatePickerTitle')}
        onClick={() => handleClickAffiliate()}
        customComponent={<div />}
        customClassName='flex items-center px-5 w-full'
      />
      <div>
        {communityAffiliates.map(affiliate => (
          <DropdownMenuItem
            key={affiliate.id}
            text={
              affiliate.profile.name ||
              affiliate.profile.providerUsername ||
              affiliate.profile.email
            }
            onClick={() => handleClickAffiliate(affiliate)}
            customComponent={<ProfileAvatar profile={affiliate.profile} />}
            customClassName='flex items-center px-5 w-full'
          />
        ))}
      </div>
    </DropdownMenu>
  )
}

export default AffiliatePicker
