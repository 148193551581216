import { useMemo } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useGetUserProfile } from '@api/queries/profile'
import { ProfileOnboardingStatusEnum } from '@enums/profile'

const useShowOrganizationSidebar = (): boolean => {
  const { isAuthenticated } = useAuth0()
  const { data: profile } = useGetUserProfile()

  return useMemo(
    () =>
      Boolean(
        isAuthenticated &&
          profile &&
          profile.onboardingStatus !== ProfileOnboardingStatusEnum.Initiated,
      ),
    [isAuthenticated, profile],
  )
}

export default useShowOrganizationSidebar
