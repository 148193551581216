import React from 'react'
import { _t } from '@locales/index'
import ImageUploadWrapper from '@components/imageUpload/ImageUploadWrapper'
import { ReactComponent as AddImageIcon } from '@assets/images/common/add-image.svg'
import classNames from 'classnames'
import { ProfileMediaTypeEnum } from '@enums/media'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { ReactComponent as PencilIcon } from '@assets/images/common/pencil.svg'

interface Props {
  uploadKey: string
  image?: string
  onSetImageUrl: (url: string) => void
  onBlur?: (e: Event) => void
  showEditIcon?: boolean
  isRectangleVersion?: boolean
}

const ProfileImage: React.FC<Props> = ({
  uploadKey,
  image,
  onSetImageUrl,
  onBlur,
  showEditIcon = false,
  isRectangleVersion = false,
}) => {
  const ref = useDetectClickOutside({
    onTriggered: e => {
      if (onBlur) {
        onBlur(e)
      }
    },
  })

  return (
    <ImageUploadWrapper
      uploadQueueKey={uploadKey}
      cropShape={'round'}
      onSetImageUrl={onSetImageUrl}
      postParams={{
        subType: ProfileMediaTypeEnum.Profile,
      }}
      image={image}
    >
      {({ isDragActive, imageSrc, open, handleClickEdit }) => (
        <>
          {isRectangleVersion && !imageSrc ? (
            <div
              onClick={open}
              className='w-full h-[50px] cursor-pointer bg-white px-4 py-2.5 gap-3 items-center border border-custom-gray-5 flex rounded'
            >
              <AddImageIcon className='h-6 fill-custom-gray-1' />
              <p>Upload Photo</p>
            </div>
          ) : (
            <div className='flex items-center gap-2.5' ref={ref}>
              <div className='relative'>
                <div
                  className={classNames(
                    'w-[150px] h-[150px] sm:w-26 sm:h-26 rounded-full border border-custom-gray-5 flex items-center justify-center overflow-hidden cursor-pointer',
                    isDragActive && 'opacity-30',
                  )}
                  onClick={open}
                >
                  {imageSrc ? (
                    <img src={imageSrc} alt={'profile'} />
                  ) : (
                    <AddImageIcon className='h-8 fill-custom-gray-1' />
                  )}
                </div>
                {imageSrc && showEditIcon && (
                  <PencilIcon
                    onClick={() => handleClickEdit()}
                    className={'w-12 h-12 absolute bottom-0 right-0'}
                  />
                )}
              </div>
              {!showEditIcon && (
                <div className='flex flex-col gap-2.5'>
                  {imageSrc ? (
                    <button
                      className='btn btn-outlined h-10'
                      onClick={handleClickEdit}
                      type='button'
                      tabIndex={-1}
                    >
                      {_t('profileImageEditButton')}
                    </button>
                  ) : (
                    <p
                      className='caption text-custom-gray-3 capitalize cursor-pointer'
                      onClick={open}
                    >
                      {_t('profileImageAddText')}
                    </p>
                  )}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </ImageUploadWrapper>
  )
}

export default ProfileImage
