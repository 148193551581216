import React from 'react'
import DropdownMenu from '@components/dropdownMenu/DropdownMenu'
import DropdownMenuItem from '@components/dropdownMenu/DropdownMenuItem'
import { _t } from '@locales/index'
import { useNavigate } from 'react-router-dom'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import { ReactComponent as HelpIcon } from '@assets/images/common/help.svg'

interface Props {}

const SupportMenu: React.FC<Props> = () => {
  const navigate = useNavigate()

  return (
    <DropdownMenu
      triggerComponent={
        <HelpIcon className='w-8 h-8 cursor-pointer min-w-max fill-custom-black' />
      }
      zIndex={12}
    >
      <DropdownMenuItem
        text={_t('contactUs')}
        onClick={() => navigate(CREATOR_PATH.contact)}
      />
    </DropdownMenu>
  )
}

export default SupportMenu
