import React, { useEffect, useMemo } from 'react'
import { _t } from '@locales/index'
import classNames from 'classnames'
import { COMMUNITY_WIZARD_FOOTER_HEIGHT } from '@const/layout'
import useBreakpoint from '@hooks/responsive/useBreakpoint'
import { useFormikContext } from 'formik'
import { TypeCreateCommunityFormValues } from '@customTypes/community'

interface Props {
  totalScreens: number
  currentScreen: number
  prevCurrentScreen: number
  onClickPreviousScreen: () => void
  disableNext: boolean
  isLastStep: boolean
}

const CommunityWizardFooter: React.FC<Props> = ({
  totalScreens,
  currentScreen,
  prevCurrentScreen,
  onClickPreviousScreen,
  disableNext,
  isLastStep,
}) => {
  const { validateForm } = useFormikContext<TypeCreateCommunityFormValues>()

  const breakpoint = useBreakpoint()

  const completionPercent = useMemo(
    () => (currentScreen / totalScreens) * 100,
    [currentScreen, totalScreens],
  )

  useEffect(() => {
    if (currentScreen !== prevCurrentScreen) {
      validateForm().then()
    }
  }, [currentScreen, prevCurrentScreen, validateForm])

  return (
    <div
      className='flex justify-between items-center px-5 relative'
      style={{
        height: COMMUNITY_WIZARD_FOOTER_HEIGHT[breakpoint],
      }}
    >
      <button
        type={'button'}
        className={classNames(
          'btn btn-text h-12',
          currentScreen === 0 && 'invisible',
        )}
        onClick={onClickPreviousScreen}
      >
        {_t('back')}
      </button>
      <button
        type={'submit'}
        disabled={disableNext}
        className={classNames('btn btn-pink h-12')}
      >
        {isLastStep ? _t('done') : _t('next')}
      </button>
      {/* MOBILE PROGRESS BAR */}
      <div className='sm:hidden absolute top-0 left-0 right-0 flex items-stretch gap-1.5'>
        {Array.apply(null, Array(totalScreens)).map((_, i) => (
          <div className='flex flex-1 h-1' key={i}>
            <div
              className={classNames(
                'overflow-hidden transition-[width] bg-custom-turquoise',
                i <= currentScreen ? 'w-full' : 'w-0',
              )}
            />
            <div
              className={classNames(
                'overflow-hidden transition-[width] bg-custom-green-gray',
                i <= currentScreen ? 'w-0' : 'w-full',
              )}
            />
          </div>
        ))}
      </div>
      {/* DESKTOP PROGRESS BAR */}
      <div className='hidden sm:flex h-[2px] absolute top-0 left-0 right-0 items-stretch'>
        <div
          className='h-full bg-custom-turquoise transition-[width] duration-75 ease-linear'
          style={{ width: `${completionPercent}%` }}
        />
        <div
          className='h-full bg-custom-gray-5 transition-[width] duration-75 ease-linear'
          style={{ width: `${100 - completionPercent}%` }}
        />
      </div>
    </div>
  )
}

export default CommunityWizardFooter
