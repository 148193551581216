import React, { useCallback, useMemo, useState } from 'react'
import { _t, Locale } from '@locales/index'
import { Form, Formik, FormikProps } from 'formik'
import { profileLanguageSchema } from '@containers/creator/createCommunity/utils'
import { ReactComponent as Check } from '@assets/images/common/check-icon.svg'
import CommunityPageWrapper from '@containers/creator/community/components/CommunityPageWrapper'
import useSupportedLanguages from '@hooks/language/useLanguage'
import classNames from 'classnames'
import EditLanguagePopup from '@containers/creator/editCommunity/components/EditLanguagePopup'
import { ProfileLanguageFormEnum } from '@enums/profile'
import { useGetUserProfile, useUpdateProfile } from '@api/queries/profile'
import { TypeEditLanguageFormValue } from '@customTypes/language'

const EditCommunityLanguage = () => {
  const [showEditLanguagePopup, setShowEditLanguagePopup] =
    useState<boolean>(false)
  const local_language = Locale.getLanguage()

  const { data: profile, refetch: refetchProfile } = useGetUserProfile()

  const { mutate: updateProfile } = useUpdateProfile({
    onSuccess: async () => {
      await refetchProfile()
    },
    onError: error => {
      console.log(error)
    },
  })

  const initialValues = useMemo<TypeEditLanguageFormValue>(() => {
    return { [ProfileLanguageFormEnum.Language]: local_language || '' }
  }, [local_language])

  const languageList = useSupportedLanguages()

  const handleSubmit = useCallback(
    (values: TypeEditLanguageFormValue) => {
      console.log('VAL=', values)
      if (profile) {
        updateProfile({
          language: values[ProfileLanguageFormEnum.Language],
          profileId: profile.id,
        })
      }
    },
    [profile, updateProfile],
  )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={profileLanguageSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        values,
        isValid,
        dirty,
        submitForm,
        isSubmitting,
        setFieldValue,
      }: FormikProps<TypeEditLanguageFormValue>) => (
        <Form className='flex flex-col flex-1'>
          <CommunityPageWrapper hasFooter>
            <div className='bg-custom-green-gray p-5 sm:p-13 flex sm:flex-1 sm:flex-col items-center justify-center sm:justify-start sm:items-start'>
              <div className='flex flex-col gap-5'>
                <div className='flex flex-col gap-3'>
                  <p className='h4 capitalize'>
                    {_t('editSignUpPageCommunityLanguageTitle')}
                  </p>
                  <p className='body-3 capitalize'>
                    {_t('editSignUpPageCommunityLanguageMobileTitle')}
                  </p>
                </div>
                <div
                  className={
                    'flex flex-wrap items-center justify-center w-[349px] h-[260px] sm:h-[156px] bg-white rounded-xl'
                  }
                >
                  {languageList.map((val, index) => (
                    <button
                      onClick={() => {
                        setFieldValue(
                          ProfileLanguageFormEnum.Language,
                          val.language,
                        )
                        setShowEditLanguagePopup(true)
                      }}
                      type={'button'}
                      className={classNames(
                        'flex items-center justify-center m-0 sm:m-3 w-[326px] sm:w-[142px] h-[50px] gap-1.5 bg-white rounded-md border',
                        local_language === val.language
                          ? 'border-custom-turquoise'
                          : '',
                      )}
                      key={index}
                    >
                      <img src={val.languageIcon} alt={val.name} /> {val.name}
                      {local_language === val.language ? (
                        <Check fill={'#3F9AA1'} width={10} height={8} />
                      ) : null}
                    </button>
                  ))}
                </div>
              </div>
              <EditLanguagePopup
                onSubmit={submitForm}
                visible={showEditLanguagePopup}
                onClose={() => setShowEditLanguagePopup(false)}
              />
            </div>
          </CommunityPageWrapper>
        </Form>
      )}
    </Formik>
  )
}

export default EditCommunityLanguage
