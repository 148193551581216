import { object, string } from 'yup'
import { TypePublishCommunityFormValues } from '@customTypes/community'
import { PublishCommunityFormEnum } from '@enums/community'
import {
  TypeChangeLaunchDateFormValues,
  TypeChangeLaunchDateOption,
  TypeLaunchDateContactFormValues,
} from '@customTypes/launchDate'
import {
  ChangeLaunchDateFormEnum,
  LaunchDateContactFormEnum,
} from '@enums/launchDate'
import { CHANGE_LAUNCH_DATE_SUBJECT } from '@const/launchDate'

export const publishCommunityInitialValues: TypePublishCommunityFormValues = {
  [PublishCommunityFormEnum.PublishNow]: false,
  [PublishCommunityFormEnum.SetLaunchDate]: false,
}

export const changeLaunchDateInitialValues: TypeChangeLaunchDateFormValues = {
  [ChangeLaunchDateFormEnum.option]: undefined,
}

export const changeLaunchDateSchema = object().shape({
  [ChangeLaunchDateFormEnum.option]: string().required(),
})

export const launchDateContactInitialValues: TypeLaunchDateContactFormValues = {
  [LaunchDateContactFormEnum.subject]: 'I want to change my launch date',
  [LaunchDateContactFormEnum.description]: '',
}

export const getLaunchDateContactInitialValues = (
  chosenOption: TypeChangeLaunchDateOption | undefined,
) => {
  if (!chosenOption) {
    return launchDateContactInitialValues
  } else {
    return {
      [LaunchDateContactFormEnum.subject]:
        CHANGE_LAUNCH_DATE_SUBJECT[chosenOption],
      [LaunchDateContactFormEnum.description]: '',
    }
  }
}

export const launchDateContactSchema = object().shape({
  [LaunchDateContactFormEnum.subject]: string().required(),
  [LaunchDateContactFormEnum.description]: string().required().max(400),
})
