import React, { Fragment, useMemo } from 'react'
import { useField } from 'formik'
import { TypeSelectBoxOption } from '@customTypes/common'
import { Listbox, Transition } from '@headlessui/react'
import { ReactComponent as PointerIcon } from '@assets/images/common/down-pointer.svg'
import SelectBoxOption from '@components/form/SelectBoxOption'
import classNames from 'classnames'

interface Props {
  name: string
  label: string
  options: TypeSelectBoxOption[]
  multi?: boolean
  buttonClassName?: string
  fontClassName?: string
  listOptionsClassName?: string
  checkbox?: string
  onClickListBox?: () => void
}

const SelectBox: React.FC<Props> = ({
  label,
  options,
  name,
  multi = false,
  buttonClassName,
  fontClassName,
  listOptionsClassName,
  checkbox,
  onClickListBox,
}) => {
  const [, meta, helpers] = useField<string[]>(name)
  const [, singleMeta, singleHelpers] = useField<string>(name)

  const hasValue = useMemo<boolean>(() => {
    return Boolean(meta?.value?.length > 0 || singleMeta?.value?.length > 0)
  }, [meta, singleMeta])

  return (
    <div className='w-full h-full'>
      <Listbox
        value={multi ? meta.value : singleMeta.value}
        onChange={multi ? helpers.setValue : singleHelpers.setValue}
        multiple={multi}
      >
        {({ open }) => (
          <div className='relative mt-1 z-[9]'>
            <div onClick={onClickListBox}>
              <Listbox.Button
                className={
                  buttonClassName
                    ? buttonClassName
                    : 'relative w-full h-full rounded border border-custom-black bg-white flex items-center py-1.5 px-3 gap-5 shadow-lg'
                }
              >
                <span
                  className={classNames(
                    'font-poppins text-sm capitalize',
                    hasValue && 'font-bold',
                    fontClassName && fontClassName,
                  )}
                >
                  {label}
                </span>
                <PointerIcon className={'w-6 h-6'} fill={'#202020'} />
              </Listbox.Button>
            </div>
            <Transition
              show={open}
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Listbox.Options
                static
                className={
                  listOptionsClassName
                    ? listOptionsClassName
                    : 'absolute mt-1 w-auto min-w-full overflow-auto rounded bg-white shadow-lg focus:outline-none border border-custom-black divide-y divide-custom-gray-5 z-10'
                }
              >
                {options.map((option, idx) => (
                  <SelectBoxOption
                    option={option}
                    key={idx}
                    checkbox={checkbox}
                  />
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </div>
  )
}

export default SelectBox
