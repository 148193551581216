import React from 'react'
import { ReactComponent as ListIcon } from '@assets/images/common/list-1.svg'
import classNames from 'classnames'
import { BANNER_HEIGHT, NAVBAR_HEIGHT } from '@const/layout'
import useBreakpoint from '@hooks/responsive/useBreakpoint'
import { useAppSelector } from '@hooks/store/redux'
import { selectBannerType } from '@selectors/banner'

interface Props {
  onClickMenu: () => void
}

const CommunityFloatingMenu: React.FC<Props> = ({ onClickMenu }) => {
  const breakpoint = useBreakpoint()
  const bannerType = useAppSelector(selectBannerType)

  return (
    <div
      className={classNames(
        'fixed rounded-full flex items-center justify-center bg-custom-gray-5 w-8 h-8 left-2.5 z-20',
      )}
      style={{
        top:
          NAVBAR_HEIGHT[breakpoint] +
          (bannerType ? BANNER_HEIGHT[breakpoint] : 0) +
          10,
      }}
    >
      <ListIcon
        className='cursor-pointer w-4 h-4 fill-custom-black'
        onClick={onClickMenu}
      />
    </div>
  )
}

export default CommunityFloatingMenu
