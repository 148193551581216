import React from 'react'
import 'react-modern-drawer/dist/index.css'
import { ReactComponent as PointerIcon } from '@assets/images/common/pointer.svg'
import { DRAWER_MENU_TITLE_HEIGHT } from '@const/layout'

interface Props {
  text: string
  onClick?: () => void
}

const DrawerMenuTitle: React.FC<Props> = ({ text, onClick }) => {
  return (
    <div
      className='w-full h-[70px] px-5 flex items-center border-b-2 border-custom-gray-5'
      style={{ height: DRAWER_MENU_TITLE_HEIGHT }}
    >
      <div className='flex items-center cursor-pointer gap-3' onClick={onClick}>
        <PointerIcon fill={'#202020'} className={'w-4'} />
        <p className='text-lg font-poppins capitalize overflow-hidden overflow-ellipsis whitespace-nowrap max-w-[80vw]'>
          {text}
        </p>
      </div>
    </div>
  )
}
//max-w-[300px]
export default DrawerMenuTitle
