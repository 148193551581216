import React, { useCallback } from 'react'
import PhoneMockup from '@components/phoneMockup/PhoneMockup'
import { TypeCommunityMediaType } from '@customTypes/media'
import { _t } from '@locales/index'
import classNames from 'classnames'

interface Props {
  label: string
  description: string
  value: TypeCommunityMediaType
  isSelected: boolean
  phonePreviewImage: string
  phonePreviewTitle: string
  phonePreviewDescription?: string
  onSelect: (value: TypeCommunityMediaType) => void
  video?: boolean
}

const CommunityMediaOption: React.FC<Props> = ({
  label,
  value,
  isSelected,
  phonePreviewImage,
  phonePreviewDescription,
  onSelect,
  video,
}) => {
  const handleSelect = useCallback(() => {
    onSelect(value)
  }, [onSelect, value])

  return (
    <div
      className={
        'w-[165px] sm:w-[455px] h-full flex flex-col gap-3 sm:gap-13 items-center justify-center sm:items-start sm:justify-start'
      }
    >
      <div className='flex flex-col sm:gap-3'>
        <div className='flex flex-col sm:flex-row items-center gap-1.5 sm:gap-3'>
          <div
            className={classNames(
              'border h-8 w-8 sm:w-6 sm:h-6 rounded-full flex items-center justify-center',
              isSelected
                ? 'border-custom-turquoise border-2'
                : 'border-px border-custom-gray-3',
            )}
          >
            <input
              className={'!w-5 !h-5 border-none sm:!w-3.5 sm:!h-3.5'}
              type={'radio'}
              name={'media_choice'}
              onChange={handleSelect}
              value={value}
              id={value}
            />
          </div>
          <label className='body2 sm:h5' htmlFor={value}>
            {label}
          </label>
        </div>
        <p className={'body2 hidden sm:block'}>
          <span className='text-custom-pink text-bold font-bold'></span>{' '}
          {video ? (
            <span className={'body2'}>
              <span className='text-custom-pink text-bold font-bold'>
                {_t('welcomeSelectMediaVideoDesc1')}
              </span>{' '}
              {_t('welcomeSelectMediaVideoDesc2')}
            </span>
          ) : (
            <span className={'body2'}>
              {_t('welcomeSelectMediaPhotoDesc1')}{' '}
              <span className='text-custom-pink text-bold font-bold'>
                {_t('welcomeSelectMediaPhotoDesc2')}
              </span>{' '}
              {_t('welcomeSelectMediaPhotoDesc3')}
            </span>
          )}
        </p>
      </div>
      <div className={'w-[165px] h-[350px] sm:w-[264px] sm:h-[545px] mt-auto'}>
        <PhoneMockup
          isVideo={video}
          backgroundMediaUrl={phonePreviewImage}
          description={phonePreviewDescription}
          borderRadiusClass={'rounded-[27px] sm:rounded-[45px]'}
          faded={!isSelected}
        />
      </div>
    </div>
  )
}

export default CommunityMediaOption
