import { TypeAuth0Config } from '@customTypes/auth0'
import { EnvironmentEnum } from '@enums/environment'
import { env } from '@src/env'

export const CHANGE_PASSWORD_URL =
  'https://auth0.com/docs/authenticate/database-connections/password-change'

const localConfig: TypeAuth0Config = {
  domain: 'dev-2-q3h936.us.auth0.com',
  clientId: 'YI6xw2EwpYCmq66svXcbCAnBBKcfdXFJ',
  audience: 'https://dev-2-q3h936.us.auth0.com/api/v2/',
}

const developmentConfig: TypeAuth0Config = {
  domain: 'dev-2-q3h936.us.auth0.com',
  clientId: 'YI6xw2EwpYCmq66svXcbCAnBBKcfdXFJ',
  audience: 'https://dev-2-q3h936.us.auth0.com/api/v2/',
}

const stagingConfig: TypeAuth0Config = {
  domain: 'zigi-accounts-staging.us.auth0.com',
  clientId: '492CBrhHfrEFF7c2YS9cNOys8BxzGPxs',
  audience: 'https://zigi-accounts-staging.us.auth0.com/api/v2/',
}

const productionConfig: TypeAuth0Config = {
  domain: 'zigi-accounts.us.auth0.com',
  clientId: 'wnOkxu6VTPoLJugOmuXZrguYj4v6ODmp',
  audience: 'https://zigi-accounts.us.auth0.com/api/v2/',
}

const config = {
  [EnvironmentEnum.Local]: localConfig,
  [EnvironmentEnum.Development]: developmentConfig,
  [EnvironmentEnum.Staging]: stagingConfig,
  [EnvironmentEnum.Production]: productionConfig,
}

export const auth0Config = config[env]
