import React from 'react'
import Skeleton from '@components/skeleton/Skeleton'

const MemberRowSkeleton = () => {
  return (
    <tr>
      <td>
        <Skeleton className='w-full h-[75px]' count={1} />
      </td>
      <td>
        <Skeleton className='w-full h-[75px]' count={1} />
      </td>
      {/*<td>*/}
      {/*  <Skeleton className='w-full h-[75px]' count={1} />*/}
      {/*</td>*/}
      <td>
        <Skeleton className='w-full h-[75px]' count={1} />
      </td>
      <td>
        <Skeleton className='w-full h-[75px]' count={1} />
      </td>
    </tr>
  )
}

export default MemberRowSkeleton
