import React, { useCallback, useEffect, useMemo } from 'react'
import Guidelines from '@containers/creator/guidelines/Guidelines'
import { GuidelinesModalModeEnum, GuidelinesTypeEnum } from '@enums/guidelines'
import { useGetDefaultGuidelines } from '@api/queries/guidelines'
import { useField } from 'formik'
import { TypeGuideline, TypeGuidelineFormValues } from '@customTypes/guidelines'
import {
  append,
  find,
  last,
  mergeLeft,
  propEq,
  propOr,
  reject,
  when,
} from 'ramda'
import { openGuidelinesModal } from '@reducers/guidelinesModal'
import update from 'immutability-helper'
import { nanoid } from '@reduxjs/toolkit'
import { openDeleteGuidelinesModal } from '@reducers/deleteGuidelinesModal'
import { useAppDispatch } from '@hooks/store/redux'

const CommunityGuidelines = () => {
  const dispatch = useAppDispatch()
  const [, meta, helpers] = useField<TypeGuideline[]>('guidelines')

  const { data: defaultGuidelines } = useGetDefaultGuidelines(
    GuidelinesTypeEnum.Community,
    {
      enabled: Boolean(!meta.initialValue || !meta.initialValue.length),
      onSuccess: helpers.setValue,
      refetchOnMount: true,
    },
  )

  const emptyGuideline = useMemo<TypeGuideline>(() => {
    const lastGuideline = meta.value ? last(meta.value) : undefined
    const lastGuidelineOrder: number = propOr(0, 'order')(lastGuideline)

    return {
      title: '',
      description: '',
      id: nanoid(),
      order: lastGuidelineOrder ? lastGuidelineOrder + 1 : 1,
    }
  }, [meta.value])

  const handleRemoveGuideline = useCallback(
    (id: string) => {
      const updatedGuidelines = reject(propEq('id', id), meta.value)
      helpers.setValue(updatedGuidelines)
    },
    [helpers, meta.value],
  )

  const handleCreateNewGuideline = useCallback(
    (guideline: TypeGuideline) => {
      const updatedGuidelines = append(guideline, meta.value)
      helpers.setValue(updatedGuidelines)
    },
    [helpers, meta.value],
  )

  const handleUpdateGuideline = useCallback(
    (guideline: TypeGuidelineFormValues) => {
      const updateGuideline = when<TypeGuideline, TypeGuideline>(
        propEq('id', guideline.id),
        mergeLeft(guideline),
      )
      const updatedGuidelines = meta.value.map(updateGuideline)
      helpers.setValue(updatedGuidelines)
    },
    [helpers, meta.value],
  )

  const handleClickEditGuideline = useCallback(
    (id: string) => {
      const selectedGuideline = find(propEq('id', id), meta.value)
      dispatch(
        openGuidelinesModal({
          visible: true,
          mode: GuidelinesModalModeEnum.Edit,
          guideline: selectedGuideline,
          onSubmit: guideline => handleUpdateGuideline(guideline),
        }),
      )
    },
    [dispatch, handleUpdateGuideline, meta.value],
  )

  const handleClickCreateGuideline = useCallback(() => {
    dispatch(
      openGuidelinesModal({
        visible: true,
        mode: GuidelinesModalModeEnum.Create,
        guideline: emptyGuideline,
        onSubmit: guideline => handleCreateNewGuideline(guideline),
      }),
    )
  }, [dispatch, emptyGuideline, handleCreateNewGuideline])

  const handleClickRemoveGuideline = useCallback(
    (id: string) => {
      dispatch(
        openDeleteGuidelinesModal({
          visible: true,
          guidelineId: id,
          onConfirm: handleRemoveGuideline,
        }),
      )
    },
    [dispatch, handleRemoveGuideline],
  )

  const handleMoveGuideline = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const prevGuidelines = meta.value
      helpers.setValue(
        update(prevGuidelines, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevGuidelines[dragIndex]],
          ],
        }),
      )
    },
    [helpers, meta.value],
  )

  const handleDropGuideline = useCallback(() => {
    const prevGuidelines = meta.value
    helpers.setValue(
      update(prevGuidelines, {
        $apply: (guidelines: TypeGuideline[]) =>
          guidelines.map((guideline, index) => {
            return {
              ...guideline,
              order: index,
            }
          }),
      }),
    )
  }, [helpers, meta.value])

  useEffect(() => {
    if (
      defaultGuidelines &&
      (!meta.initialValue || !meta.initialValue.length) &&
      (!meta.value || !meta.value.length)
    ) {
      helpers.setValue(defaultGuidelines)
    }
  }, [defaultGuidelines, helpers, meta.initialValue, meta.value])

  return (
    <Guidelines
      guidelines={meta.value}
      onMove={handleMoveGuideline}
      onDrop={handleDropGuideline}
      onRemove={handleClickRemoveGuideline}
      onClickEdit={handleClickEditGuideline}
      onClickCreate={handleClickCreateGuideline}
    />
  )
}

export default CommunityGuidelines
