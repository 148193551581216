import React from 'react'

interface Props {
  className?: string
  style?: object
  count?: number
}

const Skeleton: React.FC<Props> = ({
  className = '',
  style = {},
  count = 1,
}) => {
  return (
    <>
      {[...Array(count)].map((_, index) => (
        <span
          className={`skeleton ${className}`}
          style={{ ...style }}
          key={index}
        />
      ))}
    </>
  )
}

export default Skeleton
