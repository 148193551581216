import React from 'react'

import { ReactComponent as MinusIcon } from '@assets/images/common/minus.svg'
import { ReactComponent as PlusIcon } from '@assets/images/common/plus-1.svg'

interface Props {
  label: string
  inputProps: React.InputHTMLAttributes<HTMLInputElement>
}

const ImageEditorZoomSlider: React.FC<Props> = ({ label, inputProps }) => {
  return (
    <div className='w-full flex flex-col gap-1.5'>
      <label className='caption sm:body2 text-custom-gray-3 sm:text-custom-gray-2'>
        {label}
      </label>
      <div className='flex gap-3 sm:gap-1.5 items-center'>
        <MinusIcon className='fill-black h-4 w-4' />
        <input className='w-full sm:w-[150px]' type='range' {...inputProps} />
        <PlusIcon className='fill-black h-4 w-4' />
      </div>
    </div>
  )
}

export default ImageEditorZoomSlider
