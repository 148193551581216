import React, { useCallback, useMemo } from 'react'
import { _t } from '@locales/index'
import { CommunityFormEnum } from '@enums/community'
import { Form, Formik } from 'formik'
import { useCommunityUpdate, useGetCommunity } from '@api/queries/community'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { TypeCommunityTypeFormValue } from '@customTypes/community'
import { communityTypeSchema } from '@containers/creator/createCommunity/utils'
import { toast } from 'react-hot-toast'
import SelectCommunityType from '@containers/creator/createCommunity/components/SelectCommunityType'
import EditSignUpPageWrapper from '@containers/creator/editCommunity/components/EditSignUpPageWrapper'

const EditCommunityType = () => {
  const communityId = useAppSelector(selectCurrentCommunityId)

  const { data: community } = useGetCommunity(communityId, {
    enabled: Boolean(communityId),
  })

  const { mutate: updateCommunity, isLoading } = useCommunityUpdate({
    onMutate: () => {
      toast.loading(_t('updateCommunityLoading'), {
        id: 'community-update',
      })
    },
    onError: error => {
      toast.error(error.message, { id: 'community-update' })
    },
  })

  const initialValues = useMemo<TypeCommunityTypeFormValue>(() => {
    return {
      [CommunityFormEnum.Type]: community?.type?.id || '',
    }
  }, [community?.type?.id])

  const handleSubmit = useCallback(
    function (formValues: TypeCommunityTypeFormValue) {
      if (community) {
        const values = {
          id: community.id,
          type: formValues[CommunityFormEnum.Type],
        }
        updateCommunity(values, {
          onSuccess: () => {
            toast.success(_t('updateCommunitySuccess'), {
              id: 'community-update',
            })
          },
        })
      }
    },
    [community, updateCommunity],
  )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={communityTypeSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isValid, isSubmitting, dirty }) => (
        <Form className='w-full'>
          <EditSignUpPageWrapper
            loading={isLoading}
            submitDisabled={!isValid || !dirty || isSubmitting}
            title={_t('editSignUpPageCommunityType')}
            mobileTitle={_t('editSignUpPageCommunityTypeMobileTitle')}
          >
            <SelectCommunityType
              name={CommunityFormEnum.Type}
              justifyClass={'justify-center'}
            />
          </EditSignUpPageWrapper>
        </Form>
      )}
    </Formik>
  )
}

export default EditCommunityType
