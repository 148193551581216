import React from 'react'
import { ReactComponent as SearchIcon } from '@assets/images/common/search.svg'
import { useField } from 'formik'
import { _t } from '@locales/index'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  id: string
}

const Search: React.FC<Props> = props => {
  const [field] = useField(props)

  return (
    <div className='w-full h-full rounded border border-custom-black bg-white flex items-center p-2.5 gap-2'>
      <SearchIcon fill={'#202020'} />
      <input
        type='text'
        className='border-0 outline-0 focus:ring-0 placeholder-custom-black placeholder:capitalize p-0 box-border w-full h-full'
        placeholder={_t('search')}
        autoComplete='off'
        {...field}
        {...props}
      />
    </div>
  )
}

export default Search
