import React, { useCallback } from 'react'
import { TypeSuppliedContent } from '@customTypes/community'
import classNames from 'classnames'

import { ReactComponent as Cross } from '@assets/images/common/reject.svg'
import _tf from '@locales/featureLocal'

interface Props {
  data: TypeSuppliedContent
  isSelected: boolean
  onSelect: (id: string) => void
  onUnselect: (id: string) => void
}

const SuppliedContentChip: React.FC<Props> = ({
  data,
  isSelected,
  onSelect,
  onUnselect,
}) => {
  const handleUnselect = useCallback(
    (e: React.MouseEvent, id: string) => {
      e.stopPropagation()
      onUnselect(id)
    },
    [onUnselect],
  )
  return (
    <div
      className={classNames(
        'flex gap-1.5 items-center border rounded-full py-[5px] transition bg-white',
        isSelected
          ? 'pl-6 pr-[18px] border-custom-pink custom-shadow-2'
          : 'px-6 cursor-pointer',
      )}
      onClick={() => onSelect(data.id)}
    >
      <p
        className={classNames(
          'body2 transition-colors',
          isSelected && 'text-custom-pink',
        )}
      >
        {_tf('suppliedContent', data.id).name || data.name}
      </p>
      {isSelected && (
        <Cross
          className='fill-custom-pink w-5 h-5 cursor-pointer'
          onClick={e => handleUnselect(e, data.id)}
        />
      )}
    </div>
  )
}

export default SuppliedContentChip
