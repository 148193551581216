import { MutableRefObject, useEffect, useRef, useState } from 'react'

interface GetHeightReturnType<T> {
  height: number
  ref: MutableRefObject<T | null>
}

const useGetHeight = <T = HTMLElement>(): GetHeightReturnType<T> => {
  const [height, setHeight] = useState<number>(0)
  const elementRef = useRef<T | null>(null)

  useEffect(() => {
    if (elementRef.current) {
      setHeight((elementRef.current as any).scrollHeight)
    }
  }, [])

  return { height, ref: elementRef }
}

export default useGetHeight
