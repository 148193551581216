import React from 'react'
import { _t } from '@locales/index'
import { useNavigate } from 'react-router-dom'

const NoMatch = () => {
  const navigate = useNavigate()

  return (
    <div className='w-screen h-full-screen flex flex-col gap-10 justify-center items-center'>
      <h3 className='h3'>{_t('noMatchTitle')}</h3>
      <button className='btn btn-pink' onClick={() => navigate(-1)}>
        {_t('noMatchButton')}
      </button>
    </div>
  )
}

export default NoMatch
