import { _t } from '@locales/index'
import React, { Fragment } from 'react'
import { useField } from 'formik'
import classNames from 'classnames'

interface Props extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  name: string
}

const ContactUsFormSubject: React.FC<Props> = ({ ...props }) => {
  const [field, meta] = useField(props)
  const error = meta.touched && meta.error

  return (
    <Fragment>
      <div className={'w-full'}>
        <textarea
          autoComplete='off'
          {...field}
          {...props}
          rows={8}
          placeholder={_t('contactUsDescription')}
          className={classNames(
            'outline-none text-lg w-full focus:ring-0 resize-none py-2.5 pl-5 text-custom-black mt-3',
            error
              ? 'bg-custom-red-2/[0.1] border border-custom-red-2'
              : 'border-none bg-custom-gray-6',
          )}
        />
      </div>
      {error && (
        <div
          className={'mt-5 text-custom-red-2 text-lg font-poppins font-normal'}
        >
          <p>({meta.error})</p>
        </div>
      )}
    </Fragment>
  )
}

export default ContactUsFormSubject
