import React, { useMemo } from 'react'
import { _t } from '@locales/index'
import CouponRow from '@containers/creator/coupons/components/CouponRow'
import { TypePromoCode } from '@customTypes/promoCode'
import { PromoCodeStatusEnum } from '@enums/promoCode'

const thClass =
  'capitalize font-poppins font-bold text-xs py-2.5 px-5 text-left'
interface Props {
  coupons: TypePromoCode[]
  onRevokeClick: Function
}

const CouponArchive: React.FC<Props> = ({ coupons, onRevokeClick }) => {
  const filteredCoupons = useMemo(() => {
    const valid = coupons.filter(
      coupon => coupon.status === PromoCodeStatusEnum.Valid,
    )
    const revoked = coupons.filter(
      coupon => coupon.status === PromoCodeStatusEnum.Revoked,
    )
    const expired = coupons.filter(
      coupon => coupon.status === PromoCodeStatusEnum.Expired,
    )
    const notValid = revoked.concat(expired)
    return valid.concat(notValid)
  }, [coupons])

  return (
    <div className='bg-white w-full rounded p-5'>
      <div>
        <h1 className='font-poppins font-normal text-lg text-custom-black mb-5'>
          {_t('promoCodesArchive')}
        </h1>
        <table className='table-auto bg-white w-full'>
          <thead className='border-custom-gray-5 border rounded'>
            <tr className='border rounded'>
              <th className={thClass}>{_t('promoCodeStatus')}</th>
              <th className={thClass}>{_t('promoCode')}</th>
              <th className={thClass}>{_t('promoCodeDates')}</th>
              <th className={thClass}>{_t('promoCodeDaysLeft')}</th>
              <th className={thClass}>{_t('promoCodeUsingMembers')}</th>
              <th className={thClass}>{_t('promoCodeDiscount')}</th>
            </tr>
          </thead>
          <tbody>
            {filteredCoupons &&
              filteredCoupons.map((coupon, idx) => (
                <CouponRow
                  key={idx}
                  coupon={coupon}
                  onRevokeClick={() => onRevokeClick(coupon)}
                />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default CouponArchive
