import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { useGetCommunity } from '@api/queries/community'
import { selectBannerType } from '@selectors/banner'
import { setBannerType } from '@reducers/banner'
import { BannerTypeEnum } from '@enums/banner'
import { useGetMembersCount } from '@api/queries/member'
import ImportMembersBanner from '@containers/organization/importMembers/ImportMembersBanner'

const Banner = () => {
  const dispatch = useAppDispatch()

  const bannerType = useAppSelector(selectBannerType)
  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)

  const { data: community } = useGetCommunity(selectedCommunityId || '', {
    enabled: Boolean(selectedCommunityId),
  })

  const { data: membersCountResponse } = useGetMembersCount(
    selectedCommunityId || '',
    {
      enabled: Boolean(selectedCommunityId),
    },
  )

  useEffect(() => {
    if (community) {
      let banner: BannerTypeEnum | null = null

      if (membersCountResponse && membersCountResponse.count === 0) {
        banner = BannerTypeEnum.ImportMembers
      }

      dispatch(setBannerType(banner))
    }
  }, [community, dispatch, membersCountResponse, selectedCommunityId])

  switch (bannerType) {
    case BannerTypeEnum.ImportMembers:
      return <ImportMembersBanner />
    default:
      return null
  }
}

export default Banner
