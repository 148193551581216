import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  TypeLaunchDateModalState,
  TypeLaunchDateSetPayload,
} from '@customTypes/store'

const initialState: TypeLaunchDateModalState = {}

/* CASE REDUCERS */
const set: CaseReducer<
  TypeLaunchDateModalState,
  PayloadAction<TypeLaunchDateSetPayload>
> = (state, action) => {
  state[action.payload.communityId] = {
    date: action.payload.date,
  }
}

const launchDateSlice = createSlice({
  name: 'launchDateModal',
  initialState,
  reducers: {
    set,
  },
})

export const { set: setLaunchDate } = launchDateSlice.actions
const launchDateReducer = launchDateSlice.reducer

export default launchDateReducer
