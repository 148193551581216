import React from 'react'

import { ReactComponent as Logo } from '@assets/images/common/logo-black.svg'
import { _t } from '@locales/index'
import classNames from 'classnames'

import CheckIcon from '@assets/images/common/rounded-check.png'
import { ReactComponent as Arrow } from '@assets/images/common/arrow-2.svg'
import { useNavigate } from 'react-router-dom'

interface Props {
  screenLabels: string[]
  currentScreenIndex: number
}

const CommunityWizardSidebar: React.FC<Props> = ({
  screenLabels,
  currentScreenIndex,
}) => {
  const navigate = useNavigate()

  return (
    <div className='py-5 px-7.5 bg-custom-green-gray max-h-screen hidden sm:flex h-full w-[300px] flex-col gap-13'>
      <Logo className='w-9 h-9' />
      <h3 className='h3 capitalize'>{_t('createCommunitySidebarTitle')}</h3>
      <div className='flex flex-col'>
        {screenLabels.map((screenLabel, i) => {
          const isCurrentScreenLabel = currentScreenIndex === i
          return (
            <div className='flex justify-between items-center h-16' key={i}>
              <p
                className={classNames(
                  'body2 transition-colors capitalize',
                  isCurrentScreenLabel
                    ? 'text-custom-gray-1'
                    : 'text-custom-gray-3',
                )}
              >
                {screenLabel}
              </p>
              {i < currentScreenIndex ? (
                <img src={CheckIcon} alt='check' className='w-5 h-5' />
              ) : (
                <div
                  className={classNames(
                    'w-5 h-5 rounded-full border transition-colors',
                    isCurrentScreenLabel
                      ? 'border-custom-gray-1'
                      : 'border-custom-gray-3',
                  )}
                />
              )}
            </div>
          )
        })}
      </div>
      <div
        className='flex items-center gap-1.5 mt-auto cursor-pointer'
        onClick={() => navigate(-1)}
      >
        <Arrow className='fill-custom-gray-1 w-6 h-6 rotate-180' />
        <p className='body1'>{_t('exit')}</p>
      </div>
    </div>
  )
}

export default CommunityWizardSidebar
