import React from 'react'
import { TypeBillingTransaction } from '@customTypes/billing'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'
import TransactionItemTableView from '@containers/creator/communityTransactionsTable/components/TransactionItemTableView'
import TransactionItemListView from '@containers/creator/communityTransactionsTable/components/TransactionItemListView'

interface Props {
  transaction: TypeBillingTransaction
}

const TransactionItem: React.FC<Props> = ({ transaction }) => {
  const isSmallerDevice = useIsSmallerDevice()

  return (
    <>
      {isSmallerDevice ? (
        <TransactionItemListView transaction={transaction} />
      ) : (
        <TransactionItemTableView transaction={transaction} />
      )}
    </>
  )
}

export default TransactionItem
