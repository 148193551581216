import React, { useMemo } from 'react'
import { _t } from '@locales/index'
import { DEFAULT_CURRENCY_SYMBOL } from '@const/currency'
import { useGetWalletData } from '@api/queries/payment'

interface Props {
  onClickWithdraw: (availableFunds: number) => void
}

const AvailableFunds: React.FC<Props> = ({ onClickWithdraw }) => {
  const { data: walletData, isLoading } = useGetWalletData()

  const availableFunds = useMemo<number>(
    () =>
      Math.round((walletData?.availableFunds?.availableFunds || 0) * 100) / 100,
    [walletData?.availableFunds?.availableFunds],
  )

  return (
    <div className='w-full bg-white p-3 sm:p-5 sm:pr-8 rounded-xl'>
      {isLoading ? (
        <div className='skeleton' />
      ) : (
        <div className='h-full flex flex-col gap-2.5 sm:flex-row sm:justify-between'>
          <div className='h-full flex flex-col gap-1.5 sm:gap-10 sm:flex-col-reverse sm:justify-between'>
            <p className='h3 sm:h2 leading-normal sm:leading-[3.5rem] text-custom-turquoise-dark sm:text-custom-turquoise'>{`${DEFAULT_CURRENCY_SYMBOL}${availableFunds}`}</p>
            <p className='caption text-custom-gray-4 capitalize sm:body2 sm:text-custom-black'>
              {_t('availableFunds')}
            </p>
          </div>
          <div className='h-14 sm:hidden' />
          <button
            className='btn btn-yellow self-start'
            disabled={!walletData?.availableFunds?.availableFunds}
            onClick={() =>
              onClickWithdraw(walletData?.availableFunds?.availableFunds || 0)
            }
          >
            {_t('withdraw')}
          </button>
        </div>
      )}
    </div>
  )
}

export default AvailableFunds
