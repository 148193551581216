import React, { useCallback, useMemo } from 'react'
import { _t } from '@locales/index'
import { CommunityFormEnum } from '@enums/community'
import { Form, Formik } from 'formik'
import { useGetCommunity } from '@api/queries/community'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { TypeCommunityGuidelinesFormValue } from '@customTypes/community'
import { communityGuidelinesSchema } from '@containers/creator/createCommunity/utils'
import { toast } from 'react-hot-toast'
import Loader from '@components/loader/Loader'
import { useCommunityGuidelinesMutation } from '@api/queries/guidelines'
import { ServerStateKeysEnum } from '@enums/serverState'
import { useQueryClient } from 'react-query'
import CommunityGuidelines from '@containers/creator/community/components/CommunityGuidelines'
import { TypeCommunityGuidelineRequest } from '@customTypes/guidelines'
import { equals, omit } from 'ramda'
import CommunityPageWrapper from '@containers/creator/community/components/CommunityPageWrapper'

const EditCommunityGuidelines = () => {
  const queryClient = useQueryClient()
  const communityId = useAppSelector(selectCurrentCommunityId)

  const { data: community, refetch } = useGetCommunity(communityId, {
    enabled: Boolean(communityId),
  })

  const { mutate: updateCommunityGuidelines, isLoading } =
    useCommunityGuidelinesMutation({
      onError: error => {
        toast.error(error.message, { id: 'guidelines-update' })
      },
      onSuccess: () => {
        queryClient.invalidateQueries([ServerStateKeysEnum.Community]).then()
      },
    })

  const initialValues = useMemo<TypeCommunityGuidelinesFormValue>(() => {
    return { [CommunityFormEnum.Guidelines]: community?.guidelines || [] }
  }, [community?.guidelines])

  const handleSubmit = useCallback(
    function (formValues: TypeCommunityGuidelinesFormValue) {
      if (community) {
        const guidelines: TypeCommunityGuidelineRequest[] =
          formValues.guidelines.map(omit(['language', 'type', 'id']))

        const initialGuidelinesWithoutIds = initialValues?.guidelines?.map(
          omit(['id', 'type', 'language']),
        )

        if (equals(initialGuidelinesWithoutIds, guidelines)) {
          return
        }

        const values = {
          communityId: community.id,
          guidelines: guidelines,
        }
        updateCommunityGuidelines(values, {
          onSuccess: async () => {
            toast.success(_t('updateCommunitySuccess'), {
              id: 'community-update',
            })
            await refetch()
          },
        })
      }
    },
    [community, initialValues?.guidelines, refetch, updateCommunityGuidelines],
  )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={communityGuidelinesSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isValid, isSubmitting, dirty }) => (
        <Form className={'w-full'}>
          <CommunityPageWrapper>
            <div className='bg-custom-green-gray p-5 pt-2 sm:p-13 flex flex-col overflow-y-auto'>
              <div className='flex flex-col gap-13 relative'>
                <div className='flex flex-col gap-5 max-w-[448px]'>
                  <div className='flex flex-col gap-3'>
                    <p className='body1 capitalize'>
                      {_t('editCommunityGuidelinesPageTitle')}
                    </p>
                    <p className='body2 text-custom-gray-3'>
                      {_t('editCommunityGuidelinesPageDescription')}
                    </p>
                  </div>
                  <p>{_t('editCommunityGuidelinesPageInstruction')}</p>
                </div>
                <CommunityGuidelines />
                <button
                  className='btn btn-pink w-[106px] hidden sm:block flex'
                  type={'submit'}
                  disabled={!isValid || !dirty || isSubmitting}
                >
                  {_t('editSignUpPageUpdateButton')}
                </button>
              </div>
            </div>
            <button
              className='btn btn-pink w-full mt-auto min-h-[60px] rounded-none sm:hidden'
              type={'submit'}
              disabled={!isValid || !dirty || isSubmitting}
            >
              {_t('editSignUpPageUpdateButton')}
            </button>
            <Loader visible={isLoading} fullWidth />
          </CommunityPageWrapper>
        </Form>
      )}
    </Formik>
  )
}

export default EditCommunityGuidelines
