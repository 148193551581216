import Loader from '@components/loader/Loader'
import useAffiliatorLogIn from '@hooks/auth/useAffiliatorLogIn'
import { useAppSelector } from '@hooks/store/redux'
import { selectNewAdminIsAffiliatorLoggedIn } from '@selectors/newAdmin'
const AffiliatorLogIn = () => {
  useAffiliatorLogIn()
  const isAffiliatorLoggedIn = useAppSelector(
    selectNewAdminIsAffiliatorLoggedIn,
  )

  return (
    <div className='w-screen h-screen'>
      <Loader visible={isAffiliatorLoggedIn} fullWidth />
    </div>
  )
}

export default AffiliatorLogIn
