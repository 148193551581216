import React from 'react'
import { ReactComponent as PaypalIcon } from '@assets/images/payment/paypal_logo.svg'

import { _t } from '@locales/index'
import { PAYPAL_URL } from '@services/payout/payoutConfig'
import { useGetWalletData } from '@api/queries/payment'

const PaypalAccount = () => {
  const { data: walletData } = useGetWalletData()

  if (walletData?.payoutSettings?.email) {
    return (
      <div className='flex items-center gap-1'>
        <PaypalIcon className='w-6 h-6' />
        <p className='body2'>{walletData?.payoutSettings?.email}</p>
      </div>
    )
  }

  return (
    <a
      className='btn btn-outlined btn-outlined-red py-1.5 px-4'
      href={PAYPAL_URL}
    >
      <PaypalIcon className='w-6 h-6' />
      <p>{_t('paymentConnectToPaypal')}</p>
    </a>
  )
}

export default PaypalAccount
