import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query'
import { ServerStateKeysEnum } from '@enums/serverState'
import { ApiKeyNameEnum } from '@enums/api'
import apiService from '@services/api/apiService'
import {
  TypeUpdateProfile,
  TypeProfile,
  TypeUpdateProfileCurrency,
  TypeUpdateProfileOccupation,
  TypeCreateProfile,
} from '@customTypes/profile'
import { useProtection } from '@hooks/auth/useProtection'
import { AxiosError } from 'axios'

/**********************************
 GET PROFILE
 **********************************/

type TypeGetProfileOptions = UseQueryOptions<
  TypeProfile,
  AxiosError,
  TypeProfile,
  any
>

const getUserProfile = async (): Promise<TypeProfile> => {
  try {
    const response = await apiService.get(ApiKeyNameEnum.my_profile)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetUserProfile = (options?: TypeGetProfileOptions) => {
  const queryOptions = useProtection<TypeGetProfileOptions>(options)

  return useQuery(ServerStateKeysEnum.Profile, getUserProfile, {
    ...queryOptions,
  })
}

/**********************************
 Create PROFILE
 **********************************/

type TypeCreateProfileOptions = UseMutationOptions<TypeProfile, AxiosError, any>

const createProfile = async (
  values?: TypeCreateProfile,
): Promise<TypeProfile> => {
  try {
    const response = await apiService.post(ApiKeyNameEnum.profile, values)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useCreateProfile = (options?: TypeCreateProfileOptions) => {
  const queryClient = useQueryClient()

  return useMutation(createProfile, {
    onSuccess: () => {
      return queryClient.invalidateQueries(ServerStateKeysEnum.Profile)
    },
    ...options,
  })
}

/**********************************
 UPDATE PROFILE
 **********************************/

type TypeUpdateProfileOptions = UseMutationOptions<
  TypeProfile,
  AxiosError,
  TypeUpdateProfile
>

const updateProfile = async (
  values: TypeUpdateProfile,
): Promise<TypeProfile> => {
  try {
    const response = await apiService.put(ApiKeyNameEnum.profile, values)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useUpdateProfile = (options?: TypeUpdateProfileOptions) => {
  const queryClient = useQueryClient()

  return useMutation(updateProfile, {
    onSuccess: () => {
      return queryClient.invalidateQueries(ServerStateKeysEnum.Profile)
    },
    ...options,
  })
}

/**********************************
 UPDATE PROFILE CURRENCY
 **********************************/
type TypeUpdateProfileCurrencyOptions = UseMutationOptions<
  TypeProfile,
  AxiosError,
  TypeUpdateProfileCurrency
>

const updateProfileCurrency = async (
  values: TypeUpdateProfileCurrency,
): Promise<TypeProfile> => {
  try {
    const response = await apiService.put(
      ApiKeyNameEnum.profile_currency,
      values,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useUpdateProfileCurrency = (
  options?: TypeUpdateProfileCurrencyOptions,
) => {
  const queryClient = useQueryClient()

  return useMutation(updateProfileCurrency, {
    onSuccess: () => {
      return queryClient.invalidateQueries(ServerStateKeysEnum.Profile)
    },
    ...options,
  })
}

/**********************************
 UPDATE PROFILE OCCUPATION
 **********************************/
type TypeUpdateProfileOccupationOptions = UseMutationOptions<
  TypeProfile,
  AxiosError,
  TypeUpdateProfileOccupation
>

const updateProfileOccupation = async (
  values: TypeUpdateProfileOccupation,
): Promise<TypeProfile> => {
  try {
    const response = await apiService.post(
      ApiKeyNameEnum.profile_occupation,
      values,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useUpdateProfileOccupation = (
  options?: TypeUpdateProfileOccupationOptions,
) => {
  const queryClient = useQueryClient()

  return useMutation(updateProfileOccupation, {
    onSuccess: () => {
      return queryClient.invalidateQueries(ServerStateKeysEnum.Profile)
    },
    ...options,
  })
}

/**********************************
 GET PROFILE
 **********************************/

type TypeGetProfileLinkOptions = UseQueryOptions<
  TypeProfile,
  AxiosError,
  TypeProfile,
  any
>

const getProfileLink = async (id: string | undefined): Promise<TypeProfile> => {
  try {
    const response = await apiService.get(ApiKeyNameEnum.profile_link, id)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetProfileLink = (
  id: string | undefined,
  options?: TypeGetProfileLinkOptions,
) => {
  const queryOptions = useProtection<TypeGetProfileLinkOptions>(options)

  return useQuery([ServerStateKeysEnum.Profile, id], () => getProfileLink(id), {
    ...queryOptions,
  })
}

/**********************************
 ATTACH NEW AUTH USER
 **********************************/

type TypeAttachNewAuthUserOptions = UseMutationOptions<
  TypeProfile,
  AxiosError,
  // TypeUpdateProfile
  any
>

const attachNewAuthUser = async (
  values: TypeUpdateProfile,
): Promise<TypeProfile> => {
  try {
    const response = await apiService.post(
      ApiKeyNameEnum.attach_auth_user,
      values,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useAttachNewAuthUser = (
  options?: TypeAttachNewAuthUserOptions,
) => {
  const queryClient = useQueryClient()

  return useMutation(attachNewAuthUser, {
    onSuccess: () => {
      return queryClient.invalidateQueries(ServerStateKeysEnum.Profile)
    },
    ...options,
  })
}
