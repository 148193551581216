import React, { useCallback, useMemo } from 'react'
import Drawer from 'react-modern-drawer'
import { DrawerProps } from '@customTypes/common'
import { useNavigate } from 'react-router-dom'
import {
  MOBILE_BOTTOM_DRAWER_ITEM_HEIGHT,
  NAVIGATION_MOBILE_MENU,
} from '@const/layout'
import { _t } from '@locales/index'
import { useAuth0 } from '@auth0/auth0-react'
import { ProfileOnboardingStatusEnum } from '@enums/profile'
import { useGetUserProfile } from '@api/queries/profile'
import { setModalType } from '@reducers/modal'
import { ModalTypeEnum } from '@enums/modal'
import { useAppDispatch } from '@hooks/store/redux'

const NavigationMenuDrawer: React.FC<DrawerProps> = ({
  open,
  onClose,
  direction,
  ...drawerProps
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { logout } = useAuth0()

  const { data: profile } = useGetUserProfile()

  const isOnboarding = useMemo<boolean>(
    () => profile?.onboardingStatus === ProfileOnboardingStatusEnum.Initiated,
    [profile?.onboardingStatus],
  )

  const handleClickMenuItem = useCallback(
    (link: string) => {
      navigate(link)
      onClose && onClose()
    },
    [navigate, onClose],
  )

  const handleClickImportMembers = useCallback(() => {
    dispatch(setModalType(ModalTypeEnum.ImportMembersManually))
    onClose && onClose()
  }, [dispatch, onClose])

  const handleClickLogout = useCallback(() => {
    logout({ returnTo: window.location.origin })
    onClose && onClose()
  }, [logout, onClose])

  return (
    <Drawer
      size={
        (isOnboarding ? 1 : NAVIGATION_MOBILE_MENU.length + 2) *
        MOBILE_BOTTOM_DRAWER_ITEM_HEIGHT
      }
      open={open}
      direction='bottom'
      onClose={onClose}
      className={'overflow-y-auto !w-screen rounded-t-[20px]'}
      {...drawerProps}
    >
      <div className='flex flex-col divide-y px-3'>
        {!isOnboarding &&
          NAVIGATION_MOBILE_MENU.map((menuItem, idx) => (
            <div
              className='flex items-center justify-center gap-3'
              onClick={() => handleClickMenuItem(menuItem.link)}
              style={{ height: MOBILE_BOTTOM_DRAWER_ITEM_HEIGHT }}
              key={idx}
            >
              <p className='body2 capitalize'>{menuItem.label}</p>
            </div>
          ))}
        {!isOnboarding && (
          <div
            className='flex items-center justify-center gap-3'
            onClick={handleClickImportMembers}
            style={{ height: MOBILE_BOTTOM_DRAWER_ITEM_HEIGHT }}
          >
            <p className='body2 capitalize'>{_t('importMembers')}</p>
          </div>
        )}
        <div
          className='flex items-center justify-center gap-3'
          onClick={handleClickLogout}
          style={{ height: MOBILE_BOTTOM_DRAWER_ITEM_HEIGHT }}
        >
          <p className='body2 capitalize'>{_t('logout')}</p>
        </div>
      </div>
    </Drawer>
  )
}

export default NavigationMenuDrawer
