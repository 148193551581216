import React from 'react'
import 'react-modern-drawer/dist/index.css'
import { _t } from '@locales/index'
import DrawerMenuWrapper from '@containers/common/mobileDrawerMenu/components/DrawerMenuWrapper'
import { USER_MENU_ITEMS } from '@const/user'
import CommunityMenuItem from '@containers/creator/sidebar/components/CommunityMenuItem'

interface Props {
  show: boolean
  onClose: () => void
}

const UserMenu: React.FC<Props> = ({ show, onClose }) => {
  return (
    <DrawerMenuWrapper
      open={show}
      direction={'right'}
      onClose={onClose}
      size={'100vw'}
      title={_t('details')}
    >
      {USER_MENU_ITEMS.map((menuItem, i) => (
        <CommunityMenuItem key={i} menuItem={menuItem} onClick={onClose} />
      ))}
    </DrawerMenuWrapper>
  )
}

export default UserMenu
