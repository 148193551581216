import React, { useMemo } from 'react'
import MemberCount from '@containers/organization/organization/components/MemberCount'
import OrganizationPageWrapper from '@containers/organization/wrapper/OrganizationPageWrapper'
import { selectUserSegmentationFiltersState } from '@root/src/core/store/selectors/userSegmentationFilters'
import { useAppSelector } from '@root/src/core/hooks/store/redux'
import { _t } from '@locales/index'
import { useGetAudienceUserSegmentation } from '@api/queries/userSegmentation'
import { selectCurrentCommunityId } from '@selectors/community'
import { useGetAudiences } from '@api/queries/segmentation'
import { AudienceTypeEnum } from '@enums/segmentation'
import Loader from '@components/loader/Loader'

const OrganizationHome = () => {
  const userSegmentationsFilters = useAppSelector(
    selectUserSegmentationFiltersState,
  )
  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)
  const { data: audiences, isLoading: isAudiencesLoading } = useGetAudiences(
    selectedCommunityId,
    {
      enabled: Boolean(selectedCommunityId),
    },
  )

  const audienceTypeAll = useMemo(() => {
    return audiences?.find(audience => audience.type === AudienceTypeEnum.All)
  }, [audiences])

  const {
    data: audienceUserSegmentation,
    isLoading: isAudienceUserSegmentationLoading,
  } = useGetAudienceUserSegmentation(audienceTypeAll?.id, {
    enabled: Boolean(audienceTypeAll?.id),
  })

  const memberCount = useMemo(() => {
    return audienceUserSegmentation?.communityMembersCount
  }, [audienceUserSegmentation?.communityMembersCount])

  return (
    <OrganizationPageWrapper>
      <div className='px-5 py-13 sm:p-26 flex flex-1 flex-col'>
        <div className='self-center w-full max-w-[360px]'>
          <Loader
            visible={isAudiencesLoading || isAudienceUserSegmentationLoading}
          />
          <MemberCount
            count={
              userSegmentationsFilters.filteredMemberCount !== undefined
                ? userSegmentationsFilters.filteredMemberCount
                : memberCount || 0
            }
            label={
              userSegmentationsFilters.filteredMemberCount !== undefined
                ? _t('filteredUsers')
                : _t('segmentationWidgetAllName')
            }
          />
        </div>
      </div>
    </OrganizationPageWrapper>
  )
}

export default OrganizationHome
