import React from 'react'
import { TypeExpandedMenuItem } from '@customTypes/sidebar'
import CommunityExpandedMenuItem from '@containers/creator/sidebar/components/CommunityExpandedMenuItem'

interface Props {
  menuItems: TypeExpandedMenuItem[]
}

const CommunityExpandedMenu: React.FC<Props> = ({ menuItems }) => {
  return (
    <div className='flex flex-col'>
      {menuItems.map((menuItem, i) => {
        return menuItem.subMenu ? (
          <div key={i} className='flex flex-col'>
            <div className='px-3 h-12 flex items-center cursor-pointer gap-2.5 border-r border-custom-gray-5'>
              <p className='body3 capitalize font-semibold text-custom-turquoise-dark'>
                {menuItem.text}
              </p>
            </div>
            {menuItem.subMenu.map((subMenuItem, j) => (
              <CommunityExpandedMenuItem
                key={j}
                menuItem={subMenuItem}
                paddingClass='px-8'
              />
            ))}
          </div>
        ) : menuItem.path ? (
          <CommunityExpandedMenuItem key={i} menuItem={menuItem} />
        ) : null
      })}
    </div>
  )
}

export default CommunityExpandedMenu
