import React, { useCallback, useEffect, useState } from 'react'
import { TypePromoCode } from '@customTypes/promoCode'
import { _t } from '@locales/index'
import { Form, Formik } from 'formik'
import CouponList from '@containers/creator/coupons/components/CouponList'
import SelectStatusBox from '@containers/creator/coupons/components/SelectStatusBox'
import { PromoCodeStatusEnum } from '@enums/promoCode'

interface Props {
  coupons: TypePromoCode[]
  onRevokeClick: Function
}

const CouponArchiveMobile: React.FC<Props> = ({ coupons, onRevokeClick }) => {
  const [couponStatus, setCouponStatus] = useState<string>('')
  const [filteredCoupons, setFilteredCoupons] = useState<TypePromoCode[]>([])

  const handleChangeStatus = useCallback(status => {
    setCouponStatus(status)
  }, [])

  useEffect(() => {
    let filtered
    if (couponStatus === PromoCodeStatusEnum.Valid) {
      filtered = coupons.filter(
        coupon => coupon.status === PromoCodeStatusEnum.Valid,
      )
    } else if (couponStatus === PromoCodeStatusEnum.Revoked) {
      filtered = coupons.filter(
        coupon => coupon.status === PromoCodeStatusEnum.Revoked,
      )
    } else {
      filtered = coupons.filter(
        coupon => coupon.status === PromoCodeStatusEnum.Expired,
      )
    }
    filtered && setFilteredCoupons(filtered)
  }, [couponStatus, coupons])

  return (
    <div className='bg-white w-full rounded-xl p-3 '>
      <div className='flex flex-row items-center justify-between mb-5'>
        <h1 className='font-poppins font-normal text-lg text-custom-black'>
          {_t('promoCodesArchive')}
        </h1>
        <Formik
          initialValues={{ couponStatus: PromoCodeStatusEnum.Valid }}
          onSubmit={() => {}}
        >
          <Form>
            <SelectStatusBox onChangeStatus={handleChangeStatus} />
          </Form>
        </Formik>
      </div>
      <CouponList coupons={filteredCoupons} onRevokeClick={onRevokeClick} />
    </div>
  )
}

export default CouponArchiveMobile
