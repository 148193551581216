import React from 'react'
import { _t } from '@locales/index'
import { EXAMPLE_SCRIPT_LINK } from '@const/common'

interface Props {
  onSetShowExampleVideo: (show: boolean) => void
}

const ExampleVideo: React.FC<Props> = ({ onSetShowExampleVideo }) => {
  return (
    <div className='flex flex-col items-center sm:justify-start gap-3 p-3 bg-custom-gray-6'>
      <p className={'body3 text-custom-gray-3'}>{_t('welcomeVideoNeedHelp')}</p>
      <div className={'flex gap-3'}>
        <button
          type={'button'}
          className='btn rounded-full shadow-none border-custom-gray-5 sm:w-[157px] h-[50px] bg-white px-4 body2'
          onClick={() => onSetShowExampleVideo(true)}
        >
          {_t('welcomeVideoExampleVideoButton')}
        </button>
        <a
          href={EXAMPLE_SCRIPT_LINK}
          target={'_blank'}
          rel='noreferrer noopener'
          className='btn rounded-full shadow-none border-custom-gray-5 sm:w-[157px] h-[50px] bg-white px-4 body2'
        >
          {_t('welcomeVideoExampleScriptButton')}
        </a>
      </div>
    </div>
  )
}

export default ExampleVideo
