import React, { useCallback } from 'react'
import { TypeUserAgreement } from '@customTypes/userAgreement'
import UserAgreement from '@containers/common/userAgreement/UserAgreement'
import { UserAgreementStatusEnum } from '@enums/userAgreement'
import moment from 'moment'
import { toast } from 'react-hot-toast'
import { _t } from '@locales/index'
import {
  useGetUserAgreement,
  useUpdateAgreementInfo,
} from '@api/queries/userAgreement'
import { useQueryClient } from 'react-query'
import { ServerStateKeysEnum } from '@enums/serverState'
import { useGetUserProfile } from '@api/queries/profile'
import UAParser from 'ua-parser-js'
import { ResponseStatusCodeEnum } from '@enums/api'
import 'moment-timezone'
import Loader from '@components/loader/Loader'

const UserAgreementWrapper = () => {
  const queryClient = useQueryClient()
  const updateUserAgreement = useUpdateAgreementInfo()

  const { data: profile, isLoading } = useGetUserProfile()

  const { data: agreements, error } = useGetUserAgreement(profile?.id, {
    enabled: Boolean(profile?.id),
    refetchInterval: () => {
      return 10 * 60 * 500 // 5 minutes
    },
  })

  const handleAgree = useCallback(
    (userAgreementId: string) => {
      const parser = new UAParser()
      const userDeviceInfo = parser.getResult()
      const timezone = moment.tz.guess()
      const sendInfoObj = {
        userAgreementId: userAgreementId,
        status: UserAgreementStatusEnum.Approved,
        timezone: timezone,
        device: userDeviceInfo.device,
        additionalUserInfo: userDeviceInfo,
      }
      updateUserAgreement.mutate(sendInfoObj, {
        onSuccess: () => {
          queryClient
            .refetchQueries([ServerStateKeysEnum.UserAgreement, profile?.id])
            .then()
        },
        onError: () => {
          toast.error(_t('defaultErrorMessage'))
        },
      })
    },
    [updateUserAgreement, queryClient, profile?.id],
  )

  if (error?.response?.status === ResponseStatusCodeEnum.ObjectDoesNotExist) {
    return null
  }

  if (isLoading) {
    return <Loader visible={true} fullWidth />
  }

  return (
    <div>
      {agreements?.agreements &&
        agreements.agreements.map(
          (userAgreement: TypeUserAgreement, i: any) => {
            return (
              <UserAgreement
                agreementId={userAgreement.agreement.id}
                title={userAgreement.agreement.title}
                onAgree={() => handleAgree(userAgreement.id)}
                isOpen={
                  userAgreement.status === UserAgreementStatusEnum.Declined ||
                  userAgreement.status === UserAgreementStatusEnum.Pending
                }
                key={i}
              />
            )
          },
        )}
    </div>
  )
}

export default UserAgreementWrapper
