import { env } from '@src/env'
import { EnvironmentEnum } from '@enums/environment'
import { TypeApiConfig } from '@customTypes/api'

const localConfig: TypeApiConfig = {
  uiUrl: 'http://localhost:3000',
  apiUrl: 'https://localhost:8000',
}

const developmentConfig: TypeApiConfig = {
  uiUrl: 'https://dev-management.wearegrouper.com',
  apiUrl: 'https://dev-api.wearegrouper.com',
}

const stagingConfig: TypeApiConfig = {
  uiUrl: 'https://staging-management.wearegrouper.com',
  apiUrl: 'https://staging-api.wearegrouper.com',
}

const productionConfig: TypeApiConfig = {
  uiUrl: 'https://management.wearegrouper.com',
  apiUrl: 'https://prod-api.wearegrouper.com',
}

const config = {
  [EnvironmentEnum.Local]: localConfig,
  [EnvironmentEnum.Development]: developmentConfig,
  [EnvironmentEnum.Staging]: stagingConfig,
  [EnvironmentEnum.Production]: productionConfig,
}

export const apiConfig = config[env]
