import React from 'react'
import classNames from 'classnames'
import useShowCommunityMenu from '@hooks/common/useShowCommunityMenu'
import { LAYOUT_FOOTER, NAVBAR_HEIGHT } from '@const/layout'
import useBreakpoint from '@hooks/responsive/useBreakpoint'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'
import useSetLanguage from '@hooks/language/useSetLanguage'

interface Props {
  children: React.ReactNode
  hasFooter?: boolean
  hasNavbar?: boolean
  bgClass?: string
}

const CommunityPageWrapper: React.FC<Props> = ({
  children,
  bgClass,
  hasFooter,
}) => {
  const breakpoint = useBreakpoint()
  const isSmallerDevice = useIsSmallerDevice()
  const showCommunityMenu = useShowCommunityMenu()

  useSetLanguage()

  return (
    <div
      className={classNames(
        'flex flex-1 flex-col w-full overflow-y-auto',
        showCommunityMenu,
        bgClass,
      )}
      style={{
        height: hasFooter
          ? `calc((${isSmallerDevice ? 'var(--vh, 1vh) * 100' : '100vh'} - ${
              LAYOUT_FOOTER[breakpoint]
            }px) - ${NAVBAR_HEIGHT[breakpoint]}px`
          : '100%',
      }}
    >
      {children}
    </div>
  )
}

export default CommunityPageWrapper
