import { object, string } from 'yup'
import { CoverPhotoFormEnum } from '@enums/coverPhoto'

export const photoCoverUrlSchema = object().shape({
  [CoverPhotoFormEnum.Photo]: string().required(),
})

export const photoCoverDescriptionSchema = object().shape({
  [CoverPhotoFormEnum.Description]: string().required(),
})
