import React, { useCallback, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { ReactComponent as Dots } from '@assets/images/common/3_dots.svg'
import { ReactComponent as BackArrow } from '@assets/images/payment/pink_arrow.svg'

import { _t } from '@locales/index'
import { openMobileBottomDrawer } from '@reducers/mobileBottomDrawer'
import { MobileBottomDrawerTypeEnum } from '@enums/common'
import { useAppDispatch, useAppSelector } from '@hooks/store/redux'
import PaypalAccount from '@containers/creator/payments/components/PaypalAccount'
import ExportSpreadsheet from '@containers/creator/payments/components/ExportSpreadsheet'
import DropdownMenu from '@components/dropdownMenu/DropdownMenu'
import DropdownMenuItem from '@components/dropdownMenu/DropdownMenuItem'
import { EXPORT_SPREADSHEET_OPTIONS } from '@const/payment'
import useExportTransactionsCSV from '@hooks/payment/useExportTransactions'
import moment from 'moment/moment'
import { ExportTransactionsTypeEnum } from '@enums/payment'
import { selectCurrentCommunityId } from '@selectors/community'
import { CREATOR_PATH } from '@routes/creator/creatorPath'

const WalletHeader = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)

  const handleOpenActionDrawer = useCallback(() => {
    dispatch(
      openMobileBottomDrawer({
        type: MobileBottomDrawerTypeEnum.WalletActions,
      }),
    )
  }, [dispatch])

  const [exportStartDate, setExportStartDate] = useState<number>()

  useExportTransactionsCSV({
    payload: {
      communityId: undefined,
      profileId: undefined,
      startDate: exportStartDate,
    },
    callback: () => {
      setExportStartDate(undefined)
    },
    type: ExportTransactionsTypeEnum.Wallet,
  })

  const handleClickExport = useCallback((value: number) => {
    setExportStartDate(moment().subtract(value, 'months').unix())
  }, [])

  return (
    <div className='flex items-center justify-between'>
      <div className='flex items-center gap-6'>
        <BackArrow
          className='cursor-pointer h-9 sm:h-12'
          onClick={() =>
            navigate(
              CREATOR_PATH.communityId.replace(
                ':id',
                selectedCommunityId || '',
              ),
            )
          }
        />
        <p className='h5 hidden sm:block'>{_t('walletHeader')}</p>
      </div>
      <p className='h5 sm:hidden'>{_t('walletHeader')}</p>
      <div className='hidden sm:flex items-center gap-8'>
        <DropdownMenu
          triggerComponent={<ExportSpreadsheet />}
          showDividers={false}
        >
          {EXPORT_SPREADSHEET_OPTIONS.map((option, i) => (
            <DropdownMenuItem
              key={i}
              text={option.label}
              onClick={() => handleClickExport(option.value)}
            />
          ))}
        </DropdownMenu>
        <PaypalAccount />
      </div>
      <Dots
        fill='#828282'
        className='dots-menu sm:hidden'
        onClick={handleOpenActionDrawer}
      />
    </div>
  )
}

export default WalletHeader
