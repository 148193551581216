import queryString from 'query-string'
import snakecaseKeys from 'snakecase-keys'

const numFormatLookup = [
  { value: 1, symbol: '' },
  { value: 1e3, symbol: 'k' },
  { value: 1e6, symbol: 'M' },
  {
    value: 1e9,
    symbol: 'G',
  },
  { value: 1e12, symbol: 'T' },
  { value: 1e15, symbol: 'P' },
  { value: 1e18, symbol: 'E' },
]

export const numFormatter = (num: number, digits: number = 2): string => {
  const rx = /\.0+$|(\.\d*[1-9])0+$/
  const item = numFormatLookup
    .slice()
    .reverse()
    .find(function (i) {
      return num >= i.value
    })
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0'
}

export const getApiQueryString = (params: Object) =>
  queryString.stringify(snakecaseKeys(params), {
    arrayFormat: 'comma',
    skipNull: true,
    skipEmptyString: true,
  })

export const isNonZeroFalsy = (value: any): boolean => {
  return !value && value !== 0 && value !== -0
}
