import React, { useCallback, useMemo, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import { _t } from '@locales/index'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import { ReactComponent as CalendarIcon } from '@assets/images/common/calendar.svg'

import LunchCommunityWrapper from '@containers/creator/launchCommunity/LaunchCommunityWrapper'
import {
  getLaunchDateContactInitialValues,
  launchDateContactSchema,
} from '@containers/creator/launchCommunity/utils'
import { LaunchDateContactFormEnum } from '@enums/launchDate'
import { TypeLaunchDateContactFormValues } from '@customTypes/launchDate'
import LaunchScreenFooter from '@containers/creator/launchCommunity/components/LaunchScreenFooter'
import { useAppSelector } from '@hooks/store/redux'
import { selectChangeLaunchDateModalOption } from '@selectors/changeLaunchDateModal'
import { useSendContactUsInfo } from '@api/queries/contactUs'
import { toast } from 'react-hot-toast'
import { useGetUserProfile } from '@api/queries/profile'
import { useGetCommunity } from '@api/queries/community'
import { selectCurrentCommunityId } from '@selectors/community'
import LaunchScreenMobileHeader from '@containers/creator/launchCommunity/components/LaunchScreenMobileHeader'

const LaunchDateContactUs = () => {
  const navigate = useNavigate()
  const id = useAppSelector(selectCurrentCommunityId)
  const [isSubmitting, setIsSubmitting] = useState<boolean>()

  const option = useAppSelector(selectChangeLaunchDateModalOption)

  const { data: profile } = useGetUserProfile()
  const { data: community } = useGetCommunity(id, {
    enabled: Boolean(id),
  })

  const sendContactUsInfo = useSendContactUsInfo({
    onMutate: () => {
      setIsSubmitting(true)
      toast.loading(_t('contactUsLoading'), {
        id: 'launch-date-contact',
      })
    },
    onSettled: () => {
      setIsSubmitting(false)
    },
  })

  const handleSubmit = useCallback(
    (values: TypeLaunchDateContactFormValues) => {
      if (id && profile && community) {
        sendContactUsInfo.mutate(
          {
            subject: values[LaunchDateContactFormEnum.subject],
            body: `Email: ${profile.email}<br />Name: ${
              profile.name
            }<br />Community: ${community.title}<br />Description: ${
              values[LaunchDateContactFormEnum.description]
            }<br />
            `,
          },
          {
            onSuccess: () => {
              toast.success(_t('contactUsSuccess'), {
                id: 'launch-date-contact',
              })
              navigate(CREATOR_PATH.launchDateContactSent.replace(':id', id))
            },
            onError: error => {
              toast.error(error.message, {
                id: 'launch-date-contact',
              })
              navigate(CREATOR_PATH.communityId.replace(':id', id))
            },
          },
        )
      } else {
        navigate('/')
      }
    },
    [community, id, navigate, profile, sendContactUsInfo],
  )

  const handleClickCancel = useCallback(() => {
    if (id) {
      navigate(CREATOR_PATH.communityId.replace(':id', id))
    } else {
      navigate('/')
    }
  }, [id, navigate])

  const initialValues = useMemo(
    () => getLaunchDateContactInitialValues(option),
    [option],
  )

  return (
    <LunchCommunityWrapper isFullScreenOnMobile isSubmitting={isSubmitting}>
      <Formik
        initialValues={initialValues}
        validateOnMount={true}
        onSubmit={handleSubmit}
        validationSchema={launchDateContactSchema}
        enableReinitialize
      >
        {({
          isSubmitting: formSubmitting,
          isValid,
        }: FormikProps<TypeLaunchDateContactFormValues>) => (
          <Form className='h-full flex flex-col'>
            <LaunchScreenMobileHeader />
            <div className='p-5 sm:py-13 sm:px-26'>
              <CalendarIcon className='hidden sm:block fill-custom-gray-1 w-12 h-12' />
              <div className='h-3' />
              <h3 className='h3 capitalize'>{_t('contactUs')}</h3>
              <div className='h-3' />
              <Field
                type='text'
                name={LaunchDateContactFormEnum.subject}
                className='input w-[299px] capitalize text-custom-gray-3'
                readOnly
              />
              <div className='h-7.5' />
              <Field
                type='textarea'
                name={LaunchDateContactFormEnum.description}
              >
                {({
                  field, // { name, value, onChange, onBlur }
                }: FieldProps) => (
                  <div>
                    <textarea
                      placeholder={_t(
                        'launchDateContactUsDescriptionPlaceholder',
                      )}
                      className='input w-full sm:w-[520px]'
                      rows={9}
                      maxLength={400}
                      {...field}
                    />
                    <div className='h-3' />
                    <p className='body3 text-custom-gray-3 text-right'>{`(${
                      400 - field.value.length
                    } ${_t(
                      400 - field.value.length > 1
                        ? 'charactersLeft'
                        : 'characterLeft',
                    )})`}</p>
                  </div>
                )}
              </Field>
            </div>
            <LaunchScreenFooter
              previousButtonText={_t('cancel')}
              nextButtonText={_t('send')}
              onClickPrevious={handleClickCancel}
              nextButtonDisabled={formSubmitting || !isValid}
            />
          </Form>
        )}
      </Formik>
    </LunchCommunityWrapper>
  )
}

export default LaunchDateContactUs
