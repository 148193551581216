import React, { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { _t } from '@locales/index'

import { ReactComponent as Check } from '@assets/images/common/checked-circle-filled.svg'

import LunchCommunityWrapper from '@containers/creator/launchCommunity/LaunchCommunityWrapper'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'

const LaunchDateContactSent: React.FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const isSmallerDevice = useIsSmallerDevice()

  const handleClose = useCallback(() => {
    if (id) {
      navigate(CREATOR_PATH.communityId.replace(':id', id))
    }
  }, [id, navigate])

  return (
    <LunchCommunityWrapper
      onClose={handleClose}
      showCloseIcon={!isSmallerDevice}
    >
      <div className='px-5 py-13 sm:px-13 flex flex-col'>
        <div className='flex items-center gap-3'>
          <Check className='fill-custom-turquoise stroke-custom-turquoise h-8 w-8 sm:hidden' />
          <h3 className='h3 capitalize'>{_t('launchDateContactSentTitle')}</h3>
        </div>
        <div className='h-3' />
        <p className='body1 text-custom-gray-3'>
          {_t('launchDateContactSentText')}
        </p>
        <div className='h-13' />
        <Check className='fill-custom-turquoise stroke-custom-turquoise h-16 w-16 hidden sm:block' />
        <button
          className='btn btn-pink sm:hidden self-center'
          onClick={handleClose}
        >
          {_t('done')}
        </button>
      </div>
    </LunchCommunityWrapper>
  )
}

export default LaunchDateContactSent
