import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { _t } from '@locales/index'
import { Form, Formik, FormikProps } from 'formik'
import { useCommunityUpdate, useGetCommunity } from '@api/queries/community'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { communityDescriptionSchema } from '@containers/creator/createCommunity/utils'
import { toast } from 'react-hot-toast'
import {
  TypeCoverPhotoFormValue,
  TypeEditDescriptionFormValue,
} from '@customTypes/coverPhoto'
import { CoverPhotoFormEnum } from '@enums/coverPhoto'
import TextareaField from '@components/form/TextareaField'
import classNames from 'classnames'
import PhoneMockup from '@components/phoneMockup/PhoneMockup'
import { useInView } from 'react-intersection-observer'
import { COMMUNITY_DESCRIPTION_LIMIT } from '@const/common'
import CommunityPageWrapper from '@containers/creator/community/components/CommunityPageWrapper'
import Footer from '@components/footer/Footer'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'
import { useNavigate } from 'react-router-dom'

const EditDescription = () => {
  const navigate = useNavigate()
  const isSmallerDevice = useIsSmallerDevice()

  const [descBoxVisible, setDescBoxVisible] = useState<boolean>(false)

  const { ref: descBoxRef, inView } = useInView()
  const communityId = useAppSelector(selectCurrentCommunityId)

  const { data: community } = useGetCommunity(communityId, {
    enabled: Boolean(communityId),
  })

  const { mutate: updateCommunity } = useCommunityUpdate({
    onMutate: () => {
      toast.loading(_t('updateCommunityLoading'), {
        id: 'community-update',
      })
    },
    onError: error => {
      toast.error(error.message, { id: 'community-update' })
    },
  })

  const initialValues = useMemo<TypeEditDescriptionFormValue>(() => {
    return { [CoverPhotoFormEnum.Description]: community?.description || '' }
  }, [community?.description])

  const handleClickBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const handleSubmit = useCallback(
    function (formValues: TypeEditDescriptionFormValue) {
      if (community) {
        const values = {
          id: community.id,
          description: formValues[CoverPhotoFormEnum.Description],
        }
        updateCommunity(values, {
          onSuccess: () => {
            toast.success(_t('updateCommunitySuccess'), {
              id: 'community-update',
            })
          },
        })
      }
    },
    [community, updateCommunity],
  )
  useEffect(() => {
    if (inView) {
      setDescBoxVisible(true)
    }
  }, [inView])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={communityDescriptionSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        values,
        isValid,
        dirty,
        isSubmitting,
      }: FormikProps<TypeCoverPhotoFormValue>) => (
        <Form className='flex flex-col flex-1'>
          <CommunityPageWrapper hasFooter>
            <div className='bg-custom-green-gray p-5 sm:p-13 flex flex-1 flex-col'>
              <div className='flex flex-col gap-5'>
                <div className='flex flex-col gap-8'>
                  <div className='flex flex-col gap-3'>
                    <p className='h4 capitalize'>{_t('description')}</p>
                  </div>
                  <div className='sm:flex hidden h-[200px] gap-[100px]'>
                    <TextareaField
                      name={CoverPhotoFormEnum.Description}
                      id={CoverPhotoFormEnum.Description}
                      placeholder={_t('welcomePhotoDescExample')}
                      textClass='body3 sm:body3 font-normal capitalize'
                      className={'w-[448px]'}
                      maxLength={COMMUNITY_DESCRIPTION_LIMIT}
                      showCharacterLimit
                      hideErrorMessage={true}
                    />
                    <div className={'h-full w-full overflow-y-hidden relative'}>
                      <div className={'w-[242px] h-[500px] absolute bottom-0'}>
                        <PhoneMockup
                          community={community}
                          description={values[CoverPhotoFormEnum.Description]}
                          backgroundMediaUrl={community?.media[0].url}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className='btn btn-pink hidden sm:block w-[106px]'
                  disabled={!isValid || !dirty || isSubmitting}
                >
                  {_t('editSignUpPageUpdateButton')}
                </button>
              </div>
              <div
                className={'flex flex-col items-center justify-start sm:hidden'}
              >
                <div ref={descBoxRef} />
                <div
                  className={classNames(
                    'absolute z-100 ease-in duration-500 border-b border-custom-gray-5 shadow-2xl ',
                    descBoxVisible ? 'top-0' : 'top-[-350px]',
                  )}
                >
                  <div
                    className={classNames(
                      'bg-white flex flex-col items-center justify-center  p-5',
                    )}
                  >
                    <p className={'capitalize body1 mb-5'}>
                      {_t('communityCoverDescriptionGuidelines')}
                    </p>
                    <div className={'flex flex-col items-end gap-5 w-full'}>
                      <TextareaField
                        name={CoverPhotoFormEnum.Description}
                        id={CoverPhotoFormEnum.Description}
                        placeholder={_t('communityCoverDescriptionExample')}
                        className={
                          'sm:w-[455px] sm:h-[120px] w-[320px] h-[120px] border-custom-gray-5 rounded resize-none body3'
                        }
                        maxLength={COMMUNITY_DESCRIPTION_LIMIT}
                        showCharacterLimit
                        hideErrorMessage={true}
                      />
                    </div>
                  </div>
                </div>
                <div className={'w-[242px] h-[500px]'}>
                  <PhoneMockup
                    community={community}
                    description={values[CoverPhotoFormEnum.Description]}
                    backgroundMediaUrl={community?.media[0].url}
                  />
                </div>
              </div>
            </div>
          </CommunityPageWrapper>
          {isSmallerDevice && (
            <Footer
              primaryButton={{
                text: _t('done'),
                disabled: !isValid || !dirty || isSubmitting,
              }}
              secondaryButton={{
                text: _t('back'),
                onClick: handleClickBack,
              }}
            />
          )}
        </Form>
      )}
    </Formik>
  )
}

export default EditDescription
