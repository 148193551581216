import {
  TypeFooterState,
  TypeFooterType,
  TypeSelector,
} from '@customTypes/store'
import { createSelector } from '@reduxjs/toolkit'

const selectFooterState: TypeSelector<TypeFooterState> = state => state.footer

export const selectFooterType: TypeSelector<TypeFooterType | null> =
  createSelector(selectFooterState, state => state.type)
