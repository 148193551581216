import React, { useCallback } from 'react'
import Drawer from 'react-modern-drawer'
import { useAppDispatch, useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { useGetCommunities } from '@api/queries/community'
import { DrawerProps } from '@customTypes/common'
import { ReactComponent as PlusIcon } from '@assets/images/common/plus-1.svg'
import { _t } from '@locales/index'
import classNames from 'classnames'
import { selectCommunity } from '@reducers/community'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import { useNavigate } from 'react-router-dom'
import { MOBILE_BOTTOM_DRAWER_ITEM_HEIGHT } from '@const/layout'

const CommunitySwitcherMobileDrawer: React.FC<DrawerProps> = ({
  open,
  onClose,
  direction,
  ...drawerProps
}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)

  const { data: communities } = useGetCommunities()

  const handleSelectCommunity = useCallback(
    (communityId: string) => {
      dispatch(selectCommunity(communityId))
      navigate(CREATOR_PATH.communityId.replace(':id', communityId))
      onClose && onClose()
    },
    [dispatch, navigate, onClose],
  )

  const handleClickCreateNewCommunity = useCallback(() => {
    navigate(CREATOR_PATH.createCommunity)
    onClose && onClose()
  }, [navigate, onClose])

  if (!communities) {
    return null
  }

  return (
    <Drawer
      size={(communities.length + 1) * MOBILE_BOTTOM_DRAWER_ITEM_HEIGHT}
      open={open}
      direction='bottom'
      onClose={onClose}
      className={'overflow-y-auto !w-screen rounded-t-[20px]'}
      {...drawerProps}
    >
      <div className='flex flex-col divide-y px-3'>
        {communities &&
          communities.map((community, index) => {
            const isSelected = community.id === selectedCommunityId

            return (
              <div
                key={index}
                className='flex items-center justify-center gap-2'
                style={{ height: MOBILE_BOTTOM_DRAWER_ITEM_HEIGHT }}
                onClick={() => handleSelectCommunity(community.id)}
              >
                {isSelected && (
                  <div className='w-1.5 h-1.5 rounded-full bg-custom-turquoise' />
                )}
                <p
                  className={classNames(
                    'body2 capitalize',
                    isSelected && 'font-bold text-custom-turquoise',
                  )}
                >
                  {community.title.length > 25
                    ? community.title.slice(0, 25) + '...'
                    : community.title}
                </p>
              </div>
            )
          })}
        <div
          className='flex items-center justify-center gap-3'
          style={{ height: MOBILE_BOTTOM_DRAWER_ITEM_HEIGHT }}
          onClick={handleClickCreateNewCommunity}
        >
          <PlusIcon className='w-6 h-6 fill-custom-black' />
          <p className='body2 capitalize'>{_t('createNewCommunity')}</p>
        </div>
      </div>
    </Drawer>
  )
}

export default CommunitySwitcherMobileDrawer
