import React, { useEffect, useMemo } from 'react'
import { useMatch, useNavigate, useRoutes } from 'react-router-dom'
import { useGetUserProfile } from '@api/queries/profile'
import { ProfileOnboardingStatusEnum } from '@enums/profile'
import UserAgreementWrapper from '@containers/common/userAgreement/UserAgreementWrapper'
import { LocalStorageTypeEnum } from '@enums/localStorage'
import SetLanguagePopup from '@components/setLanguage/SetLanguagePopup'
import useSetLanguage from '@hooks/language/useSetLanguage'
import { ORGANIZATION_ROUTES } from '@routes/organization/organizationRoutes'
import MobileBottomDrawer from '@containers/common/mobileBottomDrawer/MobileBottomDrawer'
import { ORGANIZATION_PATH } from '@routes/organization/organizationPath'
import MobileDrawerMenu from '@containers/common/mobileDrawerMenu/MobileDrawerMenu'
import { selectCommunity } from '@reducers/community'
import { useGetCommunities } from '@api/queries/community'
import { useAppDispatch } from '@hooks/store/redux'

const OrganizationFlow = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const organizationRoutes = useRoutes(ORGANIZATION_ROUTES)
  useSetLanguage()
  const organizationIdMatch = useMatch(ORGANIZATION_PATH.organizationId + '*')

  const { data: profile } = useGetUserProfile()
  const { data: communities } = useGetCommunities()

  useEffect(() => {
    if (communities) {
      if (
        organizationIdMatch &&
        organizationIdMatch.params &&
        organizationIdMatch.params.id
      ) {
        const id = organizationIdMatch.params.id
        const hasId = communities.some(c => c.id === id)
        if (hasId) {
          dispatch(selectCommunity(id))
        }
      } else {
        dispatch(selectCommunity(communities[0].id))
      }
    }
  }, [communities, dispatch, organizationIdMatch])

  useEffect(() => {
    // redirect to onboarding if it isn't completed
    if (profile?.onboardingStatus === ProfileOnboardingStatusEnum.Initiated) {
      navigate(ORGANIZATION_PATH.createOrganization)
    }
  }, [navigate, profile])

  const userLanguageInLocalStorage = useMemo(() => {
    return localStorage.getItem(LocalStorageTypeEnum.IsSelectedLanguage)
  }, [])

  return (
    <div>
      {userLanguageInLocalStorage ? (
        <>
          {organizationRoutes}
          <UserAgreementWrapper />
          <MobileBottomDrawer />
          <MobileDrawerMenu />
        </>
      ) : (
        <SetLanguagePopup />
      )}
    </div>
  )
}

export default OrganizationFlow
