import React from 'react'
import classNames from 'classnames'
import { _t } from '@locales/index'
import { ReactComponent as TelegramIcon } from '@assets/images/common/telegram-icon.svg'

interface Props {
  clickTG?: () => void
}

const MobileBeforeTg: React.FC<Props> = ({ clickTG }) => {
  return (
    <div
      className={classNames(
        'bg-custom-black text-white flex flex-col items-center justify-center',
      )}
    >
      <div className={classNames('text-white flex flex-col items-center mt-5')}>
        <h1
          className={classNames(
            'text-[32px] text-center font-bold font-extrabold text-transparent  bg-clip-text bg-gradient-to-r from-custom-yellow-1 via-custom-yellow-2 to-custom-yellow-3',
          )}
        >
          {_t('youDontHaveTg')}
        </h1>
        <h1 className={classNames('text-[20px] mt-3')}>
          {_t('tgMyCommunityIsOnMobile')}
        </h1>
      </div>
      <div className={'flex flex-col items-center justify-center'}>
        <div className={'flex flex-col justify-between mt-[50px]'}>
          <button
            className={
              'w-[253px] h-[56px] bg-white rounded-full text-custom-black text-[18px] p-2'
            }
            onClick={clickTG}
          >
            <div className={'flex items-center justify-center relative'}>
              <TelegramIcon className='w-10 h-10 absolute left-0' />
              <p className={'absolute right-3'}>{_t('downloadTgMobile')}</p>
            </div>
          </button>
        </div>
        <div className={'w-[363px] text-center mt-10 text-[20px]'}>
          <h1>{_t('afterDownloadTgMobile')}</h1>
        </div>
      </div>
    </div>
  )
}

export default MobileBeforeTg
