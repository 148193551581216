export enum UserAgreementStatusEnum {
  Approved = 'AP',
  Pending = 'PE',
  Declined = 'DE',
  DeclinedByZigi = 'DZ',
}

export enum AgreementEntityEnum {
  Empty = 'EM',
  Profile = 'PR',
  Member = 'ME',
}

export enum AgreementTypeEnum {
  creatorToCreator = 'CC',
  zigiToCreator = 'ZC',
  creatorToMember = 'CM',
  zigiToMember = 'ZM',
}
