import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import { TypeExpandedMenuItem } from '@customTypes/sidebar'
import { ReactComponent as ArrowIcon } from '@assets/images/common/arrow.svg'
import { Disclosure, Transition } from '@headlessui/react'

type Props = {
  expandedMenuItem: TypeExpandedMenuItem
  onClick?: (menuItem: TypeExpandedMenuItem) => void
}

const CommunityExpandedMenuMenuItemMobile: React.FC<Props> = ({
  expandedMenuItem,
  onClick,
}) => {
  return (
    <Disclosure as={'div'}>
      {({ open }) => {
        return (
          <Fragment>
            <Disclosure.Button as={Fragment}>
              {expandedMenuItem?.subMenu &&
              expandedMenuItem?.subMenu.length > 0 ? (
                <div
                  className={classNames(
                    'px-5 sm:px-3 h-16 flex items-center cursor-pointer gap-2.5 border-r border-custom-gray-5 bg-white',
                    open
                      ? 'text-custom-turquoise fill-custom-turquoise'
                      : 'text-custom-gray-1 fill-custom-gray-1',
                  )}
                >
                  <div className='w-6 h-6 fill-inherit' />
                  <p className='body3 sm:body2 capitalize text-inherit'>
                    {expandedMenuItem.text}
                  </p>
                  {expandedMenuItem?.subMenu && (
                    <ArrowIcon
                      className={classNames(
                        'ml-auto sm:hidden transition-transform',
                        open
                          ? 'rotate-90 fill-custom-turquoise'
                          : '-rotate-90 fill-custom-black',
                      )}
                    />
                  )}
                </div>
              ) : (
                <NavLink
                  to={expandedMenuItem.path || ''}
                  className={({ isActive }) =>
                    classNames(
                      'px-5 sm:px-3 h-16 flex items-center cursor-pointer gap-2.5 border-r border-custom-gray-5 bg-white',
                      isActive
                        ? 'text-custom-turquoise fill-custom-turquoise'
                        : 'text-custom-gray-1 fill-custom-gray-1',
                    )
                  }
                  onClick={() => onClick && onClick(expandedMenuItem)}
                >
                  <div className='w-6 h-6 fill-inherit' />
                  <p className='body3 sm:body2 capitalize text-inherit'>
                    {expandedMenuItem.text}
                  </p>
                </NavLink>
              )}
            </Disclosure.Button>

            {expandedMenuItem?.subMenu && (
              <Transition
                enter='transition duration-100 ease-out'
                enterFrom='transform scale-95 opacity-0'
                enterTo='transform scale-100 opacity-100'
                leave='transition duration-75 ease-out'
                leaveFrom='transform scale-100 opacity-100'
                leaveTo='transform scale-95 opacity-0'
              >
                <Disclosure.Panel as={'div'} className='sm:hidden'>
                  {expandedMenuItem?.subMenu?.map((subMenuItem, i) => (
                    <NavLink
                      key={i}
                      to={subMenuItem.path || ''}
                      className={({ isActive }) =>
                        classNames(
                          'px-5 sm:px-3 h-16 flex items-center cursor-pointer gap-7.5 border-r border-custom-gray-5 bg-white',
                          isActive
                            ? 'text-custom-turquoise fill-custom-turquoise'
                            : 'text-custom-gray-1 fill-custom-gray-1',
                        )
                      }
                      onClick={() => onClick && onClick(expandedMenuItem)}
                    >
                      <div className='w-6 h-6 fill-inherit' />
                      <p className='body3 sm:body2 capitalize text-inherit'>
                        {subMenuItem.text}
                      </p>
                    </NavLink>
                  ))}
                </Disclosure.Panel>
              </Transition>
            )}
          </Fragment>
        )
      }}
    </Disclosure>
  )
}

export default CommunityExpandedMenuMenuItemMobile
