import React, { useCallback, useMemo, useState } from 'react'
import { Field, useFormikContext } from 'formik'
import { _t } from '@locales/index'
import { BroadcastMessageFormEnum } from '@enums/broadcastMessage'
import Drawer from 'react-modern-drawer'
import { ReactComponent as MessageIcon } from '@assets/images/community/messenger-icon.svg'
import { ReactComponent as BackArrow } from '@assets/images/common/arrow.svg'
import { ReactComponent as AddFileIcon } from '@assets/images/common/upload-file-icon.svg'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'
import Modal from '@components/modal/Modal'
import MediaUploadWrapper from '@components/mediaUpload/MediaUploadWrapper'
import { TypeBroadcastMessageFormValues } from '@customTypes/broadcastMessage'

interface Props {
  open: boolean
  onClose: () => void
  mediaUploadKey: string
}

const BroadcastMessagePopup: React.FC<Props> = ({
  open,
  onClose,
  mediaUploadKey,
}) => {
  const {
    resetForm,
    setFieldValue,
    isValid,
    isSubmitting,
    values,
    submitForm,
  } = useFormikContext<TypeBroadcastMessageFormValues>()

  const [addLink, setAddLink] = useState(false)
  const isSmallerDevice = useIsSmallerDevice()

  const handleClose = useCallback(() => {
    resetForm()
    onClose()
  }, [onClose, resetForm])

  const header = useMemo(
    () => (
      <div
        className={
          'flex px-3 py-5 items-center justify-between w-full bg-custom-navy-blue sm:rounded-t-2xl'
        }
      >
        <div className={'flex items-center gap-2'}>
          <MessageIcon className='w-6 h-6' />
          <p className='text-white sm:text-2xl body1 font-pangram-regular'>
            {_t('segmentationBroadcastMessageTitle')}
          </p>
        </div>
        <button
          type='submit'
          onClick={() => {
            submitForm().then(r => resetForm())
          }}
          disabled={!isValid || isSubmitting}
          className={'btn btn-white rounded-full h-[35px] sm:h-10'}
        >
          {_t('segmentationBroadcastMessageSendButton')}
        </button>
      </div>
    ),
    [isSubmitting, isValid, resetForm, submitForm],
  )

  const messageField = useMemo(
    () => (
      <div className={'flex items-center justify-center pt-8 px-5'}>
        <div
          className={
            'border border-custom-gray-4 rounded-md overflow-hidden w-full h-full relative'
          }
        >
          <Field
            as={'textarea'}
            autoComplete='off'
            rows={5}
            placeholder={_t('segmentationBroadcastMessagePlaceholder')}
            name={BroadcastMessageFormEnum.Message}
            className='border-none text-lg w-full h-full focus:ring-0 resize-none p-3'
          />
          <MediaUploadWrapper
            uploadQueueKey={mediaUploadKey}
            onSetMediaUrl={value => {
              setFieldValue(BroadcastMessageFormEnum.MediaUrl, value)
            }}
            onSetMediaFileType={value => {
              setFieldValue(BroadcastMessageFormEnum.MediaFileType, value)
            }}
            media={values[BroadcastMessageFormEnum.MediaUrl]}
          >
            {() => <AddFileIcon className={'absolute z-10 left-2 bottom-2'} />}
          </MediaUploadWrapper>
        </div>
      </div>
    ),
    [setFieldValue, mediaUploadKey, values],
  )

  const addButton = useMemo(
    () => (
      <div className={'flex items-center justify-center pb-8 px-5 '}>
        <div
          className={
            'border border-custom-gray-4 rounded-md overflow-hidden w-full h-full relative p-3 flex flex-col gap-6'
          }
        >
          <div className={'flex flex-col gap-3'}>
            <div className={'flex justify-between'}>
              <p className={'text-custom-purple'}>
                {_t('sendBroadcastMessageAddLinkTitle')}
              </p>
              <label
                className={'relative inline-flex items-center cursor-pointer'}
              >
                <input
                  type={'checkbox'}
                  checked={addLink}
                  onChange={() => {
                    setAddLink(!addLink)
                    console.log('addLink', addLink)
                  }}
                  className={'sr-only'}
                />
                <div
                  className={`w-11 h-6 bg-gray-200 rounded-full transition-colors duration-300 '}`}
                ></div>
                <div
                  className={`absolute w-5 h-5 bg-custom-purple rounded-full shadow-md transition-left duration-300 ${
                    addLink ? 'left-5' : 'left-1'
                  }`}
                ></div>
              </label>
            </div>
            <p className={'text-custom-gray-3 text-xs'}>
              {_t('sendBroadcastMessageAddLinkDescription')}
            </p>
          </div>
          {addLink ? (
            <div className={'flex flex-col gap-6'}>
              <div className={'flex flex-col gap-1.5'}>
                <p>{_t('sendBroadcastMessageUrlTitle')}</p>
                <Field
                  as={'input'}
                  autoComplete='off'
                  rows={5}
                  placeholder={_t('sendBroadcastMessageUrlPlaceholder')}
                  name={BroadcastMessageFormEnum.LinkLabel}
                  className='border rounded-md text-lg w-full focus:ring-0 resize-none p-3'
                />
              </div>
              <div className={'flex flex-col gap-1.5'}>
                <p>{_t('sendBroadcastMessageUrlLabel')}</p>
                <Field
                  as={'input'}
                  autoComplete='off'
                  rows={5}
                  placeholder={_t('sendBroadcastMessageUrlLabelPlaceholder')}
                  name={BroadcastMessageFormEnum.Link}
                  className='border rounded-md text-lg w-full focus:ring-0 resize-none p-3'
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    ),
    [addLink],
  )
  return isSmallerDevice ? (
    <Drawer
      open={open}
      direction='bottom'
      onClose={handleClose}
      className={'overflow-y-auto !w-screen rounded-t-[12px] !h-full'}
    >
      <div className='flex flex-col divide-y  h-full w-full'>
        {header}
        <div className={'flex flex-col justify-between h-full relative'}>
          <div className={'flex flex-col gap-5'}>
            {messageField}
            {addButton}
          </div>
          <div
            onClick={handleClose}
            className={
              'absolute bottom-2 left-2 flex items-center justify-center bg-custom-navy-blue rounded-full w-12 h-12'
            }
          >
            <BackArrow fill={'white'} />
          </div>
        </div>
      </div>
    </Drawer>
  ) : (
    <Modal
      open={open}
      backgroundClassName='!bg-black/70 blur-none'
      mainContainerClassName='px-5'
      showClose={true}
      onClose={handleClose}
    >
      <div className='flex flex-col divide-y w-[593px]'>
        {header}
        <div className={'flex flex-col gap-5'}>
          {messageField}
          {addButton}
        </div>
      </div>
    </Modal>
  )
}

export default BroadcastMessagePopup
