import React, { useMemo, useState, useCallback } from 'react'
import Modal from '@components/modal/Modal'
import { _t } from '@locales/index'
import {
  USER_AGREEMENT_DESKTOP_HEIGHT,
  USER_AGREEMENT_DESKTOP_WIDTH,
  USER_AGREEMENT_MOBILE_HEIGHT,
  USER_AGREEMENT_URL,
} from '@const/userAgreement'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'

interface Props {
  agreementId: string
  title: string
  onAgree: () => void
  isOpen: boolean
}

const UserAgreement: React.FC<Props> = ({
  agreementId,
  title,
  onAgree,
  isOpen,
}) => {
  const [termsScroll, setTermsScroll] = useState<boolean>(true)
  const [isOver18, setIsOver18] = useState<boolean>(false)
  const [isAgree, setIsAgree] = useState<boolean>(false)
  const isSmallerDevice = useIsSmallerDevice()

  const src = useMemo(() => {
    return USER_AGREEMENT_URL + agreementId
  }, [agreementId])

  const handleScrollTerms = useCallback(() => {
    setTermsScroll(false)
  }, [])

  return (
    <Modal
      open={isOpen}
      onClose={() => {}}
      containerClassName='!w-full sm:!w-auto'
    >
      <div
        className='w-[100vw] h-[calc(var(--vh,_1vh)_*_100)] sm:w-auto sm:h-auto overflow-y-auto relative'
        onScroll={handleScrollTerms}
      >
        <h1 className='font-poppins font-bold text-2xl capitalize flex justify-center px-5 pt-5'>
          {title}
        </h1>
        <div
          className='p-8 justify-center relative sm:h-[600px] overflow-scroll no-scrollbar'
          onScroll={handleScrollTerms}
        >
          {termsScroll ? (
            <div
              className={
                'absolute bottom-0 bg-gradient-to-t from-white w-full h-96 pointer-events-none'
              }
            />
          ) : null}
          <iframe
            title='userAgreement'
            src={src}
            sandbox='allow-same-origin allow-scripts allow-popups'
            width={isSmallerDevice ? '100%' : USER_AGREEMENT_DESKTOP_WIDTH}
            height={
              isSmallerDevice
                ? USER_AGREEMENT_MOBILE_HEIGHT
                : USER_AGREEMENT_DESKTOP_HEIGHT
            }
            className={'no-scrollbar'}
          />
        </div>
        <div className={'flex justify-between items-center sticky bottom-0'}>
          <div className='flex flex-col justify-center items-center ml-8'>
            <div>
              <div className='flex row'>
                <div className='mb-2'>
                  <input
                    name='over18'
                    type='checkbox'
                    checked={isOver18}
                    onChange={() => setIsOver18(!isOver18)}
                    className='rounded-full border '
                  />
                </div>
                <h1 className='text-sm font-poppins font-bold sm:text-lg capitalize px-2'>
                  {_t('userAgreementOver18')}
                </h1>
              </div>

              <div className='flex row'>
                <div className='mb-2'>
                  <input
                    name='agree'
                    type='checkbox'
                    checked={isAgree}
                    onChange={() => setIsAgree(!isAgree)}
                    className='rounded-full border'
                  />
                </div>
                <h1 className='text-sm font-poppins font-bold sm:text-lg capitalize px-2'>
                  {_t('userAgreementAccept') + title}
                </h1>
              </div>
            </div>
          </div>
          <div className='flex justify-center p-5'>
            <button
              className='btn-base btn-pink-contained mx-2'
              type='button'
              onClick={onAgree}
              disabled={!isOver18 || !isAgree}
            >
              {_t('submit')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default UserAgreement
