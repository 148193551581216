import { Outlet, RouteObject } from 'react-router-dom'

import { CREATOR_PATH } from './creatorPath'
import NewAdmin from '@containers/creator/newAdmin/NewAdmin'
import NewAdminRedirect from '@containers/creator/newAdmin/NewAdminRedirect'
import AffiliatorLogIn from '@containers/creator/newAdmin/AffiliatorLogIn'
import AffiliatorRedirect from '@containers/creator/newAdmin/AffiliatorRedirect'

export const AFFILIATOR_ROUTES: RouteObject[] = [
  {
    path: CREATOR_PATH.newAdmin,
    element: <NewAdmin />,
  },

  {
    path: CREATOR_PATH.newAdminRedirect,
    element: <NewAdminRedirect />,
  },
  {
    path: CREATOR_PATH.affiliator,
    element: <AffiliatorLogIn />,
  },
  {
    path: CREATOR_PATH.affiliatorRedirect,
    element: <AffiliatorRedirect />,
  },
  {
    path: CREATOR_PATH.root,
    element: <Outlet />,
  },
  {
    path: CREATOR_PATH.onboarding,
    element: <Outlet />,
  },
  {
    path: CREATOR_PATH.communityId,
    element: <Outlet />,
  },
  {
    path: CREATOR_PATH.paymentsAndBilling,
    element: <Outlet />,
  },
  {
    path: CREATOR_PATH.uploadWelcomeCoverPhoto,
    element: <Outlet />,
  },
  {
    path: CREATOR_PATH.affiliatePage,
    element: <Outlet />,
  },
  {
    path: CREATOR_PATH.communityMembers,
    element: <Outlet />,
  },
]
