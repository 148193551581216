import { useQuery, UseQueryOptions } from 'react-query'
import { ServerStateKeysEnum } from '@enums/serverState'
import { useProtection } from '@hooks/auth/useProtection'
import apiService from '@services/api/apiService'
import { ApiKeyNameEnum } from '@enums/api'
import { AxiosError } from 'axios'
import { TypeAllCurrenciesResponse } from '@customTypes/currency'

/**********************************
 GET ALL CURRENCIES
 **********************************/

type TypeGetAllCurrenciesOptions = UseQueryOptions<
  TypeAllCurrenciesResponse,
  AxiosError,
  TypeAllCurrenciesResponse,
  any
>

const getAllCurrencies = async (): Promise<TypeAllCurrenciesResponse> => {
  try {
    const response = await apiService.get(ApiKeyNameEnum.currency)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetAllCurrencies = (options?: TypeGetAllCurrenciesOptions) => {
  const queryOptions = useProtection<TypeGetAllCurrenciesOptions>(options)

  return useQuery([ServerStateKeysEnum.Currencies], getAllCurrencies, {
    ...queryOptions,
  })
}
