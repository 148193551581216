import React from 'react'
import { _t } from '@locales/index'
import MemberRow from '@containers/creator/members/components/MemberRow'
import MemberRowSkeleton from '@containers/creator/members/components/MemberRowSkeleton'
import { TypeCommunityMember } from '@customTypes/community'

interface Props {
  members?: TypeCommunityMember[]
  isLoading: boolean
}

const thClass = 'capitalize font-poppins font-bold text-lg p-3 text-left'

const MembersList: React.FC<Props> = ({ members, isLoading }) => {
  return (
    <>
      <table className='table-auto bg-white'>
        <thead className='border-b'>
          <tr>
            <th className={thClass}>{_t('memberTableHead1')}</th>
            <th className={thClass}>{_t('memberTableHead2')}</th>
            {/*<th className={thClass}>{_t('memberTableHead3')}</th>*/}
            <th className={thClass}>{_t('memberTableHead4')}</th>
            <th className={thClass}></th>
          </tr>
        </thead>

        <tbody>
          {members &&
            members.map((member, idx) => (
              <MemberRow key={idx} member={member} />
            ))}
          {isLoading && <MemberRowSkeleton />}
        </tbody>
      </table>
    </>
  )
}

export default MembersList
