import { TypeNotification } from '@customTypes/notification'

export const DESCRIPTION_LIMIT = 150
export const COMMUNITY_DESCRIPTION_LIMIT = 250

export const MOCK_NOTIFICATION: TypeNotification = {
  title: 'this is an exmaple of a notifiaction you will recieve from us',
  content:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore',
  date: 'Today, 20:30',
  hasRead: false,
}

export const EXAMPLE_VIDEO_LINK =
  'https://zigi-general-assets.s3.amazonaws.com/7853ec2361964ee6a46e0f143dcfe75  b.mov'

export const EXAMPLE_SCRIPT_LINK =
  'https://zigi-general-assets.s3.amazonaws.com/welcome_video_example.pdf'
export const SUPPORT_EMAIL = 'support@wearegrouper.com'

export const PHONE_MOCKUP_URL = 'wearegrouper.com'

export const OPEN_TELEGRAM_APP_LINK_BASE = `tg://resolve`

export const OPEN_TELEGRAM_BROWSER_LINK_BASE = 'https://t.me/'

export const DOWNLOAD_TELEGRAM_DESKTOP_LINK = 'https://desktop.telegram.org/'

export const OPEN_TELEGRAM_WITH_CHANNEL_ID = 'https://t.me/c/provider_url/1'
export const TELEGRAM_APP_LINK = 'https://onelink.to/hymj3w'

export const MONTH_NAMES = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]
export const UUID_REGEXP =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
