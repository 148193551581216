import React, { useMemo } from 'react'

import { Outlet } from 'react-router-dom'
import 'react-modern-drawer/dist/index.css'

import Navbar from '@containers/creator/navbar/Navbar'
import useBreakpoint from '@hooks/responsive/useBreakpoint'
import { NAVBAR_HEIGHT } from '@const/layout'
import { BreakpointsEnum } from '@enums/theme'
import UserSidebar from '@containers/creator/sidebar/UserSidebar'

const UserLayout = () => {
  const breakpoint = useBreakpoint()
  const isSmallerDevice: boolean = useMemo(
    () => breakpoint === BreakpointsEnum.sm,
    [breakpoint],
  )

  return (
    <div className='flex flex-col overflow-hidden w-full h-full-screen'>
      <Navbar />
      <div
        className='flex-1 flex'
        style={{
          height: `calc(${
            isSmallerDevice ? 'var(--vh, 1vh) * 100' : '100%'
          } - ${NAVBAR_HEIGHT[breakpoint]}px)`,
        }}
      >
        <UserSidebar />
        <div className='flex-1 flex flex-col'>
          <div className='flex-1 overflow-y-auto'>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserLayout
