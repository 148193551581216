import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query'
import { ServerStateKeysEnum } from '@enums/serverState'
import {
  TypeAttachProfileToCommunity,
  TypeCommunity,
  TypeCommunityAdmin,
  TypeCommunityBase,
  TypeCommunityCreate,
  TypeCommunitySuppliedContentCreate,
  TypeCommunityTask,
  TypeCommunityTaskUpdate,
  TypeCommunityType,
  TypeCommunityUpdate,
  TypeGetCommunitiesResponse,
  TypeSuppliedContent,
  TypeUploadMembersCSV,
  TypeUploadMembersManually,
} from '@customTypes/community'
import { useProtection } from '@hooks/auth/useProtection'
import apiService from '@services/api/apiService'
import { ApiKeyNameEnum } from '@enums/api'
import { AxiosError } from 'axios'
import { useCallQueryAfterProfile } from '@hooks/auth/useCallQueryAfterProfile'

/**********************************
 GET COMMUNITY BY ID
 **********************************/

type TypeGetCommunityOptions = UseQueryOptions<
  TypeCommunity,
  AxiosError,
  TypeCommunity,
  any
>

const getCommunity = async (id: string | undefined): Promise<TypeCommunity> => {
  try {
    const response = await apiService.get(ApiKeyNameEnum.community, id)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetCommunity = (
  id: string | undefined,
  options?: TypeGetCommunityOptions,
) => {
  const queryOptions = useProtection<TypeGetCommunityOptions>(options)

  return useQuery([ServerStateKeysEnum.Community, id], () => getCommunity(id), {
    ...queryOptions,
  })
}

/**********************************
 GET ALL COMMUNITIES
 **********************************/

type TypeGetCommunitiesOptions = UseQueryOptions<
  TypeGetCommunitiesResponse,
  AxiosError,
  TypeCommunityBase[],
  any
>

const getCommunities = async (): Promise<TypeGetCommunitiesResponse> => {
  try {
    const response = await apiService.get(ApiKeyNameEnum.communities)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetCommunities = (options?: TypeGetCommunitiesOptions) => {
  let queryOptions = useProtection<TypeGetCommunitiesOptions>(options)

  queryOptions =
    useCallQueryAfterProfile<TypeGetCommunitiesOptions>(queryOptions)

  return useQuery([ServerStateKeysEnum.Community, 'all'], getCommunities, {
    select: data => data.communities,
    ...queryOptions,
  })
}

/**********************************
 CREATE COMMUNITY
 **********************************/

type TypeCommunityCreateOptions = UseMutationOptions<
  TypeCommunity,
  AxiosError,
  TypeCommunityCreate
>

const createCommunity = async (
  values: TypeCommunityCreate,
): Promise<TypeCommunity> => {
  try {
    const response = await apiService.post(ApiKeyNameEnum.community, values)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useCommunityCreate = (options?: TypeCommunityCreateOptions) => {
  const queryClient = useQueryClient()

  const queryOptions = useProtection<TypeCommunityCreateOptions>(options)

  return useMutation(createCommunity, {
    onSuccess: () => {
      return queryClient.invalidateQueries(ServerStateKeysEnum.Communities)
    },
    ...queryOptions,
  })
}

/**********************************
 UPDATE COMMUNITY
 **********************************/

type TypeCommunityUpdateOptions = UseMutationOptions<
  TypeCommunity,
  AxiosError,
  TypeCommunityUpdate
>

const updateCommunity = async (
  values: TypeCommunityUpdate,
): Promise<TypeCommunity> => {
  try {
    const response = await apiService.put(ApiKeyNameEnum.community, values)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useCommunityUpdate = (options?: TypeCommunityUpdateOptions) => {
  const queryClient = useQueryClient()

  const queryOptions = useProtection<TypeCommunityUpdateOptions>(options)

  return useMutation(updateCommunity, {
    onSuccess: () => {
      return queryClient.invalidateQueries(ServerStateKeysEnum.Community)
    },
    ...queryOptions,
  })
}

/**********************************
 PUBLISH COMMUNITY
 **********************************/
type TypeCommunityPublishOptions = UseMutationOptions<
  TypeCommunity,
  AxiosError,
  string // community id
>

const publishCommunity = async (
  communityId: string,
): Promise<TypeCommunity> => {
  try {
    const response = await apiService.post(ApiKeyNameEnum.publish_community, {
      communityId,
    })
    return response.data
  } catch (err) {
    throw err
  }
}

export const usePublishCommunityMutation = (
  options?: TypeCommunityPublishOptions,
) => {
  const queryClient = useQueryClient()

  const queryOptions = useProtection<TypeCommunityPublishOptions>(options)

  return useMutation(publishCommunity, {
    onSuccess: () => {
      return queryClient.invalidateQueries(ServerStateKeysEnum.Community)
    },
    ...queryOptions,
  })
}

/**********************************
 GET COMMUNITY TYPES
 **********************************/

type TypeGetCommunityTypesOptions = UseQueryOptions<
  TypeCommunityType[],
  AxiosError,
  TypeCommunityType[],
  any
>

const getCommunityTypes = async (): Promise<TypeCommunityType[]> => {
  try {
    const response = await apiService.get(ApiKeyNameEnum.community_types)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetCommunityTypes = (
  options?: TypeGetCommunityTypesOptions,
) => {
  const queryOptions = useProtection<TypeGetCommunityTypesOptions>(options)

  return useQuery([ServerStateKeysEnum.CommunityTypes], getCommunityTypes, {
    ...queryOptions,
  })
}

/**********************************
 GET SUPPLIED CONTENT
 **********************************/

type TypeGetSuppliedContentOptions = UseQueryOptions<
  TypeSuppliedContent[],
  AxiosError,
  TypeSuppliedContent[],
  any
>

const getSuppliedContent = async (): Promise<TypeSuppliedContent[]> => {
  try {
    const response = await apiService.get(ApiKeyNameEnum.supplied_content)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetSuppliedContent = (
  options?: TypeGetSuppliedContentOptions,
) => {
  const queryOptions = useProtection<TypeGetSuppliedContentOptions>(options)

  return useQuery([ServerStateKeysEnum.SuppliedContent], getSuppliedContent, {
    ...queryOptions,
  })
}

/******************************************
 CREATE COMMUNITY SUPPLIED CONTENT
 ******************************************/

type TypeCreateCommunitySuppliedContentOptions = UseMutationOptions<
  TypeCommunity,
  AxiosError,
  TypeCommunitySuppliedContentCreate
>

const createCommunitySuppliedContent = async (
  values: TypeCommunitySuppliedContentCreate,
): Promise<TypeCommunity> => {
  try {
    const response = await apiService.post(
      ApiKeyNameEnum.community_supplied_content,
      values,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useCommunityCreateSuppliedContent = (
  options?: TypeCreateCommunitySuppliedContentOptions,
) => {
  const queryOptions =
    useProtection<TypeCreateCommunitySuppliedContentOptions>(options)

  return useMutation(createCommunitySuppliedContent, {
    ...queryOptions,
  })
}

/******************************************
 CREATE COMMUNITY SUPPLIED CONTENT
 ******************************************/

type TypeUpdateCommunitySuppliedContentOptions = UseMutationOptions<
  TypeCommunity,
  AxiosError,
  TypeCommunitySuppliedContentCreate
>

const updateCommunitySuppliedContent = async (
  values: TypeCommunitySuppliedContentCreate,
): Promise<TypeCommunity> => {
  try {
    const response = await apiService.put(
      ApiKeyNameEnum.community_supplied_content,
      values,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useCommunityUpdateSuppliedContent = (
  options?: TypeUpdateCommunitySuppliedContentOptions,
) => {
  const queryOptions =
    useProtection<TypeUpdateCommunitySuppliedContentOptions>(options)

  return useMutation(updateCommunitySuppliedContent, {
    ...queryOptions,
  })
}

/**********************************
 GET COMMUNITY TASKS
 **********************************/

type TypeGetAllCommunityTasksOptions = UseQueryOptions<
  TypeCommunityTask[],
  AxiosError,
  TypeCommunityTask[],
  any
>

const getAllCommunityTasks = async (
  id: string | undefined,
): Promise<TypeCommunityTask[]> => {
  try {
    const response = await apiService.get(ApiKeyNameEnum.community_task, id)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetAllTasks = (
  id: string | undefined,
  options?: TypeGetAllCommunityTasksOptions,
) => {
  const queryOptions = useProtection<TypeGetAllCommunityTasksOptions>(options)

  return useQuery(
    [ServerStateKeysEnum.CommunityTasks, id],
    () => getAllCommunityTasks(id),
    {
      ...queryOptions,
    },
  )
}

/**********************************
 UPDATE COMMUNITY TASKS
 **********************************/

type TypeCommunityTasksUpdateOptions = UseMutationOptions<
  TypeCommunityTask,
  AxiosError,
  TypeCommunityTaskUpdate
>

const updateCommunityTask = async (
  values: TypeCommunityTaskUpdate,
): Promise<TypeCommunityTask> => {
  try {
    const response = await apiService.put(ApiKeyNameEnum.community_task, values)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useCommunityTaskUpdate = (
  options?: TypeCommunityTasksUpdateOptions,
) => {
  const queryClient = useQueryClient()

  const queryOptions = useProtection<TypeCommunityTasksUpdateOptions>(options)

  return useMutation(updateCommunityTask, {
    onSuccess: () => {
      return queryClient.invalidateQueries(ServerStateKeysEnum.CommunityTasks)
    },
    ...queryOptions,
  })
}

/**********************************
 ATTACH PROFILE TO COMMUNITY
 **********************************/

type TypeAttachProfileToCommunityOptions = UseMutationOptions<
  TypeCommunityAdmin,
  AxiosError,
  TypeAttachProfileToCommunity
>

const attachProfileToCommunity = async (
  values: TypeAttachProfileToCommunity,
): Promise<TypeCommunityAdmin> => {
  try {
    const response = await apiService.post(
      ApiKeyNameEnum.attach_profile_to_community,
      values,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useAttachProfileToCommunity = (
  options?: TypeAttachProfileToCommunityOptions,
) => {
  const queryClient = useQueryClient()

  return useMutation(attachProfileToCommunity, {
    onSuccess: () => {
      return queryClient.invalidateQueries(ServerStateKeysEnum.CommunityAdmin)
    },
    ...options,
  })
}

/******************************************
 IMPORT MEMBERS MANUALLY
 ******************************************/

type TypeUploadMembersManuallyOptions = UseMutationOptions<
  TypeCommunity,
  AxiosError,
  TypeUploadMembersManually
>

const uploadMembersManually = async (
  values: TypeUploadMembersManually,
): Promise<TypeCommunity> => {
  try {
    const response = await apiService.post(
      ApiKeyNameEnum.upload_members_manually,
      values,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useUploadMembersManually = (
  options?: TypeUploadMembersManuallyOptions,
) => {
  const queryOptions = useProtection<TypeUploadMembersManuallyOptions>(options)

  return useMutation(uploadMembersManually, {
    ...queryOptions,
  })
}

/******************************************
 IMPORT MEMBERS CSV
 ******************************************/

type TypeUploadMembersCSVOptions = UseMutationOptions<
  TypeCommunity,
  AxiosError,
  TypeUploadMembersCSV
>

const uploadMembersCSV = async (
  values: TypeUploadMembersCSV,
): Promise<TypeCommunity> => {
  try {
    const response = await apiService.uploadFile(
      ApiKeyNameEnum.upload_members_csv,
      `${values.profileId}/${values.communityId}/`,
      values.csv_file,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useUploadMembersCSV = (options?: TypeUploadMembersCSVOptions) => {
  const queryOptions = useProtection<TypeUploadMembersCSVOptions>(options)

  return useMutation(uploadMembersCSV, {
    ...queryOptions,
  })
}
