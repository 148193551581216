import React, { useCallback } from 'react'
import Modal from '@components/modal/Modal'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

interface Props {
  children: React.ReactNode
  isFullScreenOnMobile?: boolean
  onClose?: () => void
  showCloseIcon?: boolean
  isSubmitting?: boolean
}

const LunchCommunityWrapper: React.FC<Props> = ({
  children,
  onClose,
  showCloseIcon = false,
  isSubmitting = false,
  isFullScreenOnMobile = false,
}) => {
  const navigate = useNavigate()

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose()
      return
    }
    navigate('/')
  }, [navigate, onClose])

  return (
    <Modal
      open={true}
      containerClassName='shadow-2xl'
      backgroundClassName='!bg-white/80 blur-md'
      onClose={handleClose}
      mainContainerClassName={classNames(
        isFullScreenOnMobile ? 'sm:p-5' : 'p-5',
      )}
      showClose={showCloseIcon}
    >
      <div
        className={classNames(
          'bg-white rounded-xl filter-drop-shadow-1 relative',
          isFullScreenOnMobile &&
            'w-[100vw] sm:w-auto h-[calc(var(--vh,_1vh)_*_100)] sm:h-auto',
        )}
      >
        {children}
        {isSubmitting && (
          <div className='absolute inset-0 rounded-lg bg-custom-gray-6/90 opacity-50 blur-md' />
        )}
      </div>
    </Modal>
  )
}

export default LunchCommunityWrapper
