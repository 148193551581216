import React, { useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import { TypeExpandedMenuItem, TypeSubmenuItem } from '@customTypes/sidebar'

type Props = {
  menuItem: TypeSubmenuItem | TypeExpandedMenuItem
  paddingClass?: string
  onClick?: (menuItem: TypeSubmenuItem | TypeExpandedMenuItem) => void
}

const CommunityExpandedMenuItem: React.FC<Props> = ({
  menuItem,
  paddingClass,
  onClick,
}) => {
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(menuItem)
    }
  }, [menuItem, onClick])

  return (
    <NavLink
      to={menuItem.path || ''}
      className={({ isActive }) =>
        classNames(
          'h-16 flex items-center cursor-pointer gap-2.5 border-r border-custom-gray-5',
          isActive
            ? 'bg-custom-green-gray text-custom-turquoise fill-custom-turquoise'
            : 'bg-white text-custom-gray-1 fill-custom-gray-1',
          paddingClass || 'px-5 sm:px-3',
        )
      }
      onClick={handleClick}
    >
      <div className={classNames('w-6 h-6 sm:hidden')} />
      <p className='body3 capitalize text-inherit'>{menuItem.text}</p>
    </NavLink>
  )
}

export default CommunityExpandedMenuItem
