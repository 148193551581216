import { useGetCommunities } from '@api/queries/community'
import { useMemo } from 'react'
import { CommunityAdminRoleEnum } from '@enums/community'
import { useGetUserProfile } from '@api/queries/profile'

const useIsAffiliateProfile = (): boolean => {
  const { data: profile } = useGetUserProfile()
  const { data: communities } = useGetCommunities()

  return useMemo(() => {
    if (profile) {
      return Boolean(
        communities?.some(community => {
          return community.admins?.some(admin => {
            return (
              admin.role === CommunityAdminRoleEnum.Affiliate &&
              admin.profile.id === profile?.id
            )
          })
        }),
      )
    }
    return false
  }, [communities, profile])
}

export default useIsAffiliateProfile
