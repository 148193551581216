import React, { useCallback, useEffect, useMemo } from 'react'
import CommunityPageWrapper from '@containers/creator/community/components/CommunityPageWrapper'
import { _t } from '@locales/index'
import Task from '@containers/creator/communityHome/components/Task'
import { useNavigate } from 'react-router-dom'
import {
  useCommunityTaskUpdate,
  useGetAllTasks,
  useGetCommunity,
} from '@api/queries/community'
import { TypeCommunityTask } from '@customTypes/community'
import { toast } from 'react-hot-toast'
import useCopyToClipboard from '@hooks/common/useCopyToClipboard'
import { TaskActionTypeEnum, TaskCtaTargetEnum } from '@enums/common'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { useGetUserProfile } from '@api/queries/profile'
import { getAffiliateUrlByTypeAndProfile } from '@containers/creator/community/utils'
import { AffiliateLinkTypeEnum } from '@enums/affiliate'

const CommunityHome = () => {
  const navigate = useNavigate()
  const communityId = useAppSelector(selectCurrentCommunityId)
  const { copiedToClipboard, copyFn } = useCopyToClipboard()

  const { data: tasks, refetch } = useGetAllTasks(communityId, {
    enabled: Boolean(communityId),
  })
  const { data: community } = useGetCommunity(communityId, {
    enabled: Boolean(communityId),
  })

  const { data: profile } = useGetUserProfile()

  const affiliateUrl = useMemo(
    () =>
      getAffiliateUrlByTypeAndProfile(
        AffiliateLinkTypeEnum.CommunityProfile,
        profile?.id || '',
        community?.affiliateLinks || [],
      ),
    [community?.affiliateLinks, profile?.id],
  )

  useEffect(() => {
    if (copiedToClipboard) {
      toast.success(_t('copiedToClipboard'), {
        id: 'community-task-update',
      })
    }
  }, [copiedToClipboard])

  const { mutate: updateCommunityTask } = useCommunityTaskUpdate({
    onMutate: taskUpdate => {
      const task = tasks?.find(tsk => tsk.taskId === taskUpdate.taskId)
      if (
        task &&
        task.taskTemplate.taskActionType === TaskActionTypeEnum.Redirect
      ) {
        toast.loading(_t('redirectingCommunityTask'), {
          id: 'community-task-update',
        })
      }
    },
    onError: error => {
      toast.error(error.message, { id: 'community-task-update' })
    },
  })

  const handleClick = useCallback(
    (taskId: string) => {
      const task = tasks?.find(tsk => tsk.taskId === taskId)
      if (task && communityId) {
        const values = {
          taskId: taskId,
          communityId: communityId,
          taskState: task.state,
        }

        const isCopyToClipboardTask =
          task.taskTemplate.taskActionType ===
          TaskActionTypeEnum.CopyToClipboard

        updateCommunityTask(values, {
          onSuccess: async () => {
            if (isCopyToClipboardTask && affiliateUrl) {
              await copyFn(affiliateUrl)
              return
            }
            if (
              task.taskTemplate.ctaTarget === TaskCtaTargetEnum.CreatorPlatform
            ) {
              navigate(task.taskTemplate.targetUrl.replace(window.origin, ''))
            } else {
              window.open(task.taskTemplate.targetUrl)
            }
            toast.success(_t('updateCommunityTaskSuccess'), {
              id: 'community-task-update',
            })
            await refetch()
          },
        })
      }
    },
    [
      updateCommunityTask,
      copyFn,
      communityId,
      tasks,
      refetch,
      navigate,
      affiliateUrl,
    ],
  )

  return (
    <CommunityPageWrapper bgClass={'bg-custom-green-gray'}>
      <div className='bg-custom-green-gray p-5 pt-2 sm:p-13 flex flex-1 flex-col'>
        <div className='container-center flex flex-col gap-8 flex-1 items-center sm:items-start'>
          <h1 className='h4 capitalize font-bold sm:font-normal'>
            {_t('communityHomeTitle')}
          </h1>
          <h1 className='body2 max-w-[21.875rem] sm:max-w-[28.375rem] h-[6.5rem] text-center sm:text-left text-custom-gray-3'>
            {_t('communityHomeDescription')}
          </h1>
          <div className='flex gap-5 flex-wrap justify-center sm:justify-start'>
            {tasks?.map((task: TypeCommunityTask, idx) => (
              <Task communityTask={task} onClick={handleClick} key={idx} />
            ))}
          </div>
        </div>
      </div>
    </CommunityPageWrapper>
  )
}

export default CommunityHome
