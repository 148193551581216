import { useQuery, UseQueryOptions } from 'react-query'
import { ServerStateKeysEnum } from '@enums/serverState'
import { useAuth0 } from '@auth0/auth0-react'
import { useCallback } from 'react'
import { auth0Config } from '@services/auth0/auth0Config'
import apiService from '@services/api/apiService'
import { AxiosError } from 'axios'

export const useGetAccessToken = (
  options?: UseQueryOptions<string, AxiosError, string, any>,
) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()

  const getAccessToken = useCallback(async (): Promise<string> => {
    try {
      return await getAccessTokenSilently({
        audience: `https://${auth0Config['domain']}/api/v2/`,
        scope: 'read:current_user',
      })
    } catch (err) {
      console.log('token err')
      throw err
    }
  }, [getAccessTokenSilently])

  return useQuery(ServerStateKeysEnum.AccessToken, getAccessToken, {
    enabled: isAuthenticated,
    onSuccess: data => apiService.setAccessToken(data),
    staleTime: 60 * 1000 * 60, // 1 hour
    ...options,
  })
}
