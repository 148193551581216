import React, { useCallback, useMemo } from 'react'

import { Outlet } from 'react-router-dom'
import 'react-modern-drawer/dist/index.css'

import CommunitySidebar from '@containers/creator/sidebar/CommunitySidebar'
import Navbar from '@containers/creator/navbar/Navbar'
import useBreakpoint from '@hooks/responsive/useBreakpoint'
import { NAVBAR_HEIGHT } from '@const/layout'
import { BreakpointsEnum } from '@enums/theme'
import CommunityFloatingMenu from '@containers/creator/layouts/components/CommunityFloatingMenu'
import { useAppDispatch, useAppSelector } from '@hooks/store/redux'
import { openDrawerMenu } from '@reducers/drawerMenu'
import { DrawerMenuTypeEnum } from '@enums/common'
import { useAuth0 } from '@auth0/auth0-react'
import CreateAndEditGuideline from '@containers/creator/guidelines/CreateAndEditGuideline'
import { selectGuidelinesModalState } from '@selectors/guidelinesModal'
import { selectDeleteGuidelinesModalState } from '@selectors/deleteGuidelinesModal'
import ConfirmDeleteGuidelineModal from '@containers/creator/guidelines/components/ConfirmDeleteModal'
import Banner from '@containers/creator/banner/Banner'
import classNames from 'classnames'
import useShowCommunityMenu from '@hooks/common/useShowCommunityMenu'
import Loader from '@components/loader/Loader'

interface Props {
  bgClass?: string
}

const CommunityLayout: React.FC<Props> = ({ bgClass }) => {
  const dispatch = useAppDispatch()
  const breakpoint = useBreakpoint()
  const { isAuthenticated } = useAuth0()
  const showCommunityMenu = useShowCommunityMenu()
  const guidelinesModalState = useAppSelector(selectGuidelinesModalState)
  const deleteGuidelinesModalState = useAppSelector(
    selectDeleteGuidelinesModalState,
  )

  const isSmallerDevice: boolean = useMemo(
    () => breakpoint === BreakpointsEnum.sm,
    [breakpoint],
  )

  const handleClickShowCommunityMenu = useCallback(() => {
    dispatch(openDrawerMenu(DrawerMenuTypeEnum.Community))
  }, [dispatch])

  return (
    <div className='flex flex-col overflow-hidden w-full h-full-screen'>
      <Navbar showCommunitySwitcher />
      {showCommunityMenu && (
        <CommunityFloatingMenu onClickMenu={handleClickShowCommunityMenu} />
      )}
      <div
        className={classNames('flex-1 flex', bgClass)}
        style={{
          height: `calc(${
            isSmallerDevice ? 'var(--vh, 1vh) * 100' : '100%'
          } - ${NAVBAR_HEIGHT[breakpoint]}px)`,
        }}
      >
        <CommunitySidebar />
        <div className={classNames('flex-1 flex flex-col relative', bgClass)}>
          <Banner />
          <div className={classNames('flex flex-1 overflow-y-auto')}>
            {!isAuthenticated && (
              <div className='page items-center justify-center'>
                <Loader visible={true} />
              </div>
            )}
            <Outlet />
          </div>
        </div>
      </div>
      <CreateAndEditGuideline {...guidelinesModalState} />
      <ConfirmDeleteGuidelineModal {...deleteGuidelinesModalState} />
    </div>
  )
}

export default CommunityLayout
