import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TypePromoCode } from '@customTypes/promoCode'
import { ReactComponent as TrashCanIcon } from '@assets/images/common/trash.svg'
import { ReactComponent as Copy } from '@assets/images/common/copy-conent.svg'
import classNames from 'classnames'
import { _t } from '@locales/index'
import { DAYS_LEFT } from '@const/promoCode'
import { CouponsUtils } from '@containers/creator/coupons/utils'
import { PromoCodeStatusEnum } from '@enums/promoCode'
import moment from 'moment'

interface Props {
  coupon: TypePromoCode
  onRevokeClick: Function
}

const tdClass = 'px-5 py-3'
const CouponRow: React.FC<Props> = ({ coupon, onRevokeClick }) => {
  const [copiedToClipboard, setCopiedToClipboard] = useState<boolean>(false)
  const [couponDaysLeft, setCouponDaysLeft] = useState<number>(0)

  const date = useMemo(() => {
    return (
      moment(coupon.validFromDate).format('MMM D') +
      ' - ' +
      moment(coupon.validUntilDate).format('MMM D')
    )
  }, [coupon.validFromDate, coupon.validUntilDate])

  useEffect(() => {
    if (coupon.status === PromoCodeStatusEnum.Valid) {
      const daysLeft = CouponsUtils.handleDaysLeft(coupon)
      setCouponDaysLeft(daysLeft)
    }
  }, [coupon])

  const handleCopyClick = useCallback(() => {
    setCopiedToClipboard(true)
    setTimeout(() => {
      setCopiedToClipboard(false)
    }, 2 * 1000)
  }, [])

  return (
    <tr
      className={classNames(
        'border-none',
        coupon.status !== PromoCodeStatusEnum.Valid && 'bg-custom-gray-6',
      )}
    >
      <td className={tdClass}>
        <div className='flex items-center  w-full h-full gap-3'>
          {coupon.status === PromoCodeStatusEnum.Valid && (
            <button type='button' onClick={() => onRevokeClick()}>
              <TrashCanIcon
                fill={'#202020'}
                className='cursor-pointer w-6 h-6'
              />
            </button>
          )}
          <p
            className={classNames(
              'font-poppins text-base capitalize',
              coupon.status === PromoCodeStatusEnum.Valid
                ? 'text-custom-green-1'
                : coupon.status === PromoCodeStatusEnum.Revoked
                ? 'text-custom-red-2'
                : 'text-custom-gray-3',
            )}
          >
            {coupon.status === PromoCodeStatusEnum.Valid
              ? _t('promoCodeStatusValid')
              : coupon.status === PromoCodeStatusEnum.Revoked
              ? _t('promoCodeStatusRevoked')
              : _t('promoCodeStatusExpired')}
          </p>
        </div>
      </td>
      <td className={classNames(tdClass, 'relative')}>
        <div className='flex items-center w-full h-full gap-3'>
          {coupon.status === PromoCodeStatusEnum.Valid && (
            <Copy
              fill={'#202020'}
              className='cursor-pointer w-6 h-6'
              onClick={() => {
                navigator.clipboard.writeText(coupon.code).then(() => {
                  handleCopyClick()
                })
              }}
            />
          )}
          <p className='font-poppins text-base'>{coupon.code}</p>
        </div>
        {copiedToClipboard && (
          <div className='absolute rounded-full min-w-[150px] py-2.5 px-3 flex justify-center items-center gap-2 bg-custom-gray-3/[0.95]'>
            <p className='font-poppins text-xs capitalize text-white'>
              {_t('textCopiedToClipboard')}
            </p>
          </div>
        )}
      </td>
      <td className={classNames(tdClass, 'min-w-[230px]')}>
        <div className='flex items-center w-full h-full gap-3'>
          <p className='font-poppins text-base capitalize'>{date}</p>
        </div>
      </td>
      <td className={tdClass}>
        <div className='flex items-center justify-center w-full h-full gap-3'>
          <p
            className={classNames(
              'font-poppins text-base capitalize',
              couponDaysLeft > DAYS_LEFT
                ? 'text-custom-black'
                : 'text-custom-red-2',
            )}
          >
            {couponDaysLeft}
          </p>
        </div>
      </td>
      <td className={tdClass}>
        <div className='flex items-center justify-center w-full h-full gap-3'>
          <p className='font-poppins text-base capitalize'>
            {coupon.memberCount}
          </p>
        </div>
      </td>
      <td className={tdClass}>
        <div className='flex items-center justify-center w-full h-full gap-3'>
          <p className='font-poppins text-base capitalize'>{coupon.discount}</p>
        </div>
      </td>
    </tr>
  )
}

export default CouponRow
