import React, { useEffect, useMemo } from 'react'
import { Toaster } from 'react-hot-toast'
import { useGetAccessToken } from '@src/core/api/queries/auth'
import LoaderAnimation from '@assets/lottie-animations/loader.json'
import Lottie from 'lottie-react'
import { useAuth0 } from '@auth0/auth0-react'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'
import useBlockClickBack from '@hooks/handleBlockBack/useHandleClickBack'
import OrganizationFlow from '@containers/organization'
import { useCreateProfile, useGetUserProfile } from '@api/queries/profile'
import { CommunityGenreEnum } from '@enums/community'
import { useQueryClient } from 'react-query'
import { ServerStateKeysEnum } from '@enums/serverState'
import { useAppSelector } from '@hooks/store/redux'
import {
  selectNewAdminIsAffiliatorLoggedIn,
  selectNewAdminProfileId,
} from '@selectors/newAdmin'
import { useMatch, useParams } from 'react-router-dom'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import CommunityFlow from '@containers/creator'
import { useGetCommunities } from '@api/queries/community'
import useIsAffiliateProfile from '@hooks/common/useIsAffiliateProfile'

const App = () => {
  const queryClient = useQueryClient()
  const isSmallerDevice = useIsSmallerDevice()
  const { isAuthenticated, isLoading: isUserLoading } = useAuth0()
  const {
    data: profile,
    isFetching: isProfileBeingFetched,
    isLoading: isProfileLoading,
    isFetchedAfterMount: isProfileFetchedAfterMount,
  } = useGetUserProfile()
  const newAdminMatch = useMatch(CREATOR_PATH.newAdmin + '*')
  const { profileId: newAdminProfileIdFromParams } = useParams()
  const newAdminProfileId = useAppSelector(selectNewAdminProfileId)
  const isAffiliatorLoggedIn = useAppSelector(
    selectNewAdminIsAffiliatorLoggedIn,
  )

  const isAffiliateProfile = useIsAffiliateProfile()

  const { isLoading: isCommunitiesLoading } = useGetCommunities()

  const isNewAdminFlow = useMemo(
    () =>
      Boolean(
        newAdminProfileId ||
          (newAdminMatch && newAdminProfileIdFromParams) ||
          (isAffiliatorLoggedIn && !isAffiliateProfile),
      ),
    [
      newAdminProfileId,
      newAdminMatch,
      newAdminProfileIdFromParams,
      isAffiliatorLoggedIn,
      isAffiliateProfile,
    ],
  )

  const {
    mutate: createProfile,
    isLoading: isProfileBeingCreated,
    isError: isCreateProfileError,
  } = useCreateProfile()

  const profileLoadingOrBeingCreated = useMemo(
    () =>
      Boolean(
        isProfileBeingFetched || isProfileLoading || isProfileBeingCreated,
      ),
    [isProfileBeingCreated, isProfileBeingFetched, isProfileLoading],
  )

  // Create the profile if it doesn't exist already or is not being created
  useEffect(() => {
    if (
      !profile &&
      isProfileFetchedAfterMount &&
      !profileLoadingOrBeingCreated &&
      !isCreateProfileError &&
      !isNewAdminFlow
    ) {
      createProfile(undefined, {
        onSuccess: () => {
          queryClient.invalidateQueries([ServerStateKeysEnum.Profile]).then()
        },
      })
    }
  }, [
    createProfile,
    isNewAdminFlow,
    isCreateProfileError,
    isProfileFetchedAfterMount,
    profile,
    profileLoadingOrBeingCreated,
    queryClient,
  ])

  useBlockClickBack()
  useGetAccessToken()

  return (
    <div className='App'>
      {isAuthenticated &&
        (isUserLoading ||
          profileLoadingOrBeingCreated ||
          isCommunitiesLoading) && (
          <div className='w-screen h-full-screen flex items-center justify-center'>
            <Lottie animationData={LoaderAnimation} loop className='w-24' />
          </div>
        )}
      <>
        <Toaster
          position={'bottom-right'}
          containerStyle={{
            right: isSmallerDevice ? 15 : 40,
            bottom: isSmallerDevice ? 15 : 30,
          }}
        />
        {profile?.genere === CommunityGenreEnum.Organization ? (
          <OrganizationFlow />
        ) : (
          <CommunityFlow isNewAdminFlow={isNewAdminFlow} />
        )}
      </>
    </div>
  )
}

export default App
