import React, { useState } from 'react'
import { _t } from '@locales/index'
import { useGetCommunityMembersCount } from '@hooks/common/useGetCommunityMembersCount'
import BroadcastMessages from '@containers/organization/broadcastMessages/BroadcastMessages'
import { ReactComponent as MessageIcon } from '@assets/images/community/messenger-icon.svg'

const BroadcastButton = () => {
  const membersCount = useGetCommunityMembersCount()

  const [showPopup, setShowPopup] = useState<boolean>(false)

  if (!membersCount) {
    return null
  }
  return (
    <>
      <button
        className='btn btn-dark-blue rounded-full h-[30px] hidden sm:flex'
        onClick={() => setShowPopup(true)}
      >
        {_t('broadcastButton')}
      </button>
      <button
        className='btn btn-dark-blue rounded-full h-12 w-12 p-0 sm:hidden'
        onClick={() => setShowPopup(true)}
      >
        <MessageIcon className='!fill-none' />
      </button>
      <BroadcastMessages open={showPopup} onClose={() => setShowPopup(false)} />
    </>
  )
}

export default BroadcastButton
