import { TypeCommunityState, TypeSelector } from '@customTypes/store'
import { createSelector } from '@reduxjs/toolkit'
import { TypeMenuItem } from '@customTypes/sidebar'

const selectCommunityState: TypeSelector<TypeCommunityState> = state =>
  state.community

export const selectCurrentCommunityId: TypeSelector<string | undefined> =
  createSelector(selectCommunityState, state => state.selectedCommunityId)

export const selectCommunityMenuItem: TypeSelector<TypeMenuItem | undefined> =
  createSelector(selectCommunityState, state => state.selectedMenuItem)
