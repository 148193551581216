import { EnvironmentEnum } from '@enums/environment'
import { env } from '@src/env'
import { TypePayoutConfig } from '@customTypes/payout'

const localConfig: TypePayoutConfig = {
  paypalUrl: 'https://www.sandbox.paypal.com/connect/',
  paypalCallbackUrl: 'https://localhost:3000/user/account',
  paypalClientId:
    'AatBAdA4Lb2lRwZL2M0IGZ9wrLOjOjDP6TiCUlsxX0o0fl-g3pqB_FeleW8hmRkQMZ1Zy65mMXPtxKyi',
}

const developmentConfig: TypePayoutConfig = {
  paypalUrl: 'https://www.sandbox.paypal.com/connect/',
  paypalCallbackUrl: 'https://dev-management.wearegrouper.com/user/account',
  paypalClientId:
    'AatBAdA4Lb2lRwZL2M0IGZ9wrLOjOjDP6TiCUlsxX0o0fl-g3pqB_FeleW8hmRkQMZ1Zy65mMXPtxKyi',
}

const stagingConfig: TypePayoutConfig = {
  paypalUrl: 'https://www.sandbox.paypal.com/connect/',
  paypalCallbackUrl: 'https://staging-management.wearegrouper.com/user/account',
  paypalClientId:
    'AatBAdA4Lb2lRwZL2M0IGZ9wrLOjOjDP6TiCUlsxX0o0fl-g3pqB_FeleW8hmRkQMZ1Zy65mMXPtxKyi',
}

const productionConfig: TypePayoutConfig = {
  paypalUrl: 'https://www.paypal.com/connect/',
  paypalCallbackUrl: 'https://management.wearegrouper.com/user/account',
  paypalClientId:
    'AVwGj2ePnqbWa-dv_zGWClnVzLmmdiXyw6rxbwZU_owWctRf8gQclltuAJDWDgfFtF5UGoC2kwiip7FG',
}

const config = {
  [EnvironmentEnum.Local]: localConfig,
  [EnvironmentEnum.Development]: developmentConfig,
  [EnvironmentEnum.Staging]: stagingConfig,
  [EnvironmentEnum.Production]: productionConfig,
}

export const payoutConfig = config[env]

export const PAYPAL_CLIENT_ID = payoutConfig.paypalClientId

export const PAYPAL_URL = `${payoutConfig.paypalUrl}?flowEntry=static&client_id=${PAYPAL_CLIENT_ID}&redirect_uri=${payoutConfig.paypalCallbackUrl}&scope=openid profile email https://uri.paypal.com/services/paypalattributes`
