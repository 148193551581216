import { useQuery, UseQueryOptions } from 'react-query'
import { ServerStateKeysEnum } from '@enums/serverState'
import { useProtection } from '@hooks/auth/useProtection'
import apiService from '@services/api/apiService'
import { ApiKeyNameEnum } from '@enums/api'
import { AxiosError } from 'axios'
import { TypeOccupation } from '@customTypes/occupation'

/**********************************
 GET ALL OCCUPATIONS
 **********************************/

type TypeGetAllOccupationsOptions = UseQueryOptions<
  TypeOccupation[],
  AxiosError,
  TypeOccupation[],
  any
>

const getAllOccupations = async (): Promise<TypeOccupation[]> => {
  try {
    const response = await apiService.get(ApiKeyNameEnum.occupation)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetAllOccupations = (
  options?: TypeGetAllOccupationsOptions,
) => {
  const queryOptions = useProtection<TypeGetAllOccupationsOptions>(options)

  return useQuery([ServerStateKeysEnum.Occupations], getAllOccupations, {
    ...queryOptions,
  })
}

/**********************************
 GET OCCUPATIONS BY KEY
 **********************************/

type TypeGetOccupationsByKeyOptions = UseQueryOptions<
  TypeOccupation[],
  AxiosError,
  TypeOccupation[],
  any
>

const getOccupationsByKey = async (key?: string): Promise<TypeOccupation[]> => {
  try {
    const response = await apiService.get(ApiKeyNameEnum.occupation, key)
    return response?.data
  } catch (err) {
    throw err
  }
}

export const useGetOccupationsByKey = (
  searchKey: string,
  options?: TypeGetOccupationsByKeyOptions,
) => {
  const queryOptions = useProtection<TypeGetOccupationsByKeyOptions>(options)

  return useQuery(
    [ServerStateKeysEnum.Occupations, 'search', searchKey],
    () => getOccupationsByKey(searchKey),
    {
      ...queryOptions,
    },
  )
}
