import apiService from '@services/api/apiService'
import { ApiKeyNameEnum } from '@enums/api'
import {
  TypePromoCodeResponse,
  TypeCreatePromoCodeRequest,
  TypeCreatePromoCodeResponse,
} from '@customTypes/promoCode'
import { useProtection } from '@hooks/auth/useProtection'
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query'
import { ServerStateKeysEnum } from '@enums/serverState'
import { AxiosError } from 'axios'
import { PROMO_CODE_LENGTH } from '@const/promoCode'
import queryString from 'query-string'
import snakecaseKeys from 'snakecase-keys'

/**********************************
 GET PROMO CODES BY COMMUNITY
 **********************************/

type TypeGetPromoCodesOptions = UseQueryOptions<
  TypePromoCodeResponse,
  AxiosError,
  TypePromoCodeResponse,
  any
>

const getPromoCodes = async (
  communityId: string | undefined,
): Promise<TypePromoCodeResponse> => {
  try {
    const qs = '?community_id=' + communityId
    const response = await apiService.get(ApiKeyNameEnum.get_coupon_list, qs)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetPromoCodes = (
  id: string | undefined,
  options?: TypeGetPromoCodesOptions,
) => {
  const queryOptions = useProtection<TypeGetPromoCodesOptions>(options)
  return useQuery([ServerStateKeysEnum.Coupons, id], () => getPromoCodes(id), {
    ...queryOptions,
  })
}

/**********************************
 CREATE PROMO CODE
 **********************************/

type TypeCreatePromoCodeOptions = UseMutationOptions<
  TypeCreatePromoCodeResponse,
  AxiosError,
  TypeCreatePromoCodeRequest
>

const createPromoCode = async (
  values: TypeCreatePromoCodeRequest,
): Promise<TypeCreatePromoCodeResponse> => {
  try {
    const response = await apiService.post(ApiKeyNameEnum.create_coupon, values)
    return response?.data
  } catch (err) {
    console.log('create coupon error', err)
    throw err
  }
}

export const useCreatePromoCode = (options?: TypeCreatePromoCodeOptions) => {
  return useMutation(createPromoCode, {
    ...options,
  })
}

/**********************************
 REVOKE PROMO CODE
 **********************************/

type TypeRevokePromoCodeOptions = UseMutationOptions<
  TypeCreatePromoCodeResponse,
  AxiosError,
  string
>

const revokePromoCode = async (
  couponId: string,
): Promise<TypeCreatePromoCodeResponse> => {
  try {
    const qs = '?coupon_id=' + couponId
    const response = await apiService.deleteUrl(
      ApiKeyNameEnum.delete_coupon,
      qs,
    )
    return response?.data
  } catch (err) {
    throw err
  }
}

export const useRevokePromoCode = (options?: TypeRevokePromoCodeOptions) => {
  const queryOptions = useProtection<TypeRevokePromoCodeOptions>(options)

  return useMutation(revokePromoCode, {
    ...queryOptions,
  })
}

/**********************************
 VALIDATE COUPON
 **********************************/

type TypeValidatePromoCodeOptions = UseQueryOptions<
  boolean,
  AxiosError,
  boolean,
  any
>

const validatePromoCode = async (
  couponCode: string | undefined,
  communityId: string | undefined,
): Promise<boolean> => {
  try {
    if ((couponCode && couponCode.length < PROMO_CODE_LENGTH) || !couponCode) {
      return false
    }
    const queryParams = {
      code: couponCode,
      communityId: communityId,
    }
    const qs = queryString.stringify(snakecaseKeys(queryParams), {
      arrayFormat: 'comma',
      skipNull: true,
      skipEmptyString: true,
    })
    const response = await apiService.get(
      ApiKeyNameEnum.validate_coupon_code,
      `?${qs}`,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useValidatePromoCode = (
  code: string | undefined,
  communityId: string | undefined,
  options?: TypeValidatePromoCodeOptions,
) => {
  return useQuery(
    [ServerStateKeysEnum.Coupons, code],
    () => validatePromoCode(code, communityId),
    { ...options },
  )
}
