import React from 'react'

interface Props {
  nextButtonText: string
  previousButtonText: string
  nextButtonDisabled?: boolean
  onClickNext?: () => void
  onClickPrevious: () => void
}

const LaunchScreenFooter: React.FC<Props> = ({
  nextButtonText,
  previousButtonText,
  nextButtonDisabled = false,
  onClickPrevious,
  onClickNext,
}) => {
  return (
    <div className='p-5 w-full flex justify-between items-center border-t border-custom-gray-5 mt-auto'>
      <button
        className='btn btn-outlined'
        type='button'
        onClick={onClickPrevious}
      >
        {previousButtonText}
      </button>
      <button
        type='submit'
        className='btn btn-pink'
        disabled={nextButtonDisabled}
        onClick={onClickNext}
      >
        {nextButtonText}
      </button>
    </div>
  )
}

export default LaunchScreenFooter
