import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { _t } from '@locales/index'
import { CommunityFormEnum } from '@enums/community'
import { Form, Formik } from 'formik'
import { useGetCommunity } from '@api/queries/community'
import { useAppDispatch, useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { TypeCommunityWelcomeVideoFormValue } from '@customTypes/community'
import { communityWelcomeVideoSchema } from '@containers/creator/createCommunity/utils'
import { getCommunityMediaByType } from '@containers/creator/community/utils'
import { CommunityMediaTypeEnum, MediaTypeEnum } from '@enums/media'
import VideoUploadWrapper from '@components/videoUpload/VideoUploadWrapper'
import UploadService from '@services/uploader/uploadService'
import uploadService from '@services/uploader/uploadService'
import Loader from '@components/loader/Loader'
import { EXAMPLE_SCRIPT_LINK, EXAMPLE_VIDEO_LINK } from '@const/common'
import VideoPopup from '@containers/creator/community/components/VideoPopup'
import CommunityPageWrapper from '@containers/creator/community/components/CommunityPageWrapper'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import { useNavigate } from 'react-router-dom'
import Footer from '@components/footer/Footer'
import PhoneMockup from '@components/phoneMockup/PhoneMockup'
import { setCommunityMenuItem } from '@reducers/community'
import { LAYOUT_FOOTER } from '@const/layout'
import useBreakpoint from '@hooks/responsive/useBreakpoint'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'
import classNames from 'classnames'
import ConfirmWelcomeVideo from '@containers/creator/editCommunity/components/ConfirmWelcomeVideo'
import { ButtonTypeEnum } from '@enums/common'

const EditWelcomeVideo = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const breakpoint = useBreakpoint()
  const isSmallerDevice = useIsSmallerDevice()
  const communityId = useAppSelector(selectCurrentCommunityId)

  const [uploadQueueKey, setUploadQueueKey] = useState<string>('')
  const [showExampleVideo, setShowExampleVideo] = useState<boolean>(false)
  const [isMediaUploading, setIsMediaUploading] = useState<boolean>(false)
  const [showConfirmationPopup, setShowConfirmationPopup] =
    useState<boolean>(false)

  const { data: community, refetch: refetchCommunity } = useGetCommunity(
    communityId,
    {
      enabled: Boolean(communityId),
    },
  )

  const initialValues = useMemo<TypeCommunityWelcomeVideoFormValue>(() => {
    if (community?.media) {
      const media = getCommunityMediaByType(
        CommunityMediaTypeEnum.WelcomeVideo,
        community?.media,
      )
      if (media && media.length > 0) {
        return {
          [CommunityFormEnum.WelcomeVideo]: media[0].url,
        }
      }
    }
    return {
      [CommunityFormEnum.WelcomeVideo]: '',
    }
  }, [community?.media])
  const handleSwitchToPhotoClick = useCallback(() => {
    if (communityId) {
      navigate(CREATOR_PATH.uploadWelcomeCoverPhoto.replace(':id', communityId))
      dispatch(setCommunityMenuItem(undefined))
    }
  }, [communityId, dispatch, navigate])

  const handleSubmit = useCallback(() => {
    if (community) {
      if (uploadQueueKey) {
        setIsMediaUploading(true)
        uploadService.setQueueItemCallback(uploadQueueKey, async () => {
          await refetchCommunity()
          setIsMediaUploading(false)
        })
        uploadService.runQueue(uploadQueueKey)
      }
    }
  }, [community, refetchCommunity, uploadQueueKey])

  useEffect(() => {
    if (community) {
      const key = `community-welcome-video-${community.id}`
      UploadService.createQueueItem({
        type: MediaTypeEnum.CommunityMedia,
        postParams: {
          mediaType: MediaTypeEnum.CommunityMedia,
          communityId: community.id,
          subType: CommunityMediaTypeEnum.WelcomeVideo,
        },
        cb: null,
        key,
        uploadAutomatically: false,
      })
      setUploadQueueKey(key)
    }
  }, [community])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={communityWelcomeVideoSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isValid, setFieldValue, values, dirty, submitForm }) => (
        <Form className='flex flex-col flex-1'>
          <CommunityPageWrapper hasFooter>
            <div className='bg-custom-green-gray p-5 sm:p-13 flex flex-1 flex-col'>
              <div className='flex flex-col sm:flex-row gap-8 sm:justify-between'>
                <div className='flex flex-col gap-8'>
                  <div className='flex flex-col gap-3'>
                    <p className='h4 capitalize'>
                      {_t('editSignUpPageWelcomeVideoTitle')}
                    </p>
                    <p className='body2 capitalize sm:hidden'>
                      {_t('welcomeVideoInstruction3')}
                    </p>
                  </div>
                  <div className='flex flex-col items-center sm:items-start sm:bg-white sm:rounded-[20px] max-w-[440px] overflow-hidden'>
                    <div className='sm:py-13 sm:px-[116px]'>
                      <div className='w-[257px] h-[527px] sm:w-[206px] sm:h-[426px]'>
                        <PhoneMockup
                          community={community}
                          description={community?.description}
                          backgroundMediaUrl={
                            values[CommunityFormEnum.WelcomeVideo]
                          }
                          isVideo
                        />
                      </div>
                    </div>
                    <div className='bg-white justify-center gap-3 items-center h-[76px] hidden sm:flex w-full'>
                      <div className='w-auto'>
                        <VideoUploadWrapper
                          uploadQueueKey={uploadQueueKey}
                          onDropFile={value => {
                            setFieldValue(CommunityFormEnum.WelcomeVideo, value)
                          }}
                          video={values[CommunityFormEnum.WelcomeVideo]}
                        >
                          {() => (
                            <div className={'flex'}>
                              <button
                                className={classNames(
                                  'btn',
                                  dirty ? 'btn-outlined' : 'btn-pink',
                                )}
                                type={'button'}
                              >
                                {_t('welcomeVideoChangeButton')}
                              </button>
                            </div>
                          )}
                        </VideoUploadWrapper>
                      </div>
                      <button
                        className={'btn btn-outlined'}
                        type={'button'}
                        onClick={handleSwitchToPhotoClick}
                      >
                        {_t('imageEditSwitchToCoverPhoto')}
                      </button>
                    </div>
                  </div>
                </div>
                <div className='flex flex-col gap-3 sm:gap-6 self-end items-center sm:border border-custom-gray-5 rounded sm:p-5'>
                  <p className='caption capitalize font-normal sm:font-bold text-custom-gray-3'>
                    {_t('editWelcomeVideoNeedHelp')}
                  </p>
                  <div className='flex sm:flex-col items-center gap-3 sm:gap-2'>
                    <button
                      type={'button'}
                      className='btn btn-outlined bg-white rounded-full w-[157px] px-4'
                      onClick={() => setShowExampleVideo(true)}
                    >
                      {_t('welcomeVideoExampleVideoButton')}
                    </button>
                    <a
                      href={EXAMPLE_SCRIPT_LINK}
                      target={'_blank'}
                      rel='noreferrer noopener'
                      className='btn btn-outlined bg-white rounded-full w-[157px] px-4'
                    >
                      {_t('welcomeVideoExampleScriptButton')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </CommunityPageWrapper>
          {!isSmallerDevice && (
            <Footer
              primaryButton={{
                text: _t('confirm'),
                disabled: !dirty || !isValid,
                type: ButtonTypeEnum.Button,
                onClick: () => setShowConfirmationPopup(true),
              }}
              secondaryButton={{
                text: _t('cancel'),
                onClick: () =>
                  setFieldValue(
                    CommunityFormEnum.WelcomeVideo,
                    initialValues[CommunityFormEnum.WelcomeVideo],
                  ),
                hidden: !dirty,
              }}
            />
          )}
          <div
            className='bg-white justify-center gap-3 items-center flex sm:hidden'
            style={{
              height: LAYOUT_FOOTER[breakpoint],
            }}
          >
            {dirty ? (
              <>
                <button
                  className={'btn btn-outlined'}
                  type={'button'}
                  onClick={() =>
                    setFieldValue(
                      CommunityFormEnum.WelcomeVideo,
                      initialValues[CommunityFormEnum.WelcomeVideo],
                    )
                  }
                >
                  {_t('cancel')}
                </button>
                <button
                  className={'btn btn-pink'}
                  type={'button'}
                  onClick={() => setShowConfirmationPopup(true)}
                >
                  {_t('confirm')}
                </button>
              </>
            ) : (
              <>
                <button
                  className={'btn btn-outlined'}
                  type={'button'}
                  onClick={handleSwitchToPhotoClick}
                >
                  {_t('imageEditSwitchToCoverPhoto')}
                </button>
                <div className='w-auto'>
                  <VideoUploadWrapper
                    uploadQueueKey={uploadQueueKey}
                    onDropFile={value => {
                      setFieldValue(CommunityFormEnum.WelcomeVideo, value)
                    }}
                    video={values[CommunityFormEnum.WelcomeVideo]}
                  >
                    {() => (
                      <div className={'flex'}>
                        <button className='btn btn-pink' type={'button'}>
                          {_t('welcomeVideoChangeButton')}
                        </button>
                      </div>
                    )}
                  </VideoUploadWrapper>
                </div>
              </>
            )}
          </div>
          <VideoPopup
            url={EXAMPLE_VIDEO_LINK}
            open={showExampleVideo}
            onClose={() => setShowExampleVideo(false)}
          />
          <ConfirmWelcomeVideo
            visible={showConfirmationPopup}
            onSubmit={submitForm}
            onClose={() => setShowConfirmationPopup(false)}
          />
          <Loader visible={isMediaUploading} fullWidth />
        </Form>
      )}
    </Formik>
  )
}

export default EditWelcomeVideo
