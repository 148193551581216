import React, { useCallback, useEffect, useState } from 'react'
import {
  DropzoneOptions,
  DropzoneState,
  FileRejection,
  useDropzone,
} from 'react-dropzone'
import { toast } from 'react-hot-toast'
import { getAcceptedFileFormats } from '@services/uploader/uploadUtils'
import { MediaFileTypeEnum } from '@enums/media'
import ImageEditor from '@components/imageEditor/ImageEditor'
import { TypeCapturedImage } from '@customTypes/uploader'
import UploadService from '@services/uploader/uploadService'
import { getImageUploadError } from '@components/imageUpload/utils'
import CameraPopup from '@components/cameraPopup/CameraPopup'
import { TypeImageEditorType } from '@customTypes/common'

interface ImageUploadProps extends DropzoneState {
  imageSrc: string
  handleClickEdit: (e?: React.MouseEvent<HTMLElement>) => void
  handleClickOpenCamera: (e?: React.MouseEvent<HTMLElement>) => void
}

interface Props {
  uploadQueueKey: string
  postParams?: object
  cb?: () => void
  allowEdit?: boolean
  editorType?: TypeImageEditorType
  cropLabel?: string
  cropAspect?: number
  cropShape?: 'rect' | 'round'
  onCancel?: () => void
  image?: string
  onSetImageUrl?: (url: string) => void
  children: (props: ImageUploadProps) => React.ReactNode
  dropzoneOptions?: DropzoneOptions
}

const ImageUploadWrapper: React.FC<Props> = ({
  uploadQueueKey,
  postParams,
  cb,
  allowEdit = true,
  cropLabel,
  cropAspect = 1,
  cropShape = 'rect',
  onCancel,
  image,
  onSetImageUrl,
  children,
  dropzoneOptions,
  editorType,
}) => {
  const [imageSrc, setImageSrc] = useState<string>(image || '')
  const [imageFile, setImageFile] = useState<File>({} as File)
  const [showEditor, setShowEditor] = useState<boolean>(false)
  const [showCameraPopup, setShowCameraPopup] = useState<boolean>(false)

  const addToUploadQueue = useCallback(
    (file: File, imageUrl: string) => {
      onSetImageUrl && onSetImageUrl(imageUrl)
      UploadService.uploadQueueItem({
        key: uploadQueueKey,
        cb: cb || null,
        postParams: postParams || {},
        file: file,
      })
    },
    [cb, onSetImageUrl, postParams, uploadQueueKey],
  )

  const handleDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      // handle
      acceptedFiles.forEach(file => {
        const fileUrl = window.URL.createObjectURL(file)
        setImageSrc(fileUrl)

        setImageFile(file)
        if (allowEdit) {
          setShowEditor(true)
        } else {
          addToUploadQueue(file, fileUrl)
        }
      })

      // handle rejected files
      fileRejections.forEach(rejectedFile => {
        console.log('rejected file', rejectedFile)
        toast.error(getImageUploadError(rejectedFile['errors'][0]), {
          id: rejectedFile.errors[0].code,
        })
      })
    },
    [addToUploadQueue, allowEdit],
  )

  const handleCrop = useCallback(
    ({ imageFile: file, imageUrl }: TypeCapturedImage) => {
      addToUploadQueue(file, imageUrl)
      setShowEditor(false)
    },
    [addToUploadQueue],
  )

  const handleCapture = useCallback(
    ({ imageFile: file, imageUrl }: TypeCapturedImage) => {
      setImageSrc(imageUrl)
      if (allowEdit) {
        setShowEditor(true)
      } else {
        addToUploadQueue(file, imageUrl)
      }
    },
    [allowEdit, addToUploadQueue],
  )

  const handleCancel = useCallback(() => {
    setImageSrc(image ? image : '')
    setImageFile({} as File)
    setShowEditor(false)
    onCancel && onCancel()
  }, [image, onCancel])

  const dropzoneState = useDropzone({
    onDrop: handleDrop,
    maxFiles: 1,
    accept: getAcceptedFileFormats(MediaFileTypeEnum.Image),
    noClick: true,
    ...dropzoneOptions,
  })

  const handleClickEdit = useCallback((e?: React.MouseEvent<HTMLElement>) => {
    e?.stopPropagation()
    setShowEditor(true)
  }, [])

  const handleClickOpenCamera = useCallback(
    (e?: React.MouseEvent<HTMLElement>) => {
      e?.stopPropagation()
      setShowCameraPopup(true)
    },
    [],
  )

  useEffect(() => {
    if (image) {
      setImageSrc(image)
    }
  }, [image])

  return (
    <div>
      <div
        {...dropzoneState.getRootProps()}
        tabIndex={-1}
        className={'cursor-auto'}
      >
        {children({
          ...dropzoneState,
          imageSrc,
          handleClickEdit,
          handleClickOpenCamera,
        })}
        <input {...dropzoneState.getInputProps()} />
      </div>
      {showEditor && (
        <ImageEditor
          visible={showEditor}
          imageSrc={imageSrc}
          aspect={cropAspect}
          label={cropLabel}
          cropShape={cropShape}
          onCrop={handleCrop}
          onCancel={handleCancel}
          imageName={imageFile.name}
          editorType={editorType}
        />
      )}
      {showCameraPopup && (
        <CameraPopup
          onSubmit={handleCapture}
          visible={showCameraPopup}
          onClose={() => setShowCameraPopup(false)}
        />
      )}
    </div>
  )
}

export default ImageUploadWrapper
