import { useSearchParams } from 'react-router-dom'
import { _t } from '@locales/index'
import LogoWithText from '@assets/images/common/logo-with-text-black.svg'
import NewAdminBgUp from '@assets/images/common/new-admin-bg-up.svg'
import NewAdminBgDown from '@assets/images/common/new-admin-bg-down.svg'
import { useAuth0 } from '@auth0/auth0-react'
import React, { useCallback } from 'react'
import { CREATOR_PATH } from '@routes/creator/creatorPath'

const NewAdmin = () => {
  const [searchParams] = useSearchParams()
  const title = searchParams.get('title')
  const name = searchParams.get('name')
  const profileId = searchParams.get('profileId')
  const { loginWithRedirect } = useAuth0()

  const handleClick = useCallback(async () => {
    await loginWithRedirect({
      redirectUri: window.location.origin + CREATOR_PATH.newAdminRedirect,
      entity: 'profile',
      appState: {
        newAdmin: {
          profileId: profileId,
        },
      },
    })
  }, [loginWithRedirect, profileId])

  return (
    <div>
      <img className='w-full' src={NewAdminBgUp} alt='background-up' />
      <div className='flex flex-col items-center'>
        <div className='flex flex-col items-center gap-[3.75rem] px-5 pt-[2.021rem] pb-[3rem]'>
          <img
            src={LogoWithText}
            className='w-[7.178rem] h-8'
            alt='logo-with-text'
          />
          <div className='flex flex-col items-start gap-6'>
            <p className='text-[3rem] sm:text-7xl leading-[3.625rem] sm:leading-[5.4rem] font-pangram-light h2-pangram capitalize '>
              hi {name}!
            </p>
            <p className='text-[1.125rem] body1-pangram font-pangram-regular '>
              {_t('communityInviteLinkPara1')}{' '}
              <span className='font-pangram-bold capitalize'>{title}</span>.
            </p>
            <p className='text-[1.125rem] body1-pangram font-pangram-regular '>
              {_t('communityInviteLinkPara2')}
            </p>
          </div>
          <button
            className='w-[11.875rem] h-[2.688rem] bg-custom-purple text-white font-normal rounded-[3.125rem] gap-2.5 text-base leading-[1.188rem] sm:self-start'
            onClick={handleClick}
          >
            {_t('communityInviteLinkJoinButton')}
          </button>
        </div>
        <div className='sm:w-full flex flex-row justify-center'>
          <img
            src={NewAdminBgDown}
            className='sm:w-[40rem] h-fit self-center'
            alt='background-down'
          />
        </div>
      </div>
    </div>
  )
}

export default NewAdmin
