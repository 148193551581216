import { AppState, Auth0Provider } from '@auth0/auth0-react'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { auth0Config } from '@services/auth0/auth0Config'
import { useAppDispatch } from '@hooks/store/redux'
import { setNewAdmin } from '@reducers/newAdmin'
import { selectCommunity } from '@reducers/community'

interface Props {
  children: React.ReactNode
}

export const Auth0ProviderWithNavigate: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const onRedirectCallback = useCallback(
    (appState: AppState | undefined) => {
      dispatch(
        setNewAdmin({
          profileId: appState?.newAdmin?.profileId,
          communityRole: appState?.newAdmin?.communityRole,
          isAffiliatorLoggedIn: appState?.newAdmin?.isAffiliatorLoggedIn,
        }),
      )
      dispatch(selectCommunity(appState?.newAdmin?.communityId))
      navigate(appState?.returnTo || window.location.pathname)
    },
    [dispatch, navigate],
  )

  return (
    <Auth0Provider
      domain={auth0Config['domain']}
      clientId={auth0Config['clientId']}
      redirectUri={window.location.origin}
      audience={auth0Config['audience']}
      scope='read:current_user update:current_user_metadata'
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      cacheLocation='localstorage'
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithNavigate
