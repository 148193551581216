import { useMutation, UseMutationOptions } from 'react-query'
import apiService from '@services/api/apiService'
import { ApiKeyNameEnum } from '@enums/api'
import {
  TypeChangePasswordRequest,
  TypeChangePasswordResponse,
} from '@customTypes/user'

type TypeChangePasswordOptions = UseMutationOptions<
  TypeChangePasswordResponse,
  Error,
  TypeChangePasswordRequest
>

const changePassword = async (): Promise<TypeChangePasswordResponse> => {
  try {
    const response = await apiService.get(ApiKeyNameEnum.change_password)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useChangePassword = (options?: TypeChangePasswordOptions) => {
  return useMutation(changePassword, { ...options })
}
