import React, { useCallback, useMemo } from 'react'
import Avatar from '@components/avatar/Avatar'
import DropdownMenu from '@components/dropdownMenu/DropdownMenu'
import DropdownMenuItem from '@components/dropdownMenu/DropdownMenuItem'
import { _t } from '@locales/index'
import { useAuth0 } from '@auth0/auth0-react'
import { DropdownItemVariantEnum } from '@enums/theme'
import { MobileBottomDrawerTypeEnum } from '@enums/common'
import { useDispatch } from 'react-redux'
import { getProfileMediaByType } from '@containers/creator/profile/utils'
import { ProfileMediaTypeEnum } from '@enums/media'
import { useGetUserProfile } from '@api/queries/profile'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'
import { openMobileBottomDrawer } from '@reducers/mobileBottomDrawer'
import { setModalType } from '@reducers/modal'
import { ModalTypeEnum } from '@enums/modal'
import { ProfileOnboardingStatusEnum } from '@enums/profile'

interface Props {}

const UserMenu: React.FC<Props> = () => {
  const dispatch = useDispatch()
  const { user, logout } = useAuth0()
  const isSmallerDevice = useIsSmallerDevice()

  const { data: profile } = useGetUserProfile()

  const profileImageUrl = useMemo<string | undefined>(() => {
    if (profile) {
      const media = getProfileMediaByType(
        ProfileMediaTypeEnum.Profile,
        profile.media,
      )
      if (media) {
        return media?.[0]?.url || user?.picture || ''
      }
    }
  }, [profile, user?.picture])

  const avatar = useMemo(
    () => <Avatar imageUrl={profileImageUrl} name={user?.name || ''} />,
    [profileImageUrl, user?.name],
  )

  const isOnboarding = useMemo<boolean>(
    () => profile?.onboardingStatus === ProfileOnboardingStatusEnum.Initiated,
    [profile?.onboardingStatus],
  )

  const handleOpenDrawerMenu = useCallback(() => {
    dispatch(
      openMobileBottomDrawer({
        type: MobileBottomDrawerTypeEnum.NavigationMenu,
      }),
    )
  }, [dispatch])

  const handleClickLogout = useCallback(() => {
    logout({ returnTo: window.location.origin })
  }, [logout])

  if (isSmallerDevice) {
    return <div onClick={handleOpenDrawerMenu}>{avatar}</div>
  }

  return (
    <DropdownMenu triggerComponent={avatar} zIndex={12}>
      {!isOnboarding && (
        <DropdownMenuItem
          text={_t('importMembers')}
          onClick={() =>
            dispatch(setModalType(ModalTypeEnum.ImportMembersManually))
          }
        />
      )}
      <DropdownMenuItem
        text={_t('signOutOfApp')}
        onClick={handleClickLogout}
        variant={DropdownItemVariantEnum.Inverted}
      />
    </DropdownMenu>
  )
}

export default UserMenu
