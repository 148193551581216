import { CREATOR_PATH } from '@routes/creator/creatorPath'
import { _t } from '@locales/index'
import { TypeMenuItem } from '@customTypes/sidebar'

export const USER_MENU_ITEMS: TypeMenuItem[] = [
  {
    text: _t('userSidebarEditProfile'),
    path: CREATOR_PATH.profile,
  },
  {
    text: _t('userSidebarEditAccount'),
    path: CREATOR_PATH.account,
  },
]
