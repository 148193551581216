import React, { useCallback } from 'react'
import { _t } from '@locales/index'

import BottleImage from '@assets/images/common/bottle.png'
import LunchCommunityWrapper from '@containers/creator/launchCommunity/LaunchCommunityWrapper'
import { useNavigate, useParams } from 'react-router-dom'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import LaunchScreenFooter from '@containers/creator/launchCommunity/components/LaunchScreenFooter'

const CommunityApproved: React.FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const handleSkip = useCallback(() => {
    if (id) {
      navigate(CREATOR_PATH.communityId.replace(':id', id))
    } else {
      navigate('/')
    }
  }, [id, navigate])

  const handlePublish = useCallback(() => {
    if (id) {
      navigate(CREATOR_PATH.publishCommunity.replace(':id', id))
    }
  }, [id, navigate])

  return (
    <LunchCommunityWrapper onClose={handleSkip}>
      <div className='flex flex-col'>
        <div className='flex-1 flex items-center justify-center py-13 px-26'>
          <div>
            <p className='h4'>{_t('communityApprovalText1')}</p>
            <p className='h1'>{_t('communityApprovalText2')}</p>
          </div>
          <img src={BottleImage} className='w-[206px]' alt='bottle' />
        </div>
        <LaunchScreenFooter
          nextButtonText={_t('publishCommunity')}
          previousButtonText={_t('skip')}
          onClickNext={handlePublish}
          onClickPrevious={handleSkip}
        />
      </div>
    </LunchCommunityWrapper>
  )
}

export default CommunityApproved
