import React, { useCallback, useState } from 'react'

import moment from 'moment'
import Lottie from 'lottie-react'
import { _t } from '@locales/index'
import { useNavigate } from 'react-router-dom'

import ScissorsImage from '@assets/images/common/scissors.png'
import ConfettiAnimation from '@assets/lottie-animations/confetti.json'
import LunchCommunityWrapper from '@containers/creator/launchCommunity/LaunchCommunityWrapper'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import { useAppSelector } from '@hooks/store/redux'
import { selectLaunchDateModalDate } from '@selectors/launchDateModal'
import { selectCurrentCommunityId } from '@selectors/community'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'

const LaunchDateCreated = () => {
  const navigate = useNavigate()
  const isSmallerDevice = useIsSmallerDevice()
  const id = useAppSelector(selectCurrentCommunityId)
  const launchDate = useAppSelector(selectLaunchDateModalDate(id))

  const [isAnimationComplete, setIsAnimationComplete] = useState<boolean>(false)

  const handleClose = useCallback(() => {
    if (id) {
      navigate(CREATOR_PATH.communityId.replace(':id', id))
    }
  }, [id, navigate])

  return (
    <LunchCommunityWrapper
      onClose={handleClose}
      showCloseIcon={!isSmallerDevice}
    >
      <div className='h-full p-10 py-5 sm:p-13 flex flex-wrap justify-center items-center gap-13 relative'>
        {!isAnimationComplete && (
          <Lottie
            animationData={ConfettiAnimation}
            className='absolute inset-0 h-full w-full'
            loop={1}
            onComplete={() => setIsAnimationComplete(true)}
          />
        )}
        <div className='flex flex-col items-center sm:items-start'>
          <h4 className='h5 sm:h5'>{_t('launchDateCreatedTitle')}</h4>
          <h1 className='h2 font-light sm:h1 text-center'>
            {moment(launchDate).format('dddd, MMM DD')}
          </h1>
        </div>
        <div className='flex flex-col gap-13 items-center'>
          <img
            src={ScissorsImage}
            alt='scissors'
            className='w-26 sm:w-[200px] h-26 sm:h-[200px]'
          />
          <button className='btn btn-pink sm:hidden' onClick={handleClose}>
            {_t('launchDateCreatedButton')}
          </button>
        </div>
      </div>
    </LunchCommunityWrapper>
  )
}

export default LaunchDateCreated
