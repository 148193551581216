import React, { useCallback } from 'react'
import { useAppDispatch } from '@hooks/store/redux'
import { setCommunityMenuItem } from '@reducers/community'
import Skeleton from '@components/skeleton/Skeleton'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { SIDEBAR_WIDTH } from '@const/layout'
import { TypeMenuItem } from '@customTypes/sidebar'
import { useGetUserProfile } from '@api/queries/profile'
import { _t } from '@locales/index'
import { ReactComponent as ArrowIcon } from '@assets/images/common/arrow-2.svg'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import CommunityMenuItem from '@containers/creator/sidebar/components/CommunityMenuItem'
import { USER_MENU_ITEMS } from '@const/user'

const UserSidebar = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { isLoading } = useGetUserProfile()

  const handleClickMenuItem = useCallback(
    (menuItem: TypeMenuItem) => {
      dispatch(setCommunityMenuItem(menuItem))
    },
    [dispatch],
  )

  return (
    <div className='hidden sm:flex'>
      <div
        className={classNames(
          'bg-white max-h-screen h-full border-r border-custom-gray-5 relative transition-all ease-linear z-10 ',
        )}
        style={{
          width: SIDEBAR_WIDTH,
        }}
      >
        {isLoading && <Skeleton />}
        <div className='flex flex-col h-full no-scrollbar w-[inherit]'>
          <div
            className='w-full h-12 flex items-center gap-2 px-3 border-b border-custom-turquoise-dark cursor-pointer'
            onClick={() => navigate(CREATOR_PATH.root)}
          >
            <ArrowIcon className='fill-custom-turquoise-dark w-4 h-4 rotate-180' />
            <p className='body2 capitalize text-custom-turquoise-dark'>
              {_t('userSidebarMenuTitle')}
            </p>
          </div>
          {USER_MENU_ITEMS.map((menuItem, i) => (
            <CommunityMenuItem
              key={i}
              menuItem={menuItem}
              onClick={handleClickMenuItem}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default UserSidebar
