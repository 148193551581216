import React, { useCallback, useMemo } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { _t } from '@locales/index'
import UserMenu from '@containers/creator/navbar/components/UserMenu'
import Logo from '@containers/creator/navbar/components/Logo'
import { NAVBAR_HEIGHT } from '@const/layout'
import useBreakpoint from '@hooks/responsive/useBreakpoint'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import CommunitySwitcher from '@containers/creator/navbar/components/CommunitySwitcher'
import WalletLogo from '@assets/images/common/wallet.svg'
import { BreakpointsEnum } from '@enums/theme'

interface Props {
  showCommunitySwitcher?: boolean
}

const Navbar: React.FC<Props> = ({ showCommunitySwitcher = false }) => {
  const navigate = useNavigate()
  const breakpoint = useBreakpoint()
  const isMobile: boolean = useMemo(
    () => breakpoint < BreakpointsEnum.md,
    [breakpoint],
  )
  const { isAuthenticated, loginWithRedirect } = useAuth0()
  const handleClickLogo = useCallback(() => {
    navigate(CREATOR_PATH.root)
  }, [navigate])

  const handleClickWallet = useCallback(() => {
    navigate(CREATOR_PATH.wallet)
  }, [navigate])

  return (
    <div
      className='relative min-w-max flex justify-between items-center px-5 border-b border-custom-gray-5'
      style={{
        height: NAVBAR_HEIGHT[breakpoint],
      }}
    >
      <Logo onClick={handleClickLogo} />

      {isAuthenticated && (
        <div>
          {showCommunitySwitcher && isMobile && (
            <div className='mx-4'>
              <CommunitySwitcher />
            </div>
          )}
        </div>
      )}

      {isAuthenticated && (
        <div className='flex gap-4'>
          {!isMobile && (
            <img
              className='cursor-pointer'
              src={WalletLogo}
              alt={''}
              onClick={handleClickWallet}
            />
          )}
          <UserMenu />
        </div>
      )}

      {!isAuthenticated && (
        <p
          className='font-sans cursor-pointer capitalize ml-auto'
          onClick={loginWithRedirect}
        >
          {_t('logIn')}
        </p>
      )}
    </div>
  )
}

export default Navbar
