import React, { useCallback } from 'react'
import { _t } from '@locales/index'
import { ProfileFormEnum } from '@enums/profile'
import InputField from '@components/form/InputField'
import TextareaField from '@components/form/TextareaField'
import Occupation from '@containers/creator/profile/components/Occupation'
import { useFormikContext } from 'formik'
import { TypeEditProfileFormValues } from '@customTypes/profile'
import {
  useGetUserProfile,
  useUpdateProfile,
  useUpdateProfileOccupation,
} from '@api/queries/profile'
import ProfileImage from '@components/profileImage/ProfileImage'
import Loader from '@components/loader/Loader'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'

interface Props {
  isUpdating: boolean
  isMediaUploading: boolean
  queueKey: string
  uploadMedia: () => void
}

const EditProfile: React.FC<Props> = ({
  isUpdating,
  isMediaUploading,
  queueKey,
  uploadMedia,
}) => {
  const isSmallerDevice = useIsSmallerDevice()
  const { values, setFieldValue, initialValues } =
    useFormikContext<TypeEditProfileFormValues>()

  const { data: profile, refetch: refetchProfile } = useGetUserProfile()

  const { mutate: updateProfile, isLoading: isProfileBeingUpdated } =
    useUpdateProfile({
      onSuccess: async () => {
        await refetchProfile()
      },
    })

  const {
    mutate: updateProfileOccupation,
    isLoading: isOccupationBeingUpdated,
  } = useUpdateProfileOccupation({
    onSuccess: async () => {
      await refetchProfile()
    },
  })

  const handleNameFieldBlur = useCallback(() => {
    if (isSmallerDevice) {
      return
    }
    if (
      profile &&
      values[ProfileFormEnum.Name] &&
      values[ProfileFormEnum.Name] !== initialValues[ProfileFormEnum.Name]
    ) {
      updateProfile({
        name: values[ProfileFormEnum.Name],
        profileId: profile.id,
      })
    }
  }, [initialValues, isSmallerDevice, profile, updateProfile, values])

  const handleBioFieldBlur = useCallback(() => {
    if (isSmallerDevice) {
      return
    }
    if (
      profile &&
      values[ProfileFormEnum.Bio] &&
      values[ProfileFormEnum.Bio] !== initialValues[ProfileFormEnum.Bio]
    ) {
      updateProfile({
        bio: values[ProfileFormEnum.Bio],
        profileId: profile.id,
      })
    }
  }, [initialValues, isSmallerDevice, profile, updateProfile, values])

  const handleOccupationFieldBlur = useCallback(() => {
    if (isSmallerDevice) {
      return
    }
    if (
      profile &&
      values[ProfileFormEnum.Occupation]?.id &&
      values[ProfileFormEnum.Occupation]?.id !==
        initialValues[ProfileFormEnum.Occupation]?.id
    ) {
      updateProfileOccupation({
        occupationId: values[ProfileFormEnum.Occupation]?.id || '',
        profileId: profile.id,
      })
    }
  }, [initialValues, isSmallerDevice, profile, updateProfileOccupation, values])

  const handleProfileImageFieldBlur = useCallback(() => {
    if (isSmallerDevice) {
      return
    }
    if (
      profile &&
      values[ProfileFormEnum.ProfileImage] &&
      values[ProfileFormEnum.ProfileImage] !==
        initialValues[ProfileFormEnum.ProfileImage]
    ) {
      uploadMedia()
    }
  }, [initialValues, isSmallerDevice, profile, uploadMedia, values])

  return (
    <div className='flex flex-col'>
      <div className='flex flex-col gap-8'>
        <h5 className='h5 capitalize'>{_t('editProfileTitle')}</h5>
        <div className='flex flex-col sm:flex-col-reverse gap-5 sm:gap-8 sm:p-5 bg-transparent sm:bg-white rounded-xl relative'>
          <div className='flex gap-2.5 w-full justify-center sm:justify-start'>
            <ProfileImage
              uploadKey={queueKey}
              onSetImageUrl={src =>
                setFieldValue(ProfileFormEnum.ProfileImage, src)
              }
              image={values[ProfileFormEnum.ProfileImage]}
              onBlur={handleProfileImageFieldBlur}
              showEditIcon={isSmallerDevice}
            />
          </div>
          <div className='flex-col sm:flex-row-reverse flex gap-5 sm:gap-8'>
            <div className='flex flex-1'>
              <TextareaField
                name={ProfileFormEnum.Bio}
                id={ProfileFormEnum.Bio}
                label={_t('profileFormBioFieldLabel')}
                placeholder={_t('profileFormBioFieldPlaceholder')}
                hideErrorMessage
                onBlur={handleBioFieldBlur}
              />
            </div>
            <div className='flex flex-col gap-5'>
              <InputField
                name={ProfileFormEnum.Name}
                id={ProfileFormEnum.Name}
                label={_t('profileFormNameFieldLabel')}
                hideErrorMessage
                className='h-[52px] capitalize'
                onBlur={handleNameFieldBlur}
              />
              <div className='flex flex-col gap-2'>
                <label
                  htmlFor={ProfileFormEnum.Occupation}
                  className='body2 text-custom-gray-3 capitalize'
                >
                  {_t('profileFormOccupationFieldLabel')}
                </label>
                <Occupation
                  value={values[ProfileFormEnum.Occupation]}
                  setValue={value =>
                    setFieldValue(ProfileFormEnum.Occupation, value)
                  }
                  onBlur={handleOccupationFieldBlur}
                />
              </div>
            </div>
          </div>
          <Loader
            visible={
              isUpdating ||
              isProfileBeingUpdated ||
              isOccupationBeingUpdated ||
              isMediaUploading
            }
            fullWidth
          />
        </div>
      </div>
    </div>
  )
}

export default EditProfile
