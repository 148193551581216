import queryString from 'query-string'
import { OPEN_TELEGRAM_APP_LINK_BASE } from '@const/common'

const openInNewTab = (url: string) => {
  window.open(url, '_blank', 'noopener,noreferrer')
}

const openBotInBrowser = (url: string, domain: string, start: string) => {
  const link = url + domain + '?start=' + start
  window.open(link, '_blank', 'noopener,noreferrer')
}

const generateLinkQueryParams = (start: string, domain: string) => {
  const queryParams = {
    start: start,
    domain: domain,
  }

  return queryString.stringify(queryParams, {
    skipNull: true,
    skipEmptyString: true,
  })
}

const generateOpenMemberBotLink = (start: string, domain: string) => {
  const qs: string = generateLinkQueryParams(start, domain)
  return OPEN_TELEGRAM_APP_LINK_BASE + '?' + qs
}
const getStartFromProfileLink = (link: string) => {
  const start = link.slice(link.indexOf('start=') + 5, link.length)
  return start
}

const getDomainFromProfileLink = (link: string) => {
  const domain = link.slice(link.indexOf('t.me/') + 5, link.indexOf('?'))
  return domain
}

export const telegramPageUtils = {
  openInNewTab,
  generateLinkQueryParams,
  generateOpenMemberBotLink,
  openBotInBrowser,
  getStartFromProfileLink,
  getDomainFromProfileLink,
}
