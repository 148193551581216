export enum CommunityAdminRoleEnum {
  Owner = 'OW',
  Creator = 'CR',
  Guest = 'GU',
  Affiliate = 'AF',
}

export enum CommunityMemberRoleEnum {
  Viewer = 'VI',
  Support = 'SU',
}

export enum CommunityApprovalStatusEnum {
  AwaitingReview = 'WR',
  Blocked = 'BL',
  Approved = 'AP',
}

export enum CommunityFormEnum {
  Type = 'type',
  SuppliedContent = 'suppliedContent',
  Currency = 'currency',
  Price = 'price',
  Title = 'title',
  Profile = 'profile',
  WelcomeVideo = 'welcomeVideo',
  CoverPhoto = 'coverPhoto',
  Guidelines = 'guidelines',
}

export enum PublishCommunityFormEnum {
  PublishNow = 'PublishNow',
  SetLaunchDate = 'SetLaunchDate',
  LaunchDate = 'LaunchDate',
}

export enum CommunityProviderStatusEnum {
  Empty = 'EM',
  Processing = 'PR',
  Complete = 'CO',
}

export enum CommunityGenreEnum {
  Community = 'CO',
  Organization = 'OG',
}
