import React, { useCallback, useEffect } from 'react'
import { useMatch, useNavigate, useRoutes } from 'react-router-dom'
import { COMMUNITY_OWNER_ROUTES } from '@routes/creator/communityOwnerRoutes'
import { useGetCommunities } from '@api/queries/community'
import { useGetUserProfile } from '@api/queries/profile'
import useCheckCommunityApproval from '@hooks/common/useCheckCommunityApproval'
import { selectCommunity } from '@reducers/community'
import { useAppDispatch } from '@hooks/store/redux'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import UserAgreementWrapper from '@containers/common/userAgreement/UserAgreementWrapper'
import { ProfileOnboardingStatusEnum } from '@enums/profile'
import MobileDrawerMenu from '@containers/common/mobileDrawerMenu/MobileDrawerMenu'
import MobileBottomDrawer from '@containers/common/mobileBottomDrawer/MobileBottomDrawer'
import { CommunityAdminRoleEnum } from '@enums/community'
import { LocalStorageTypeEnum } from '@enums/localStorage'
import { getLocalStorageCommunityAdminKey } from '@containers/creator/community/utils'
import SetLanguagePopup from '@components/setLanguage/SetLanguagePopup'
import useSetLanguage from '@hooks/language/useSetLanguage'
import useIsAffiliateProfile from '@hooks/common/useIsAffiliateProfile'

const CommunityOwnerFlow = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isAffiliateProfile = useIsAffiliateProfile()
  const mainRoutes = useRoutes(COMMUNITY_OWNER_ROUTES)
  const communityIdMatch = useMatch(CREATOR_PATH.communityId + '*')
  const createCommunityMatch = useMatch(CREATOR_PATH.createCommunity)
  const telegramMatch = useMatch(CREATOR_PATH.telegramPage + '/*')
  useSetLanguage()

  useCheckCommunityApproval()

  const { data: profile } = useGetUserProfile()
  const { data: communities } = useGetCommunities()

  useEffect(() => {
    // redirect to onboarding if it isn't completed
    if (
      profile?.onboardingStatus &&
      [
        ProfileOnboardingStatusEnum.Initiated,
        ProfileOnboardingStatusEnum.ProfileCreated,
      ].includes(profile?.onboardingStatus) &&
      !isAffiliateProfile
    ) {
      const currentCommunityFromStorage = localStorage.getItem(
        LocalStorageTypeEnum.CurrentCommunity,
      )
      const communityAdminKey = currentCommunityFromStorage
        ? getLocalStorageCommunityAdminKey(
            currentCommunityFromStorage,
            profile.id,
          )
        : null
      const storageCommunityAdminRole = communityAdminKey
        ? localStorage.getItem(communityAdminKey)
        : null
      if (
        createCommunityMatch &&
        profile.onboardingStatus === ProfileOnboardingStatusEnum.Initiated
      ) {
        return
      }
      if (telegramMatch) {
        return
      }
      if (storageCommunityAdminRole !== CommunityAdminRoleEnum.Affiliate) {
        return navigate(CREATOR_PATH.onboarding)
      }
    }
  }, [
    createCommunityMatch,
    isAffiliateProfile,
    navigate,
    profile,
    telegramMatch,
  ])

  // select the right community
  useEffect(() => {
    if (communities) {
      if (
        communityIdMatch &&
        communityIdMatch.params &&
        communityIdMatch.params.id
      ) {
        const id = communityIdMatch.params.id
        const hasId = communities.some(c => c.id === id)
        if (hasId) {
          dispatch(selectCommunity(id))
        }
      } else {
        dispatch(selectCommunity(communities[0].id))
      }
    }
  }, [communities, dispatch, communityIdMatch])

  const useUserSelectLanguage = useCallback(() => {
    return localStorage.getItem(LocalStorageTypeEnum.IsSelectedLanguage)
  }, [])

  return (
    <div className='App'>
      {useUserSelectLanguage() ? (
        <>
          {mainRoutes}
          <UserAgreementWrapper />
          <MobileDrawerMenu />
          <MobileBottomDrawer />
        </>
      ) : (
        <SetLanguagePopup />
      )}
    </div>
  )
}

export default CommunityOwnerFlow
