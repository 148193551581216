import React from 'react'

const MemberCardSkeleton = () => {
  return (
    <div className='rounded bg-white shadow-lg p-3 pr-6 min-w-[200px]'>
      <div className='skeleton rounded-full w-[3.125rem] h-[3.125rem]'></div>
      <div className='h-1' />
      <div className='skeleton h-5' />
      <div className='h-3' />
      <div className='flex flex-col gap-2'>
        <div className='skeleton h-5' />
        <div className='skeleton h-5' />
        <div className='skeleton h-5' />
        <div className='h-5' />
        <div className='skeleton h-8 w-40' />
      </div>
    </div>
  )
}

export default MemberCardSkeleton
