import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
// libs
import { FormikHelpers } from 'formik'
import { useNavigate } from 'react-router-dom'
// pages
// wizard
import CommunityWizard from '@containers/creator/communityWizard/CommunityWizard'
import CommunityWizardScreen from '@containers/creator/communityWizard/components/Screen'
// utils
import { _t } from '@locales/index'
import {
  communityNameSchema,
  communitySubscriptionFeeSchema,
  communitySuppliedContentSchema,
  communityTypeSchema,
  convertProfileDataToCommunityValues,
  createInitialProfileSchema,
} from '@containers/creator/createCommunity/utils'
// types
import {
  TypeCommunityCreate,
  TypeCreateCommunityFormValues,
} from '@customTypes/community'
// queries
import {
  useCommunityCreate,
  useCommunityCreateSuppliedContent,
  useGetCommunityTypes,
  useGetSuppliedContent,
} from '@api/queries/community'
import {
  useGetUserProfile,
  useUpdateProfile,
  useUpdateProfileCurrency,
  useUpdateProfileOccupation,
} from '@api/queries/profile'
import UploadService from '@services/uploader/uploadService'
import uploadService from '@services/uploader/uploadService'
import { MediaTypeEnum } from '@enums/media'
import { useQueryClient } from 'react-query'
import { ServerStateKeysEnum } from '@enums/serverState'
import CommunityType from '@containers/creator/createCommunity/screens/CommunityType'
import WhatsIncluded from '@containers/creator/createCommunity/screens/WhatsIncluded'
import SubscriptionFee from '@containers/creator/createCommunity/screens/SubscriptionFee'
import { useGetAllCurrencies } from '@api/queries/currency'
import CommunityName from '@containers/creator/createCommunity/screens/CommunityName'
import PersonalDetails from '@containers/creator/createCommunity/screens/PersonalDetails'
import { CommunityFormEnum, CommunityGenreEnum } from '@enums/community'
import CreateCommunityLoader from '@containers/creator/createCommunity/components/CreateCommunityLoader'
import { ProfileFormEnum, ProfileOnboardingStatusEnum } from '@enums/profile'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import { LocalStorageTypeEnum } from '@enums/localStorage'

const CreateCommunity = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  useGetAllCurrencies()
  useGetCommunityTypes()
  useGetSuppliedContent()

  const [queueKey, setQueueKey] = useState<string>()

  const { data: profile, refetch: refetchProfile } = useGetUserProfile()

  const {
    mutate: createCommunity,
    isError,
    isLoading: isCommunityBeingCreated,
  } = useCommunityCreate({})

  const { mutate: createInitialProfile, isLoading: isProfileBeingCreated } =
    useUpdateProfile()

  const {
    mutate: updateProfileCurrency,
    isLoading: isProfileCurrencyBeingUpdated,
  } = useUpdateProfileCurrency()

  const {
    mutate: updateProfileOccupation,
    isLoading: isProfileOccupationBeingUpdated,
  } = useUpdateProfileOccupation()

  const {
    mutate: createCommunitySuppliedContent,
    isLoading: isSuppliedContentBeingCreated,
  } = useCommunityCreateSuppliedContent()

  const initialValues = useMemo<TypeCreateCommunityFormValues>(() => {
    return convertProfileDataToCommunityValues(profile)
  }, [profile])

  const isFirstCommunity = useMemo<Boolean>(
    () =>
      Boolean(
        profile?.onboardingStatus === ProfileOnboardingStatusEnum.Initiated,
      ),
    [profile?.onboardingStatus],
  )

  const uploadMedia = useCallback(() => {
    if (queueKey) {
      uploadService.setQueueItemCallback(queueKey, async () => {
        await queryClient.refetchQueries([ServerStateKeysEnum.Profile])
      })
      uploadService.runQueue(queueKey)
    }
  }, [queueKey, queryClient])

  const handleSubmit = useCallback(
    (
      formValues: TypeCreateCommunityFormValues,
      formikHelpers: FormikHelpers<TypeCreateCommunityFormValues>,
    ) => {
      if (profile) {
        const values: TypeCommunityCreate = {
          genere: CommunityGenreEnum.Community,
          title: formValues[CommunityFormEnum.Title],
          typeId: formValues[CommunityFormEnum.Type],
          profileId: profile.id,
          price: formValues[CommunityFormEnum.Price],
        }

        if (isFirstCommunity) {
          // update profile request - Full name, profileImage
          createInitialProfile({
            profileId: profile.id,
            name: formValues[ProfileFormEnum.Name] || '',
          })

          // update profile occupation
          updateProfileOccupation({
            profileId: profile.id,
            occupationId: formValues[ProfileFormEnum.Occupation]?.id || '',
          })

          // update profile currency request
          updateProfileCurrency(
            {
              profileId: profile.id,
              currencyIso: formValues[CommunityFormEnum.Currency],
            },
            {
              onSuccess: () => {
                createCommunity(values, {
                  onSuccess: async createdCommunity => {
                    localStorage.setItem(
                      LocalStorageTypeEnum.CurrentCommunity,
                      createdCommunity.id,
                    )
                    createCommunitySuppliedContent({
                      communityId: createdCommunity.id,
                      suppliedContentIds: formValues.suppliedContent,
                    })
                    formikHelpers.resetForm()
                    await refetchProfile()
                    navigate(CREATOR_PATH.onboarding)
                  },
                })
              },
            },
          )

          uploadMedia()
        } else {
          createCommunity(values, {
            onSuccess: async createdCommunity => {
              createCommunitySuppliedContent({
                communityId: createdCommunity.id,
                suppliedContentIds: formValues.suppliedContent,
              })
              formikHelpers.resetForm()
              await refetchProfile()
              navigate(
                CREATOR_PATH.communityId.replace(':id', createdCommunity.id),
              )
            },
          })
        }

        setTimeout(() => navigate(CREATOR_PATH.onboarding), 3 * 1000)
      }
    },
    [
      createCommunity,
      createCommunitySuppliedContent,
      createInitialProfile,
      isFirstCommunity,
      navigate,
      profile,
      refetchProfile,
      updateProfileCurrency,
      updateProfileOccupation,
      uploadMedia,
    ],
  )

  useEffect(() => {
    if (profile) {
      const key = `profile-queue-${profile.id}`
      UploadService.createQueueItem({
        type: MediaTypeEnum.ProfileMedia,
        postParams: {
          mediaType: MediaTypeEnum.ProfileMedia,
          profileId: profile.id,
        },
        cb: null,
        key,
        uploadAutomatically: false,
      })
      setQueueKey(key)
    }
  }, [profile])

  return (
    <Fragment>
      {profile && (
        <CommunityWizard initialValues={initialValues} onSubmit={handleSubmit}>
          {isFirstCommunity && (
            <CommunityWizardScreen
              label={_t('createCommunityProfileScreenLabel')}
              validationSchema={createInitialProfileSchema}
              disableSubmitOnInvalid
            >
              <PersonalDetails uploadKey={queueKey} />
            </CommunityWizardScreen>
          )}
          <CommunityWizardScreen
            label={_t('createCommunityTypeScreenLabel')}
            validationSchema={communityTypeSchema}
            disableSubmitOnInvalid
          >
            <CommunityType />
          </CommunityWizardScreen>
          <CommunityWizardScreen
            label={_t('createCommunityWhatsIncludedScreenLabel')}
            validationSchema={communitySuppliedContentSchema}
            disableSubmitOnInvalid
          >
            <WhatsIncluded />
          </CommunityWizardScreen>
          <CommunityWizardScreen
            label={_t('createCommunitySubscriptionFeeScreenLabel')}
            validationSchema={communitySubscriptionFeeSchema}
            disableSubmitOnInvalid
          >
            <SubscriptionFee />
          </CommunityWizardScreen>
          <CommunityWizardScreen
            label={_t('createCommunityNameScreenLabel')}
            validationSchema={communityNameSchema}
            disableSubmitOnInvalid
          >
            <CommunityName />
          </CommunityWizardScreen>
        </CommunityWizard>
      )}
      <CreateCommunityLoader
        visible={
          isCommunityBeingCreated ||
          isProfileBeingCreated ||
          isSuppliedContentBeingCreated ||
          isProfileCurrencyBeingUpdated ||
          isProfileOccupationBeingUpdated
        }
        hasError={isError}
      />
    </Fragment>
  )
}

export default CreateCommunity
