export enum BreakpointsEnum {
  sm,
  md,
  lg,
  xl,
  dxl,
}

export enum DropdownItemVariantEnum {
  Default = 'default',
  Inverted = 'inverted',
  Yellow = 'Yellow',
}
