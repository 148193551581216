import React, { useEffect, useMemo } from 'react'
import { _t } from '@locales/index'
import { useFormikContext } from 'formik'
import { TypeCoverPhotoFormValue } from '@customTypes/coverPhoto'
import Footer from '@components/footer/Footer'
import { TypeCommunity } from '@customTypes/community'
import useBreakpoint from '@hooks/responsive/useBreakpoint'
import { BreakpointsEnum } from '@enums/theme'
import { CoverPhotoFormEnum } from '@enums/coverPhoto'

interface Props {
  totalScreens: number
  currentScreen: number
  prevCurrentScreen: number
  onClickPreviousScreen: () => void
  disableNext: boolean
  primaryButtonLabel?: string
  isLastStep: boolean
  community?: TypeCommunity
  initialValues: TypeCoverPhotoFormValue
}

const PhotoCoverWizardFooter: React.FC<Props> = ({
  currentScreen,
  prevCurrentScreen,
  onClickPreviousScreen,
  disableNext,
  isLastStep,
  primaryButtonLabel,
  initialValues,
}) => {
  const { validateForm } = useFormikContext<TypeCoverPhotoFormValue>()
  const breakpoint = useBreakpoint()

  const isMobile: boolean = useMemo(
    () => breakpoint < BreakpointsEnum.xl,
    [breakpoint],
  )
  useEffect(() => {
    if (currentScreen !== prevCurrentScreen) {
      validateForm().then()
    }
  }, [currentScreen, prevCurrentScreen, validateForm])

  return (
    <Footer
      primaryButton={{
        text:
          primaryButtonLabel || !isLastStep
            ? initialValues[CoverPhotoFormEnum.Photo]
              ? _t('continue')
              : _t('next')
            : _t('done'),
        disabled: disableNext,
      }}
      secondaryButton={
        isMobile
          ? {
              text: _t('back'),
              onClick: onClickPreviousScreen,
            }
          : undefined
      }
    />
  )
}

export default PhotoCoverWizardFooter
