import { useQuery, UseQueryOptions } from 'react-query'
import { ServerStateKeysEnum } from '@enums/serverState'
import apiService from '@services/api/apiService'
import { ApiKeyNameEnum } from '@enums/api'
import { AxiosError } from 'axios'
import { useProtection } from '@hooks/auth/useProtection'
import {
  TypeAvailableFunds,
  TypeBillingDetails,
  TypeBillingDetailsPayload,
  TypeBillingTransactionResponse,
  TypeCommunityBillingTransactionsPayload,
  TypeExportTransactionsPayload,
  TypeExportTransactionsResponse,
  TypeProfileBillingDetails,
  TypeRevenuePayload,
  TypeRevenueResponse,
} from '@customTypes/billing'
import { DEFAULT_TRANSACTIONS_PAGE_SIZE } from '@const/billing'
import { TypeAvailableFundsPayload } from '@customTypes/payment'
import { getApiQueryString } from '@common/index'

/**********************************
 COMMUNITY PAYMENT DETAILS
 **********************************/

type TypeGetBillingDetailsOptions = UseQueryOptions<
  TypeBillingDetails,
  AxiosError,
  TypeBillingDetails,
  any
>

const getBillingDetails = async (
  payload: TypeBillingDetailsPayload,
): Promise<TypeBillingDetails> => {
  try {
    const response = await apiService.get(
      ApiKeyNameEnum.billing_details,
      `${payload.communityId}/${payload.profileId}`,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetBillingDetails = (
  payload: TypeBillingDetailsPayload,
  options?: TypeGetBillingDetailsOptions,
) => {
  const queryOptions = useProtection<TypeGetBillingDetailsOptions>(options)

  return useQuery(
    [ServerStateKeysEnum.CommunityBillingDetails, payload],
    () => getBillingDetails(payload),
    {
      ...queryOptions,
    },
  )
}

/**********************************
 PROFILE PAYMENT DETAILS
 **********************************/

type TypeGetProfileBillingDetailsOptions = UseQueryOptions<
  TypeProfileBillingDetails,
  AxiosError,
  TypeProfileBillingDetails,
  any
>

const getProfileBillingDetails = async (
  profileId: string | undefined,
): Promise<TypeProfileBillingDetails> => {
  try {
    const response = await apiService.get(
      ApiKeyNameEnum.profile_billing_details,
      profileId,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetProfileBillingDetails = (
  profileId: string | undefined,
  options?: TypeGetProfileBillingDetailsOptions,
) => {
  const queryOptions =
    useProtection<TypeGetProfileBillingDetailsOptions>(options)

  return useQuery(
    [ServerStateKeysEnum.ProfileBillingDetails, profileId],
    () => getProfileBillingDetails(profileId),
    {
      ...queryOptions,
    },
  )
}

/**********************************
 AVAILABLE FUNDS
 **********************************/

type TypeGetAvailableFundsOptions = UseQueryOptions<
  TypeAvailableFunds,
  AxiosError,
  TypeAvailableFunds,
  any
>

const getAvailableFunds = async (
  payload: TypeAvailableFundsPayload,
): Promise<TypeAvailableFunds> => {
  try {
    const queryParams = {
      futureIncomeTillDate: payload.futureIncomeTillDate,
    }
    const qs = getApiQueryString(queryParams)
    const response = await apiService.get(
      ApiKeyNameEnum.available_funds,
      payload.profileId + '?' + qs,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetAvailableFunds = (
  payload: TypeAvailableFundsPayload,
  options?: TypeGetAvailableFundsOptions,
) => {
  const queryOptions = useProtection<TypeGetAvailableFundsOptions>(options)

  return useQuery(
    [ServerStateKeysEnum.AvailableFunds, payload],
    () => getAvailableFunds(payload),
    {
      ...queryOptions,
    },
  )
}

/**********************************
 COMMUNITY BILLING TRANSACTIONS
 **********************************/

type TypeGetCommunityTransactionsOptions = UseQueryOptions<
  TypeBillingTransactionResponse,
  AxiosError,
  TypeBillingTransactionResponse,
  any
>

const getCommunityBillingTransactions = async (
  payload: TypeCommunityBillingTransactionsPayload,
): Promise<TypeBillingTransactionResponse> => {
  try {
    const filtersWithPage = {
      ...payload.filters,
      page: payload.page,
      pageSize: DEFAULT_TRANSACTIONS_PAGE_SIZE,
    }
    const queryParams = getApiQueryString(filtersWithPage)
    const qs = `${payload.communityId}/${payload.profileId}?${queryParams}`
    const response = await apiService.get(
      ApiKeyNameEnum.billing_transactions,
      qs,
    )
    return response.data
  } catch (err: any) {
    throw err
  }
}

export const useGetCommunityBillingTransactions = (
  payload: TypeCommunityBillingTransactionsPayload,
  options?: TypeGetCommunityTransactionsOptions,
) => {
  const queryOptions =
    useProtection<TypeGetCommunityTransactionsOptions>(options)

  return useQuery(
    [ServerStateKeysEnum.BillingTransactions, payload],
    () => getCommunityBillingTransactions(payload),
    {
      getNextPageParam: data => data.nextPage || undefined,
      getPreviousPageParam: data => data.previousPage || undefined,
      keepPreviousData: true,
      ...queryOptions,
    },
  )
}

/*****************************************
 AGGREGATED PROFILE BILLING TRANSACTIONS
 ****************************************/

type TypeGetProfileRevenueOptions = UseQueryOptions<
  TypeRevenueResponse,
  AxiosError,
  TypeRevenueResponse,
  any
>

const getCommunityRevenue = async (
  payload: TypeRevenuePayload,
): Promise<TypeRevenueResponse> => {
  try {
    const queryParams = {
      year: payload.year,
      month: payload.month,
    }
    const qs = getApiQueryString(queryParams)
    const response = await apiService.get(
      ApiKeyNameEnum.revenue,
      `${payload.communityId}/${payload.profileId}?${qs}`,
    )
    return response.data
  } catch (err: any) {
    throw err
  }
}

export const useGetCommunityRevenue = (
  payload: TypeRevenuePayload,
  options?: TypeGetProfileRevenueOptions,
) => {
  const queryOptions = useProtection<TypeGetProfileRevenueOptions>(options)

  return useQuery(
    [ServerStateKeysEnum.RevenueSummary, payload],
    () => getCommunityRevenue(payload),
    {
      ...queryOptions,
    },
  )
}

/*****************************************
 BILLING TRANSACTIONS FOR EXPORT
 ****************************************/

type TypeExportTransactionsOptions = UseQueryOptions<
  TypeExportTransactionsResponse,
  AxiosError,
  TypeExportTransactionsResponse,
  any
>

const getTransactionsForExport = async (
  payload: TypeExportTransactionsPayload,
): Promise<TypeExportTransactionsResponse> => {
  try {
    const queryParams = {
      startDate: payload.startDate,
    }
    const qs = getApiQueryString(queryParams)
    let path = ''
    if (payload.communityId && payload.profileId) {
      path = `${payload.communityId}/${payload.profileId}?${qs}`
    } else {
      path = `?${qs}`
    }

    const response = await apiService.get(
      ApiKeyNameEnum.export_billing_transactions,
      path,
    )
    return response.data
  } catch (err: any) {
    throw err
  }
}

export const useGetTransactionsForExport = (
  payload: TypeExportTransactionsPayload,
  options?: TypeExportTransactionsOptions,
) => {
  const queryOptions = useProtection<TypeExportTransactionsOptions>(options)

  return useQuery(
    [ServerStateKeysEnum.ExportBillingTransactions, payload],
    () => getTransactionsForExport(payload),
    {
      ...queryOptions,
    },
  )
}
