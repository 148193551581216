import React from 'react'
import { TypeNotification } from '@customTypes/notification'
import { ReactComponent as CheckIcon } from '@assets/images/common/check-small.svg'
import { ReactComponent as TrashCanIcon } from '@assets/images/common/trash.svg'

interface Props {
  notification: TypeNotification
  onClick: () => void
}

const Notification: React.FC<Props> = ({ notification, onClick }) => {
  return (
    <div
      onClick={onClick}
      className='block p-5 border-b border-custom-gray-5 bg-white hover:bg-custom-light-blue-1 active:bg-custom-light-blue-1'
    >
      <p className='text-lg font-poppins capitalize  font-bold'>
        {notification.title}
      </p>
      <div className='h-2.5' />
      <p className='text-base font-poppins capitalize  line-clamp-2'>
        {notification.content}
      </p>
      <div className='h-5' />
      <div className='flex justify-between items-center'>
        <span className='text-sm font-poppins text-custom-gray-3'>
          Today, 20:30
        </span>
        <div className='flex gap-5'>
          <div className='w-6 h-6 rounded-full flex items-center justify-center border border-custom-black cursor-pointer'>
            <CheckIcon fill={'#202020'} />
          </div>
          <TrashCanIcon fill={'#202020'} className='cursor-pointer w-6 h-6' />
        </div>
      </div>
    </div>
  )
}

export default Notification
