import React, { useCallback } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { _t } from '@locales/index'
import { CREATOR_PATH } from '@routes/creator/creatorPath'

import { ReactComponent as CalendarIcon } from '@assets/images/common/calendar.svg'

import { useGetMembersCount } from '@api/queries/member'
import LunchCommunityWrapper from '@containers/creator/launchCommunity/LaunchCommunityWrapper'
import LaunchScreenFooter from '@containers/creator/launchCommunity/components/LaunchScreenFooter'
import LaunchScreenMobileHeader from '@containers/creator/launchCommunity/components/LaunchScreenMobileHeader'

const ChangeLaunchDateDisclaimer = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { data: membersData } = useGetMembersCount(id || '', {
    enabled: Boolean(id),
  })

  const handleClickContactUs = useCallback(() => {
    if (id) {
      navigate(CREATOR_PATH.launchDateContactUs.replace(':id', id))
    } else {
      navigate('/')
    }
  }, [id, navigate])

  return (
    <LunchCommunityWrapper isFullScreenOnMobile>
      <div className='h-full flex flex-col'>
        <LaunchScreenMobileHeader />
        <div className='p-5 sm:px-26 sm:py-13 gap-7.5 flex flex-col'>
          <div className=' flex flex-col gap-3 max-w-[341px]'>
            <CalendarIcon className='fill-custom-gray-1 w-12 h-12' />
            <h3 className='h3 capitalize'>
              {_t('changeLaunchDateDisclaimerTitle')}
            </h3>
            <p className='body2 text-custom-red'>
              {_t('changeLaunchDateDisclaimerSubtitle').replace(
                '{X}',
                membersData?.count || 0,
              )}
            </p>
          </div>
          <div className='flex flex-col gap-8 max-w-[414px] body2'>
            <p
              dangerouslySetInnerHTML={{
                __html: _t('changeLaunchDateDisclaimerText1'),
              }}
            ></p>
            <p
              dangerouslySetInnerHTML={{
                __html: _t('changeLaunchDateDisclaimerText2'),
              }}
            ></p>
          </div>
        </div>
        <LaunchScreenFooter
          previousButtonText={_t('cancel')}
          nextButtonText={_t('contactUs')}
          onClickPrevious={() => navigate(-1)}
          onClickNext={handleClickContactUs}
        />
      </div>
    </LunchCommunityWrapper>
  )
}

export default ChangeLaunchDateDisclaimer
