import { TypeEditProfileFormValues, TypeProfile } from '@customTypes/profile'
import { ProfileFormEnum } from '@enums/profile'
import { object, string } from 'yup'
import { TypeProfileMedia, TypeProfileMediaType } from '@customTypes/media'
import { ProfileMediaTypeEnum } from '@enums/media'

export const convertProfileDataToFormValues = (
  profile?: TypeProfile,
): TypeEditProfileFormValues => {
  let profileImageUrl = ''
  if (profile) {
    const profileMedia = getProfileMediaByType(
      ProfileMediaTypeEnum.Profile,
      profile?.media,
    )
    profileImageUrl =
      profileMedia && profileMedia.length > 0 ? profileMedia[0].url : ''
  }

  return {
    [ProfileFormEnum.Name]: profile?.name || '',
    [ProfileFormEnum.Bio]: profile?.bio || '',
    [ProfileFormEnum.Occupation]: profile?.occupation || null,
    [ProfileFormEnum.ProfileImage]: profileImageUrl,
  }
}

export const editProfileFormSchema = object().shape({
  [ProfileFormEnum.Name]: string().min(2).max(64).required(),
  [ProfileFormEnum.Bio]: string().max(400),
  [ProfileFormEnum.Occupation]: object().required(),
  [ProfileFormEnum.ProfileImage]: string().required(),
})

export const getProfileMediaByType = (
  type: TypeProfileMediaType,
  media: TypeProfileMedia[],
): TypeProfileMedia[] | undefined =>
  media ? media.filter(mediaItem => mediaItem.subType === type) : undefined
