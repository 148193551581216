import React, { Fragment } from 'react'
import { _t } from '@locales/index'
import { useField } from 'formik'

interface Props extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  name: string
}

const ContactUsFormSubject: React.FC<Props> = ({ ...props }) => {
  const [field] = useField(props)

  return (
    <Fragment>
      <div className={'w-full'}>
        <textarea
          autoComplete='off'
          {...field}
          {...props}
          rows={1}
          placeholder={_t('contactUsSubject')}
          className='border-none outline-none bg-custom-gray-6 text-lg w-full focus:ring-0 resize-none py-2.5 pl-5 text-custom-black'
        />
      </div>
    </Fragment>
  )
}

export default ContactUsFormSubject
