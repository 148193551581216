import React from 'react'
import classNames from 'classnames'
import { LAYOUT_FOOTER } from '@const/layout'
import useBreakpoint from '@hooks/responsive/useBreakpoint'
import { ButtonTypeEnum } from '@enums/common'

interface TypeFooterButton {
  text: string
  onClick?: () => void
  disabled?: boolean
  type?: ButtonTypeEnum
  hidden?: boolean
}

interface Props {
  primaryButton: TypeFooterButton
  secondaryButton?: TypeFooterButton
}

const Footer: React.FC<Props> = ({ primaryButton, secondaryButton }) => {
  const breakpoint = useBreakpoint()

  return (
    <div
      className={classNames(
        'flex items-center  relative border-t border-custom-gray-5 bg-white',
        secondaryButton && !secondaryButton.hidden
          ? 'justify-between px-5'
          : 'justify-around px-0',
      )}
      style={{
        minHeight: LAYOUT_FOOTER[breakpoint],
      }}
    >
      {secondaryButton && !secondaryButton.hidden && (
        <button
          type={'button'}
          className={'btn btn-text h-10 sm:h-12'}
          onClick={secondaryButton.onClick}
        >
          {secondaryButton.text}
        </button>
      )}
      <button
        type={primaryButton.type || 'submit'}
        disabled={primaryButton.disabled}
        onClick={primaryButton.onClick}
        className={classNames(
          'btn btn-pink h-10 sm:h-12',
          secondaryButton
            ? 'w-auto'
            : 'w-full h-full sm:w-auto sm:h-12 rounded-none sm:rounded',
        )}
      >
        {primaryButton.text}
      </button>
    </div>
  )
}

export default Footer
