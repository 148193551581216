import React, { useEffect, useMemo, useState } from 'react'
import { TypePromoCode } from '@customTypes/promoCode'
import classNames from 'classnames'
import { _t } from '@locales/index'
import { DAYS_LEFT, MEMBERS_USING_PROMO_CODE } from '@const/promoCode'
import { ReactComponent as Dots } from '@assets/images/common/3_dots.svg'
import DropdownMenuItem from '@components/dropdownMenu/DropdownMenuItem'
import DropdownMenu from '@components/dropdownMenu/DropdownMenu'
import { CouponsUtils } from '@containers/creator/coupons/utils'
import { PromoCodeStatusEnum } from '@enums/promoCode'
import moment from 'moment'

interface Props {
  coupon: TypePromoCode
  onRevokeClick: Function
}

const CouponListBox: React.FC<Props> = ({ coupon, onRevokeClick }) => {
  const [couponDaysLeft, setCouponDaysLeft] = useState<number>(0)

  const date = useMemo(() => {
    return (
      moment(coupon.validFromDate).format('MMM D') +
      ' - ' +
      moment(coupon.validUntilDate).format('MMM D')
    )
  }, [coupon.validFromDate, coupon.validUntilDate])

  useEffect(() => {
    if (coupon.status === PromoCodeStatusEnum.Valid) {
      setCouponDaysLeft(CouponsUtils.handleDaysLeft(coupon))
    }
  }, [coupon])

  return (
    <div
      className={classNames(
        'border border-custom-gray-5 rounded p-3 my-1',
        coupon.status === PromoCodeStatusEnum.Valid
          ? 'bg-white'
          : 'bg-custom-gray-6',
      )}
    >
      <div className='relative flex flex-row justify-between items-center pb-2.5'>
        <div className='flex flex-row justify-start'>
          <span className='pr-3 text-lg font-bold font-poppins'>
            {coupon.code}
          </span>
          <span className='text-lg font-bold font-poppins'>
            {coupon.discount}
          </span>
        </div>
        <DropdownMenu triggerComponent={<Dots fill='#828282' />}>
          <DropdownMenuItem
            text={_t('promoCodeRevokePopupButtonRevoke')}
            onClick={() => onRevokeClick()}
          />
          <DropdownMenuItem
            text={_t('promoCodeCopyCodeButton')}
            onClick={() => {
              navigator.clipboard.writeText(coupon.code).then()
            }}
          />
        </DropdownMenu>
      </div>
      <div className='flex flex-row justify-start pb-2.5'>
        <span className='pr-1 text-custom-gray-2 font-poppins text-base'>
          {date}
        </span>
        {coupon.status === PromoCodeStatusEnum.Valid && (
          <span
            className={classNames(
              'text-custom-gray-2 font-poppins text-base',
              couponDaysLeft <= DAYS_LEFT && 'text-custom-red-2',
            )}
          >
            ({couponDaysLeft}
            {_t('promoCodesDayLeft')})
          </span>
        )}
      </div>
      <div>
        <span
          className={classNames(
            Number(coupon.memberCount) >= MEMBERS_USING_PROMO_CODE &&
              'text-custom-green-1',
          )}
        >
          {coupon.memberCount} {_t('promoCodesMembersUsing')}
        </span>
      </div>
    </div>
  )
}

export default CouponListBox
