import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'

interface Props {
  triggerComponent: React.ReactNode
  children: React.ReactNode
  customClass?: string
  inverted?: boolean
  zIndex?: number
  positionClass?: string
  showDividers?: boolean
}

const DropdownMenu: React.FC<Props> = ({
  triggerComponent,
  children,
  customClass,
  inverted = false,
  zIndex,
  positionClass,
  showDividers = true,
}) => {
  return (
    <Menu
      as='div'
      className={'relative z-10'}
      style={
        zIndex
          ? {
              zIndex: zIndex,
            }
          : {}
      }
    >
      {({ open }) => (
        <Fragment>
          <div>
            <Menu.Button className='flex'>{triggerComponent}</Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter='transition ease-out duration-200'
            enterFrom='opacity-0 translate-y-1'
            enterTo='opacity-100 translate-y-0'
            leave='transition ease-in duration-150'
            leaveFrom='opacity-100 translate-y-0'
            leaveTo='opacity-0 translate-y-1'
            show={open}
          >
            <Menu.Items
              static
              className={classNames(
                'absolute mt-2 rtl:right-[-60px] rounded-md shadow-lg focus:outline-none overflow-hidden w-56 z-10',
                customClass || 'w-max',
                inverted ? 'bg-custom-black' : 'bg-white',
                positionClass || 'origin-top-right right-0',
                showDividers && 'divide-y divide-custom-gray-5',
              )}
            >
              {children}
            </Menu.Items>
          </Transition>
        </Fragment>
      )}
    </Menu>
  )
}

export default DropdownMenu
