import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { useGetCommunity } from '@api/queries/community'
import { selectFooterType } from '@selectors/footer'
import { setFooterType } from '@reducers/footer'
import { FooterTypeEnum } from '@enums/footer'
import ImportMembersFooter from '@containers/organization/importMembers/ImportMembersFooter'
import FiltersFooter from '@containers/organization/footer/components/Filters'
import { useGetCommunityMembersCount } from '@hooks/common/useGetCommunityMembersCount'

const Footer = () => {
  const dispatch = useAppDispatch()

  const footerType = useAppSelector(selectFooterType)
  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)

  const { data: community } = useGetCommunity(selectedCommunityId || '', {
    enabled: Boolean(selectedCommunityId),
  })

  const membersCount = useGetCommunityMembersCount()

  useEffect(() => {
    if (community) {
      let footer: FooterTypeEnum | null
      if (membersCount) {
        footer = FooterTypeEnum.Filters
      } else {
        footer = FooterTypeEnum.ImportMembers
      }

      dispatch(setFooterType(footer))
    }
  }, [community, dispatch, membersCount, selectedCommunityId])

  switch (footerType) {
    case FooterTypeEnum.ImportMembers:
      return <ImportMembersFooter />
    case FooterTypeEnum.Filters:
      return <FiltersFooter />
    default:
      return null
  }
}

export default Footer
