import React, { useCallback, useMemo } from 'react'
import 'react-modern-drawer/dist/index.css'
import { _t } from '@locales/index'
import { toast } from 'react-hot-toast'
import { Form, Formik } from 'formik'
import { ReactComponent as GroupIcon } from '@assets/images/common/group-icon.svg'
import Modal from '@components/modal/Modal'
import { CreateAudienceFormEnum } from '@enums/audience'
import {
  TypeCreateAudience,
  TypeCreateAudienceForm,
} from '@customTypes/segmentation'
import { useCreateAudience } from '@api/queries/segmentation'
import { createGroupSchema } from '@containers/organization/organization/utils'
import { useAppDispatch, useAppSelector } from '@hooks/store/redux'
import { selectUserSegmentationFiltersState } from '@selectors/userSegmentationFilters'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'
import { ReactComponent as Arrow } from '@assets/images/common/arrow.svg'
import InputField from '@components/form/InputField'
import TextareaField from '@components/form/TextareaField'
import { useQueryClient } from 'react-query'
import { ServerStateKeysEnum } from '@enums/serverState'
import { clearUserSegmentationFilters } from '@reducers/userSegmentationFilters'

interface Props {
  open: boolean
  onClose: () => void
}

const CreateGroupForm: React.FC<Props> = ({ open, onClose }) => {
  const queryClient = useQueryClient()
  const selectUserSegmentationFilters = useAppSelector(
    selectUserSegmentationFiltersState,
  )
  const isSmallerDevice = useIsSmallerDevice()
  const dispatch = useAppDispatch()

  const initialValues = useMemo<TypeCreateAudienceForm>(() => {
    return {
      [CreateAudienceFormEnum.title]: '',
      [CreateAudienceFormEnum.description]: '',
    }
  }, [])

  const { mutate: createGroup } = useCreateAudience({
    onMutate: () => {
      toast.loading('Create Group...', {
        id: 'audience-group',
      })
    },
    onError: error => {
      toast.error(error.message, { id: 'audience-group' })
    },
  })

  const handleSubmit = useCallback(
    function (formValues: TypeCreateAudienceForm) {
      if (
        selectUserSegmentationFilters.communityId &&
        selectUserSegmentationFilters.segmentationsValues
      ) {
        const values: TypeCreateAudience = {
          title: formValues[CreateAudienceFormEnum.title],
          communityId: selectUserSegmentationFilters.communityId,
          description: formValues[CreateAudienceFormEnum.description],
          segmentationsValues:
            selectUserSegmentationFilters.segmentationsValues,
        }

        createGroup(values, {
          onSuccess: () => {
            toast.success(_t('createGroupSuccess'), { id: 'audience-group' })
            onClose()
            queryClient
              .invalidateQueries(ServerStateKeysEnum.CommunityAudiences)
              .then()
            dispatch(clearUserSegmentationFilters())
          },
        })
      }
    },
    [
      createGroup,
      dispatch,
      onClose,
      queryClient,
      selectUserSegmentationFilters.communityId,
      selectUserSegmentationFilters.segmentationsValues,
    ],
  )

  return (
    <Modal
      open={open}
      backgroundClassName='!bg-white/95 blur-none'
      mainContainerClassName='px-5'
      showClose={!isSmallerDevice}
      onClose={onClose}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={createGroupSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        <Form className='flex flex-col gap-5 min-w-[350px] px-4 py-8 justify-center'>
          <div
            className={
              'flex flex-col items-center justify-center mt-3 mb-8 relative'
            }
          >
            {isSmallerDevice ? (
              <div onClick={onClose} className={'absolute left-0'}>
                <Arrow fill={'black'} />
              </div>
            ) : null}
            <GroupIcon />
            <p>{_t('createGroupTitle')}</p>
          </div>
          <div
            className={
              'flex flex-col items-center justify-center gap-3 border-none'
            }
          >
            <InputField
              id={CreateAudienceFormEnum.title}
              placeholder={_t('createGroupTitlePlaceholder')}
              name={CreateAudienceFormEnum.title}
            />
            <TextareaField
              id={CreateAudienceFormEnum.description}
              placeholder={_t('createGroupDescriptionPlaceholder')}
              name={CreateAudienceFormEnum.description}
            />
          </div>
          <div
            className={'w-full flex items-center justify-center border-none'}
          >
            <button
              type={'submit'}
              className={'btn rounded-full bg-custom-purple text-white body2'}
            >
              {_t('createGroupBtn')}
            </button>
          </div>
        </Form>
      </Formik>
    </Modal>
  )
}

export default CreateGroupForm
