import React, { useMemo } from 'react'
import { Disclosure } from '@headlessui/react'
import UserInfo from './UserInfo'
import FeeDetails from './FeeDetails'
import { TypeBillingTransaction } from '@customTypes/billing'
import { CommunityAdminRoleEnum } from '@enums/community'
import ExpandContractButton from '@containers/creator/communityTransactionsTable/components/ExpandContractButton'
import { DEFAULT_CURRENCY_SYMBOL } from '@const/currency'
import useGetCommunityAdminRole from '@hooks/common/useGetCommunityAdminRole'
import { BillingTransactionTypeEnum } from '@enums/billing'
import classNames from 'classnames'
import moment from 'moment'
import { TRANSACTION_TYPE_TEXT } from '@const/billing'
import AffiliateFee from '@containers/creator/communityTransactionsTable/components/AffiliateFee'

interface Props {
  transaction: TypeBillingTransaction
}

const TransactionItemListView: React.FC<Props> = ({ transaction }) => {
  const { communityAdminRole: communityAdminRoleOfLoggedInUser } =
    useGetCommunityAdminRole()

  const member = useMemo(
    () =>
      transaction.payingBillingEntity?.entity ||
      transaction.associatedBillingEntity.entity,
    [
      transaction.associatedBillingEntity.entity,
      transaction.payingBillingEntity?.entity,
    ],
  )

  const isCommunityOwner = useMemo(
    () => communityAdminRoleOfLoggedInUser === CommunityAdminRoleEnum.Owner,
    [communityAdminRoleOfLoggedInUser],
  )

  const isRefundType = useMemo(
    () => transaction.type === BillingTransactionTypeEnum.ManualRefund,
    [transaction.type],
  )

  return (
    <Disclosure as={'div'} className='px-1'>
      {({ open }) => (
        <>
          <div
            className={classNames(
              'flex items-center justify-between px-2 py-2.5 rounded-md',
              open ? 'bg-white' : 'odd:bg-custom-gray-6',
            )}
          >
            <UserInfo
              entity={member}
              avatarSizeClass='w-10 h-10'
              transactionDate={transaction.dueDate}
            />
            <div className='flex items-center gap-3'>
              <p
                className={classNames(
                  'body1',
                  isRefundType && 'text-custom-error-red',
                )}
              >{`${DEFAULT_CURRENCY_SYMBOL}${
                isCommunityOwner ? transaction.totalAmount : transaction.amount
              }`}</p>
              {!isCommunityOwner ? (
                <div />
              ) : (
                <ExpandContractButton open={open} hidden={!isCommunityOwner} />
              )}
            </div>
          </div>
          {isCommunityOwner && (
            <Disclosure.Panel
              as={'div'}
              className='flex flex-col pt-0.5 px-3 gap-3 pb-1 justify-between'
            >
              <div className='border-[0.5px]' />
              <div className='flex items-center justify-between'>
                <p className='body3 capitalize text-custom-gray-3'>
                  {moment.unix(transaction.dueDate).format('dddd, hh:mm A')}
                </p>
                <p className='body3 capitalize text-custom-gray-3'>
                  {TRANSACTION_TYPE_TEXT[transaction.type]}
                </p>
              </div>
              <FeeDetails
                productFee={transaction.productFee}
                providerFee={transaction.providerFee}
                earned={transaction.earned}
              />
              {transaction.affiliateBillingEntity &&
              transaction.affiliateFee ? (
                <>
                  <div className='border-[0.5px]' />
                  <AffiliateFee
                    transaction={transaction}
                    nameClass='text-custom-gray-3'
                  />
                </>
              ) : null}
            </Disclosure.Panel>
          )}
        </>
      )}
    </Disclosure>
  )
}

export default TransactionItemListView
