import React, { useMemo } from 'react'
import Avatar from '@components/avatar/Avatar'
import { TypeBillingEntityInner } from '@customTypes/billing'
import moment from 'moment'
import classNames from 'classnames'

interface Props {
  entity: TypeBillingEntityInner

  avatarSizeClass?: string
  nameClass?: string
  transactionDate?: number
}

const UserInfo: React.FC<Props> = ({
  entity,
  transactionDate,
  avatarSizeClass,
  nameClass,
}) => {
  const name = useMemo<string>(() => {
    return entity.firstName
      ? `${entity.firstName} ${entity.lastName || ''}`
      : entity.name || entity.email
  }, [entity.email, entity.firstName, entity.lastName, entity.name])

  return (
    <div className='flex items-center gap-2.5 sm:gap-3'>
      <Avatar
        imageUrl={entity.profileImageUrl}
        widthClass={avatarSizeClass || 'w-7.5 h-7.5'}
        textSizeClass='text-base'
        name={name}
      />
      <div className='flex flex-col'>
        <p className={classNames('body2', nameClass)}>{name}</p>
        {transactionDate && (
          <p className='body3 text-custom-gray-3'>
            {moment.unix(transactionDate).format('MMM D, YYYY')}
          </p>
        )}
      </div>
    </div>
  )
}

export default UserInfo
