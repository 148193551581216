import React, { useCallback } from 'react'
import { _t } from '@locales/index'
import { ReactComponent as Counter } from '@assets/images/common/counter.svg'
import { useNavigate } from 'react-router-dom'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import { useGetCommunity } from '@api/queries/community'
import Bottle from '@assets/images/common/bottle.png'
import LunchCommunityWrapper from '@containers/creator/launchCommunity/LaunchCommunityWrapper'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import LaunchScreenMobileHeader from '@containers/creator/launchCommunity/components/LaunchScreenMobileHeader'

const PublishCommunity = () => {
  const navigate = useNavigate()
  const id = useAppSelector(selectCurrentCommunityId)

  const { data: community } = useGetCommunity(id, {
    enabled: Boolean(id),
  })

  const handleClickSetCountdown = useCallback(() => {
    if (community) {
      navigate(
        CREATOR_PATH.setLaunchDateDisclaimer.replace(':id', community.id),
      )
    }
  }, [community, navigate])

  const handleClickPublishNow = useCallback(() => {
    if (community) {
      navigate(
        CREATOR_PATH.confirmPublishCommunity.replace(':id', community.id),
      )
    }
  }, [community, navigate])

  return (
    <LunchCommunityWrapper isFullScreenOnMobile>
      <LaunchScreenMobileHeader />
      <div className='p-5 sm:p-13 flex flex-col gap-13 sm:gap-5 items-center max-w-[564px]'>
        <h3 className='h3 capitalize sm:text-center'>
          {_t('publishCommunityPopupTitle')}
        </h3>
        <div className='flex flex-col gap-5 items-center relative'>
          <div className='flex flex-col gap-4 p-5 border border-custom-pink rounded'>
            <div className='flex flex-col items-center'>
              <Counter />
              <p className='text-center body2'>
                {_t('publishCommunityPopupBody')}
              </p>
            </div>
            <button
              type='button'
              className='btn btn-pink'
              onClick={handleClickSetCountdown}
            >
              {_t('publishCommunitySetCountdownButton')}
            </button>
            <img
              src={Bottle}
              alt={'bottle'}
              className='sm:hidden absolute -top-[95px] right-0 w-26'
            />
          </div>
          <div className='flex items-center justify-between w-full'>
            <button
              type='button'
              className='btn btn-text body3'
              onClick={() => navigate(-1)}
            >
              {_t('publishCommunityPopupSkipButton')}
            </button>
            <button
              type='button'
              className='btn btn-text body3'
              onClick={handleClickPublishNow}
            >
              {_t('publishCommunityPublishNowButton')}
            </button>
          </div>
        </div>
        <img
          src={Bottle}
          alt={'bottle'}
          className='hidden sm:block absolute top-0 w-[104px] left-[-50px]'
        />
      </div>
    </LunchCommunityWrapper>
  )
}

export default PublishCommunity
