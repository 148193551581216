/**
 * generating a mongodb ObjectId
 */

const toHexString = (n: number, length: number) => {
  return `00000000${Math.floor(n).toString(16)}`.slice(-(length * 2))
}

let index = Math.random() * 0xffffff
const machine5Bytes = toHexString(Math.random() * 0xffffff, 4)
const pid3Bytes = toHexString(Math.random() * 0xffff, 3)

const nextIndex = () => {
  return (index = (index + 1) % 0xffffff)
}

// https://docs.mongodb.com/v3.2/reference/method/ObjectId/
export const generate = (): string => {
  const time4Bytes = toHexString(Date.now() / 1000, 4)
  const index3Bytes = toHexString(nextIndex(), 3)
  return `${time4Bytes}${machine5Bytes}${pid3Bytes}${index3Bytes}`
}
