import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { _t } from '@locales/index'
import { DEFAULT_CURRENCY_SYMBOL } from '@const/currency'
import ToggleButtons from '@components/toggleButtons/ToggleButtons'
import { FUTURE_INCOME_OPTIONS } from '@const/payment'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'
import { useGetWalletData } from '@api/queries/payment'

interface Props {
  showTotalFutureIncome?: boolean
}

const FutureIncome: React.FC<Props> = ({ showTotalFutureIncome = false }) => {
  const isSmallerDevice = useIsSmallerDevice()

  const [numberOfDays, setNumberOfDays] = useState<number>(
    FUTURE_INCOME_OPTIONS[0].value,
  )
  const [tillDate, setTillDate] = useState<string>()

  const { data: walletData, isLoading } = useGetWalletData({
    futureIncomeTillDate: showTotalFutureIncome ? undefined : tillDate,
  })

  const handleChangeNumberOfDays = useCallback((days: number) => {
    setNumberOfDays(days)
    const date = new Date()
    date.setDate(date.getDate() + days)
    setTillDate(date.toISOString().slice(0, 10)) // date in 'YYYY-MM-DD' format
  }, [])

  useEffect(() => {
    if (!isSmallerDevice) {
      handleChangeNumberOfDays(FUTURE_INCOME_OPTIONS[0].value)
    }
  }, [handleChangeNumberOfDays, isSmallerDevice])

  const futureIncome = useMemo<number>(
    () =>
      Math.round((walletData?.availableFunds?.futureFunds || 0) * 100) / 100,
    [walletData?.availableFunds?.futureFunds],
  )

  return (
    <div className='w-full bg-white p-3 sm:p-5 sm:pr-8 rounded-xl'>
      <div className='w-full h-full flex justify-between items-start'>
        <div className='h-full flex flex-col sm:flex-col-reverse gap-6 justify-between'>
          {isLoading ? (
            <div className='skeleton' />
          ) : (
            <p className='h4 sm:h2 leading-normal sm:leading-[3.5rem]'>{`${DEFAULT_CURRENCY_SYMBOL}${futureIncome}`}</p>
          )}
          <div className='flex justify-between items-center'>
            <p className='caption text-custom-gray-4 capitalize sm:body2 sm:text-custom-black sm:!leading-[2.25rem]'>
              {_t('futureIncome')}
            </p>
          </div>
        </div>
        {!showTotalFutureIncome && (
          <div className='hidden sm:block'>
            <ToggleButtons<number>
              options={FUTURE_INCOME_OPTIONS}
              value={numberOfDays}
              onChange={handleChangeNumberOfDays}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default FutureIncome
