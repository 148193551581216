import React, { useMemo, useState } from 'react'
import CommunityPageWrapper from '@containers/creator/community/components/CommunityPageWrapper'
import { _t } from '@locales/index'
import { ReactComponent as TelegramIcon } from '@assets/images/common/telegram-shape.svg'
import AffiliateLinkBox from '@containers/creator/affiliatePage/components/AffiliateLinkBox'
import classNames from 'classnames'
import useBreakpoint from '@hooks/responsive/useBreakpoint'
import { BreakpointsEnum } from '@enums/theme'
import { TELEGRAM_APP_LINK } from '@const/common'
import { useGetCommunity } from '@api/queries/community'
import { useGetUserProfile } from '@api/queries/profile'
import {
  getAffiliateTelegramUrlByType,
  getAffiliateUrlByTypeAndProfile,
} from '@containers/creator/community/utils'
import { AffiliateLinkTypeEnum } from '@enums/affiliate'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { useParams } from 'react-router-dom'

const AffiliatePage = () => {
  const breakpoint = useBreakpoint()
  const { id: communityIdFromParams } = useParams()

  const [copyText, setCopyText] = useState(false)
  const isSmallerDevice: boolean = useMemo(
    () => breakpoint === BreakpointsEnum.sm,
    [breakpoint],
  )

  const communityIdFromStore = useAppSelector(selectCurrentCommunityId)

  const communityId = communityIdFromStore || communityIdFromParams

  const handleCopy = (link: string) => {
    if (link) {
      navigator.clipboard.writeText(link)
      setCopyText(true)

      setTimeout(() => {
        setCopyText(false)
      }, 3000)
    }
  }

  const { data: community } = useGetCommunity(communityId, {
    enabled: Boolean(communityId),
  })

  const { data: profile } = useGetUserProfile()

  const affiliateUrl = useMemo(
    () =>
      getAffiliateUrlByTypeAndProfile(
        AffiliateLinkTypeEnum.CommunityProfile,
        profile?.id || '',
        community?.affiliateLinks || [],
      ),
    [community?.affiliateLinks, profile?.id],
  )

  const telegramFlowUrl = useMemo(
    () =>
      getAffiliateTelegramUrlByType(
        AffiliateLinkTypeEnum.CommunityProfile,
        profile?.id || '',
        community?.affiliateLinks || [],
        community?.customBotId || '',
      ),
    [community?.affiliateLinks, community?.customBotId, profile?.id],
  )

  if (!community) {
    return <></>
  }

  return (
    <CommunityPageWrapper>
      <div className='bg-custom-green-gray flex flex-1 gap-[50px] flex-col'>
        {!profile?.providerChatId ? (
          <div
            className={
              'flex items-center sm:gap-[32px] gap-3 justify-center h-16 bg-white w-full'
            }
          >
            <p className={'body2 '}>
              {isSmallerDevice
                ? _t('affiliateTelegramDownloadTitleMobile')
                : _t('affiliateTelegramDownloadTitle')}
            </p>
            <div
              className={
                'flex cursor-pointer bg-gradient-to-r from-[#34B0DF] to-[#1E88D3] rounded-3xl items-center justify-center p-0.5'
              }
            >
              <a
                href={profile?.link || TELEGRAM_APP_LINK}
                target={'_blank'}
                rel={'noreferrer'}
              >
                <div
                  className={classNames(
                    'flex rounded-3xl items-center body2 justify-center gap-2.5 py-1.5 px-3.5 bg-white',
                  )}
                >
                  <TelegramIcon className={'w-[13px] h-[11px]'} />
                  <p className={'text-[#1E88D3]'}>
                    {_t('affiliateTelegramDownloadButton')}
                  </p>
                </div>
              </a>
            </div>
          </div>
        ) : null}
        <div
          className={
            'flex flex-col justify-center items-center gap-[50px] px-5 relative mt-12'
          }
        >
          <div className={'sm:px-[50px]'}>
            <div className='flex flex-col gap-3 w-full'>
              <h1 className='h4 capitalize font-medium'>
                {_t('affiliateHeadTitle')}
              </h1>
              <h1 className='body2 max-w-[21.875rem] sm:max-w-[28.375rem] h-[6.5rem] h-5'>
                {_t('affiliateHeadDescription')}
              </h1>
            </div>
            <div
              className={
                'flex flex-col sm:flex-row gap-5 w-full justify-center items-center'
              }
            >
              <AffiliateLinkBox
                link={telegramFlowUrl || ''}
                telegramFlow={true}
                handleClick={handleCopy}
              />
              <AffiliateLinkBox
                link={affiliateUrl || ''}
                telegramFlow={false}
                handleClick={handleCopy}
              />
            </div>
          </div>
          <div
            className={classNames(
              'flex items-center justify-center rounded-3xl w-[140px] h-[46px] bg-custom-navy-blue sticky top-0',
              'transition-opacity',
              'duration-500',
              'ease-in-out',
              {
                'opacity-100': copyText,
                'opacity-0': !copyText,
              },
            )}
          >
            {copyText && (
              <p className={'text-white'}>{_t('affiliateCopyTextMessage')}</p>
            )}
          </div>
        </div>
      </div>
    </CommunityPageWrapper>
  )
}

export default AffiliatePage
