import React, { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { useGetLocationsByKey } from '@api/queries/locations'
import { TypeLocation } from '@customTypes/location'
import SearchBar from '@containers/creator/locations/components/LocationSearchBar'
import { append, without } from 'ramda'
import LocationList from '@containers/creator/locations/components/LocationList'

interface Props {
  label?: string
  labelClassName?: string
  searchId?: string
  searchBarClassName?: string
  searchWrapperClassName?: string
  locationListWrapperClassName?: string
  wrapperClassName?: string
}

const Locations: React.FC<Props> = ({
  label,
  searchId = 'interests-search-bar',
  labelClassName,
  searchBarClassName,
  wrapperClassName,
  searchWrapperClassName,
  locationListWrapperClassName,
}) => {
  const [searchText, setSearchText] = useState<string>('') // text entered
  const [searchFilter, setSearchFilter] = useState<string>('') // search filter using debounced values (avoiding too many requests)
  const [clickedLocationId, setClickedLocationId] = useState<string>('') // the Location's id that gets clicked from the interests list
  const [locations] = useState<TypeLocation[]>([]) // selected Location
  const [selectedLocationIds, setSelectedLocationIds] = useState<string[]>([]) // selectedLocationsIds from the list

  const {
    data: searchedLocations,
    isLoading: searchedLocationsLoading,
    error: searchedLocationsError,
  } = useGetLocationsByKey(searchFilter, {
    enabled: Boolean(searchFilter),
  })

  const handleSelectFromSearchBar = useCallback((option: TypeLocation) => {
    setSearchText(option.name)
    setClickedLocationId(option.id)
  }, [])

  const handleSelectLocation = useCallback((locationId: string) => {
    setClickedLocationId(locationId)
    setSelectedLocationIds(append(locationId))
  }, [])

  const handleUnselectLocation = useCallback((locationId: string) => {
    setSelectedLocationIds(without([locationId]))
  }, [])

  // debounce search text
  useEffect(() => {
    const timeout = setTimeout(() => {
      setSearchFilter(searchText)
    }, 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [searchText])

  return (
    <div
      className={classNames(
        'w-full flex flex-wrap justify-between gap-8 lg:gap-48',
        wrapperClassName,
      )}
    >
      <div
        className={classNames('flex-auto lg:flex-1', searchWrapperClassName)}
      >
        {label && (
          <label htmlFor={searchId} className={`block ${labelClassName}`}>
            {label}
          </label>
        )}
        <div className='h-5' />
        <SearchBar
          id={searchId}
          wrapperClassName={classNames(searchBarClassName)}
          value={searchText}
          data={searchedLocations}
          dataLoading={searchedLocationsLoading}
          dataError={searchedLocationsError}
          onChange={setSearchText}
          onSelect={handleSelectFromSearchBar}
        />
      </div>
      <div
        className={classNames(
          'flex-auto lg:flex-1 max-h-52 overflow-y-scroll no-scrollbar',
          locationListWrapperClassName,
        )}
      >
        {clickedLocationId && locations && (
          <LocationList
            clickedLocationId={clickedLocationId}
            locations={locations}
            selectedLocationIds={selectedLocationIds}
            onSelect={handleSelectLocation}
            onUnselect={handleUnselectLocation}
          />
        )}
      </div>
    </div>
  )
}

export default Locations
