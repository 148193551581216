import React, { useCallback, useMemo, useState } from 'react'
import Modal from '@components/modal/Modal'
import ImportMembersField from '@containers/organization/importMembers/components/ImportMemberField'
import { BreakpointsEnum } from '@enums/theme'
import useBreakpoint from '@hooks/responsive/useBreakpoint'
import { _t } from '@locales/index'
import { Form, Formik } from 'formik'
import { array, object, string } from 'yup'
import { useUploadMembersManually } from '@api/queries/community'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { useGetUserProfile } from '@api/queries/profile'
import Loader from '@components/loader/Loader'
import { ServerStateKeysEnum } from '@enums/serverState'
import { useQueryClient } from 'react-query'

interface Props {
  show: boolean
  onClose: () => void
}

const UploadMembersManually: React.FC<Props> = ({ show, onClose }) => {
  const queryClient = useQueryClient()
  const breakpoint = useBreakpoint()
  const isMobile: boolean = useMemo(
    () => breakpoint < BreakpointsEnum.lg,
    [breakpoint],
  )

  const { data: profile } = useGetUserProfile()

  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)

  const [membersCount, setMembersCount] = useState(0)

  const closeImportWindows = useCallback(() => {
    setMembersCount(0)
    onClose()
  }, [setMembersCount, onClose])

  const { mutate: uploadMembersManually, isLoading: isUploadMembersManually } =
    useUploadMembersManually()

  const initialValues = {
    members: [],
  }
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const validationSchema = object().shape({
    members: array().of(
      object().shape({
        name: string().required('Required'),
        phone: string()
          .matches(phoneRegExp, 'Phone number is not valid')
          .required('Required'),
      }),
    ),
  })

  const handleSubmit = useCallback(
    async (membersData: any) => {
      if (!(selectedCommunityId && profile?.id)) {
        return
      }

      uploadMembersManually(
        {
          communityId: selectedCommunityId,
          profileId: profile.id,
          members: membersData,
        },
        {
          onSuccess: () => {
            setMembersCount(0)
            queryClient
              .invalidateQueries(ServerStateKeysEnum.AudienceSegmentation)
              .then()
            queryClient
              .invalidateQueries(ServerStateKeysEnum.CommunityMembersCount)
              .then()
            closeImportWindows()
          },
        },
      )
    },
    [
      closeImportWindows,
      profile?.id,
      queryClient,
      selectedCommunityId,
      uploadMembersManually,
    ],
  )

  const handleValuesChange = useCallback((isAdding: boolean) => {
    if (isAdding) {
      setMembersCount(prev => prev + 1)
    } else {
      setMembersCount(prev => prev - 1)
    }
  }, [])

  return (
    <Modal
      open={show}
      onClose={closeImportWindows}
      showClose={true}
      isMobileVersion={isMobile}
      from='bottom'
    >
      <main className='flex flex-col items-start mt-2 py-12 px-5 gap-8'>
        <Loader visible={isUploadMembersManually} />
        <section className='flex justify-between w-full items-center'>
          <h1 className='text-3xl text-center'>
            {_t('manuallyImportMembersTitle')}
          </h1>
          {membersCount ? <p className='text-3xl'>{membersCount}</p> : null}
        </section>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={async (values, { setSubmitting }) => {
            await handleSubmit(values.members)
            setSubmitting(false)
          }}
        >
          <Form className='flex flex-col w-full justify-between gap-12'>
            <ImportMembersField
              onValuesChange={handleValuesChange}
              fieldName={'members'}
            />
            <button
              type={'submit'}
              className='p-4 bg-custom-purple rounded-[55px] text-white'
            >
              {_t('done')}
            </button>
          </Form>
        </Formik>
      </main>
    </Modal>
  )
}

export default UploadMembersManually
