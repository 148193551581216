import {
  TypeChangeLaunchDateModalState,
  TypeSelector,
} from '@customTypes/store'
import { createSelector } from '@reduxjs/toolkit'
import { TypeChangeLaunchDateOption } from '@customTypes/launchDate'

const selectChangeLaunchDateModalState: TypeSelector<
  TypeChangeLaunchDateModalState
> = state => state.changeLaunchDateModal

export const selectChangeLaunchDateModalOption: TypeSelector<
  TypeChangeLaunchDateOption | undefined
> = createSelector(selectChangeLaunchDateModalState, state => state.option)
