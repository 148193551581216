import React from 'react'
import { useField } from 'formik'
import { _t } from '@locales/index'
import {
  MAXIMUM_PROMO_CODE_LENGTH,
  MINIMUM_PROMO_CODE_LENGTH,
} from '@const/promoCode'

interface Props extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  name: string
}

const CouponCode: React.FC<Props> = ({ ...props }) => {
  const [field] = useField(props)

  return (
    <div className={' w-[240px] border-b border-b-custom-gray-5'}>
      <textarea
        autoComplete='off'
        {...field}
        {...props}
        rows={1}
        placeholder={_t('createPromoCodePlaceholder', {
          MAXIMUM_PROMO_CODE_LENGTH: MAXIMUM_PROMO_CODE_LENGTH,
        })}
        className='outline-none border-none text-sm w-full focus:ring-0 resize-none pl-5 text-custom-black'
        minLength={MINIMUM_PROMO_CODE_LENGTH}
        maxLength={MAXIMUM_PROMO_CODE_LENGTH}
      />
    </div>
  )
}

export default CouponCode
