import React from 'react'
import MemberCard from '@containers/creator/members/components/MemberCard'
import MemberCardSkeleton from '@containers/creator/members/components/MemberCardSkeleton'
import { TypeCommunityMember } from '@customTypes/community'

interface Props {
  members?: TypeCommunityMember[]
  isLoading: boolean
}

const MembersGrid: React.FC<Props> = ({ members, isLoading }) => {
  return (
    <div
      className='grid gap-3'
      style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))' }}
    >
      {members &&
        members.length > 0 &&
        members.map((member, idx) => <MemberCard key={idx} member={member} />)}
      {isLoading && <MemberCardSkeleton />}
    </div>
  )
}

export default MembersGrid
