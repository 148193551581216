import React, { useCallback, useMemo, useState } from 'react'
import Modal from '@components/modal/Modal'
import { _t } from '@locales/index'
import { Field, Form, Formik, FormikProps } from 'formik'
import { TypeWithdrawalFormValues } from '@customTypes/billing'
import { number, object } from 'yup'
import { MINIMUM_WITHDRAWAL_AMOUNT } from '@const/billing'
import { DEFAULT_CURRENCY_SYMBOL } from '@const/currency'
import { ReactComponent as SuccessIcon } from '@assets/images/common/success.svg'
import { useGetPayoutSettings, usePayout } from '@api/queries/payout'
import { PAYPAL_URL } from '@services/payout/payoutConfig'
import { ResponseStatusCodeEnum } from '@enums/api'
import { useQueryClient } from 'react-query'
import { ServerStateKeysEnum } from '@enums/serverState'

interface Props {
  amount: number
  open: boolean
  onClose: () => void
}

const PayoutPopup: React.FC<Props> = ({ open, amount, onClose }) => {
  const queryClient = useQueryClient()

  const [transactionSuccessful, setTransactionsSuccessful] =
    useState<boolean>(false)

  const payout = usePayout({
    onSuccess: async response => {
      if (
        ['success', 'processing', 'pending'].includes(
          response.response.toLowerCase(),
        )
      ) {
        setTransactionsSuccessful(true)
        await queryClient.refetchQueries([ServerStateKeysEnum.AvailableFunds])
      }
    },
    onSettled: async () => {
      await queryClient.refetchQueries([ServerStateKeysEnum.AvailableFunds])
      onClose()
    },
  })

  const { data: payoutSettings, error: payoutSettingsError } =
    useGetPayoutSettings()

  const handleSubmit = useCallback(
    (values: TypeWithdrawalFormValues) => {
      if (payoutSettings) {
        payout.mutate({
          payoutSettingId: payoutSettings.id,
          amount: values.inputAmount,
        })
      }
    },
    [payout, payoutSettings],
  )

  const handleClose = useCallback(() => {
    setTransactionsSuccessful(false)
    onClose()
  }, [onClose])

  const validationSchema = useMemo(() => {
    return object().shape({
      inputAmount: number()
        .min(
          MINIMUM_WITHDRAWAL_AMOUNT,
          _t('minWithdrawalAmountError') +
            DEFAULT_CURRENCY_SYMBOL +
            MINIMUM_WITHDRAWAL_AMOUNT,
        )
        .max(
          amount,
          _t('maxWithdrawalAmountError') + DEFAULT_CURRENCY_SYMBOL + amount,
        )
        .required(_t('withdrawalAmountRequiredError')),
    })
  }, [amount])

  const renderConnectView = useMemo(
    () => (
      <div>
        <h1 className='font-poppins font-bold text-2xl capitalize max-w-[360px]'>
          {_t('withdrawalConnectPaypalText')}
        </h1>
        <div className='h-8' />
        <a href={PAYPAL_URL}>
          <button className='btn-base btn-inverted-contained'>
            {_t('withdrawalConnectPaypalButton')}
          </button>
        </a>
      </div>
    ),
    [],
  )

  const renderFormView = useMemo(
    () => (
      <div>
        <h1 className='font-poppins font-bold text-2xl capitalize'>
          {_t('withdrawalAmount')}?
        </h1>
        <div className='h-8' />
        <Formik
          onSubmit={handleSubmit}
          initialValues={{ inputAmount: amount }}
          validationSchema={validationSchema}
        >
          {({
            isValid,
            isSubmitting,
            errors,
          }: FormikProps<TypeWithdrawalFormValues>) => (
            <Form className='flex flex-col gap-3'>
              <p className='font-poppins capitalize text'>
                {_t('withdrawalInputLabel')}
              </p>
              <div className='flex'>
                <Field
                  type='number'
                  name='inputAmount'
                  className='font-poppins outline-none border-custom-gray-5 rounded-l focus:ring-0 focus:border-custom-gray-5'
                />
                <button
                  className='btn-base btn-pink-contained font-normal rounded-l-none shadow-none'
                  disabled={isSubmitting || !isValid}
                >
                  {_t('confirm')}
                </button>
              </div>
              {errors.inputAmount && (
                <p className='text-custom-red-1 text-sm font-poppins first-letter:uppercase'>
                  {errors.inputAmount}
                </p>
              )}
            </Form>
          )}
        </Formik>
      </div>
    ),
    [amount, handleSubmit, validationSchema],
  )

  const renderSuccessView = useMemo(
    () => (
      <div className='flex flex-col items-center gap-[30px]'>
        <h1 className='font-poppins font-bold text-2xl capitalize max-w-[360px]'>
          {_t('withdrawalSuccessfulTitle')}
        </h1>
        <SuccessIcon />
        <p className='font-poppins first-letter:uppercase'>
          {_t('withdrawalSuccessText')}
        </p>
      </div>
    ),
    [],
  )

  const notConnectedToPaypal = useMemo<boolean>(
    () =>
      payoutSettingsError?.response?.status ===
      ResponseStatusCodeEnum.ObjectDoesNotExist,
    [payoutSettingsError],
  )

  return (
    <Modal
      open={open}
      onClose={handleClose}
      showClose
      mainContainerClassName='!px-5'
    >
      <div className='w-full h-full p-[50px]'>
        {transactionSuccessful
          ? renderSuccessView
          : notConnectedToPaypal
          ? renderConnectView
          : renderFormView}
      </div>
    </Modal>
  )
}

export default PayoutPopup
