import React, { useMemo, useState } from 'react'

import { ReactComponent as BackIcon } from '@assets/images/common/back-arrow.svg'
import { ReactComponent as CreateIcon } from '@assets/images/common/plus-1.svg'
import FiltersButton from '@containers/organization/organization/components/FiltersButton'
import GroupButton from '@containers/organization/organization/components/GroupButton'
import BroadcastButton from '@containers/organization/organization/components/BroadcastButton'
import { FilterModeEnum } from '@enums/segmentation'
import { TypeFilterMode } from '@customTypes/segmentation'

const FiltersFooter = () => {
  const [mode, setMode] = useState<TypeFilterMode>(FilterModeEnum.Default)

  const isCreateOrEditMode = useMemo<boolean>(
    () => [FilterModeEnum.Create, FilterModeEnum.Edit].includes(mode),
    [mode],
  )

  return (
    <div className='flex sm:hidden items-center py-2.5 px-8 bg-white custom-shadow-5'>
      <div className='flex items-center gap-8'>
        {isCreateOrEditMode ? (
          <button
            className='btn btn-purple rounded-full h-12 w-12 p-0'
            onClick={() => setMode(FilterModeEnum.Default)}
          >
            <BackIcon />
          </button>
        ) : (
          <button
            className='btn btn-purple rounded-full h-12 w-12 p-0'
            onClick={() => setMode(FilterModeEnum.Create)}
          >
            <CreateIcon />
          </button>
        )}
        <div className='w-[0.5px] h-[70px] bg-custom-gray-5' />
      </div>
      <div className='flex flex-1 justify-center items-center gap-3'>
        {isCreateOrEditMode ? (
          <>
            <FiltersButton />
            <GroupButton />
          </>
        ) : (
          <BroadcastButton />
        )}
      </div>
    </div>
  )
}

export default FiltersFooter
