import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { _t } from '@locales/index'
import OnboardingStep from '@containers/creator/onboarding/components/OnboardingStep'
import OnboardingStep1Icon from '@assets/images/common/logo-black.svg'
import OnboardingStep2Icon from '@assets/images/common/telegram-circle.png'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import { useGetUserProfile } from '@api/queries/profile'
import { ProfileOnboardingStatusEnum } from '@enums/profile'
import useIsProfileApproved from '@hooks/common/useIsProfileApproved'
import useIsAffiliateProfile from '@hooks/common/useIsAffiliateProfile'

const Onboarding = () => {
  const navigate = useNavigate()
  const isAffiliateProfile = useIsAffiliateProfile()
  const secondStepRef = useRef<HTMLDivElement>(null)

  const { data: profile } = useGetUserProfile()
  useIsProfileApproved({
    keepCheckingForApproval: Boolean(
      profile &&
        profile.onboardingStatus === ProfileOnboardingStatusEnum.ProfileCreated,
    ),
  })

  const handleClickCreateCommunity = useCallback(() => {
    navigate(CREATOR_PATH.createCommunity)
  }, [navigate])

  const handleClickConnectToTelegram = useCallback(() => {
    if (profile?.link) {
      window.open(profile?.link, '_blank')
    }
  }, [profile?.link])

  const isFirstStep = useMemo<boolean>(
    () =>
      Boolean(
        profile &&
          profile.onboardingStatus === ProfileOnboardingStatusEnum.Initiated,
      ),
    [profile],
  )

  const isSecondStep = useMemo<boolean>(
    () =>
      Boolean(
        profile && // isSuccess &&
          profile.onboardingStatus ===
            ProfileOnboardingStatusEnum.ProfileCreated,
      ),
    [profile],
  )

  useEffect(() => {
    if (
      (profile &&
        profile.onboardingStatus ===
          ProfileOnboardingStatusEnum.TelegramConnected) ||
      isAffiliateProfile
    ) {
      navigate('/')
    }
  }, [isAffiliateProfile, navigate, profile])

  useEffect(() => {
    if (isSecondStep) {
      secondStepRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      })
    }
  }, [isSecondStep])

  return (
    <div className='flex justify-center items-center flex-1 h-full w-full px-3 pt-8 sm:px-5 sm:pt-0'>
      <div className='flex flex-col items-center gap-13'>
        <div className='flex flex-col items-center gap-3 sm:gap-0'>
          <p className='h3 sm:h1 text-center capitalize'>
            {isFirstStep
              ? _t('onboardingFirstStepTitle')
              : _t('onboardingSecondStepTitle')}
          </p>
          <p className='body1 capitalize sm:h5 text-center text-custom-gray-3'>
            {isFirstStep
              ? _t('onboardingFirstStepSubtitle')
              : _t('onboardingSecondStepSubtitle')}
          </p>
        </div>
        <div className='flex gap-5 justify-center flex-wrap pb-13'>
          <OnboardingStep
            iconSrc={OnboardingStep1Icon}
            title={_t('onboardingStep1Title')}
            description={_t('onboardingStep1Description')}
            isCurrent={isFirstStep}
            onClickCta={handleClickCreateCommunity}
            stepNumber={1}
            isCompleted={isSecondStep}
          />
          <OnboardingStep
            iconSrc={OnboardingStep2Icon}
            title={_t('onboardingStep2Title')}
            description={_t('onboardingStep2Description')}
            isCurrent={isSecondStep}
            onClickCta={handleClickConnectToTelegram}
            stepNumber={2}
            isCompleted={false}
          />
          <div ref={secondStepRef} />
        </div>
      </div>
    </div>
  )
}

export default Onboarding
