import React, { useCallback, useMemo } from 'react'
import { useGetCommunityTypes } from '@api/queries/community'
import CommunityTypeCard from '@containers/creator/createCommunity/components/CommunityTypeCard'
import { useField } from 'formik'
import classNames from 'classnames'
import { TypeCommunityType } from '@customTypes/community'

interface Props {
  name: string
  justifyClass?: string
}

const SelectCommunityType: React.FC<Props> = ({ name, justifyClass }) => {
  const [, meta, helpers] = useField<string>(name)

  const { data: communityTypes } = useGetCommunityTypes()

  const handleClickCard = useCallback(
    (cardId: string) => {
      helpers.setValue(cardId)
    },
    [helpers],
  )

  const selectedType = useMemo<TypeCommunityType | undefined>(() => {
    if (communityTypes) {
      return communityTypes.find(type => type.id === meta.value)
    }
  }, [communityTypes, meta.value])

  return (
    <div className='flex flex-col gap-5 items-start'>
      <p
        className={classNames(
          'body3 text-custom-gray-3 text-center overflow-hidden transition-all ease-in duration-300 sm:hidden',
          selectedType ? 'max-h-[400px]' : 'max-h-0',
        )}
      >
        {selectedType?.description}
      </p>
      <div
        className={classNames(
          'flex gap-3 flex-wrap',
          justifyClass || 'justify-start',
        )}
      >
        {communityTypes?.map(communityType => {
          return (
            <CommunityTypeCard
              data={communityType}
              key={communityType.id}
              isSelected={meta.value === communityType.id}
              isAnythingElseSelected={Boolean(
                meta.value && meta.value !== communityType.id,
              )}
              onClick={handleClickCard}
            />
          )
        })}
      </div>
    </div>
  )
}

export default SelectCommunityType
