import { useMemo } from 'react'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { useGetCommunity } from '@api/queries/community'
import { TypeCommunityAdminRole } from '@customTypes/community'
import { useGetUserProfile } from '@api/queries/profile'
// import { CommunityAdminRoleEnum } from '@enums/community'

interface ReturnProps {
  communityAdminRole: TypeCommunityAdminRole | undefined
}

const useGetCommunityAdminRole = (communityId?: string): ReturnProps => {
  const selectedAdminCommunityId = useAppSelector(selectCurrentCommunityId)

  const { data: community } = useGetCommunity(selectedAdminCommunityId, {
    enabled: Boolean(communityId || selectedAdminCommunityId),
  })
  const { data: profile } = useGetUserProfile()

  return useMemo<ReturnProps>(() => {
    // let communityAdminRole = CommunityAdminRoleEnum.Owner
    let communityAdminRole
    if (community && profile) {
      const loggedInAdmin = community.admins.find(
        admin => admin.profile.id === profile.id,
      )
      if (loggedInAdmin) {
        communityAdminRole = loggedInAdmin.role
      }
    }
    return { communityAdminRole }
  }, [community, profile])
}

export default useGetCommunityAdminRole
