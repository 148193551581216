import { TypeOptions } from '@customTypes/common'
import { _t } from '@locales/index'
import { GraphPeriodOptionsEnum, TotalIncomeOptionsEnum } from '@enums/payment'
import {
  TypeGraphPeriodOptions,
  TypeTotalIncomeOptions,
} from '@customTypes/payment'

export const EXPORT_SPREADSHEET_OPTIONS: TypeOptions<number> = [
  {
    label: _t('exportCurrentMonth'),
    value: 1,
  },
  {
    label: _t('exportPreviousMonth'),
    value: 2,
  },
  {
    label: _t('export3Months'),
    value: 3,
  },
  {
    label: _t('export6Months'),
    value: 6,
  },
]

export const FUTURE_INCOME_OPTIONS: TypeOptions<number> = [
  {
    label: _t('futureIncomeOption1'),
    value: 15,
  },
  {
    label: _t('futureIncomeOption2'),
    value: 30,
  },
]

export const GRAPH_PERIOD_OPTIONS: TypeOptions<TypeGraphPeriodOptions> = [
  {
    label: _t('graphPeriodOption1'),
    value: GraphPeriodOptionsEnum.Year,
  },
  {
    label: _t('graphPeriodOption2'),
    value: GraphPeriodOptionsEnum.Month,
  },
]

export const TOTAL_INCOME_OPTIONS: TypeOptions<TypeTotalIncomeOptions> = [
  {
    label: _t('totalIncomeOption1'),
    value: TotalIncomeOptionsEnum.LastMonth,
  },
  {
    label: _t('totalIncomeOption2'),
    value: TotalIncomeOptionsEnum.Total,
  },
]
