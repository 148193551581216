import React from 'react'
import { _t } from '@locales/index'
import { CommunityFormEnum } from '@enums/community'
import SuppliedContent from '@containers/creator/createCommunity/components/SuppliedContent'

const WhatsIncluded = () => {
  return (
    <div className='community-page'>
      <div className='flex flex-col gap-10 sm:gap-13 items-center'>
        <div className='flex flex-col gap-3 sm:gap-5 items-center'>
          <h2 className='h4 capitalize sm:h2 text-center'>
            {_t('createCommunityWhatsIncludedHeading')}
          </h2>
          <h4 className='body1 text-center text-custom-gray-3'>
            {_t('createCommunityWhatsIncludedSubheading')}
          </h4>
        </div>
        <div className='max-w-[550px] pb-2'>
          <SuppliedContent
            name={CommunityFormEnum.SuppliedContent}
            justifyClass={'justify-center'}
          />
        </div>
      </div>
    </div>
  )
}

export default WhatsIncluded
