import React, { useCallback, useState } from 'react'
import Modal from '@components/modal/Modal'
import { _t } from '@locales/index'
import PhoneMockup from '@components/phoneMockup/PhoneMockup'
import exampleCoverPhoto from '@assets/images/community/example-cover-photo.png'
import { ReactComponent as PhotoIcon } from '@assets/images/common/image.svg'
import { ReactComponent as CameraIcon } from '@assets/images/common/camera-1.svg'
import Footer from '@components/footer/Footer'
import { TypeCommunity } from '@customTypes/community'
import { ButtonTypeEnum, ImageEditorTypeEnum } from '@enums/common'
import ImageUploadWrapper from '@components/imageUpload/ImageUploadWrapper'

interface Props {
  visible: boolean
  uploadKey: string
  coverPhotoUrl: string | undefined
  onSubmit: (url: string) => void
  onClose: () => void
  onClickCancel: () => void
  community?: TypeCommunity
}

const UploadCoverPhotoPopup: React.FC<Props> = ({
  visible,
  community,
  coverPhotoUrl,
  onSubmit,
  uploadKey,
  onClose,
  onClickCancel,
}) => {
  const [imageUrl, setImageUrl] = useState<string>(coverPhotoUrl || '')

  const handleSubmit = useCallback(() => {
    if (imageUrl) {
      onSubmit(imageUrl)
      onClose()
    }
  }, [imageUrl, onClose, onSubmit])

  return (
    <Modal open={visible}>
      <div className='rounded w-screen h-screen sm:h-auto sm:w-auto flex flex-col'>
        <div className='flex flex-col flex-1 p-5 sm:px-26 sm:py-13 items-center gap-13'>
          {/* HEADER */}
          <h4 className='h4 font-light capitalize hidden sm:block'>
            {_t('communityWelcomeCoverPhotoUploadTitle')}
          </h4>
          {/* UPLOAD AREA */}
          <ImageUploadWrapper
            uploadQueueKey={uploadKey}
            onSetImageUrl={setImageUrl}
            editorType={ImageEditorTypeEnum.PhoneMockup}
          >
            {({ open, handleClickOpenCamera }) => (
              <div className='flex items-center gap-13'>
                <div className='w-[242px] h-[500px] relative' onClick={open}>
                  <PhoneMockup
                    community={community}
                    description={community?.description}
                    backgroundMediaUrl={imageUrl || exampleCoverPhoto}
                  />
                </div>
                <div className='flex flex-col items-center gap-5'>
                  <p className='h4 font-light'>{_t('welcomeMediaDragImage')}</p>
                  <p className='body2'>
                    {`- ${_t('communityWelcomeCoverPhotoUploadOr')} -`}
                  </p>
                  <div className='flex gap-5'>
                    <button
                      className='btn btn-outlined shadow-none'
                      onClick={open}
                    >
                      <PhotoIcon />
                      {_t('communityWelcomeUploadFormPc')}
                    </button>
                    <button
                      className='btn btn-outlined shadow-none'
                      onClick={handleClickOpenCamera}
                    >
                      <CameraIcon />
                      {_t('communityWelcomeMediaOpenCamera')}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </ImageUploadWrapper>
        </div>
        {/* FOOTER */}
        <Footer
          primaryButton={{
            text: _t('welcomeVideoConfirmButton'),
            disabled: Boolean(!imageUrl),
            type: ButtonTypeEnum.Button,
            onClick: handleSubmit,
          }}
          secondaryButton={{
            text: _t('cancel'),
            onClick: onClickCancel,
          }}
        />
      </div>
    </Modal>
  )
}

export default UploadCoverPhotoPopup
