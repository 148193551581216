import React, { useCallback } from 'react'
import { _t } from '@locales/index'
import { Form, Formik } from 'formik'
import { guidelineValidationSchema } from '@containers/creator/guidelines/utils'
import Modal from '@components/modal/Modal'
import { TypeGuidelinesModalState } from '@customTypes/store'
import { GuidelinesModalModeEnum } from '@enums/guidelines'
import { TypeGuidelineFormValues } from '@customTypes/guidelines'
import { useDispatch } from 'react-redux'
import { closeGuidelinesModal } from '@reducers/guidelinesModal'
import InputField from '@components/form/InputField'
import TextareaField from '@components/form/TextareaField'

type Props = TypeGuidelinesModalState

const CreateAndEditGuideline: React.FC<Props> = ({
  mode,
  onSubmit,
  visible,
  onClose,
  guideline,
}) => {
  const dispatch = useDispatch()

  const handleSubmit = useCallback(
    (values: TypeGuidelineFormValues) => {
      onSubmit(values)
      dispatch(closeGuidelinesModal())
    },
    [dispatch, onSubmit],
  )

  const handleClose = useCallback(() => {
    onClose && onClose()
    dispatch(closeGuidelinesModal())
  }, [dispatch, onClose])

  if (!guideline) {
    return null
  }

  return (
    <Modal
      open={visible}
      backgroundClassName='!z-[60]'
      mainContainerClassName='!z-[70]'
      onClose={onClose}
      containerClassName='!w-11/12 sm:!w-[560px]'
    >
      <Formik
        initialValues={guideline}
        onSubmit={handleSubmit}
        validationSchema={guidelineValidationSchema}
        enableReinitialize
      >
        {({ isValid }) => (
          <Form>
            <div className='flex flex-col gap-10 p-5 w-full rounded-xl dynamic-align'>
              <div className='flex flex-col gap-10'>
                <div className='flex flex-col gap-[5px] max-w-[334px]'>
                  <p className='body1 capitalize font-medium'>
                    {mode === GuidelinesModalModeEnum.Edit
                      ? _t('guidelinesEditPopupTitle')
                      : _t('guidelinesCreateNewPopupTitle')}
                  </p>
                  {mode === GuidelinesModalModeEnum.Create && (
                    <p className='text-custom-gray-2 body2 '>
                      {_t('guidelinesCreateNewDescription')}
                    </p>
                  )}
                </div>
                <div className='flex flex-col gap-5'>
                  <div className='flex flex-col gap-3'>
                    <p className='body2 capitalize text-custom-gray-3'>
                      {_t('title')}
                    </p>
                    <InputField
                      name={'title'}
                      id={'title'}
                      fontWeightClass='font-normal'
                      hideErrorMessage
                    />
                  </div>
                  <div className='flex flex-col gap-3 w-full'>
                    <p className='body2 capitalize text-custom-gray-3'>
                      {_t('description')}
                    </p>
                    <TextareaField
                      name={'description'}
                      id={'description'}
                      fontWeightClass='font-normal'
                      hideErrorMessage
                    />
                  </div>
                </div>
              </div>
              <div className='flex justify-between items-center p-5'>
                <button className='btn' type='button' onClick={handleClose}>
                  {_t('cancel')}
                </button>
                <button
                  className='btn btn-pink'
                  type='submit'
                  disabled={!isValid}
                >
                  {_t('submit')}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default CreateAndEditGuideline
