import React, { Fragment, useEffect, useState } from 'react'
import SelectBox from '@components/form/SelectBox'
import { useFormikContext } from 'formik'
import { TypePromoCodeValues } from '@customTypes/promoCode'
import { PROMO_CODE_DISCOUNT } from '@const/promoCode'

interface Props {
  onCouponDiscountError: Function
}

const CouponDiscount: React.FC<Props> = ({ onCouponDiscountError }) => {
  const { values: couponValues, errors } =
    useFormikContext<TypePromoCodeValues>()
  const [discountLabel, setDiscountLabel] = useState<string>(
    PROMO_CODE_DISCOUNT().label,
  )
  const [isTouched, setIsTouched] = useState<boolean>(false)

  useEffect(() => {
    if (couponValues.couponDiscount) {
      setDiscountLabel(couponValues.couponDiscount)
    }
  }, [couponValues])

  useEffect(() => {
    if (isTouched && errors.couponDiscount) {
      onCouponDiscountError(errors.couponDiscount, couponValues)
    } else {
      onCouponDiscountError('', couponValues)
    }
  }, [errors, onCouponDiscountError, isTouched, couponValues])

  return (
    <Fragment>
      <SelectBox
        name={'couponDiscount'}
        label={discountLabel}
        options={PROMO_CODE_DISCOUNT().options}
        buttonClassName='border border-custom-gray-5 rounded sm:ml-8 px-3 py-1 min-w-[150px] flex flex-row items-center justify-between'
        fontClassName='font-normal'
        listOptionsClassName='absolute mt-1 w-auto min-w-full overflow-auto rounded bg-white shadow-lg focus:outline-none border border-custom-gray-5 divide-y divide-custom-gray-5 z-10'
        checkbox={'solid'}
        onClickListBox={() => setIsTouched(true)}
      />
    </Fragment>
  )
}

export default CouponDiscount
