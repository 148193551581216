import React, { useCallback, useEffect, useState } from 'react'
import Header from '@containers/creator/payments/components/WalletHeader'
import PayoutPopup from '@containers/creator/payoutPopup/PayoutPopup'
import TotalIncome from '@containers/creator/payments/components/TotalIncome'
import FutureIncome from '@containers/creator/payments/components/FutureIncome'
import AvailableFunds from '@containers/creator/payments/components/AvailableFunds'
import MyIncome from '@containers/creator/payments/components/MyIncome'
import { useGetUserProfile } from '@api/queries/profile'
import { CommunityAdminContext } from '@context/community'
import { TypeCommunityAdminContext } from '@customTypes/community'
import useGetCommunityAdminRole from '@hooks/common/useGetCommunityAdminRole'

const PaymentsAndBilling = () => {
  const { data: profile } = useGetUserProfile()
  const { communityAdminRole } = useGetCommunityAdminRole()

  const [communityAdminState, setCommunityAdminState] =
    useState<TypeCommunityAdminContext>()
  const [showWithdrawModal, setShowWithdrawModal] = useState<boolean>(false)
  const [availableFundsAmount, setAvailableFundsAmount] = useState<number>(0)

  const handleClickWithdraw = useCallback((availableFunds: number) => {
    setAvailableFundsAmount(availableFunds)
    setShowWithdrawModal(true)
  }, [])

  useEffect(() => {
    setCommunityAdminState({
      profileId: profile?.id,
      role: communityAdminRole,
    })
  }, [communityAdminRole, profile?.id])

  return (
    <CommunityAdminContext.Provider value={communityAdminState}>
      <div className='flex flex-col w-full h-full overflow-y-auto bg-custom-green-gray py-8 px-5 sm:px-13 sm:py-13 gap-13'>
        <Header />
        <div>
          <div className='flex flex-col gap-8 md:gap-5'>
            <div className='grid grid-cols-2 md:grid-cols-3 gap-3 md:gap-5'>
              <div className='col-span-1 row-span-2 sm:row-span-1'>
                <AvailableFunds onClickWithdraw={handleClickWithdraw} />
              </div>
              <FutureIncome />
              <TotalIncome />
            </div>
            <MyIncome />
          </div>
          <PayoutPopup
            onClose={() => setShowWithdrawModal(false)}
            amount={availableFundsAmount}
            open={showWithdrawModal}
          />
        </div>
      </div>
    </CommunityAdminContext.Provider>
  )
}

export default PaymentsAndBilling
