export const ORGANIZATION_PATH: Record<string, string> = {
  // root
  root: '/',

  // language
  setLanguage: '/set-language',

  // organization
  organizationId: '/organization/:id/',
  createOrganization: '/organization/create',

  importMembers: '/organization/:id/import-members',

  audience: '/organization/:id/audience/:audienceId',

  // Contact
  contact: '/contact-us',

  // No match
  noMatch: '*',
}
