import { BreakpointsEnum } from '@enums/theme'
import { _t } from '@locales/index'
import { CREATOR_PATH } from '@routes/creator/creatorPath'

export const NAVBAR_HEIGHT = {
  [BreakpointsEnum.sm]: 48,
  [BreakpointsEnum.md]: 54,
  [BreakpointsEnum.lg]: 54,
  [BreakpointsEnum.xl]: 54,
  [BreakpointsEnum.dxl]: 54,
}

export const BANNER_HEIGHT = {
  [BreakpointsEnum.sm]: 65,
  [BreakpointsEnum.md]: 88,
  [BreakpointsEnum.lg]: 88,
  [BreakpointsEnum.xl]: 88,
  [BreakpointsEnum.dxl]: 88,
}

export const MOBILE_BOTTOM_DRAWER_ITEM_HEIGHT = 66
export const DRAWER_MENU_TITLE_HEIGHT = 70

export const MOBILE_COMMUNITY_SIDEBAR_FOOTER_HEIGHT = 148

export const SOCIAL_MEDIA_DRAWER_SIZE = {
  [BreakpointsEnum.sm]: '100vw',
  [BreakpointsEnum.md]: '100vw',
  [BreakpointsEnum.lg]: 'calc(100vw - 120px)',
  [BreakpointsEnum.xl]: '840px',
  [BreakpointsEnum.dxl]: '840px',
}

export const SIDEBAR_WIDTH = 220
export const EXPANDED_SIDEBAR_WIDTH = 200

export const COMMUNITY_WIZARD_HEADER_HEIGHT = {
  [BreakpointsEnum.sm]: 48,
  [BreakpointsEnum.md]: 0,
  [BreakpointsEnum.lg]: 0,
  [BreakpointsEnum.xl]: 0,
  [BreakpointsEnum.dxl]: 0,
}

export const COMMUNITY_WIZARD_FOOTER_HEIGHT = {
  [BreakpointsEnum.sm]: 80,
  [BreakpointsEnum.md]: 80,
  [BreakpointsEnum.lg]: 80,
  [BreakpointsEnum.xl]: 80,
  [BreakpointsEnum.dxl]: 80,
}

export const LAYOUT_FOOTER = {
  [BreakpointsEnum.sm]: 60,
  [BreakpointsEnum.md]: 84,
  [BreakpointsEnum.lg]: 84,
  [BreakpointsEnum.xl]: 84,
  [BreakpointsEnum.dxl]: 84,
}

export const FOOTER_HEIGHT = {
  [BreakpointsEnum.sm]: 80,
  [BreakpointsEnum.md]: 0,
  [BreakpointsEnum.lg]: 0,
  [BreakpointsEnum.xl]: 0,
  [BreakpointsEnum.dxl]: 0,
}

export const CREATOR_NAVIGATION_MOBILE_MENU = [
  {
    label: _t('editProfile'),
    link: CREATOR_PATH.profile,
  },
  {
    label: _t('editAccount'),
    link: CREATOR_PATH.account,
  },
  {
    label: _t('helpCenter'),
    link: CREATOR_PATH.contact,
  },
  {
    label: _t('walletHeader'),
    link: CREATOR_PATH.wallet,
  },
]

export const NAVIGATION_MOBILE_MENU = [
  {
    label: _t('helpCenter'),
    link: CREATOR_PATH.contact,
  },
]
