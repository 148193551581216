import React from 'react'
import { _t } from '@locales/index'
import Loader from '@components/loader/Loader'
import CommunityPageWrapper from '@containers/creator/community/components/CommunityPageWrapper'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'

interface Props {
  title: string
  mobileTitle?: string
  children: React.ReactNode
  loading: boolean
  submitDisabled: boolean
}

const EditSignUpPageWrapper: React.FC<Props> = ({
  title,
  mobileTitle,
  children,
  loading,
  submitDisabled,
}) => {
  const isSmallerDevice = useIsSmallerDevice()

  return (
    <CommunityPageWrapper>
      <div className='bg-custom-green-gray p-5 sm:p-13 flex flex-1 flex-col overflow-y-auto'>
        <div className='flex flex-col gap-8'>
          <div className='flex flex-col gap-3'>
            <p className='body1 capitalize hidden sm:block'>
              {_t('editSignUpPageTitle')}
            </p>
            <p className='h4 sm:body2'>
              {isSmallerDevice ? mobileTitle || title : title}
            </p>
          </div>
          {children}
          <button
            className='btn btn-pink w-[106px] hidden sm:flex'
            type={'submit'}
            disabled={submitDisabled}
          >
            {_t('editSignUpPageUpdateButton')}
          </button>
        </div>
        <Loader visible={loading} fullWidth />
      </div>
      <button
        className='btn btn-pink w-full mt-auto h-[60px] rounded-none sm:hidden'
        type={'submit'}
        disabled={submitDisabled}
      >
        {_t('editSignUpPageUpdateButton')}
      </button>
    </CommunityPageWrapper>
  )
}

export default EditSignUpPageWrapper
