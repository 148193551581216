import React, { useMemo } from 'react'
import { TypeSortOrderOption } from '@customTypes/common'
import { useField } from 'formik'
import classNames from 'classnames'

interface Props {
  option: TypeSortOrderOption
  sortValue: string
  onSelect: (value: string, orderValue: string) => void
}

const SortOrderOption: React.FC<Props> = ({ option, onSelect, sortValue }) => {
  const [sortBy] = useField('sortBy')
  const [orderBy] = useField('orderBy')

  const isSelected = useMemo(
    () => sortBy.value === sortValue && orderBy.value === option.value,
    [option.value, orderBy.value, sortBy.value, sortValue],
  )

  return (
    <p
      className={classNames(
        'cursor-pointer transition-[font-weight] capitalize',
        isSelected && 'font-bold',
      )}
      onClick={() => onSelect(sortValue, option.value)}
    >
      {option.text}
    </p>
  )
}

export default SortOrderOption
