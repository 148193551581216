import React, { useState } from 'react'
import { _t } from '@locales/index'
import { ReactComponent as GroupIcon } from '@assets/images/sideMenu/members.svg'
import { useAppSelector } from '@hooks/store/redux'
import { selectUserSegmentationFiltersState } from '@selectors/userSegmentationFilters'
import CreateGroupForm from '@containers/organization/organization/components/CreateGroupForm'

const GroupButton = () => {
  const userSegmentationsFilters = useAppSelector(
    selectUserSegmentationFiltersState,
  )

  const [showForm, setShowForm] = useState<boolean>(false)

  if (!userSegmentationsFilters.valuesCount) {
    return null
  }

  return (
    <>
      <button
        className='btn btn-purple rounded-full h-[30px] hidden sm:flex'
        onClick={() => setShowForm(true)}
      >
        {_t('groupButton')}
      </button>
      <button
        className='btn btn-purple rounded-full h-12 w-12 p-0 sm:hidden'
        onClick={() => setShowForm(true)}
      >
        <GroupIcon />
      </button>
      <CreateGroupForm open={showForm} onClose={() => setShowForm(false)} />
    </>
  )
}

export default GroupButton
