import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TypeChangeLaunchDateModalState } from '@customTypes/store'
import { TypeChangeLaunchDateOption } from '@customTypes/launchDate'

const initialState: TypeChangeLaunchDateModalState = {
  option: undefined,
}

/* CASE REDUCERS */
const setOption: CaseReducer<
  TypeChangeLaunchDateModalState,
  PayloadAction<TypeChangeLaunchDateOption | undefined>
> = (state, action) => {
  state.option = action.payload
}

const changeLaunchDateSlice = createSlice({
  name: 'changeLaunchDateModal',
  initialState,
  reducers: {
    setOption,
  },
})

export const { setOption: setLaunchDateOption } = changeLaunchDateSlice.actions
const changeLaunchDateReducer = changeLaunchDateSlice.reducer

export default changeLaunchDateReducer
