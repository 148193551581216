import React from 'react'
import { TypeDeviation } from '@customTypes/common'
import { ReactComponent as Arrow } from '@assets/images/common/arrow-2.svg'
import { DeviationEnum } from '@enums/common'
import classNames from 'classnames'

interface Props {
  deviation?: TypeDeviation
}

const DeviationArrow: React.FC<Props> = ({ deviation }) => {
  if (deviation === null) {
    return <p className='text-custom-gray-4'>-</p>
  }

  return (
    <Arrow
      className={classNames(
        'h-6 w-6',
        deviation === DeviationEnum.Down
          ? 'fill-custom-error-red rotate-90'
          : 'fill-custom-turquoise -rotate-90',
      )}
    />
  )
}

export default DeviationArrow
