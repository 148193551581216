import React, { useCallback, useEffect, useState } from 'react'
import { TypePromoCode } from '@customTypes/promoCode'
import CouponListBox from '@containers/creator/coupons/components/CouponListBox'
import ReactPaginate from 'react-paginate'
import { ReactComponent as Arrow } from '@assets/images/common/arrow-thin.svg'
import { PROMO_CODES_PER_PAGE } from '@const/promoCode'

interface Props {
  coupons: TypePromoCode[]
  onRevokeClick: Function
}

const CouponList: React.FC<Props> = ({ coupons, onRevokeClick }) => {
  const [page, setPage] = useState<number>(0)
  const [itemOffset, setItemOffset] = useState<number>(0)
  const [currentCoupons, setCurrentCoupons] = useState<TypePromoCode[]>([])
  const [pageCount, setPageCount] = useState(0)

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + PROMO_CODES_PER_PAGE
    setCurrentCoupons(coupons.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(coupons.length / PROMO_CODES_PER_PAGE))
  }, [itemOffset, coupons])

  const handlePageChange = useCallback(
    selectedItem => {
      const newOffset =
        (selectedItem.selected * PROMO_CODES_PER_PAGE) % coupons.length
      setItemOffset(newOffset)
      setPage(selectedItem.selected)
    },
    [coupons],
  )

  return (
    <div>
      {currentCoupons.map((coupon, idx) => (
        <CouponListBox
          coupon={coupon}
          key={idx}
          onRevokeClick={() => onRevokeClick(coupon)}
        />
      ))}
      <div className='flex justify-center'>
        <ReactPaginate
          pageCount={pageCount}
          previousLabel={<Arrow className='cursor-pointer' />}
          nextLabel={<Arrow className='cursor-pointer rotate-180' />}
          className='flex gap-2.5 font-source-sans text-custom-gray-3'
          activeClassName='text-custom-black font-bold'
          onPageChange={handlePageChange}
          forcePage={page}
          breakLabel={'...'}
          pageRangeDisplayed={5}
          renderOnZeroPageCount={() => null}
        />
      </div>
    </div>
  )
}

export default CouponList
