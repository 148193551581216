import React, { useCallback, useMemo, useState } from 'react'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import { TypeSidebarMenuItem } from '@customTypes/sidebar'
import { ReactComponent as PointerIcon } from '@assets/images/common/down-pointer.svg'
import useGetHeight from '@hooks/common/useMaxHeight'

interface Props extends TypeSidebarMenuItem {
  onClick?: () => void
}

const OrganizationSidebarMenuItem: React.FC<Props> = ({
  path,
  label,
  description,
  Icon,
  onClick = () => {},
}) => {
  const { height, ref } = useGetHeight<HTMLParagraphElement>()
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const isExpandable = useMemo<boolean>(
    () => Boolean(description),
    [description],
  )

  const handleClickPointer = useCallback((e: React.MouseEvent) => {
    e.nativeEvent.stopPropagation()
    setIsExpanded(prevState => !prevState)
  }, [])

  return (
    <NavLink
      to={path}
      className={({ isActive }) =>
        classNames(
          'flex flex-col justify-center min-h-12 p-3 cursor-pointer',
          isActive
            ? 'bg-custom-light-purple text-custom-purple fill-custom-purple'
            : 'bg-white text-custom-gray-1 fill-custom-gray-1',
        )
      }
      onClick={onClick}
    >
      {label && (
        <div className='flex items-center justify-between'>
          <div className='flex items-center gap-1.5'>
            {Icon && <Icon className='w-6 h-6 fill-inherit' />}
            <p className='body3 sm:body2 capitalize text-inherit'>{label}</p>
          </div>
          {isExpandable && (
            <PointerIcon
              className={classNames(
                'w-6 h-6 fill-inherit transition-transform',
                isExpanded ? 'rotate-180' : 'rotate-0',
              )}
              onClick={handleClickPointer}
            />
          )}
        </div>
      )}
      {description && (
        <p
          ref={ref}
          className='text-custom-gray-3 body3 transition-all overflow-hidden'
          style={{
            maxHeight: isExpanded ? height : 0,
            marginTop: isExpanded ? 10 : 0,
          }}
        >
          {description}
        </p>
      )}
    </NavLink>
  )
}

export default OrganizationSidebarMenuItem
