import useNewAdminRedirect from '@hooks/auth/useNewAdminRedirect'
import Loader from '@components/loader/Loader'

const NewAdminRedirect = () => {
  const { isLoading } = useNewAdminRedirect()

  return (
    <div className='w-screen h-screen'>
      <Loader visible={isLoading} fullWidth />
    </div>
  )
}

export default NewAdminRedirect
