import React from 'react'
import MemberCount from '@containers/organization/organization/components/MemberCount'
import OrganizationPageWrapper from '@containers/organization/wrapper/OrganizationPageWrapper'
import { useGetAudienceUserSegmentation } from '@api/queries/userSegmentation'
import { useParams } from 'react-router-dom'
import { _t } from '@locales/index'
import Loader from '@components/loader/Loader'

const OrganizationAudience = () => {
  const { audienceId } = useParams()
  const {
    data: audienceUserSegmentation,
    isLoading: isAudienceUserSegmentationLoading,
  } = useGetAudienceUserSegmentation(audienceId, {
    enabled: Boolean(audienceId),
  })

  return (
    <OrganizationPageWrapper>
      <div className='px-5 py-13 sm:p-26 flex flex-1 flex-col'>
        <div className='self-center w-full max-w-[360px]'>
          <Loader visible={isAudienceUserSegmentationLoading} />
          <MemberCount
            count={audienceUserSegmentation?.communityMembersCount || 0}
            label={_t('users')}
          />
        </div>
      </div>
    </OrganizationPageWrapper>
  )
}

export default OrganizationAudience
