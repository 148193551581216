import React from 'react'
import classNames from 'classnames'

import { TypeCommunityType } from '@customTypes/community'

import DefaultIcon from '@assets/images/common/book.png'
import _tf from '@locales/featureLocal'

interface Props {
  data: TypeCommunityType
  isSelected: boolean
  isAnythingElseSelected: boolean
  onClick: (id: string) => void
}

const CommunityTypeCard: React.FC<Props> = ({
  data,
  isSelected,
  isAnythingElseSelected,
  onClick,
}) => {
  return (
    <div
      className={classNames(
        'flex flex-col gap-5 border rounded-md py-5 px-2 sm:p-4 w-36 sm:w-60 cursor-pointer transition bg-white',
        isSelected ? 'border-custom-pink popup-shadow' : 'border-custom-gray-5',
        isAnythingElseSelected && 'opacity-50',
      )}
      onClick={() => onClick(data.id)}
    >
      <img
        src={data.icon || DefaultIcon}
        alt='icon'
        className='w-12 h-12 sm:w-[54px] sm:h-[54px]'
      />
      <div>
        <h4
          className={classNames(
            'body1 sm:h4',
            isSelected
              ? 'text-custom-pink sm:text-custom-turquoise'
              : 'text-custom-black sm:text-custom-turquoise',
          )}
        >
          {_tf('communityType', data.id).name || data.name}
        </h4>
        <p className='body2 hidden sm:block'>
          {_tf('communityType', data.id).description || data.description}
        </p>
      </div>
    </div>
  )
}

export default CommunityTypeCard
