import { useGetAccessToken } from '@src/core/api/queries/auth'

interface UseProtectionFn {
  <Type>(arg: any): Type
}

export const useProtection: UseProtectionFn = options => {
  const { data: accessToken } = useGetAccessToken()

  return {
    ...options,
    enabled:
      options && 'enabled' in options
        ? Boolean(accessToken && options.enabled)
        : Boolean(accessToken),
  }
}
