import React, { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { usePublishCommunityMutation } from '@api/queries/community'
import { toast } from 'react-hot-toast'
import { _t } from '@locales/index'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import { setBannerType } from '@reducers/banner'
import { useAppDispatch } from '@hooks/store/redux'
import LaunchScreenFooter from '@containers/creator/launchCommunity/components/LaunchScreenFooter'
import LunchCommunityWrapper from '@containers/creator/launchCommunity/LaunchCommunityWrapper'
import Loader from '@components/loader/Loader'

const PublishCommunityConfirm: React.FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { mutate: publishCommunity, isLoading: isPublishing } =
    usePublishCommunityMutation()

  const handleSubmit = useCallback(() => {
    if (id) {
      publishCommunity(id, {
        onSuccess: () => {
          dispatch(setBannerType(null))
          navigate(CREATOR_PATH.communityLive.replace(':id', id))
        },
        onError: error => {
          toast.error(error.message, {
            id: 'community-publish-error',
          })
          navigate(CREATOR_PATH.communityId.replace(':id', id))
        },
      })
    }
  }, [dispatch, id, navigate, publishCommunity])

  return (
    <LunchCommunityWrapper>
      <div className='max-w-[532px]'>
        <div className='p-5 sm:p-13 flex flex-col gap-3'>
          <h4 className='h4'>{_t('publishCommunityConfirmPopupTitle')}</h4>
          <p className='body2'>{_t('publishCommunityConfirmPopupBody')}</p>
        </div>
        <LaunchScreenFooter
          nextButtonText={_t('publish')}
          previousButtonText={_t('cancel')}
          onClickPrevious={() => navigate(-1)}
          onClickNext={handleSubmit}
        />
      </div>
      {isPublishing && (
        <div className='absolute inset-0 flex items-center justify-center bg-white/80'>
          <Loader visible={true} />
        </div>
      )}
    </LunchCommunityWrapper>
  )
}

export default PublishCommunityConfirm
