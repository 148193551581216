import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TypeNewAdminState } from '@customTypes/store'

const initialState: TypeNewAdminState = {
  profileId: undefined,
  communityRole: undefined,
  isAffiliatorLoggedIn: false,
}

/* CASE REDUCERS */
const set: CaseReducer<TypeNewAdminState, PayloadAction<TypeNewAdminState>> = (
  state,
  action,
) => {
  state.profileId = action.payload.profileId
  state.communityRole = action.payload.communityRole
  state.isAffiliatorLoggedIn = action.payload.isAffiliatorLoggedIn
}

const clear: CaseReducer<TypeNewAdminState> = () => {
  return initialState
}

const newAdminSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    set,
    clear,
  },
})

export const { set: setNewAdmin, clear: clearNewAdmin } = newAdminSlice.actions
const newAdminReducer = newAdminSlice.reducer

export default newAdminReducer
