import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { useGetCommunity } from '@api/queries/community'
import { LaunchDateStatusEnum } from '@enums/launchDate'
import UnpublishedCommunity from '@containers/creator/banner/components/UnpublishedCommunity'
import LaunchDateBanner from '@containers/creator/banner/components/LaunchDateBanner'
import moment from 'moment'
import { selectBannerType } from '@selectors/banner'
import { setBannerType } from '@reducers/banner'
import { BannerTypeEnum } from '@enums/banner'
import { CommunityApprovalStatusEnum } from '@enums/community'
import UnapprovedCommunity from '@containers/creator/banner/components/UnapprovedCommunity'
import useDoesWelcomeMediaExist from '@hooks/common/useDoesWelcomeMediaExist'

const Banner = () => {
  const dispatch = useAppDispatch()
  const doesWelcomeMediaExist = useDoesWelcomeMediaExist()

  const bannerType = useAppSelector(selectBannerType)
  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)

  const { data: community } = useGetCommunity(selectedCommunityId || '', {
    enabled: Boolean(selectedCommunityId),
  })

  useEffect(() => {
    if (community) {
      let banner: BannerTypeEnum | null = null
      // if community is not approved, return false
      if (community && doesWelcomeMediaExist && selectedCommunityId) {
        if (
          community.communityStatus ===
          CommunityApprovalStatusEnum.AwaitingReview
        ) {
          banner = BannerTypeEnum.UnapprovedCommunity
        } else if (
          community.communityStatus === CommunityApprovalStatusEnum.Approved &&
          !community.launchDate &&
          !community.isPublish
        ) {
          banner = BannerTypeEnum.UnpublishedCommunity
        }
      }

      // check for launch date
      if (!banner) {
        if (
          community?.launchDate?.status !==
            LaunchDateStatusEnum.CommunityLaunched &&
          selectedCommunityId
        ) {
          const isLaunchDateBeforeToday = moment
            .utc(community?.launchDate?.date)
            .isAfter(moment())
          if (isLaunchDateBeforeToday) {
            banner = BannerTypeEnum.LaunchDate
          }
        }
      }

      dispatch(setBannerType(banner))
    }
  }, [community, dispatch, doesWelcomeMediaExist, selectedCommunityId])

  switch (bannerType) {
    case BannerTypeEnum.UnapprovedCommunity:
      return <UnapprovedCommunity community={community} />
    case BannerTypeEnum.UnpublishedCommunity:
      return <UnpublishedCommunity community={community} />
    case BannerTypeEnum.LaunchDate:
      return <LaunchDateBanner community={community} />
    default:
      return null
  }
}

export default Banner
