import { TypeSelectFilter } from '@customTypes/common'
import { _t } from '@locales/index'
import { PromoCodeDiscountEnum, PromoCodeStatusEnum } from '@enums/promoCode'

export const PROMO_CODE_LENGTH = 10
export const MINIMUM_PROMO_CODE_LENGTH = 10
export const MAXIMUM_PROMO_CODE_LENGTH = 10
export const DAYS_LEFT = 5
export const MEMBERS_USING_PROMO_CODE = 20
export const PROMO_CODES_PER_PAGE = 6
export type TypePromoCodeStatus = PromoCodeStatusEnum
export type TypePromoCodeDiscount = PromoCodeDiscountEnum

export const PROMO_CODE_DISCOUNT =
  (): TypeSelectFilter<TypePromoCodeDiscount> => {
    return {
      label: _t('promoCodeDiscount'),
      options: [
        {
          id: '1',
          text: '25%',
          value: PromoCodeDiscountEnum.discount25,
        },
        {
          id: '2',
          text: '50%',
          value: PromoCodeDiscountEnum.discount50,
        },
        {
          id: '3',
          text: '75%',
          value: PromoCodeDiscountEnum.discount75,
        },
        {
          id: '4',
          text: '100%',
          value: PromoCodeDiscountEnum.discount100,
        },
      ],
    }
  }

export const PROMO_CODE_STATUS = (): TypeSelectFilter<TypePromoCodeStatus> => {
  return {
    label: _t('promoCodeStatus'),
    options: [
      {
        id: '1',
        text: _t('promoCodeStatusValid'),
        value: PromoCodeStatusEnum.Valid,
      },
      {
        id: '2',
        text: _t('promoCodeStatusRevoked'),
        value: PromoCodeStatusEnum.Revoked,
      },
      {
        id: '3',
        text: _t('promoCodeStatusExpired'),
        value: PromoCodeStatusEnum.Expired,
      },
    ],
  }
}
