import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useGetBillingDetails } from '@api/queries/billing'
import { CommunityAdminContext } from '@context/community'
import { CommunityAdminRoleEnum } from '@enums/community'
import { _t } from '@locales/index'

interface Props {}

const PayingMembers: React.FC<Props> = () => {
  const { id: communityId } = useParams()
  const communityAdminContext = useContext(CommunityAdminContext)

  const { data: paymentDetails, isLoading } = useGetBillingDetails(
    { communityId, profileId: communityAdminContext?.profileId },
    {
      enabled: Boolean(communityId && communityAdminContext?.profileId),
    },
  )

  return (
    <div className='w-full bg-white p-3 sm:p-5 sm:pr-8 rounded-xl'>
      <div className='w-full h-full flex justify-between items-start'>
        <div className='h-full flex flex-col sm:flex-col-reverse gap-6 justify-between'>
          {isLoading ? (
            <div className='skeleton' />
          ) : (
            <p className='h4 sm:h2 leading-normal sm:leading-[3.5rem]'>{`${
              paymentDetails?.payingMembers || 0
            }`}</p>
          )}
          <div className='flex justify-between items-center'>
            <p className='caption text-custom-gray-4 capitalize sm:body2 sm:text-custom-black sm:!leading-[2.25rem]'>
              {communityAdminContext?.role === CommunityAdminRoleEnum.Owner
                ? _t('payingMembers')
                : _t('payingMembersAffiliateTitle')}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PayingMembers
