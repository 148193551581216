import React, { useCallback, useEffect, useState } from 'react'
import CommunityPageWrapper from '@containers/creator/community/components/CommunityPageWrapper'
import Header from '@containers/creator/payments/components/CommunityPaymentHeader'
import useGetCommunityAdminRole from '@hooks/common/useGetCommunityAdminRole'
import AffiliatePicker from '@containers/creator/payments/components/AffiliatePicker'
import { ReactComponent as SpreadsheetIcon } from '@assets/images/payment/spreadsheet.svg'
import { openMobileBottomDrawer } from '@reducers/mobileBottomDrawer'
import { MobileBottomDrawerTypeEnum } from '@enums/common'
import { useAppDispatch, useAppSelector } from '@hooks/store/redux'
import LastMonthIncome from '@containers/creator/payments/components/LastMonthIncome'
import MembershipFee from '@containers/creator/payments/components/MembershipFee'
import PayingMembers from '@containers/creator/payments/components/PayingMembers'
import MyIncome from '@containers/creator/payments/components/MyIncome'
import MemberGrowth from '@containers/creator/payments/components/MemberGrowth'
import {
  TypeCommunityAdmin,
  TypeCommunityAdminContext,
} from '@customTypes/community'
import { useGetUserProfile } from '@api/queries/profile'
import { CommunityAdminRoleEnum } from '@enums/community'
import { CommunityAdminContext } from '@context/community'
import CommunityTransactionsTable from '@containers/creator/communityTransactionsTable/CommunityTransactionsTable'
import IncomeToOrganisation from '@containers/creator/payments/components/IncomeToOrganisation'
import moment from 'moment/moment'
import useExportTransactionsCSV from '@hooks/payment/useExportTransactions'
import { selectCurrentCommunityId } from '@selectors/community'
import { ExportTransactionsTypeEnum } from '@enums/payment'

const CommunityPayment = () => {
  const dispatch = useAppDispatch()
  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)

  const { communityAdminRole: communityAdminRoleOfLoggedInUser } =
    useGetCommunityAdminRole()

  const [selectedAffiliate, setSelectedAffiliate] =
    useState<TypeCommunityAdmin>()

  const [communityAdminState, setCommunityAdminState] =
    useState<TypeCommunityAdminContext>()

  const [exportStartDate, setExportStartDate] = useState<number>()

  const { data: profile } = useGetUserProfile()

  useExportTransactionsCSV({
    payload: {
      communityId: selectedCommunityId,
      profileId: communityAdminState?.profileId,
      startDate: exportStartDate,
    },
    callback: () => {
      setExportStartDate(undefined)
    },
    type: ExportTransactionsTypeEnum.CommunityPayment,
  })

  const handleClickExport = useCallback(
    (value?: number) => {
      if (value) {
        setExportStartDate(moment().subtract(value, 'months').unix())
      } else {
        dispatch(
          openMobileBottomDrawer({
            type: MobileBottomDrawerTypeEnum.ExportOptions,
            meta: {
              communityId: selectedCommunityId,
              profileId: communityAdminState?.profileId,
              type: ExportTransactionsTypeEnum.CommunityPayment,
            },
          }),
        )
      }
    },
    [communityAdminState?.profileId, dispatch, selectedCommunityId],
  )

  useEffect(() => {
    setCommunityAdminState({
      profileId: selectedAffiliate?.profile.id || profile?.id,
      role: selectedAffiliate?.role || communityAdminRoleOfLoggedInUser,
    })
  }, [
    communityAdminRoleOfLoggedInUser,
    profile?.id,
    selectedAffiliate?.profile.id,
    selectedAffiliate?.role,
  ])

  return (
    <CommunityAdminContext.Provider value={communityAdminState}>
      <CommunityPageWrapper bgClass={'bg-custom-green-gray'}>
        <div className='p-5 pt-8 sm:p-13 flex flex-1 flex-col gap-8 sm:gap-13'>
          <Header onClickExport={handleClickExport} />
          <div className='flex flex-col gap-8 sm:gap-6'>
            <div className='flex items-center justify-between'>
              {communityAdminRoleOfLoggedInUser ===
                CommunityAdminRoleEnum.Owner && (
                <AffiliatePicker onSelect={setSelectedAffiliate} />
              )}
              <SpreadsheetIcon
                className='w-6 h-6 ml-auto sm:hidden'
                onClick={() => handleClickExport()}
              />
            </div>
            <div className='flex flex-col gap-3 sm:gap-6'>
              {communityAdminState?.role ===
                CommunityAdminRoleEnum.Affiliate && (
                <div className='grid grid-cols-2 sm:grid-cols-4 gap-3 sm:gap-5'>
                  <LastMonthIncome />
                  <MembershipFee />
                  <PayingMembers />
                  <IncomeToOrganisation />
                </div>
              )}
              {communityAdminState?.role === CommunityAdminRoleEnum.Owner && (
                <div className='grid grid-cols-1 sm:grid-cols-3 gap-3 sm:gap-5'>
                  <LastMonthIncome />
                  <MembershipFee />
                  <PayingMembers />
                </div>
              )}
              <CommunityTransactionsTable />
              <div className='grid grid-cols-1 sm:grid-cols-2 gap-5'>
                <MyIncome isCommunity />
                <MemberGrowth />
              </div>
            </div>
          </div>
        </div>
      </CommunityPageWrapper>
    </CommunityAdminContext.Provider>
  )
}

export default CommunityPayment
