import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Modal from '@components/modal/Modal'
import { Area, Point } from 'react-easy-crop/types'
import { getCroppedImg } from '@components/imageEditor/utils'
import { TypeCapturedImage } from '@customTypes/uploader'
import { _t } from '@locales/index'
import { useDropzone } from 'react-dropzone'
import { ReactComponent as DragIcon } from '@assets/images/common/drag_arrows.svg'
import { ReactComponent as BackArrow } from '@assets/images/common/arrow.svg'
import ImageEditorRangeSlider from '@components/imageEditor/subComponents/RangeSlider'
import ZoomSlider from '@components/imageEditor/subComponents/ZoomSlider'
import { CropperProps } from 'react-easy-crop/Cropper'
import PhoneMockup from '@components/phoneMockup/PhoneMockup'
import { TypeImageEditorType } from '@customTypes/common'
import { ImageEditorTypeEnum } from '@enums/common'
import classNames from 'classnames'
import Cropper from 'react-easy-crop'

interface Props {
  visible: boolean
  title?: string
  label?: string
  imageSrc: string
  imageName: string
  aspect?: number
  cropShape?: 'rect' | 'round'
  onCrop?: (image: TypeCapturedImage) => void
  onCancel?: () => void
  editorType?: TypeImageEditorType
}

const ImageEditor: React.FC<Props> = ({
  visible,
  title,
  imageSrc,
  imageName,
  aspect,
  cropShape = 'rect',
  onCrop,
  onCancel,
  editorType = ImageEditorTypeEnum.Default,
}) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(2)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({} as Area)
  const [brightness, setBrightness] = useState(100)
  const [contrast, setContrast] = useState(100)
  const [saturation, setSaturation] = useState(100)
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone()
  const imageFrameHandler = useRef<HTMLHeadingElement>(null)
  const [imageFile, setImageFile] = useState(imageSrc)

  const onCropComplete = useCallback((_: Area, pixels: Area) => {
    setCroppedAreaPixels(pixels)
  }, [])

  const handleSubmit = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageFile,
        imageName,
        brightness,
        contrast,
        saturation,
        croppedAreaPixels,
      )
      onCrop && onCrop(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [
    imageFile,
    imageName,
    brightness,
    contrast,
    saturation,
    croppedAreaPixels,
    onCrop,
  ])

  useEffect(() => {
    const imgView = Array.from(
      document.getElementsByClassName(
        'reactEasyCrop_Image',
      ) as HTMLCollectionOf<HTMLElement>,
    )
    imgView[0].style.filter = `brightness(${brightness.toString()}%) contrast(${contrast.toString()}%) saturate(${saturation.toString()}%)`
  }, [brightness, contrast, saturation])

  useEffect(() => {
    if (acceptedFiles) {
      const files = acceptedFiles.map(file => file)
      if (files && files.length > 0) {
        const fileUrl = window.URL.createObjectURL(files[0])
        setImageFile(fileUrl)
      }
    }
  }, [acceptedFiles, setImageFile])

  useEffect(() => {
    if (null !== imageFrameHandler.current) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      crop.x !== 0 || crop.y !== 0
        ? (imageFrameHandler.current.style.display = 'none')
        : null
    }
  }, [crop])

  const isEditorTypePhoneMockup = useMemo<boolean>(
    () => editorType === ImageEditorTypeEnum.PhoneMockup,
    [editorType],
  )

  const cropperProps = useMemo<CropperProps>(() => {
    return {
      image: imageFile,
      crop: crop,
      zoom: zoom,
      aspect: aspect || isEditorTypePhoneMockup ? 3 / 4 : 1,
      cropShape: cropShape,
      showGrid: isEditorTypePhoneMockup ? false : cropShape === 'rect',
      onCropChange: setCrop,
      onCropComplete: onCropComplete,
      onZoomChange: setZoom,
      objectFit: 'contain',
      classes: {
        containerClassName: 'w-full h-full',
        cropAreaClassName: '!w-full !h-full',
      },
    } as CropperProps
  }, [
    aspect,
    crop,
    cropShape,
    imageFile,
    isEditorTypePhoneMockup,
    onCropComplete,
    zoom,
  ])

  return (
    <Modal open={visible}>
      <div className='flex flex-col w-[100vw] h-[calc(var(--vh,_1vh)_*_100)] sm:w-[630px] sm:h-auto p-5 sm:p-0 gap-6 sm:gap-0 z-100'>
        <div className='flex items-center justify-center relative'>
          <BackArrow
            className={classNames(
              'fill-custom-black h-8 w-8 absolute left-0',
              isEditorTypePhoneMockup ? 'hidden' : 'sm:hidden',
            )}
            onClick={onCancel}
          />
          <p
            className={classNames(
              'body2 sm:h4 capitalize',
              isEditorTypePhoneMockup
                ? 'hidden sm:block'
                : 'font-bold sm:font-normal',
            )}
          >
            {title || _t('imageEditorTitle')}
          </p>
          <div />
        </div>
        <div
          className={classNames(
            'flex flex-col-reverse p-0 sm:p-13 justify-between gap-5 sm:gap-0',
            isEditorTypePhoneMockup
              ? 'sm:flex-row-reverse sm:items-center'
              : 'sm:flex-row',
          )}
        >
          <div className='flex flex-col gap-10'>
            <div className='flex flex-col gap-5 sm:gap-3 items-center sm:items-start'>
              <div
                className={classNames(
                  'w-full',
                  !isEditorTypePhoneMockup && 'sm:hidden',
                )}
              >
                <ZoomSlider
                  label={_t('imageEditorZoomLabel')}
                  inputProps={{
                    min: 1,
                    max: 3,
                    step: 0.1,
                    value: zoom,
                    onChange: e => setZoom(Number(e.target.value)),
                  }}
                />
              </div>
              <ImageEditorRangeSlider
                label={_t('imageEditorLabel1')}
                value={brightness}
                inputProps={{
                  min: 0,
                  max: 200,
                  step: 10,
                  onChange: e => setBrightness(Number(e.target.value)),
                }}
              />
              <ImageEditorRangeSlider
                label={_t('imageEditorLabel2')}
                value={contrast}
                inputProps={{
                  min: 0,
                  max: 200,
                  step: 10,
                  onChange: e => setContrast(Number(e.target.value)),
                }}
              />
              <ImageEditorRangeSlider
                label={_t('imageEditorLabel3')}
                value={saturation}
                inputProps={{
                  min: 0,
                  max: 200,
                  step: 10,
                  onChange: e => setSaturation(Number(e.target.value)),
                }}
              />
            </div>
            {!isEditorTypePhoneMockup && (
              <div {...getRootProps({ className: 'dropzone hidden sm:block' })}>
                <input {...getInputProps()} />
                <button
                  className='btn btn-outlined w-[170px] h-[38px] body2'
                  type='button'
                >
                  {_t('changeImage')}
                </button>
              </div>
            )}
          </div>
          <div className='flex flex-col gap-3 items-center'>
            <div
              className={classNames(
                'relative',
                isEditorTypePhoneMockup
                  ? 'w-[118.53px] h-[244px] sm:w-[242px] sm:h-[500px]'
                  : 'w-[200px] h-[200px] sm:w-[250px] sm:h-[250px]',
              )}
            >
              {isEditorTypePhoneMockup ? (
                <PhoneMockup
                  cropperProps={cropperProps}
                  borderRadiusClass={'rounded-[19px] sm:rounded-[40px]'}
                />
              ) : (
                <Cropper {...cropperProps} />
              )}

              <div
                className={classNames(
                  'justify-center items-center gap-1.5 bg-black/50 h-7.5 px-4 w-[175px] border border-white rounded absolute z-40 left-0 right-0 mx-auto sm:bottom-10 bottom-12',
                  isEditorTypePhoneMockup ? 'hidden sm:flex' : 'flex',
                )}
              >
                <DragIcon className={'fill-white h-4'} />
                <p className='text-white caption'>{_t('dragToReposition')}</p>
              </div>
            </div>
            <div
              className={classNames(
                isEditorTypePhoneMockup ? 'hidden' : 'hidden sm:block',
              )}
            >
              <ZoomSlider
                label={_t('imageEditorZoomLabel')}
                inputProps={{
                  min: 1,
                  max: 3,
                  step: 0.1,
                  value: zoom,
                  onChange: e => setZoom(Number(e.target.value)),
                }}
              />
            </div>
          </div>
        </div>
        {/* MOBILE FOOTER */}
        <div className='flex justify-between sm:hidden mt-auto'>
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <button className='btn btn-outlined h-12'>
              {_t('imageEditorReplaceButton')}
            </button>
          </div>
          <button
            className='btn btn-pink h-12'
            type='submit'
            onClick={handleSubmit}
          >
            {_t('confirm')}
          </button>
        </div>
        {/* DESKTOP FOOTER */}
        <div className='hidden sm:flex w-full justify-between items-center mt-auto h-[80px] px-5'>
          <button className='btn h-[44px]' type='button' onClick={onCancel}>
            {_t('cancel')}
          </button>
          <button
            className='btn btn-pink h-[44px]'
            type='submit'
            onClick={handleSubmit}
          >
            {_t('next')}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ImageEditor
