import React, { useCallback, useEffect, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { toast } from 'react-hot-toast'
import { getAcceptedFileFormats } from '@services/uploader/uploadUtils'
import { MediaFileTypeEnum } from '@enums/media'
import UploadService from '@services/uploader/uploadService'
import { getVideoUploadError } from '@components/videoUpload/utils'

interface VideoUploadProps {
  isDragActive: boolean
  videoSrc: string
}

interface Props {
  uploadQueueKey: string
  postParams?: object
  cb?: () => void
  video?: string
  onDropFile?: (url: string) => void
  children: (props: VideoUploadProps) => React.ReactNode
}

const VideoUploadWrapper: React.FC<Props> = ({
  uploadQueueKey,
  postParams,
  cb,
  video,
  onDropFile,
  children,
}) => {
  const [videoSrc, setVideoSrc] = useState<string>(video || '')

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      // handle
      acceptedFiles.forEach(file => {
        const fileUrl = window.URL.createObjectURL(file)
        setVideoSrc(window.URL.createObjectURL(file))
        onDropFile && onDropFile(fileUrl)
        UploadService.uploadQueueItem({
          key: uploadQueueKey,
          cb: cb || null,
          postParams: postParams || {},
          file: file,
        })
      })

      // handle rejected files
      fileRejections.forEach(rejectedFile => {
        console.log('rejected file', rejectedFile)
        toast.error(getVideoUploadError(rejectedFile['errors'][0]), {
          id: rejectedFile.errors[0].code,
        })
      })
    },
    [cb, onDropFile, postParams, uploadQueueKey],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDrop,
    maxFiles: 1,
    accept: getAcceptedFileFormats(MediaFileTypeEnum.Video),
    maxSize: 500 * 1000 * 1000, // 500 MB
  })

  useEffect(() => {
    if (video) {
      setVideoSrc(video)
    }
  }, [video])

  return (
    <div className='w-full' {...getRootProps()}>
      {children({ isDragActive, videoSrc })}
      <input {...getInputProps()} />
    </div>
  )
}

export default VideoUploadWrapper
