import React from 'react'
import { _t } from '@locales/index'
import { BANNER_HEIGHT } from '@const/layout'
import useBreakpoint from '@hooks/responsive/useBreakpoint'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'
import { useAppDispatch } from '@hooks/store/redux'
import { setModalType } from '@reducers/modal'
import { ModalTypeEnum } from '@enums/modal'

const ImportMembersBanner: React.FC = () => {
  const dispatch = useAppDispatch()
  const breakpoint = useBreakpoint()
  const isSmallerDevice = useIsSmallerDevice()

  if (isSmallerDevice) {
    return null
  }

  return (
    <div
      className='px-[46px] flex items-center justify-between bg-custom-purple/20 z-1'
      style={{
        height: BANNER_HEIGHT[breakpoint],
      }}
    >
      <p className='body1 flex-1 whitespace-nowrap text-custom-purple'>
        {_t('importMembersBarTitle')}
      </p>
      <div className='flex items-center gap-1.5'>
        <button
          className='py-4 px-8 btn bg-white text-custom-purple rounded-full'
          onClick={() =>
            dispatch(setModalType(ModalTypeEnum.ImportMembersManually))
          }
        >
          {_t('importMembersUploadManually')}
        </button>
        <button
          className='py-4 px-8 btn btn-purple rounded-full'
          onClick={() => dispatch(setModalType(ModalTypeEnum.ImportMembersCSV))}
        >
          {_t('importMembersUploadCsvButton')}
        </button>
      </div>
    </div>
  )
}

export default ImportMembersBanner
