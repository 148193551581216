import React, { useCallback } from 'react'
import { _t } from '@locales/index'
import Modal from '@components/modal/Modal'

interface Props {
  visible: boolean
  onClose: () => void
  onSubmit: () => void
}

const ConfirmWelcomeVideo: React.FC<Props> = ({
  visible,
  onClose,
  onSubmit,
}) => {
  const handleSubmit = useCallback(() => {
    onSubmit()
    onClose()
  }, [onClose, onSubmit])

  return (
    <Modal open={visible}>
      <div
        className={
          'p-5 sm:p-13 w-[332px] sm:w-[492px] flex flex-col items-center gap-5 sm:gap-13'
        }
      >
        <div className='flex flex-col items-center'>
          <p className='h5 capitalize'>{_t('confirmWelcomeVideoTitle')}</p>
          <p className='body2'>{_t('confirmWelcomeVideoSubtitle')}</p>
        </div>
        <div className='flex justify-center gap-3'>
          <button
            className='btn btn-outlined'
            onClick={onClose}
            type={'button'}
          >
            {_t('cancel')}
          </button>
          <button
            className='btn btn-pink w-[152px]'
            onClick={handleSubmit}
            type={'button'}
          >
            {_t('confirmWelcomeVideoSubmitButton')}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmWelcomeVideo
