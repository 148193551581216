import React, { Fragment, useCallback } from 'react'
import { ModalTypeEnum } from '@enums/modal'
import { useAppDispatch, useAppSelector } from '@hooks/store/redux'
import { selectModalType } from '@selectors/modal'
import UploadMembersManually from '@containers/organization/importMembers/models/UploadMembersManually'
import UploadMembersCSV from '@containers/organization/importMembers/models/UploadMembersCSV'
import { closeModal } from '@reducers/modal'

const Modal = () => {
  const dispatch = useAppDispatch()
  const modalType = useAppSelector(selectModalType)

  const handleClose = useCallback(() => {
    dispatch(closeModal())
  }, [dispatch])

  return (
    <Fragment>
      <UploadMembersManually
        show={modalType === ModalTypeEnum.ImportMembersManually}
        onClose={handleClose}
      />
      <UploadMembersCSV
        show={modalType === ModalTypeEnum.ImportMembersCSV}
        onClose={handleClose}
      />
    </Fragment>
  )
}

export default Modal
