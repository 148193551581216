import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import moment from 'moment/moment'
import { Line } from 'react-chartjs-2'
import { useGetCommunityRevenue } from '@api/queries/billing'
import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import { MONTH_NAMES } from '@const/common'
import { _t } from '@locales/index'
import ToggleButtons from '@components/toggleButtons/ToggleButtons'
import { GRAPH_PERIOD_OPTIONS } from '@const/payment'
import { TypeGraphPeriodOptions } from '@customTypes/payment'
import { GraphPeriodOptionsEnum } from '@enums/payment'
import { DEFAULT_CURRENCY_SYMBOL } from '@const/currency'
import MonthPicker from '@containers/creator/payments/components/MonthPicker'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { CommunityAdminContext } from '@context/community'
import { useGetWalletData } from '@api/queries/payment'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
)

interface Props {
  isCommunity?: boolean
}

const MyIncome: React.FC<Props> = ({ isCommunity = false }) => {
  const [chartData, setChartData] =
    useState<ChartData<'line', number[], string>>()
  const [period, setPeriod] = useState<TypeGraphPeriodOptions>(
    GraphPeriodOptionsEnum.Year,
  )

  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)

  const [date, setDate] = useState<number>(moment().unix())

  const communityAdminContext = useContext(CommunityAdminContext)
  const { data: communityRevenueData } = useGetCommunityRevenue(
    {
      profileId: communityAdminContext?.profileId,
      communityId: selectedCommunityId,
      year: moment().year(),
      month:
        period === GraphPeriodOptionsEnum.Month
          ? moment.unix(date).month() + 1
          : undefined,
    },
    {
      enabled: Boolean(
        isCommunity &&
          communityAdminContext?.profileId &&
          date &&
          selectedCommunityId,
      ),
    },
  )

  const { data: profileRevenueData } = useGetWalletData(
    {
      year: moment().year(),
      month:
        period === GraphPeriodOptionsEnum.Month
          ? moment.unix(date).month() + 1
          : undefined,
    },
    {
      enabled: Boolean(!isCommunity && date),
    },
  )

  const revenueData = useMemo(
    () =>
      profileRevenueData
        ? profileRevenueData.revenueSummary
        : communityRevenueData,
    [communityRevenueData, profileRevenueData],
  )

  const getMaxFromDataset = useCallback(() => {
    let maxVal = 0
    if (chartData && chartData.datasets && chartData.datasets.length > 0) {
      chartData.datasets.forEach(dataset => {
        const maxInThisDataset = Math.max(...dataset.data)
        maxVal = maxInThisDataset > maxVal ? maxInThisDataset : maxVal
      })
    }
    return maxVal
  }, [chartData])

  const calculateStepSize = useCallback(
    (maxValue: number, numSteps: number) => {
      if (maxValue <= numSteps) {
        return 1
      } else {
        return Math.ceil(maxValue / numSteps)
      }
    },
    [],
  )

  const getOptions = useCallback(() => {
    const maxValue = getMaxFromDataset()
    return {
      responsive: true,
      plugins: {
        legend: {
          position: 'top' as const,
          labels: {
            font: {
              size: 12,
              family: 'Poppins',
              weight: 'bold',
            },
          },
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            stepSize: calculateStepSize(maxValue, 8),
            precision: 0,
          },
        },
      },
    }
  }, [calculateStepSize, getMaxFromDataset])

  useEffect(() => {
    const data = revenueData?.revenueTotals
    let labels: string[] = MONTH_NAMES
    let dataset: number[] = Array(12).fill(0)

    // Check if data contains day information
    if (data && data.length > 0) {
      if (data[0].day) {
        // If data contains day information, labels are numbers from 1 to the number of days in the month
        const daysInMonth = new Date(data[0].year, data[0].month, 0).getDate()
        labels = Array.from({ length: daysInMonth }, (_, i) =>
          (i + 1).toString(),
        )
        dataset = Array(daysInMonth).fill(0)

        data.forEach(item => {
          if (item.day) {
            dataset[item.day - 1] = item.revenue
          }
        })
      } else {
        data.forEach(item => {
          dataset[item.month - 1] = item.revenue
        })
      }
    }

    setChartData({
      labels: labels,
      datasets: [
        {
          label: `${_t('myIncomeChartLabel')} (${DEFAULT_CURRENCY_SYMBOL})`,
          data: dataset,
          fill: true,
          backgroundColor: 'rgba(63, 154, 161, 0.3)',
          borderColor: 'rgba(63, 154, 161, 1)', // turquoise
        },
      ],
    })
  }, [revenueData])

  const handleClickNextMonth = useCallback(() => {
    setDate(prevDate => moment.unix(prevDate).add(1, 'M').unix())
  }, [])

  const handleClickPreviousMonth = useCallback(() => {
    setDate(prevDate => moment.unix(prevDate).subtract(1, 'M').unix())
  }, [])

  const monthPicker = useMemo(() => {
    if (period === GraphPeriodOptionsEnum.Year) {
      return null
    }
    return (
      <MonthPicker
        date={date}
        onClickNext={handleClickNextMonth}
        onClickPrevious={handleClickPreviousMonth}
        showYear={false}
      />
    )
  }, [date, handleClickNextMonth, handleClickPreviousMonth, period])

  return (
    <div className='w-full h-full bg-white p-3 sm:p-5 rounded-xl'>
      <div className='w-full h-full flex flex-col gap-[34px] sm:gap-5'>
        <div className='flex flex-col gap-2.5'>
          <div className='flex flex-1 items-center justify-between'>
            <p className='body1 capitalize'>{_t('myIncomeTitle')}</p>
            <div className='hidden sm:block'>{monthPicker}</div>
            <ToggleButtons<TypeGraphPeriodOptions>
              options={GRAPH_PERIOD_OPTIONS}
              value={period}
              onChange={setPeriod}
              showBorder
            />
          </div>
          <div className='flex items-center justify-end sm:hidden'>
            {monthPicker}
          </div>
        </div>
        <div className='w-full max-h-[700px]'>
          {chartData && <Line options={getOptions()} data={chartData} />}
        </div>
      </div>
    </div>
  )
}

export default MyIncome
