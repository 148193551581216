import React, { useCallback, useMemo } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { Field, Form, Formik, FormikProps } from 'formik'
import { _t } from '@locales/index'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import { ReactComponent as CalendarIcon } from '@assets/images/common/calendar.svg'

import LunchCommunityWrapper from '@containers/creator/launchCommunity/LaunchCommunityWrapper'
import {
  changeLaunchDateInitialValues,
  changeLaunchDateSchema,
} from '@containers/creator/launchCommunity/utils'
import {
  ChangeLaunchDateFormEnum,
  ChangeLaunchDateOptionEnum,
} from '@enums/launchDate'
import { TypeChangeLaunchDateFormValues } from '@customTypes/launchDate'
import LaunchScreenFooter from '@containers/creator/launchCommunity/components/LaunchScreenFooter'
import { usePublishCommunityMutation } from '@api/queries/community'
import { toast } from 'react-hot-toast'
import { useGetMembersCount } from '@api/queries/member'
import { setLaunchDateOption } from '@reducers/changeLaunchDateModal'
import { useAppDispatch } from '@hooks/store/redux'
import { useDeleteLaunchDate } from '@api/queries/launchDate'
import { setBannerType } from '@reducers/banner'
import LaunchScreenMobileHeader from '@containers/creator/launchCommunity/components/LaunchScreenMobileHeader'

const ChangeLaunchDate = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { data: membersData } = useGetMembersCount(id || '', {
    enabled: Boolean(id),
  })

  const publishCommunity = usePublishCommunityMutation({
    onMutate: () => {
      toast.loading(_t('communityPublishLoading'), {
        id: 'community-publish',
      })
    },
  })

  const deleteLaunchDate = useDeleteLaunchDate({
    onMutate: () => {
      toast.loading(_t('launchDateDeleteLoading'), {
        id: 'launch-date-delete',
      })
    },
  })

  const hasSubscribedMembers = useMemo(
    () => Boolean(membersData && membersData.count),
    [membersData],
  )

  const handlePublishCommunity = useCallback(() => {
    if (id) {
      publishCommunity.mutate(id, {
        onSuccess: () => {
          toast.success(_t('communityPublishSuccess'), {
            id: 'community-publish',
          })
          dispatch(setBannerType(null))
          navigate(CREATOR_PATH.communityId.replace(':id', id))
        },
        onError: error => {
          toast.error(error.message, {
            id: 'community-publish-error',
          })
          navigate(CREATOR_PATH.communityId.replace(':id', id))
        },
      })
    }
  }, [dispatch, id, navigate, publishCommunity])

  const handleDeleteCommunity = useCallback(() => {
    if (id) {
      deleteLaunchDate.mutate(id, {
        onSuccess: () => {
          toast.success(_t('launchDateDeleteSuccess'), {
            id: 'launch-date-delete',
          })
          navigate(CREATOR_PATH.communityId.replace(':id', id))
        },
        onError: error => {
          toast.error(error.message, {
            id: 'launch-date-delete',
          })
          navigate(CREATOR_PATH.communityId.replace(':id', id))
        },
      })
    }
  }, [deleteLaunchDate, id, navigate])

  const handleSubmit = useCallback(
    (values: TypeChangeLaunchDateFormValues) => {
      if (id) {
        if (hasSubscribedMembers) {
          dispatch(setLaunchDateOption(values[ChangeLaunchDateFormEnum.option]))
          navigate(CREATOR_PATH.changeLaunchDateDisclaimer.replace(':id', id))
        } else {
          switch (values[ChangeLaunchDateFormEnum.option]) {
            case ChangeLaunchDateOptionEnum.PublishNow:
              handlePublishCommunity()
              break
            case ChangeLaunchDateOptionEnum.ChangeDate:
              navigate(CREATOR_PATH.setLaunchDate.replace(':id', id))
              break
            case ChangeLaunchDateOptionEnum.Cancel:
              handleDeleteCommunity()
              break
            default:
              return
          }
        }
      } else {
        navigate('/')
      }
    },
    [
      dispatch,
      handleDeleteCommunity,
      handlePublishCommunity,
      hasSubscribedMembers,
      id,
      navigate,
    ],
  )

  const handleClickCancel = useCallback(() => {
    if (id) {
      navigate(CREATOR_PATH.communityId.replace(':id', id))
    } else {
      navigate('/')
    }
  }, [id, navigate])

  return (
    <LunchCommunityWrapper isFullScreenOnMobile>
      <Formik
        initialValues={changeLaunchDateInitialValues}
        validateOnMount={true}
        onSubmit={handleSubmit}
        validationSchema={changeLaunchDateSchema}
      >
        {({
          isSubmitting,
          isValid,
        }: FormikProps<TypeChangeLaunchDateFormValues>) => (
          <Form className='h-full flex flex-col'>
            <LaunchScreenMobileHeader />
            <div className='p-5 sm:py-13 sm:px-26'>
              <CalendarIcon className='fill-custom-gray-1 w-12 h-12' />
              <div className='h-3' />
              <h3 className='sm:h3 text-[32px] capitalize'>
                {_t('changeLaunchDateTitle')}
              </h3>
              <div className='h-3' />
              <p className='body2' id='change-launch-date-option'>
                {_t('changeLaunchDateOptionTitle')}
              </p>
              <div className='h-[30px]' />
              <div
                role='group'
                aria-labelledby='change-launch-date-option'
                className='flex flex-col gap-3'
              >
                <label className='flex items-center gap-3 capitalize body2'>
                  <Field
                    type='radio'
                    name={ChangeLaunchDateFormEnum.option}
                    value={ChangeLaunchDateOptionEnum.ChangeDate}
                  />
                  {_t('changeLaunchDateOption1')}
                </label>
                <label className='flex items-center gap-3 capitalize body2'>
                  <Field
                    type='radio'
                    name={ChangeLaunchDateFormEnum.option}
                    value={ChangeLaunchDateOptionEnum.PublishNow}
                  />
                  {_t('changeLaunchDateOption2')}
                </label>
                <label className='flex items-center gap-3 capitalize body2'>
                  <Field
                    type='radio'
                    name={ChangeLaunchDateFormEnum.option}
                    value={ChangeLaunchDateOptionEnum.Cancel}
                  />
                  {_t('changeLaunchDateOption3')}
                </label>
              </div>
            </div>
            <LaunchScreenFooter
              previousButtonText={_t('cancel')}
              nextButtonText={_t('next')}
              onClickPrevious={handleClickCancel}
              nextButtonDisabled={isSubmitting || !isValid}
            />
          </Form>
        )}
      </Formik>
    </LunchCommunityWrapper>
  )
}

export default ChangeLaunchDate
