import React from 'react'
import { _t } from '@locales/index'

interface Props {
  filtersApplied?: boolean
}

const NoResults: React.FC<Props> = ({ filtersApplied = false }) => {
  return (
    <div className='flex-1 flex flex-col justify-center items-center'>
      <h1 className='font-poppins font-light text-[54px] leading-[110%] text-custom-gray-4 capitalize text-center'>
        {filtersApplied
          ? _t('membersEmptyStateTextWithFilters')
          : _t('membersEmptyStateText')}
      </h1>
      <div className='h-8' />
      {filtersApplied && (
        <p className='font-poppins font-light text-custom-gray-4 first-letter:capitalize text-lg text-center'>
          {_t('membersEmptyStateChangeFilters')}
        </p>
      )}
    </div>
  )
}

export default NoResults
