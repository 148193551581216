import React from 'react'
import Modal from '@components/modal/Modal'
import { _t } from '@locales/index'
import { ReactComponent as MagicWand } from '@assets/images/common/wand.svg'
import Loader from '@components/loader/Loader'

interface Props {
  visible: boolean
  hasError: boolean
}

const CreateCommunityLoader: React.FC<Props> = ({ visible, hasError }) => {
  return (
    <Modal open={visible} mainContainerClassName={'p-5'}>
      <div className='flex flex-col items-center gap-7.5 p-5 sm:gap-13 sm:p-13 relative'>
        <div className='flex flex-col items-center gap-3 sm:gap-5'>
          <MagicWand className='w-13 h-13 sm:w-26 sm:h-26' />
          <h1 className='h3 sm:h1 capitalize text-center'>
            {_t('createCommunityLoadingScreenTitle')}
          </h1>
        </div>
        <Loader visible={true} fullWidth={true} />
        {hasError && (
          <div className='flex flex-col gap-5'>
            <p className='body2 text-custom-error-red'>
              {_t('createCommunityLoadingScreenError')}
            </p>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default CreateCommunityLoader
