import React from 'react'
import { ReactComponent as SpreadsheetIcon } from '@assets/images/payment/spreadsheet.svg'

import { _t } from '@locales/index'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'

const ExportSpreadsheet = () => {
  const isSmallerDevice = useIsSmallerDevice()

  return (
    <div className='flex gap-3 sm:gap-1 items-center cursor-pointer'>
      <SpreadsheetIcon className='w-6 h-6' />
      <p>
        {isSmallerDevice ? _t('paymentExportSpreadsheet') : _t('paymentExport')}
      </p>
    </div>
  )
}

export default ExportSpreadsheet
