import { _t } from '@locales/index'
import { TypeSelectFilter, TypeSortFilter } from '@customTypes/common'
import {
  MemberEngagementEnum,
  MemberJoinTimeEnum,
  MemberOrderFilterEnum,
  MemberSortFilterEnum,
  MemberStatusEnum,
} from '@enums/member'
import {
  TypeMemberEngagement,
  TypeMemberOrder,
  TypeMemberStatus,
  TypeMemberTime,
} from '@customTypes/member'
import { SortOrderEnum } from '@enums/common'

export const MEMBER_ENGAGEMENT_FILTER: TypeSelectFilter<TypeMemberEngagement> =
  {
    label: _t('memberFilterEngagementLabel'),
    options: [
      {
        id: '1',
        text: _t('memberFilterEngagementOption1'),
        value: MemberEngagementEnum.High,
      },
      {
        id: '2',
        text: _t('memberFilterEngagementOption2'),
        value: MemberEngagementEnum.Mid,
      },
      {
        id: '3',
        text: _t('memberFilterEngagementOption3'),
        value: MemberEngagementEnum.Low,
      },
    ],
  }

export const MEMBER_STATUS_FILTER: TypeSelectFilter<TypeMemberStatus> = {
  label: _t('memberFilterStatusLabel'),
  options: [
    {
      id: '1',
      text: _t('memberFilterStatusOption1'),
      value: MemberStatusEnum.Active,
    },
    {
      id: '2',
      text: _t('memberFilterStatusOption2'),
      value: MemberStatusEnum.Trial,
    },
    {
      id: '3',
      text: _t('memberFilterStatusOption3'),
      value: MemberStatusEnum.Unsubscribed,
    },
  ],
}

export const MEMBER_TIME_FILTER: TypeSelectFilter<TypeMemberTime> = {
  label: _t('memberFilterTimeLabel'),
  options: [
    {
      id: '1',
      text: _t('memberFilterTimeOption1'),
      value: MemberJoinTimeEnum.OneMonth,
    },
    {
      id: '2',
      text: _t('memberFilterTimeOption2'),
      value: MemberJoinTimeEnum.OneToThreeMonths,
    },
    {
      id: '3',
      text: _t('memberFilterTimeOption3'),
      value: MemberJoinTimeEnum.ThreeToSixMonths,
    },
    {
      id: '4',
      text: _t('memberFilterTimeOption4'),
      value: MemberJoinTimeEnum.SixPlusMonths,
    },
  ],
}

export const MEMBER_ORDER_FILTER: TypeSelectFilter<TypeMemberOrder> = {
  label: _t('memberFilterOrderLabel'),
  options: [
    {
      id: '0',
      text: _t('memberFilterOrderOption1'),
      value: MemberOrderFilterEnum.Alphabetical,
    },
    {
      id: '1',
      text: _t('memberFilterOrderOption2'),
      value: MemberOrderFilterEnum.ReverseAlphabetical,
    },
  ],
}

export const MEMBER_SORT_FILTER: TypeSortFilter = {
  label: _t('sort'),
  options: [
    {
      id: '0',
      value: MemberSortFilterEnum.Name,
      order: [
        {
          text: _t('memberSortOption1'),
          value: SortOrderEnum.Ascending,
        },
        {
          text: _t('memberSortOption2'),
          value: SortOrderEnum.Descending,
        },
      ],
    },
    {
      id: '1',
      value: MemberSortFilterEnum.Newest,
      order: [
        {
          text: _t('memberSortOption3'),
          value: SortOrderEnum.Descending,
        },
        {
          text: _t('memberSortOption4'),
          value: SortOrderEnum.Ascending,
        },
      ],
    },
  ],
}

export const MEMBER_ENGAGEMENT_TEXT: Record<TypeMemberEngagement, string> = {
  [MemberEngagementEnum.High]: _t('memberEngagementHigh'),
  [MemberEngagementEnum.Mid]: _t('memberEngagementMid'),
  [MemberEngagementEnum.Low]: _t('memberEngagementLow'),
}

export const MEMBER_STATUS_TEXT: Record<TypeMemberStatus, string> = {
  [MemberStatusEnum.Default]: _t('memberStatusActive'),
  [MemberStatusEnum.Active]: _t('memberStatusActive'),
  [MemberStatusEnum.Trial]: _t('memberStatusTrial'),
  [MemberStatusEnum.Unsubscribed]: _t('memberStatusUnsubscribed'),
}

export const MEMBER_ENGAGEMENT_COLOR: Record<TypeMemberEngagement, string> = {
  [MemberEngagementEnum.High]: '#14D365',
  [MemberEngagementEnum.Mid]: '#F1BC00',
  [MemberEngagementEnum.Low]: '#F53737',
}

export const DEFAULT_MEMBER_PAGE_SIZE = 14

export const MESSAGE_MEMBER_URL = 'https://t.me/'
