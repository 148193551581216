import React, { useCallback } from 'react'
import { _t } from '@locales/index'

interface Props {
  currencySymbol: string
  value: number
  setValue: (price: number) => void
}

const PriceField: React.FC<Props> = ({ currencySymbol, value, setValue }) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(Number(e.target.value))
    },
    [setValue],
  )

  return (
    <div className='flex items-center gap-4'>
      <div className='flex border border-custom-gray-5 rounded overflow-hidden'>
        <div className='py-1.5 px-4 bg-custom-gray-5'>
          <h4 className='h4 text-custom-gray-3'>{currencySymbol}</h4>
        </div>
        <input
          type='number'
          value={value}
          className='py-[11px] px-4 body2 text-custom-turquoise-dark outline-none border-0 w-[73px] text-center'
          onChange={handleChange}
          tabIndex={-1}
        />
      </div>
      <p className='body3 capitalize'>{_t('perMonth')}</p>
    </div>
  )
}

export default PriceField
