import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query'
import { ServerStateKeysEnum } from '@enums/serverState'
import { ApiKeyNameEnum } from '@enums/api'
import apiService from '@services/api/apiService'
import { useProtection } from '@hooks/auth/useProtection'
import {
  TypeCommunityGuidelines,
  TypeGetDefaultGuidelinesResponse,
  TypeGuideline,
  TypeGuidelinesType,
} from '@customTypes/guidelines'
import { AxiosError } from 'axios'

/**********************************
 GET DEFAULT GUIDELINES
 **********************************/

type TypeGetDefaultGuidelinesOptions = UseQueryOptions<
  TypeGetDefaultGuidelinesResponse,
  AxiosError,
  TypeGuideline[],
  any
>

const getDefaultGuidelines = async (
  type: TypeGuidelinesType,
): Promise<TypeGetDefaultGuidelinesResponse> => {
  try {
    const response = await apiService.get(
      ApiKeyNameEnum.template_guidelines,
      type,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetDefaultGuidelines = (
  guidelineType: TypeGuidelinesType,
  options?: TypeGetDefaultGuidelinesOptions,
) => {
  const queryOptions = useProtection<TypeGetDefaultGuidelinesOptions>(options)

  return useQuery(
    [ServerStateKeysEnum.TemplateGuidelines],
    () => getDefaultGuidelines(guidelineType),
    {
      select: data => {
        return data.guidelinesTemplate.map(({ ...guideline }) => guideline)
      },
      ...queryOptions,
    },
  )
}

/**********************************
 CREATE/UPDATE GUIDELINES
 **********************************/

type TypeCommunityGuidelinesOptions = UseMutationOptions<
  TypeCommunityGuidelines,
  AxiosError,
  TypeCommunityGuidelines
>

const createOrUpdateCommunityGuidelines = async (
  values: TypeCommunityGuidelines,
): Promise<TypeCommunityGuidelines> => {
  try {
    const response = await apiService.post(
      ApiKeyNameEnum.community_guidelines,
      values,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useCommunityGuidelinesMutation = (
  options?: TypeCommunityGuidelinesOptions,
) => {
  const queryClient = useQueryClient()

  const queryOptions = useProtection<TypeCommunityGuidelinesOptions>(options)

  return useMutation(createOrUpdateCommunityGuidelines, {
    onSuccess: () => {
      return queryClient.invalidateQueries(ServerStateKeysEnum.Communities)
    },
    ...queryOptions,
  })
}
