import { useMemo } from 'react'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { useGetCommunity } from '@api/queries/community'
import { getCommunityMediaByType } from '@containers/creator/community/utils'
import { CommunityMediaTypeEnum } from '@enums/media'

const useDoesWelcomeMediaExist = (): boolean => {
  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)

  const { data: community } = useGetCommunity(selectedCommunityId, {
    enabled: Boolean(selectedCommunityId),
  })

  return useMemo<boolean>(() => {
    if (!community) {
      return false
    }

    const video = getCommunityMediaByType(
      CommunityMediaTypeEnum.WelcomeVideo,
      community.media,
    )

    const coverPhoto = getCommunityMediaByType(
      CommunityMediaTypeEnum.Cover,
      community.media,
    )

    return Boolean((video && video.length) || (coverPhoto && coverPhoto.length))
  }, [community])
}

export default useDoesWelcomeMediaExist
