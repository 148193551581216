import React, { useCallback } from 'react'
import classNames from 'classnames'
import { ReactComponent as CrossIcon } from '@assets/images/common/cross-thin.svg'
import { TypeLocation } from '@customTypes/location'

interface Props {
  location: TypeLocation
  isSelected: boolean
  onSelect: (locationId: string) => void
  onUnselect: (locationId: string) => void
}

const LocationPill: React.FC<Props> = ({
  location,
  isSelected,
  onSelect,
  onUnselect,
}) => {
  const handleUnselect = useCallback(
    (e: React.MouseEvent, locationId: string) => {
      e.stopPropagation()
      onUnselect(locationId)
    },
    [onUnselect],
  )

  return (
    <div
      className={classNames(
        'px-5 py-2.5 rounded flex items-center justify-center border cursor-pointer gap-x-2.5 transition-all',
      )}
      onClick={() => onSelect(location.id)}
    >
      <p>{location.name}</p>
      {isSelected && (
        <CrossIcon
          stroke={'white'}
          className='hover:scale-110 transition-transform w-4'
          onClick={e => handleUnselect(e, location.id)}
        />
      )}
    </div>
  )
}

export default LocationPill
