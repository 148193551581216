import React, { ComponentType } from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { WithAuthenticationRequiredOptions } from '@auth0/auth0-react/dist/with-authentication-required'

interface Props extends WithAuthenticationRequiredOptions {
  component: ComponentType
}

export const ProtectedComponent = ({ component, ...options }: Props) => {
  const Component = withAuthenticationRequired(component, options)

  return <Component />
}
