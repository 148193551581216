import React, { useCallback, useEffect, useMemo, useState } from 'react'
import 'react-modern-drawer/dist/index.css'
import { _t } from '@locales/index'
import {
  TypeBroadcastMessage,
  TypeBroadcastMessageFormValues,
  TypeBroadcastMessagePayload,
} from '@customTypes/broadcastMessage'
import { BroadcastMessageFormEnum } from '@enums/broadcastMessage'
import { toast } from 'react-hot-toast'
import { useSendBroadcastMessage } from '@api/queries/broadcastMessage'
import { Form, Formik } from 'formik'
import { useParams } from 'react-router-dom'
import BroadcastMessagePopup from '@containers/organization/broadcastMessages/BroadcastMessagePopup'
import { useGetAudiences } from '@api/queries/segmentation'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { AudienceTypeEnum } from '@enums/segmentation'
import UploadService from '@services/uploader/uploadService'
import {
  CommunityMediaTypeEnum,
  MediaFileTypeEnum,
  MediaTypeEnum,
} from '@enums/media'
import { broadcastMessageSchema } from '@containers/organization/broadcastMessages/utils'

interface Props {
  open: boolean
  onClose: () => void
}

const BroadcastMessages: React.FC<Props> = ({ open, onClose }) => {
  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)
  const { audienceId: audienceIdFromParams } = useParams()

  const [uploadQueueKey, setUploadQueueKey] = useState<string>('')

  const { data: communityAudiences } = useGetAudiences(selectedCommunityId, {
    enabled: Boolean(selectedCommunityId),
  })

  const allMembersAudienceId = useMemo<string | undefined>(() => {
    if (!communityAudiences) {
      return undefined
    }
    const allMembersAudience = communityAudiences.find(
      audience => audience.type === AudienceTypeEnum.All,
    )
    return allMembersAudience?.id
  }, [communityAudiences])

  const initialValues = useMemo<TypeBroadcastMessageFormValues>(() => {
    return {
      [BroadcastMessageFormEnum.Message]: '',
      [BroadcastMessageFormEnum.MediaUrl]: '',
      [BroadcastMessageFormEnum.MediaFileType]: '',
      [BroadcastMessageFormEnum.Link]: '',
      [BroadcastMessageFormEnum.LinkLabel]: '',
    }
  }, [])

  const { mutate: sendBroadcast } = useSendBroadcastMessage({
    onMutate: () => {
      toast.loading(_t('sendBroadcastMessageLoading'), {
        id: 'broadcast-send',
      })
    },
    onSuccess: () => {
      toast.success(_t('sendBroadcastMessageSuccess'), {
        id: 'broadcast-send',
      })
      onClose()
    },
    onError: error => {
      toast.error(error.message, {
        id: 'broadcast-send',
      })
    },
  })

  const uploadMedia = useCallback(() => {
    if (uploadQueueKey) {
      UploadService.runQueue(uploadQueueKey)
    }
  }, [uploadQueueKey])

  useEffect(() => {
    if (selectedCommunityId) {
      const key = `broadcast-message-queue-${selectedCommunityId}`
      UploadService.createQueueItem({
        type: MediaTypeEnum.CommunityMedia,
        postParams: {
          mediaType: MediaTypeEnum.CommunityMedia,
          communityId: selectedCommunityId,
          subType: CommunityMediaTypeEnum.GeneralMedia,
        },
        cb: () => {},
        key,
        uploadAutomatically: false,
      })
      setUploadQueueKey(key)
    }
  }, [selectedCommunityId, uploadMedia])

  const handleSubmit = useCallback(
    (formValues: TypeBroadcastMessageFormValues) => {
      if (audienceIdFromParams || allMembersAudienceId) {
        const message = {} as TypeBroadcastMessage
        if (
          formValues[BroadcastMessageFormEnum.Link] &&
          formValues[BroadcastMessageFormEnum.LinkLabel]
        ) {
          message['link'] = formValues[BroadcastMessageFormEnum.Link]
          message['linkLabel'] = formValues[BroadcastMessageFormEnum.LinkLabel]
        }

        if (formValues[BroadcastMessageFormEnum.MediaUrl]) {
          UploadService.setQueueItemCallback(uploadQueueKey, data => {
            const mediaUrl = data?.response?.media?.url
            if (mediaUrl) {
              message['caption'] = formValues[BroadcastMessageFormEnum.Message]
              console.log(
                'formValues[BroadcastMessageFormEnum.MediaFileType]',
                formValues[BroadcastMessageFormEnum.MediaFileType],
              )
              if (
                formValues[BroadcastMessageFormEnum.MediaFileType] ===
                MediaFileTypeEnum.Image
              ) {
                message['photo'] = mediaUrl
              }

              if (
                formValues[BroadcastMessageFormEnum.MediaFileType] ===
                MediaFileTypeEnum.Video
              ) {
                message['video'] = mediaUrl
              }
            }
            const payload: TypeBroadcastMessagePayload = {
              audience: audienceIdFromParams || allMembersAudienceId || '',
              message,
            }
            sendBroadcast(payload)
          })
          uploadMedia()
        } else {
          message['text'] = formValues[BroadcastMessageFormEnum.Message]
          const payload: TypeBroadcastMessagePayload = {
            audience: audienceIdFromParams || allMembersAudienceId || '',
            message,
          }
          sendBroadcast(payload)
        }
      }
    },
    [
      allMembersAudienceId,
      audienceIdFromParams,
      sendBroadcast,
      uploadMedia,
      uploadQueueKey,
    ],
  )

  if (!audienceIdFromParams && !allMembersAudienceId) {
    return null
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={broadcastMessageSchema}
      onSubmit={handleSubmit}
    >
      <Form className='h-full w-full'>
        <BroadcastMessagePopup
          open={open}
          onClose={onClose}
          mediaUploadKey={uploadQueueKey}
        />
      </Form>
    </Formik>
  )
}

export default BroadcastMessages
