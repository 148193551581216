import React, { useEffect, useState } from 'react'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { useGetCommunity } from '@api/queries/community'
import CommunityCreatedPopup from '@containers/creator/community/components/CommunityCreatedPopup'
import UploadCommunityMedia from '@containers/creator/uploadCommunityMedia/UploadCommunityMedia'
import CommunityHome from '@containers/creator/communityHome/CommunityHome'
import useDoesWelcomeMediaExist from '@hooks/common/useDoesWelcomeMediaExist'
import useGetCommunityAdminRole from '@hooks/common/useGetCommunityAdminRole'
import { CommunityAdminRoleEnum } from '@enums/community'
import { useNavigate } from 'react-router-dom'
import { CREATOR_PATH } from '@routes/creator/creatorPath'

const Community = () => {
  const navigate = useNavigate()

  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)
  const { communityAdminRole } = useGetCommunityAdminRole()
  const doesWelcomeMediaExist = useDoesWelcomeMediaExist()

  const [showCommunityCreatedPopup, setShowCommunityCreatedPopup] =
    useState(false)

  const { data: community } = useGetCommunity(selectedCommunityId, {
    enabled: Boolean(selectedCommunityId),
  })

  useEffect(() => {
    if (communityAdminRole && selectedCommunityId) {
      if (communityAdminRole === CommunityAdminRoleEnum.Creator)
        navigate(
          CREATOR_PATH.paymentsAndBilling.replace(':id', selectedCommunityId),
        )
    }
  }, [communityAdminRole, navigate, selectedCommunityId])

  if (!community) {
    return null
  }

  return (
    <div className='flex flex-1 w-full h-full'>
      {!doesWelcomeMediaExist ? <UploadCommunityMedia /> : <CommunityHome />}
      <CommunityCreatedPopup
        open={showCommunityCreatedPopup}
        onClose={() => setShowCommunityCreatedPopup(false)}
      />
    </div>
  )
}

export default Community
