import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { _t } from '@locales/index'
import { Form, Formik, FormikHelpers } from 'formik'
import { useCommunityUpdate, useGetCommunity } from '@api/queries/community'
import { useAppDispatch, useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { photoCoverUrlSchema } from '@containers/creator/createCommunity/utils'
import { CommunityMediaTypeEnum, MediaTypeEnum } from '@enums/media'
import UploadService from '@services/uploader/uploadService'
import uploadService from '@services/uploader/uploadService'
import CommunityPageWrapper from '@containers/creator/community/components/CommunityPageWrapper'
import PhoneMockup from '@components/phoneMockup/PhoneMockup'
import { useNavigate } from 'react-router-dom'
import { CREATOR_PATH } from '@routes/creator/creatorPath'
import { TypeEditCoverPhotoFormValue } from '@customTypes/coverPhoto'
import { CoverPhotoFormEnum } from '@enums/coverPhoto'
import { toast } from 'react-hot-toast'
import { setCommunityMenuItem } from '@reducers/community'
import Footer from '@components/footer/Footer'
import { ImageEditorTypeEnum } from '@enums/common'
import ImageUploadWrapper from '@components/imageUpload/ImageUploadWrapper'
import { getCommunityMediaByType } from '@containers/creator/community/utils'
import { getImageUrl } from '@common/media'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'
import { LAYOUT_FOOTER } from '@const/layout'
import useBreakpoint from '@hooks/responsive/useBreakpoint'
import classNames from 'classnames'

const EditCoverPhoto = () => {
  const dispatch = useAppDispatch()
  const breakpoint = useBreakpoint()
  const isSmallerDevice = useIsSmallerDevice()
  const communityId = useAppSelector(selectCurrentCommunityId)

  const [uploadQueueKey, setUploadQueueKey] = useState<string>('')
  const navigate = useNavigate()
  const { data: community, refetch: refetchCommunity } = useGetCommunity(
    communityId,
    {
      enabled: Boolean(communityId),
    },
  )

  const { mutate: updateCommunity } = useCommunityUpdate({
    onMutate: () => {
      toast.loading(_t('updateCommunityLoading'), {
        id: 'community-update',
      })
    },
    onError: error => {
      toast.error(error.message, { id: 'community-update' })
    },
  })

  const initialValues = useMemo<TypeEditCoverPhotoFormValue>(() => {
    const coverPhoto = getCommunityMediaByType(
      CommunityMediaTypeEnum.Cover,
      community?.media || [],
    )

    let coverPhotoUrl = ''

    if (coverPhoto) {
      coverPhotoUrl = getImageUrl(coverPhoto[0])
    }

    return {
      [CoverPhotoFormEnum.Photo]: coverPhotoUrl,
    }
  }, [community?.media])

  const handleUploadMedia = useCallback(() => {
    if (uploadQueueKey) {
      uploadService.setQueueItemCallback(uploadQueueKey, async () => {
        await refetchCommunity()
      })
      uploadService.runQueue(uploadQueueKey)
      console.log(uploadService.getQueueItem(uploadQueueKey))
    }
  }, [refetchCommunity, uploadQueueKey])

  const handleSwitchToVideoClick = useCallback(() => {
    if (communityId) {
      navigate(CREATOR_PATH.uploadWelcomeVideo.replace(':id', communityId))
      dispatch(setCommunityMenuItem(undefined))
    }
  }, [communityId, dispatch, navigate])

  const handleSubmit = useCallback(
    (
      formValues: TypeEditCoverPhotoFormValue,
      _: FormikHelpers<TypeEditCoverPhotoFormValue>,
    ) => {
      console.log('sub')
      if (formValues[CoverPhotoFormEnum.Photo]) {
        handleUploadMedia()
      }
      if (communityId) {
        updateCommunity({
          id: communityId,
          coverPhotoUrl: formValues[CoverPhotoFormEnum.Photo],
        })
      }
    },
    [communityId, handleUploadMedia, updateCommunity],
  )

  useEffect(() => {
    if (community) {
      const key = `community-welcome-cover-photo-${community.id}`
      UploadService.createQueueItem({
        type: MediaTypeEnum.CommunityMedia,
        postParams: {
          mediaType: MediaTypeEnum.CommunityMedia,
          communityId: community.id,
          subType: CommunityMediaTypeEnum.Cover,
        },
        cb: null,
        key,
        uploadAutomatically: false,
      })
      setUploadQueueKey(key)
    }
  }, [community])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={photoCoverUrlSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ setFieldValue, dirty, isValid, values, submitForm }) => (
        <Form className='flex flex-col flex-1'>
          <CommunityPageWrapper hasFooter>
            <div className='bg-custom-green-gray p-5 sm:p-13 flex flex-1 flex-col'>
              <div className='flex flex-col h-full gap-8'>
                <div className='flex flex-col gap-3'>
                  <p className='h4 capitalize'>
                    {_t('editSignUpPageCoverPhotoTitle')}
                  </p>
                  <p className='body2 capitalize sm:hidden'>
                    {_t('communityUploadCoverPhotoSize')}
                  </p>
                </div>
                <div className='flex flex-col items-center sm:items-start sm:bg-white sm:rounded-[20px] max-w-[440px] overflow-hidden'>
                  <div className='sm:py-13 sm:px-[116px]'>
                    <div className='w-[257px] h-[527px] sm:w-[206px] sm:h-[426px]'>
                      <PhoneMockup
                        community={community}
                        description={community?.description}
                        backgroundMediaUrl={values[CoverPhotoFormEnum.Photo]}
                      />
                    </div>
                  </div>
                  <div className='bg-white justify-center gap-3 items-center h-[76px] hidden sm:flex w-full'>
                    <ImageUploadWrapper
                      uploadQueueKey={uploadQueueKey}
                      onSetImageUrl={value => {
                        setFieldValue(CoverPhotoFormEnum.Photo, value)
                      }}
                      editorType={ImageEditorTypeEnum.PhoneMockup}
                    >
                      {({ open }) => (
                        <div className={'flex'}>
                          <button
                            className={classNames(
                              'btn',
                              dirty ? 'btn-outlined' : 'btn-pink',
                            )}
                            type={'button'}
                            onClick={open}
                          >
                            {_t('imageEditorTitle')}
                          </button>
                        </div>
                      )}
                    </ImageUploadWrapper>
                    <button
                      className={'btn btn-outlined'}
                      type={'button'}
                      onClick={handleSwitchToVideoClick}
                    >
                      {_t('imageEditSwitchToVideo')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </CommunityPageWrapper>
          {!isSmallerDevice && (
            <Footer
              primaryButton={{
                text: _t('confirm'),
                disabled: !dirty || !isValid,
              }}
              secondaryButton={{
                text: _t('cancel'),
                onClick: () =>
                  setFieldValue(
                    CoverPhotoFormEnum.Photo,
                    initialValues[CoverPhotoFormEnum.Photo],
                  ),
                hidden: !dirty,
              }}
            />
          )}
          <div
            className='bg-white justify-center gap-3 items-center flex sm:hidden'
            style={{
              height: LAYOUT_FOOTER[breakpoint],
            }}
          >
            <button
              className={'btn btn-outlined'}
              type={'button'}
              onClick={handleSwitchToVideoClick}
            >
              {_t('imageEditSwitchToVideo')}
            </button>
            <ImageUploadWrapper
              uploadQueueKey={uploadQueueKey}
              onSetImageUrl={value => {
                setFieldValue(CoverPhotoFormEnum.Photo, value)
                submitForm().then()
              }}
              editorType={ImageEditorTypeEnum.PhoneMockup}
            >
              {({ open }) => (
                <div className={'flex'}>
                  <button
                    className='btn btn-pink'
                    type={'button'}
                    onClick={open}
                  >
                    {_t('imageEditorTitle')}
                  </button>
                </div>
              )}
            </ImageUploadWrapper>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default EditCoverPhoto
