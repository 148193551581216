import React, { useCallback } from 'react'
import { useGetSuppliedContent } from '@api/queries/community'
import { useField } from 'formik'
import { append, without } from 'ramda'
import SuppliedContentChip from '@containers/creator/createCommunity/components/SuppliedContentChip'
import classNames from 'classnames'

interface Props {
  name: string
  justifyClass?: string
}

const SuppliedContent: React.FC<Props> = ({ name, justifyClass }) => {
  const [, meta, helpers] = useField<string[]>(name)

  const { data: communitySuppliedContent } = useGetSuppliedContent()

  const handleSelectChip = useCallback(
    (id: string) => {
      const updatedInterests = append(id)(meta.value)
      helpers.setValue(updatedInterests)
    },
    [helpers, meta.value],
  )

  const handleUnselectChip = useCallback(
    (id: string) => {
      const updatedInterests = without([id])(meta.value)
      helpers.setValue(updatedInterests)
    },
    [helpers, meta.value],
  )

  return (
    <div
      className={classNames(
        'flex gap-2.5 flex-wrap w-full h-full',
        justifyClass || 'justify-start',
      )}
    >
      {communitySuppliedContent?.map(suppliedContent => {
        return (
          <SuppliedContentChip
            data={suppliedContent}
            key={suppliedContent.id}
            isSelected={meta.value.includes(suppliedContent.id)}
            onSelect={handleSelectChip}
            onUnselect={handleUnselectChip}
          />
        )
      })}
    </div>
  )
}

export default SuppliedContent
