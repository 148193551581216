import React, { useCallback, useMemo } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { _t } from '@locales/index'
import UserMenu from '@containers/organization/navbar/components/UserMenu'
import Logo from '@containers/organization/navbar/components/Logo'
import { NAVBAR_HEIGHT } from '@const/layout'
import useBreakpoint from '@hooks/responsive/useBreakpoint'
import { ORGANIZATION_PATH } from '@routes/organization/organizationPath'
import SupportMenu from '@containers/organization/navbar/components/SupportMenu'
import useIsSmallerDevice from '@hooks/common/useIsSmallerDevice'
import { ReactComponent as HamburgerMenuIcon } from '@assets/images/common/hamburger-menu.svg'
import { openDrawerMenu } from '@reducers/drawerMenu'
import { DrawerMenuTypeEnum } from '@enums/common'
import { useAppDispatch } from '@hooks/store/redux'
import { useGetUserProfile } from '@api/queries/profile'
import { ProfileOnboardingStatusEnum } from '@enums/profile'
import FiltersButton from '@containers/organization/organization/components/FiltersButton'
import GroupButton from '@containers/organization/organization/components/GroupButton'
import BroadcastButton from '@containers/organization/organization/components/BroadcastButton'

interface Props {}

const Navbar: React.FC<Props> = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const breakpoint = useBreakpoint()
  const isSmallerDevice = useIsSmallerDevice()
  const { isAuthenticated, loginWithRedirect } = useAuth0()

  const { data: profile } = useGetUserProfile()

  const handleClickLogo = useCallback(() => {
    navigate(ORGANIZATION_PATH.root)
  }, [navigate])

  const handleClickMenuIcon = useCallback(() => {
    dispatch(openDrawerMenu(DrawerMenuTypeEnum.Organization))
  }, [dispatch])

  const showOrganizationMenuIcon = useMemo<boolean>(() => {
    return (
      isSmallerDevice &&
      isAuthenticated &&
      profile?.onboardingStatus !== ProfileOnboardingStatusEnum.Initiated
    )
  }, [isAuthenticated, isSmallerDevice, profile?.onboardingStatus])

  return (
    <div
      className='relative min-w-max flex justify-between items-center px-5 border-b border-custom-gray-5'
      style={{
        height: NAVBAR_HEIGHT[breakpoint],
      }}
    >
      {showOrganizationMenuIcon && (
        <HamburgerMenuIcon
          className='cursor-pointer fill-custom-navy-blue'
          onClick={handleClickMenuIcon}
        />
      )}
      <Logo onClick={handleClickLogo} />
      <div className='hidden sm:flex items-center gap-1.5'>
        <FiltersButton />
        <GroupButton />
        <BroadcastButton />
      </div>
      {isAuthenticated ? (
        <div className='flex gap-4'>
          {!isSmallerDevice && <SupportMenu />}
          <UserMenu />
        </div>
      ) : (
        <p
          className='font-sans cursor-pointer capitalize ml-auto'
          onClick={loginWithRedirect}
        >
          {_t('logIn')}
        </p>
      )}
    </div>
  )
}

export default Navbar
