import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { _t } from '@locales/index'
import { ReactComponent as PointerIcon } from '@assets/images/common/down-pointer.svg'
import { useField } from 'formik'
import useBreakpoint from '@hooks/responsive/useBreakpoint'
import '../CouponCalendar.css'
import { BreakpointsEnum } from '@enums/theme'
import { Popover } from '@headlessui/react'
import CalendarWrapper from '@components/calendar/Calendar'
import classNames from 'classnames'
import moment from 'moment'

interface Props {
  onCouponDurationError: Function
  onHandleRange: Function
}

const CouponDuration: React.FC<Props> = ({
  onCouponDurationError,
  onHandleRange,
}) => {
  const breakpoint = useBreakpoint()
  const [values, meta, helpers] = useField('couponDuration')
  const [buttonPlaceHolder, setButtonPlaceHolder] = useState<string>(
    _t('promoCodeDuration'),
  )
  const [maxDate, setMaxDate] = useState<any>(undefined)
  const [showDoubleView, setShowDoubleView] = useState<boolean>(true)
  const [startDate, setStartDate] = useState<Date | undefined>(undefined)
  const [isRangeComplete, setIsRangeComplete] = useState<boolean>(false)

  useEffect(() => {
    setShowDoubleView(breakpoint !== BreakpointsEnum.sm)
  }, [breakpoint])

  const handleDuration = useCallback(
    dates => {
      onHandleRange(dates)
      if (values.value[0] === dates[0] && values.value[1] === dates[1]) {
        return
      }
      setIsRangeComplete(true)
      setStartDate(dates[0])

      if (
        values.value.length === 0 ||
        values.value[0] !== dates[0] ||
        values.value[1] !== dates[1]
      ) {
        helpers.setValue(dates)
      }
      const stringDate =
        moment(dates[0]).format('MMM') +
        ' ' +
        moment(dates[0]).format('D') +
        ' - ' +
        moment(dates[1]).format('MMM') +
        ' ' +
        moment(dates[1]).format('D')
      setButtonPlaceHolder(stringDate)
    },
    [
      setButtonPlaceHolder,
      setStartDate,
      setIsRangeComplete,
      helpers,
      values,
      onHandleRange,
    ],
  )

  useEffect(() => {
    if (startDate && !isRangeComplete) {
      const newDay = startDate
      newDay.setMonth(newDay.getMonth() + 1)
      setMaxDate(newDay)
    }
  }, [startDate, isRangeComplete])

  useEffect(() => {
    isRangeComplete && setMaxDate(undefined)
  }, [isRangeComplete])

  useEffect(() => {
    meta.touched && onCouponDurationError(meta.error)
  }, [meta.touched, meta.error, onCouponDurationError])

  return (
    <Fragment>
      <Popover className='relative'>
        <Popover.Button className='border border-custom-gray-5 rounded sm:ml-8 px-3 py-1 min-w-[150px] flex flex-row items-center justify-between mt-1'>
          {buttonPlaceHolder}
          <PointerIcon className='w-6 h-6' fill={'#000'} />
        </Popover.Button>
        <Popover.Overlay className='fixed inset-0 bg-white opacity-60 z-[100]' />
        <Popover.Panel
          className={classNames(
            'absolute z-[100] top-[60px]',
            showDoubleView && 'left-[-460px]',
          )}
        >
          <CalendarWrapper
            onSaveDurationDates={dates => handleDuration(dates)}
            title1={_t('calendarSetDates')}
            title2={_t('calendarMaxDuration')}
            showDoubleView={showDoubleView}
            onGetClickedDay={day => {
              setStartDate(day)
              setIsRangeComplete(false)
            }}
            maxDate={maxDate}
            showFooter
          />
        </Popover.Panel>
      </Popover>
    </Fragment>
  )
}

export default CouponDuration
