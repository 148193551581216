import React, { useMemo } from 'react'
import { TypeCreateCommunityFormValues } from '@customTypes/community'
import { FormikHelpers } from 'formik'
import {
  COMMUNITY_WIZARD_FOOTER_HEIGHT,
  COMMUNITY_WIZARD_HEADER_HEIGHT,
} from '@const/layout'
import useBreakpoint from '@hooks/responsive/useBreakpoint'
import { BreakpointsEnum } from '@enums/theme'

export interface CommunityWizardScreenProps {
  validationSchema?: object
  onSubmit?: (
    values: TypeCreateCommunityFormValues,
    formikHelpers: FormikHelpers<TypeCreateCommunityFormValues>,
  ) => void
  disableSubmitOnInvalid?: boolean
  hideFooter?: boolean
  hideSidebar?: boolean
  label: string
  children: React.ReactNode
}

const CommunityWizardScreen: React.FC<CommunityWizardScreenProps> = ({
  children,
}) => {
  const breakpoint = useBreakpoint()

  const isSmallerDevice: boolean = useMemo(
    () => breakpoint === BreakpointsEnum.sm,
    [breakpoint],
  )

  return (
    <div
      className='overflow-y-scroll no-scrollbar'
      style={{
        height: `calc((${
          isSmallerDevice ? 'var(--vh, 1vh) * 100' : '100vh'
        } - ${COMMUNITY_WIZARD_FOOTER_HEIGHT[breakpoint]}px) - ${
          COMMUNITY_WIZARD_HEADER_HEIGHT[breakpoint]
        }px`,
      }}
    >
      {children}
    </div>
  )
}
export default CommunityWizardScreen
