import React from 'react'
import FilterSegmentationLabelDD from '@containers/organization/filtersForm/components/FilterSegmentationLabelDD'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { useGetCommunityUserSegmentation } from '@api/queries/userSegmentation'

const SegmentationLabelWrapper = () => {
  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)
  const { data: userSegmentation } = useGetCommunityUserSegmentation(
    selectedCommunityId,
    {
      enabled: Boolean(selectedCommunityId),
    },
  )
  return (
    <div className={'max-h-[450px] overflow-scroll'}>
      {userSegmentation?.segmentations !== undefined &&
      userSegmentation?.segmentations?.length > 0 ? (
        userSegmentation?.segmentations?.map((segmentation, index) => {
          return (
            <FilterSegmentationLabelDD
              segmentationId={segmentation.segmentationId}
              key={index}
            />
          )
        })
      ) : (
        <div>no segmentations to show</div>
      )}
    </div>
  )
}

export default SegmentationLabelWrapper
