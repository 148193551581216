import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TypeFooterState, TypeFooterType } from '@customTypes/store'

const initialState: TypeFooterState = {
  type: null,
}

/* CASE REDUCERS */
const setType: CaseReducer<
  TypeFooterState,
  PayloadAction<TypeFooterType | null>
> = (state, action) => {
  state.type = action.payload
}

const footerSlice = createSlice({
  name: 'footer',
  initialState,
  reducers: {
    setType,
  },
})

export const { setType: setFooterType } = footerSlice.actions
const footerReducer = footerSlice.reducer

export default footerReducer
