import { TypeMedia } from '@customTypes/media'
import { propOr } from 'ramda'

export const getImageUrl: (media?: TypeMedia) => string = propOr<string>(
  '',
  'url',
)

export const getVideoUrl: (media?: TypeMedia) => string = propOr<string>(
  '',
  'url',
)
