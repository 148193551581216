import React, { useMemo } from 'react'
import { _t } from '@locales/index'
import { DEFAULT_CURRENCY_SYMBOL } from '@const/currency'
import { RevenueChangeEnum } from '@enums/payment'
import { useGetUserProfile } from '@api/queries/profile'
import { useGetCommunityRevenue } from '@api/queries/billing'
import DeviationArrow from '@components/deviationArrow/DeviationArrow'
import { TypeDeviation } from '@customTypes/common'
import { DeviationEnum } from '@enums/common'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'

interface Props {}

const LastMonthIncome: React.FC<Props> = () => {
  const { data: profile } = useGetUserProfile()
  const selectedCommunityId = useAppSelector(selectCurrentCommunityId)
  const { data: revenueData, isLoading } = useGetCommunityRevenue(
    {
      profileId: profile?.id,
      communityId: selectedCommunityId,
    },
    {
      enabled: Boolean(profile?.id && selectedCommunityId),
    },
  )

  const deviation = useMemo<TypeDeviation>(() => {
    if (!revenueData || !revenueData.revenueChange) {
      return null
    }
    if (revenueData.revenueChange === RevenueChangeEnum.Increase) {
      return DeviationEnum.Up
    } else {
      return DeviationEnum.Down
    }
  }, [revenueData])

  const revenue = useMemo<number>(
    () => Math.round((revenueData?.lastMonthRevenue || 0) * 100) / 100,
    [revenueData?.lastMonthRevenue],
  )

  return (
    <div className='w-full bg-white p-3 sm:p-5 rounded-xl'>
      <div className='w-full h-full flex justify-between items-start'>
        <div className='h-full flex flex-col sm:flex-col-reverse gap-6 justify-between'>
          {isLoading ? (
            <div className='skeleton' />
          ) : (
            <div className='flex items-center gap-3'>
              <p className='h4 sm:h2 leading-normal sm:leading-[3.5rem]'>{`${DEFAULT_CURRENCY_SYMBOL}${revenue}`}</p>
              {revenueData && <DeviationArrow deviation={deviation} />}
            </div>
          )}
          <div className='flex justify-between items-center'>
            <p className='caption text-custom-gray-4 capitalize sm:body2 sm:text-custom-black sm:!leading-[2.25rem]'>
              {_t('lastMonthIncomeTitle')}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LastMonthIncome
