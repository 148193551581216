import React, { useCallback, useMemo } from 'react'
import { _t } from '@locales/index'
import InputField from '@components/form/InputField'
import { CommunityFormEnum } from '@enums/community'
import { Form, Formik } from 'formik'
import { useCommunityUpdate, useGetCommunity } from '@api/queries/community'
import { useAppSelector } from '@hooks/store/redux'
import { selectCurrentCommunityId } from '@selectors/community'
import { TypeCommunityTitleFormValue } from '@customTypes/community'
import { communityNameSchema } from '@containers/creator/createCommunity/utils'
import { toast } from 'react-hot-toast'
import EditSignUpPageWrapper from '@containers/creator/editCommunity/components/EditSignUpPageWrapper'

const EditCommunityName = () => {
  const communityId = useAppSelector(selectCurrentCommunityId)

  const { data: community } = useGetCommunity(communityId, {
    enabled: Boolean(communityId),
  })

  const { mutate: updateCommunity, isLoading } = useCommunityUpdate({
    onMutate: () => {
      toast.loading(_t('updateCommunityLoading'), {
        id: 'community-update',
      })
    },
    onError: error => {
      toast.error(error.message, { id: 'community-update' })
    },
  })

  const initialValues = useMemo<TypeCommunityTitleFormValue>(() => {
    return { [CommunityFormEnum.Title]: community?.title || '' }
  }, [community?.title])

  const handleSubmit = useCallback(
    function (formValues: TypeCommunityTitleFormValue) {
      if (community) {
        const values = {
          id: community.id,
          title: formValues[CommunityFormEnum.Title],
        }
        updateCommunity(values, {
          onSuccess: () => {
            toast.success(_t('updateCommunitySuccess'), {
              id: 'community-update',
            })
          },
        })
      }
    },
    [community, updateCommunity],
  )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={communityNameSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isValid, dirty, isSubmitting }) => (
        <Form className='w-full'>
          <EditSignUpPageWrapper
            loading={isLoading}
            submitDisabled={!isValid || !dirty || isSubmitting}
            title={_t('editSignUpPageCommunityNameTitle')}
            mobileTitle={_t('editSignUpPageCommunityNameMobileTitle')}
          >
            <div className='max-w-[572px]'>
              <InputField
                name={CommunityFormEnum.Title}
                id={CommunityFormEnum.Title}
                placeholder={_t('createCommunityNameInputFieldPlaceholder')}
                textClass='body2 sm:h3 font-normal capitalize w-full sm:w-auto'
                paddingClass='p-5'
              />
            </div>
          </EditSignUpPageWrapper>
        </Form>
      )}
    </Formik>
  )
}

export default EditCommunityName
