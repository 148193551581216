import React from 'react'

import classNames from 'classnames'

import { _t } from '@locales/index'

import { ReactComponent as RightArrow } from '@assets/images/common/arrow-2.svg'
import { ReactComponent as CheckedCircle } from '@assets/images/common/checked-circle-filled.svg'

interface Props {
  iconSrc: string
  title: string
  description: string
  isCurrent: boolean
  onClickCta: () => void
  stepNumber: number
  isCompleted: boolean
}

const OnboardingStep: React.FC<Props> = ({
  iconSrc,
  isCurrent,
  title,
  description,
  onClickCta,
  stepNumber,
  isCompleted,
}) => {
  return (
    <div
      className={classNames(
        'p-5 sm:px-8 sm:py-5 rounded-xl flex flex-col gap-5 max-w-[370px] z-0 relative sm:min-h-[350px]',
        isCurrent
          ? 'custom-shadow-3 border border-custom-pink'
          : 'border border-custom-gray-5 pointer-events-none',
      )}
    >
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-3'>
          <img src={iconSrc} className='w-6 h-6 sm:hidden' alt='option-icon' />
          <p className='body2 capitalize'>{`${_t(
            'onboardingStep',
          )} ${stepNumber}`}</p>
        </div>
        {isCompleted && (
          <CheckedCircle className='fill-custom-turquoise stroke-custom-turquoise w-6 h-6 opacity-100 z-10 sm:hidden' />
        )}
      </div>
      <div className='flex flex-col items-start gap-3'>
        <img
          src={iconSrc}
          className='w-8 h-8 hidden sm:block'
          alt='option-icon'
        />
        <h4
          className={classNames(
            'capitalize',
            isCompleted
              ? 'body2 sm:h4 sm:tracking=[-1px] font-semibold'
              : 'h4 tracking-[-1px]',
          )}
        >
          {title}
        </h4>
        <p className='body3'>{description}</p>
      </div>
      {isCurrent && (
        <button
          type={'button'}
          className='btn btn-pink rounded-full mt-auto w-full sm:w-[146px]'
          onClick={onClickCta}
        >
          {_t('onboardingStepCta')}
          <RightArrow className='w-6 h-6 fill-white' />
        </button>
      )}
      {isCompleted && (
        <CheckedCircle className='fill-custom-turquoise stroke-custom-turquoise w-12 h-12 opacity-100 z-10 absolute bottom-5 left-8 hidden sm:block' />
      )}
      {!isCurrent && <div className='absolute inset-0 bg-white/50' />}
    </div>
  )
}

export default OnboardingStep
