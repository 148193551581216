import React, { useMemo } from 'react'
import { Disclosure } from '@headlessui/react'

import classNames from 'classnames'
import UserInfo from './UserInfo'
import FeeDetails from './FeeDetails'
import { CommunityAdminRoleEnum } from '@enums/community'
import { TypeBillingTransaction } from '@customTypes/billing'
import { DEFAULT_CURRENCY_SYMBOL } from '@const/currency'
import moment from 'moment/moment'
import { TRANSACTION_TYPE_TEXT } from '@const/billing'
import { BillingTransactionTypeEnum } from '@enums/billing'
import ExpandContractButton from '@containers/creator/communityTransactionsTable/components/ExpandContractButton'
import AffiliateFee from '@containers/creator/communityTransactionsTable/components/AffiliateFee'
import useGetCommunityAdminRole from '@hooks/common/useGetCommunityAdminRole'

interface Props {
  transaction: TypeBillingTransaction
}

const TransactionItemTableView: React.FC<Props> = ({ transaction }) => {
  const { communityAdminRole: communityAdminRoleOfLoggedInUser } =
    useGetCommunityAdminRole()

  const member = useMemo(
    () =>
      transaction.payingBillingEntity?.entity ||
      transaction.associatedBillingEntity.entity,
    [
      transaction.associatedBillingEntity.entity,
      transaction.payingBillingEntity?.entity,
    ],
  )

  const isCommunityOwner = useMemo(
    () => communityAdminRoleOfLoggedInUser === CommunityAdminRoleEnum.Owner,
    [communityAdminRoleOfLoggedInUser],
  )

  const isRefundType = useMemo(
    () => transaction.type === BillingTransactionTypeEnum.ManualRefund,
    [transaction.type],
  )

  return (
    <Disclosure as={'div'} className='col-span-10 grid grid-cols-10'>
      {({ open }) => (
        <div
          className={classNames(
            'col-span-10 grid grid-cols-10 mb-1 rounded py-1 px-5 items-baseline',
            open ? 'bg-custom-turquoise bg-opacity-10' : 'odd:bg-custom-gray-6',
          )}
        >
          <div className='col-span-2'>
            <p className='h5'>{`${DEFAULT_CURRENCY_SYMBOL}${
              isCommunityOwner ? transaction.totalAmount : transaction.amount
            }`}</p>
          </div>
          <div className='col-span-2 flex flex-col justify-center gap-2.5'>
            <p className='body2'>
              {moment.unix(transaction.dueDate).format('MMM D, YYYY')}
            </p>
            {open && (
              <p className='body2 italic text-custom-gray-3'>
                {moment.unix(transaction.dueDate).format('dddd, hh:mm A')}
              </p>
            )}
          </div>
          <div className='col-span-4 flex items-center gap-2'>
            <UserInfo entity={member} />
          </div>
          <div className='col-span-2 flex items-center justify-between'>
            <p
              className={classNames(
                'body2',
                isRefundType && 'text-custom-error-red',
              )}
            >
              {TRANSACTION_TYPE_TEXT[transaction.type]}
            </p>
            <ExpandContractButton hidden={!isCommunityOwner} open={open} />
          </div>
          {isCommunityOwner && (
            <Disclosure.Panel
              as={'div'}
              className='col-span-10 grid grid-cols-10 justify-between'
            >
              <div className='col-span-10 border-[0.5px] my-5' />
              <div className='col-span-2'>
                <FeeDetails
                  productFee={transaction.productFee}
                  providerFee={transaction.providerFee}
                  earned={transaction.earned}
                />
              </div>
              <div className='col-span-2' />
              <div className='col-span-4'>
                <AffiliateFee transaction={transaction} />
              </div>
            </Disclosure.Panel>
          )}
        </div>
      )}
    </Disclosure>
  )
}

export default TransactionItemTableView
