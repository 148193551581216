import moment from 'moment'

export const getCapturedImageFile = async (
  canvas: HTMLCanvasElement,
): Promise<File> => {
  const imageName = moment().format()
  return new Promise(resolve => {
    canvas.toBlob((blob: any) => {
      const file = new File([blob], imageName, {
        type: blob.type,
      })
      resolve(file)
    }, 'image/jpeg')
  })
}
